import { ILaizeOpening } from '../Components/Laizes/LaizeCalc';
import { MapConstants } from '../MapConstants';
import { IRoom, RoomShape } from '../Models/IRoom';
import { IRoomItem } from '../Models/IRoomItem';
import { isObjectsEquals } from './Funcs';
import { PointUtils } from './PointUtils';
import { qSVG } from './qSVG';
import { CreateRoomFactory } from './Room/RoomUtils';
import { CoordPoint, Equation, Wall, WallLine, WallNode, WallPolygon } from './Types';
import { WallUtils } from './Wall/WallUtils';

export class Editor {
    public static wall = (
        start: CoordPoint,
        end: CoordPoint,
        type: string,
        thick: number,
        roomShape?: RoomShape,
        roomId?: string,
        sideName?: string
    ): Wall => {
        return {
            thick: 0.00000001,
            start,
            end,
            type,
            parent: undefined,
            child: undefined,
            angle: 0,
            originalAngle: 0,
            equations: {},
            coords: [],
            backUp: false,
            roomShape,
            roomId,
            sideName,
            coteManuallySetted: false,
        };
    };

    public static architect = (walls: Array<Wall>, rooms: Array<IRoom>) => {
        //todo render
        //$(PlaygroundIds.boxRoom).empty();
        //$(PlaygroundIds.boxSurface).empty();
        //hoverOnPlanElement(false);

        this.wallsComputing(walls);
        const wallPolygons = qSVG.polygonize(walls);
        //before this.roomMaker
        this.setRoomCoordsFromWall(wallPolygons, rooms, walls);
        return true;
    };

    //* Regenere le polygon des murs (graph)
    public static wallsComputing = (walls: Array<Wall>, action?: string) => {
        // IF ACTION == MOVE -> equation2 exist !!!!!
        // if (action != 'move') {
        //$(PlaygroundIds.boxwall).empty();
        // } else {
        //   $('#boxwall').children().addClass('wall-to-remove');
        // }

        // $(PlaygroundIds.boxwall).empty();
        // $(PlaygroundIds.boxArea).empty();

        for (var vertice = 0; vertice < walls.length; vertice++) {
            var wall = walls[vertice];
            if (wall.parent) {
                if (!isObjectsEquals(wall.parent.start, wall.start) && !isObjectsEquals(wall.parent.end, wall.start)) {
                    wall.parent = undefined;
                }
            }
            if (wall.child) {
                if (!isObjectsEquals(wall.child.start, wall.end) && !isObjectsEquals(wall.child.end, wall.end)) {
                    wall.child = undefined;
                }
            }
        }

        let previousWall: Wall;
        let previousWallStart: CoordPoint;
        let previousWallEnd: CoordPoint;
        let nextWall: Wall;
        let nextWallStart: CoordPoint;
        let nextWallEnd: CoordPoint;

        for (let vertice = 0; vertice < walls.length; vertice++) {
            const wall = walls[vertice];
            if (wall.parent) {
                if (isObjectsEquals(wall.parent.start, wall.start)) {
                    previousWall = wall.parent;
                    previousWallStart = previousWall.end;
                    previousWallEnd = previousWall.start;
                }
                if (isObjectsEquals(wall.parent.end, wall.start)) {
                    previousWall = wall.parent;
                    previousWallStart = previousWall.start;
                    previousWallEnd = previousWall.end;
                }
            } else {
                const S: Array<WallNode> = this.getWallNode(walls, wall.start, wall);
                //  if (wallInhibation && isObjectsEquals(wall, wallInhibation)) S = false;
                for (let k in S) {
                    const eqInter = this.createEquationFromWall(S[k].wall);
                    let angleInter = 90; // TO PASS TEST
                    if (action === 'move') {
                        //angleInter = qSVG.angleBetweenEquations(eqInter.A, equation2.A); //TODO OLD
                        angleInter = qSVG.angleBetweenEquations(eqInter.A);
                    }
                    if (S[k].type === 'start' && S[k].wall.parent == null && angleInter > 20 && angleInter < 160) {
                        wall.parent = S[k].wall;
                        S[k].wall.parent = wall;
                        previousWall = wall.parent;
                        previousWallStart = previousWall.end;
                        previousWallEnd = previousWall.start;
                    }
                    if (S[k].type === 'end' && S[k].wall.child == null && angleInter > 20 && angleInter < 160) {
                        wall.parent = S[k].wall;
                        S[k].wall.child = wall;
                        previousWall = wall.parent;
                        previousWallStart = previousWall.start;
                        previousWallEnd = previousWall.end;
                    }
                }
            }

            if (wall.child) {
                if (isObjectsEquals(wall.child.end, wall.end)) {
                    nextWall = wall.child;
                    nextWallStart = nextWall.end;
                    nextWallEnd = nextWall.start;
                } else {
                    nextWall = wall.child;
                    nextWallStart = nextWall.start;
                    nextWallEnd = nextWall.end;
                }
            } else {
                const E: Array<WallNode> = this.getWallNode(walls, wall.end, wall);
                // if (wallInhibation && isObjectsEquals(wall, wallInhibation)) E = false;
                for (let k in E) {
                    const eqInter = this.createEquationFromWall(E[k].wall);
                    let angleInter = 90; // TO PASS TEST
                    if (action === 'move') {
                        // angleInter = qSVG.angleBetweenEquations(eqInter.A, equation2.A); //TODO OLD
                        angleInter = qSVG.angleBetweenEquations(eqInter.A); //TODO FIX ASK @thierry
                    }
                    if (E[k].type === 'end' && E[k].wall.child == null && angleInter > 20 && angleInter < 160) {
                        wall.child = E[k].wall;
                        E[k].wall.child = wall;
                        nextWall = wall.child;
                        nextWallStart = nextWall.end;
                        nextWallEnd = nextWall.start;
                    }
                    if (E[k].type === 'start' && E[k].wall.parent == null && angleInter > 20 && angleInter < 160) {
                        wall.child = E[k].wall;
                        E[k].wall.parent = wall;
                        nextWall = wall.child;
                        nextWallStart = nextWall.start;
                        nextWallEnd = nextWall.end;
                    }
                }
            }

            const angleWall = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
            wall.angle = angleWall;
            const wallThickX = (wall.thick / 2) * Math.sin(angleWall);
            const wallThickY = (wall.thick / 2) * Math.cos(angleWall);
            const eqWallUp = qSVG.createEquation(
                wall.start.x + wallThickX,
                wall.start.y - wallThickY,
                wall.end.x + wallThickX,
                wall.end.y - wallThickY,
            );
            const eqWallDw = qSVG.createEquation(
                wall.start.x - wallThickX,
                wall.start.y + wallThickY,
                wall.end.x - wallThickX,
                wall.end.y + wallThickY
            );
            const eqWallBase = qSVG.createEquation(wall.start.x, wall.start.y, wall.end.x, wall.end.y);
            wall.equations = { up: eqWallUp, down: eqWallDw, base: eqWallBase };
            let dWay;

            // WALL STARTED
            if (!wall.parent) {
                const eqP = qSVG.perpendicularEquation(eqWallUp, wall.start.x, wall.start.y)!;
                const interUp: any = qSVG.intersectionOfEquations(eqWallUp, eqP!, 'object') as Equation;
                const interDw: any = qSVG.intersectionOfEquations(eqWallDw, eqP!, 'object') as Equation;
                wall.coords = [interUp, interDw];
                dWay = 'M' + interUp.x + ',' + interUp.y + ' L' + interDw.x + ',' + interDw.y + ' ';
            } else {
                const eqP = qSVG.perpendicularEquation(eqWallUp, wall.start.x, wall.start.y)!;
                // var previousWall = wall.parent;
                //   var previousWallStart = previousWall.start;
                //   var previousWallEnd = previousWall.end;
                const anglePreviousWall = Math.atan2(
                    previousWallEnd!.y - previousWallStart!.y,
                    previousWallEnd!.x - previousWallStart!.x
                );
                const previousWallThickX = (previousWall!.thick / 2) * Math.sin(anglePreviousWall);
                const previousWallThickY = (previousWall!.thick / 2) * Math.cos(anglePreviousWall);
                const eqPreviousWallUp = qSVG.createEquation(
                    previousWallStart!.x + previousWallThickX,
                    previousWallStart!.y - previousWallThickY,
                    previousWallEnd!.x + previousWallThickX,
                    previousWallEnd!.y - previousWallThickY
                );
                const eqPreviousWallDw = qSVG.createEquation(
                    previousWallStart!.x - previousWallThickX,
                    previousWallStart!.y + previousWallThickY,
                    previousWallEnd!.x - previousWallThickX,
                    previousWallEnd!.y + previousWallThickY
                );
                let interUp: any;
                let interDw: any;

                if (Math.abs(anglePreviousWall - angleWall) > 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqWallUp, eqPreviousWallUp, 'object')!;
                    interDw = qSVG.intersectionOfEquations(eqWallDw, eqPreviousWallDw, 'object')!;

                    if (eqWallUp.A === eqPreviousWallUp.A) {
                        interUp = { x: wall.start.x + wallThickX, y: wall.start.y - wallThickY };
                        interDw = { x: wall.start.x - wallThickX, y: wall.start.y + wallThickY };
                    }

                    const miter = qSVG.gap(interUp, { x: previousWallEnd!.x, y: previousWallEnd!.y });
                    if (miter > 1000) {
                        interUp = qSVG.intersectionOfEquations(eqP, eqWallUp, 'object');
                        interDw = qSVG.intersectionOfEquations(eqP, eqWallDw, 'object');
                    }
                }
                if (Math.abs(anglePreviousWall - angleWall) <= 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqP, eqWallUp, 'object');
                    interDw = qSVG.intersectionOfEquations(eqP, eqWallDw, 'object');
                }
                wall.coords = [interUp, interDw];
                dWay = 'M' + interUp.x + ',' + interUp.y + ' L' + interDw.x + ',' + interDw.y + ' ';
            }

            // WALL FINISHED
            if (!wall.child) {
                const eqP = qSVG.perpendicularEquation(eqWallUp, wall.end.x, wall.end.y);
                const interUp: any = qSVG.intersectionOfEquations(eqWallUp, eqP!, 'object');
                const interDw: any = qSVG.intersectionOfEquations(eqWallDw, eqP!, 'object');
                wall.coords.push(interDw, interUp);
                dWay = dWay + 'L' + interDw.x + ',' + interDw.y + ' L' + interUp.x + ',' + interUp.y + ' Z';
            } else {
                const eqP: any = qSVG.perpendicularEquation(eqWallUp, wall.end.x, wall.end.y);
                // var nextWall = wall.child;
                //   var nextWallStart = nextWall.start;
                //   var nextWallEnd = nextWall.end;
                const angleNextWall = Math.atan2(nextWallEnd!.y - nextWallStart!.y, nextWallEnd!.x - nextWallStart!.x);
                const nextWallThickX = (nextWall!.thick / 2) * Math.sin(angleNextWall);
                const nextWallThickY = (nextWall!.thick / 2) * Math.cos(angleNextWall);
                const eqNextWallUp = qSVG.createEquation(
                    nextWallStart!.x + nextWallThickX,
                    nextWallStart!.y - nextWallThickY,
                    nextWallEnd!.x + nextWallThickX,
                    nextWallEnd!.y - nextWallThickY
                );
                const eqNextWallDw = qSVG.createEquation(
                    nextWallStart!.x - nextWallThickX,
                    nextWallStart!.y + nextWallThickY,
                    nextWallEnd!.x - nextWallThickX,
                    nextWallEnd!.y + nextWallThickY
                );

                let interUp: any;
                let interDw: any;

                if (Math.abs(angleNextWall - angleWall) > 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqWallUp, eqNextWallUp, 'object');
                    interDw = qSVG.intersectionOfEquations(eqWallDw, eqNextWallDw, 'object');

                    if (eqWallUp.A === eqNextWallUp.A) {
                        interUp = { x: wall.end.x + wallThickX, y: wall.end.y - wallThickY };
                        interDw = { x: wall.end.x - wallThickX, y: wall.end.y + wallThickY };
                    }

                    const miter = qSVG.gap(interUp, { x: nextWallStart!.x, y: nextWallStart!.y });
                    if (miter > 1000) {
                        interUp = qSVG.intersectionOfEquations(eqWallUp, eqP, 'object');
                        interDw = qSVG.intersectionOfEquations(eqWallDw, eqP, 'object');
                    }
                }
                if (Math.abs(angleNextWall - angleWall) <= 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqWallUp, eqP, 'object');
                    interDw = qSVG.intersectionOfEquations(eqWallDw, eqP, 'object');
                }

                wall.coords.push(interDw, interUp);
                dWay = dWay + 'L' + interDw.x + ',' + interDw.y + ' L' + interUp.x + ',' + interUp.y + ' Z';
            }

            //TODO render in BoxWall
            // if (wall.roomId) {
            //     console.log('  //TODO render in BoxWall 1');
            //     if (!$('#walls-room-' + wall.roomId).length) {
            //         var isPolygon = wall.roomShape !== 'open_bottom_rectangle';

            //         console.log('  //TODO render in BoxWall 2');

            //         $(PlaygroundIds.boxwall).append(
            //             qSVG.create('not_used', isPolygon ? 'polygon' : 'polyline', {
            //                 id: 'walls-room-' + wall.roomId,
            //                 class: 'room-' + wall.roomId,
            //                 fill: 'none',
            //                 stroke: 'black',
            //                 //style: `stroke:var(--wall-default-color);stroke-width:${MapConstants.wallThickness}`,
            //                 strokeWidth: MapConstants.wallThickness,
            //                 points: wall.start.x + ',' + wall.start.y,
            //                 'start-point': wall.start.x + ',' + wall.start.y,
            //             })
            //         );
            //     }
            // }

            // if (wall.roomId) {
            //     var points = $('#walls-room-' + wall.roomId).attr('points');
            //     var newPoint = wall.end.x + ',' + wall.end.y;
            //     $('#walls-room-' + wall.roomId).attr('points', points + ' ' + newPoint);
            // }
        }

        //* after update walls in state
    };

    // RETURN OBJECTS ARRAY INDEX OF WALLS [WALL1, WALL2, n...] WALLS WITH THIS NODE, EXCEPT PARAM = OBJECT WALL
    public static getWallNode = (walls: Array<Wall>, coords: CoordPoint, except: Wall): Array<WallNode> => {
        const nodes: Array<WallNode> = [];
        for (let k in walls) {
            if (!isObjectsEquals(walls[k], except!)) {
                if (isObjectsEquals(walls[k].start, coords)) {
                    nodes.push({ wall: walls[k], type: 'start' });
                }
                if (isObjectsEquals(walls[k].end, coords)) {
                    nodes.push({ wall: walls[k], type: 'end' });
                }
            }
        }
        return nodes;
    };

    public static testPointBelongToWall = (wall: WallLine, point: CoordPoint) => {
        return this.testPointBelongToLine([wall.start, wall.end], point);
    };

    public static testPointBelongToLine = (line: Array<CoordPoint>, point: CoordPoint) => {
        const eq = qSVG.createEquation(line[0].x, line[0].y, line[1].x, line[1].y);
        const search = qSVG.nearPointOnEquation(eq, point);
        const tolerance_round_value = MapConstants.tolerance_round_value;
        return (
            search.distance < 1.0 &&
            qSVG.btwn(point.x, line[0].x, line[1].x, false, tolerance_round_value) &&
            qSVG.btwn(point.y, line[0].y, line[1].y, false, tolerance_round_value)
        );
    };

    public static createEquationFromWall = (wall: WallLine) => {
        return qSVG.createEquation(wall.start.x, wall.start.y, wall.end.x, wall.end.y);
    };

    //rooms is current rooms in context, walls is current walls in context,
    // public static roomMaker = (roomsPolygons: Array<WallPolygon>, rooms: Array<IRoom>, walls: Array<Wall>) => {
    //     let globalArea = 0;
    //     var oldVertexNumber = [];

    //     if (roomsPolygons.length === 0) {
    //         rooms = [];
    //     }
    //     for (let pp = 0; pp < roomsPolygons.length; pp++) {
    //         const polygon = roomsPolygons[pp];

    //         var foundRoom = false;
    //         for (let rr = 0; rr < rooms.length; rr++) {
    //             const room = rooms[rr];

    //             var countCoords = polygon.coords.length;
    //             var diffCoords = qSVG.diffObjIntoArray(polygon.coords, room.coords);

    //             if (polygon.way.length === room.way?.length) {
    //                 if (qSVG.diffArray(polygon.way, room.way!).length === 0 || diffCoords === 0) {
    //                     countCoords = 0;
    //                 }
    //             }

    //             if (polygon.way.length === (room.way?.length || 0) + 1) {
    //                 if (qSVG.diffArray(polygon.way, room.way!).length === 1 || diffCoords === 2) {
    //                     countCoords = 0;
    //                 }
    //             }

    //             if (polygon.way.length === (room.way?.length || 0) - 1) {
    //                 if (qSVG.diffArray(polygon.way, room.way!).length === 1) {
    //                     countCoords = 0;
    //                 }
    //             }
    //             if (countCoords === 0) {
    //                 foundRoom = true;
    //                 room.area = polygon.area;
    //                 room.inside = polygon.inside;
    //                 room.coords = polygon.coords;
    //                 room.coordsOutside = polygon.coordsOutside;
    //                 room.way = polygon.way;
    //                 room.coordsInside = polygon.coordsInside;
    //                 break;
    //             }
    //         }
    //         if (!foundRoom) {
    //             const foundedRoom = rooms.find((x) => x.roomId === polygon.roomId);
    //             if (foundedRoom) {
    //                 rooms.push({
    //                     ...foundedRoom, //todo no sure
    //                     coords: polygon.coords,
    //                     coordsOutside: polygon.coordsOutside,
    //                     coordsInside: polygon.coordsInside,
    //                     inside: polygon.inside,
    //                     way: polygon.way,
    //                     area: polygon.area,
    //                     name:
    //                         Boolean(foundedRoom) && Boolean(foundedRoom.name)
    //                             ? rooms.find((x) => x.roomId === polygon.roomId)?.name
    //                             : '',
    //                     //color: 'gradientWhite',
    //                     //surface: '',
    //                     //showSurface: true,
    //                     //action: 'add',
    //                     //roomShape: polygon.roomShape,
    //                     //roomId: polygon.roomId,
    //                     //collisions: undefined,
    //                     //_walls: undefined,
    //                     //setWall: setRoomWall,
    //                 });
    //             }
    //         }
    //     }

    //     let toSplice = [];
    //     for (let rr = 0; rr < rooms.length; rr++) {
    //         let found = true;
    //         const room = rooms[rr];
    //         for (let pp = 0; pp < roomsPolygons.length; pp++) {
    //             const polygon = roomsPolygons[pp];
    //             let countRoom = room.coords?.length;
    //             const diffCoords = qSVG.diffObjIntoArray(polygon.coords, room.coords);

    //             if (polygon.way.length === room.way?.length) {
    //                 if (qSVG.diffArray(polygon.way, room.way!).length === 0 || diffCoords === 0) {
    //                     countRoom = 0;
    //                 }
    //             }

    //             if (polygon.way.length === (room.way?.length || 0) + 1) {
    //                 if (qSVG.diffArray(polygon.way, room.way!).length === 1 || diffCoords === 2) {
    //                     countRoom = 0;
    //                 }
    //             }

    //             if (polygon.way.length === (room.way?.length || 0) - 1) {
    //                 if (qSVG.diffArray(polygon.way, room.way!).length === 1) {
    //                     countRoom = 0;
    //                 }
    //             }
    //             if (countRoom === 0) {
    //                 found = true;
    //                 break;
    //             } else {
    //                 found = false;
    //             }
    //         }
    //         if (!found) {
    //             toSplice.push(rr);
    //         }
    //         if (!room.walls) {
    //             room.walls = walls.filter((x) => x.roomId === room.roomId);
    //         }
    //     }

    //     toSplice.sort((a, b) => b - a);
    //     for (var ss = 0; ss < toSplice.length; ss++) {
    //         rooms.splice(toSplice[ss], 1);
    //     }

    //     //todo: render in components
    //     // $(PlaygroundIds.boxRoom).empty();
    //     // $(PlaygroundIds.boxSurface).empty();
    //     // $(PlaygroundIds.boxArea).empty();
    //     //hoverOnPlanElement(false);

    //     //todo render in components
    //     for (let rr = 0; rr < rooms.length; rr++) {
    //         globalArea = globalArea + rooms[rr].area!;

    //         // if (rooms[rr].action === 'add') {
    //         //     globalArea = globalArea + rooms[rr].area!;
    //         // }

    //         // const pathSurface = rooms[rr].coords || [];
    //         // let pathCreate = 'M' + pathSurface[0].x + ',' + pathSurface[0].y;
    //         // for (var p = 1; p < pathSurface.length; p++) {
    //         //     pathCreate = pathCreate + ' ' + 'L' + pathSurface[p].x + ',' + pathSurface[p].y;
    //         // }
    //         // if (rooms[rr].inside.length > 0) {
    //         //     for (var ins = 0; ins < rooms[rr].inside.length; ins++) {
    //         //         pathCreate =
    //         //             pathCreate +
    //         //             ' M' +
    //         //             polygons[rooms[rr].inside[ins]].coords[
    //         //                 polygons[rooms[rr].inside[ins]].coords.length - 1
    //         //             ].x +
    //         //             ',' +
    //         //             polygons[rooms[rr].inside[ins]].coords[
    //         //                 polygons[rooms[rr].inside[ins]].coords.length - 1
    //         //             ].y;
    //         //         for (var free = polygons[rooms[rr].inside[ins]].coords.length - 2; free > -1; free--) {
    //         //             pathCreate = pathCreate +
    //         //                 ' L' +
    //         //                 polygons[rooms[rr].inside[ins]].coords[free].x +
    //         //                 ',' +
    //         //                 polygons[rooms[rr].inside[ins]].coords[free].y;
    //         //         }
    //         //     }
    //         // }

    //         // qSVG.create('boxRoom', 'path', {
    //         //     d: pathCreate,
    //         //     fill: 'url(#' + rooms[rr].color + ')',
    //         //     'fill-opacity': 0,
    //         //     stroke: 'none',
    //         //     'fill-rule': 'evenodd',
    //         //     class: 'room-' + rooms[rr].roomId,
    //         // });

    //         // qSVG.create('boxSurface', 'path', {
    //         //     d: pathCreate,
    //         //     fill: 'var(--room-background-color)',
    //         //     'fill-opacity': 0.3,
    //         //     stroke: 'none',
    //         //     'fill-rule': 'evenodd',
    //         //     class: 'room-' + rooms[rr].roomId,
    //         // });
    //         //* for qSVG.create('boxRoom', 'path', {
    //         // 'fill-opacity': 0, stroke: 'none', 'fill-rule': 'evenodd', class: 'room' + (MapConstants.ROOMS[rr].walls !== undefined ? ' room-' + MapConstants.ROOMS[rr].walls[0].roomId : '')});

    //         //*    qSVG.create('boxSurface', 'path', {
    //         // fill: '#2f3136', 'fill-opacity': 0.3, stroke: 'none', 'fill-rule': 'evenodd', class: 'room' + (MapConstants.ROOMS[rr].walls !== undefined ? ' room-' + MapConstants.ROOMS[rr].walls[0].roomId : '')});

    //         // var centroid = qSVG.polygonVisualCenter(MapConstants.ROOMS[rr]);
    //         // const polylabel = getPolylabel();
    //         // console.log('polylabel', { polylabel, window });
    //         // let centroid = polylabel([rooms[rr].coords?.map((p) => [p.x, p.y])], 1.0); //qSVG.polygonVisualCenter(MapConstants.ROOMS[rr]);
    //         // centroid = { x: centroid[0], y: centroid[1] };

    //         // if (centroid) {
    //         //     const bbox: any = PointUtils.calculateBoundingBox(walls, roomItems, rooms[rr].coords);
    //         //     var width = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMax, y: bbox.yMin });
    //         //     var height = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMin, y: bbox.yMax });
    //         //     var minSize = (Math.min(width, height) * 100) / 60;
    //         //     var scale = minSize < 60 ? 0.2 : minSize < 120 ? 0.3 : minSize < 180 ? 0.5 : minSize < 220 ? 0.7 : 1;

    //         //     var roomInfoWrapper = qSVG.create('none', 'g', {
    //         //         //moved with moveroom() method
    //         //         class: 'room-' + rooms[rr].roomId,
    //         //     });

    //         //     var roomInfoSubWrapper = qSVG.create('none', 'g', {
    //         //         //apply transform translate & scale
    //         //         transform: 'translate(' + centroid.x + ',' + centroid.y + ') scale(' + scale + ')',
    //         //     });

    //         //     var elementPos = { x: 0, y: 0 };

    //         //     if (rooms[rr].name !== '') {
    //         //         const styled = { color: '#343938', fontSize: '16px', fontFamily: 'var(--fontFamilyAvenir)' };
    //         //         if (rooms[rr].color === 'gradientBlack' || rooms[rr].color === 'gradientBlue') styled.color = 'white';
    //         //         qSVG.textOnDiv(
    //         //             similyStringEllipsis(rooms[rr].name, 20),
    //         //             elementPos,
    //         //             styled,
    //         //             'nameroom-' + rooms[rr].roomId,
    //         //             roomInfoSubWrapper
    //         //         );
    //         //     }

    //         //     if (rooms[rr].name !== '') elementPos.y = elementPos.y + 20;
    //         //     const meter = MapConstants.meter;
    //         //     let area = Boolean(roomItems) && roomItems.length > 0
    //         //         ? (rooms[rr].area! / (meter * meter)).toFixed(2) +
    //         //         ' m² / ' +
    //         //         (
    //         //             (Boolean(rooms[rr].areaWithoutRoomItems) ? rooms[rr].areaWithoutRoomItems : rooms[rr].area) /
    //         //             (meter * meter)
    //         //         ).toFixed(2) +
    //         //         'm² utilisables'
    //         //         : (rooms[rr].area! / (meter * meter)).toFixed(2) + ' m²';

    //         //     const styled: CSSProperties = {
    //         //         color: '#343938',
    //         //         fontSize: '16px',
    //         //         fontWeight: 'normal',
    //         //         fontFamily: 'var(--fontFamilyLato)',
    //         //     };
    //         //     if (rooms[rr].surface !== '') {
    //         //         styled.fontWeight = 'bold';
    //         //         area = rooms[rr].surface + ' m²';
    //         //     }
    //         //     if (rooms[rr].color === 'gradientBlack' || rooms[rr].color === 'gradientBlue') styled.color = 'white';
    //         //     if (rooms[rr].showSurface)
    //         //         qSVG.textOnDiv(area, elementPos, styled, 'surfaceroom-' + rooms[rr].roomId, roomInfoSubWrapper);

    //         //     elementPos.y += 20;

    //         //     var iconWrapper = qSVG.create('none', 'g', {
    //         //         transform: 'translate(' + (elementPos.x - MapConstants.picto_size / 2) + ',' + elementPos.y + ')',
    //         //         class: 'ContextMenuIcon ContextMenuRoom' + rooms[rr].roomId,
    //         //         style: 'cursor: pointer',
    //         //     });
    //         //     const backgroundSize = 23;

    //         //     const roomForIcon = rooms[rr];
    //         //     const roomId = `${roomForIcon.roomId}`;

    //         //     var editIconBackground = qSVG.create('none', 'circle', {
    //         //         fill: '#ffffff',
    //         //         cx: backgroundSize / 2,
    //         //         cy: backgroundSize / 2,
    //         //         r: backgroundSize,
    //         //         class: 'ContextMenuIconBackground',
    //         //         'data-id': roomId, //todo @thierry : #22117 @thierry à voir avec le binder quand tu auras le temps
    //         //     });

    //         //     var editIcon = $('#edit-picto').clone();
    //         //     editIcon.attr('class', 'edit-picto');
    //         //     editIcon.removeAttr('id');

    //         //     iconWrapper.append(editIconBackground);
    //         //     iconWrapper.append(editIcon);
    //         //     roomInfoSubWrapper.append(iconWrapper);
    //         //     roomInfoWrapper.append(roomInfoSubWrapper);
    //         //     $(PlaygroundIds.boxArea).append(roomInfoWrapper);
    //         // }
    //     }

    //     //todo: not sure if necessary again
    //     if (globalArea <= 0) {
    //         globalArea = 0;
    //         $('#areaValue').html('');
    //     } else {
    //         $('#areaValue').html(
    //             '<i class="fa fa-map-o" aria-hidden="true"></i> ' + (globalArea / 3600).toFixed(1) + ' m²'
    //         );
    //     }
    //     //updateRooms(); //todo review
    // };

    public static setRoomCoordsFromWall = (
        roomsPolygons: Array<WallPolygon>,
        rooms: Array<IRoom>,
        walls: Array<Wall>
    ) => {
        roomsPolygons.forEach((polygon, index) => {
            const room = rooms.find((r) => r.roomId === polygon.roomId);
            if (room) {
                room.area = polygon.area;
                room.inside = polygon.inside;
                room.coords = polygon.coords;
                room.coordsOutside = polygon.coordsOutside;
                room.way = polygon.way;
                room.coordsInside = polygon.coordsInside;
                room.walls = walls.filter((x) => x.roomId === room.roomId);
                room.sides = CreateRoomFactory.toRoomSides(room);
                room.sidesObject = Object.fromEntries(room.sides);
            }
        });
    };

    // RETURN OBJDATA INDEX LIST FROM AN WALL
    public static objFromWall = (wall: WallLine, openings: Array<ILaizeOpening>) => {
        const wallOpenings: Array<ILaizeOpening> = [];
        for (let scan = 0; scan < openings.length; scan++) {
            const opening: ILaizeOpening = openings[scan];
            //if (opening.family === 'inWall') {
            if (this.testPointBelongToWall(wall, opening as CoordPoint)) {
                wallOpenings.push(opening);
            }
            // WARNING 0.01 TO NO COUNT OBJECT ON LIMITS OF THE EDGE !!!!!!!!!!!! UGLY CODE( MOUSE PRECISION)
            // TRY WITH ANGLE MAYBE ???
            //}
        }
        return wallOpenings;
    };

    // RETURN OBJLIST WITH ROOM FILTER
    //todo review this: urgent
    public static objFromWallRoom = (wall: WallLine, currentOpenings: Array<ILaizeOpening>) => {
        //return this.objFromWall(wall, currentOpenings).filter((x) => x.graph.parent().attr('class') === 'room-' + wall.roomId);
        return this.objFromWall(wall, currentOpenings).filter((x) => x.roomId === wall.roomId);
    };

    // value can be "text label", "step number in stair", etc...
    // move to Obj2D file
    // public static obj2D = (
    //     family: string,
    //     classe: string,
    //     type: string,
    //     pos: any,
    //     angle: number,
    //     angleSign: number,
    //     size: number,
    //     hinge = 'normal',
    //     thick: number,
    //     value?: number,
    //     name?: string,
    //     id?: string,
    // ) => {

    //     const obj2D: any = {
    //         id: id ?? IdGenerator.generate(),
    //         family, // inWall, stick, collision, free
    //         class: classe, // door, window, energy, stair, measure, text ?
    //         type, // simple, double, simpleSlide, aperture, doubleSlide, fixed, switch, lamp....

    //         x: pos.x,
    //         y: pos.y,
    //         angle,
    //         angleSign,
    //         limit: [],
    //         hinge, // normal, reverse
    //         graph: qSVG.create('none', 'g'),
    //         scale: { x: 1, y: 1 },
    //         value,
    //         size,
    //         thick,
    //         width: (size / MapConstants.meter).toFixed(2),
    //         height: (thick / MapConstants.meter).toFixed(2),
    //     };

    //     if (classe === 'socle') {
    //         obj2D.type = pos.type;
    //         type = pos.type;
    //     }

    //     obj2D.name = name ?? OpeningUtils.defaultName(type);

    //     var cc = CarpentryUtils.carpentryCalc(classe, type, size, thick, value);
    //     let blank;

    //     for (let tt = 0; tt < cc.length; tt++) {
    //         if (cc[tt].path) {
    //             blank = qSVG.create('none', 'path', {
    //                 d: cc[tt].path,
    //                 'stroke-width': 1,
    //                 fill: cc[tt].fill,
    //                 stroke: cc[tt].stroke,
    //                 'stroke-dasharray': cc[tt].strokeDashArray,
    //             });
    //         }
    //         if (cc[tt].picto) {
    //             blank = qSVG.create('none', 'use', {
    //                 fill: cc[tt].fill,
    //                 href: cc[tt].href,
    //             });
    //         }
    //         if (cc[tt].line) {
    //             blank = qSVG.create('none', 'line', {
    //                 x1: cc[tt].x1,
    //                 y1: cc[tt].y1,
    //                 x2: cc[tt].x2,
    //                 y2: cc[tt].y2,
    //                 style: cc[tt].style,
    //                 'stroke-linecap': cc[tt].strokeLinecap,
    //             });
    //         }
    //         if (cc[tt].circle) {
    //             blank = qSVG.create('none', 'circle', {
    //                 cx: cc[tt].cx,
    //                 cy: cc[tt].cy,
    //                 r: cc[tt].r,
    //                 fill: cc[tt].fill,
    //                 style: cc[tt].style,
    //             });
    //         }
    //         if (cc[tt].text) {
    //             blank = qSVG.create('none', 'text', {
    //                 x: cc[tt].x,
    //                 y: cc[tt].y,
    //                 'font-size': cc[tt].fontSize,
    //                 stroke: cc[tt].stroke,
    //                 'stroke-width': cc[tt].strokeWidth,
    //                 'font-family': 'roboto',
    //                 'text-anchor': 'middle',
    //                 fill: cc[tt].fill,
    //             });
    //             blank.context.textContent = cc[tt].text;
    //         }
    //         obj2D.graph.append(blank);
    //     } // ENDFOR

    //     const offset = MapConstants.offset();
    //     var bbox = obj2D.graph.get(0).getBoundingClientRect();
    //     bbox.x = bbox.x * MapConstants.factor - offset.left * MapConstants.factor + MapConstants.originX_viewbox;
    //     bbox.y = bbox.y * MapConstants.factor - offset.top * MapConstants.factor + MapConstants.originY_viewbox;
    //     bbox.origin = { x: obj2D.x, y: obj2D.y };
    //     obj2D.bbox = bbox;
    //     // this.realBbox = [{x: -this.size/2, y: -this.thick/2}, {x: this.size/2, y: -this.thick/2},{x: this.size/2, y: this.thick/2},{x: -this.size/2, y: this.thick/2}];// original bbox (rectangle shape)

    //     const picto_circle_size = MapConstants.picto_circle_size;
    //     obj2D.realBbox = [
    //         // custom bbox (rectangle shape + diamond shape (for the circle icon))
    //         { x: -obj2D.size / 2, y: -obj2D.thick / 2 },
    //         { x: -picto_circle_size, y: -obj2D.thick / 2 },
    //         { x: 0, y: -picto_circle_size },
    //         { x: picto_circle_size, y: -obj2D.thick / 2 },
    //         { x: obj2D.size / 2, y: -obj2D.thick / 2 },
    //         { x: obj2D.size / 2, y: obj2D.thick / 2 },
    //         { x: picto_circle_size, y: obj2D.thick / 2 },
    //         { x: 0, y: picto_circle_size },
    //         { x: -picto_circle_size, y: obj2D.thick / 2 },
    //         { x: -obj2D.size / 2, y: obj2D.thick / 2 },
    //     ];

    //     if (family === 'byObject') obj2D.family = cc.family;
    //     obj2D.params = cc.params; // (bindBox, move, resize, rotate)
    //     cc.params.width ? (obj2D.size = cc.params.width) : (obj2D.size = size);
    //     cc.params.height ? (obj2D.thick = cc.params.height) : (obj2D.thick = thick);

    //     obj2D.update = (updateType: string) => {
    //         obj2D.width = (obj2D.size / MapConstants.meter).toFixed(2);
    //         obj2D.height = (obj2D.thick / MapConstants.meter).toFixed(2);
    //         if (classe === 'socle' && obj2D.obj) {
    //             cc = CarpentryUtils.carpentryCalc(obj2D.class, obj2D.obj.type, obj2D.size, obj2D.thick, obj2D.value);
    //         } else {
    //             cc = CarpentryUtils.carpentryCalc(obj2D.class, obj2D.type, obj2D.size, obj2D.thick, obj2D.value);
    //         }
    //         let ti = 0;
    //         for (let tt = 0; tt < cc.length; tt++) {
    //             if (cc[tt].path) {
    //                 obj2D.graph.find('path')[tt].setAttribute('d', cc[tt].path);
    //             } else if (cc[tt].x1 && cc[tt].y1 && cc[tt].x2 && cc[tt].y2 && obj2D.graph.find('line').length > ti) {
    //                 obj2D.graph.find('line')[ti].setAttribute('x1', cc[tt].x1);
    //                 obj2D.graph.find('line')[ti].setAttribute('y1', cc[tt].y1);
    //                 obj2D.graph.find('line')[ti].setAttribute('x2', cc[tt].x2);
    //                 obj2D.graph.find('line')[ti].setAttribute('y2', cc[tt].y2);
    //                 ti++;
    //             }
    //         }
    //         if (updateType) {
    //             obj2D.graph.find('use').remove();
    //             obj2D.graph.append(
    //                 qSVG.create('none', 'use', {
    //                     fill: cc.find((x: any) => x.picto).fill,
    //                     href: cc.find((x: any) => x.picto).href,
    //                 })
    //             );
    //         }
    //         const hingeStatus = obj2D.hinge; // normal - reverse
    //         let hingeUpdate;
    //         if (hingeStatus === 'normal') hingeUpdate = 1;
    //         else hingeUpdate = -1;
    //         obj2D.graph.attr({ transform: 'translate(' + obj2D.x + ',' + obj2D.y + ') scale(' + hingeUpdate + ', 1)' });
    //         obj2D.graph.children().each((index: any, child: any) => {
    //             if (child.tagName !== 'use') {
    //                 child.setAttribute('transform', 'rotate(' + obj2D.angle + ',0,0)');
    //             } else if (child.getAttribute('href') === "#front-door-picto") {
    //                 child.setAttribute('transform', 'rotate(0,0,0), scale(0.75), translate(-18.65, -17)');
    //             } else {
    //                 child.setAttribute('transform', 'rotate(0,0,0), scale(0.45), translate(-26, -26)');
    //             }
    //         });
    //         var bbox = obj2D.graph.get(0).getBoundingClientRect();
    //         bbox.x = bbox.x * MapConstants.factor - offset.left * MapConstants.factor + MapConstants.originX_viewbox;
    //         bbox.y = bbox.y * MapConstants.factor - offset.top * MapConstants.factor + MapConstants.originY_viewbox;
    //         bbox.origin = { x: obj2D.x, y: obj2D.y };
    //         obj2D.bbox = bbox;

    //         if (obj2D.class === 'text' && obj2D.angle === 0) {
    //             obj2D.realBbox = [
    //                 { x: obj2D.bbox.x, y: obj2D.bbox.y },
    //                 { x: obj2D.bbox.x + obj2D.bbox.width, y: obj2D.bbox.y },
    //                 { x: obj2D.bbox.x + obj2D.bbox.width, y: obj2D.bbox.y + obj2D.bbox.height },
    //                 { x: obj2D.bbox.x, y: obj2D.bbox.y + obj2D.bbox.height },
    //             ];
    //             obj2D.size = obj2D.bbox.width;
    //             obj2D.thick = obj2D.bbox.height;
    //         }

    //         var angleRadian = -obj2D.angle * (Math.PI / 180);
    //         // this.realBbox = [{x: -this.size/2, y: -this.thick/2}, {x: this.size/2, y: -this.thick/2},{x: this.size/2, y: this.thick/2},{x: -this.size/2, y: this.thick/2}];// original bbox (rectangle shape)
    //         obj2D.realBbox = [
    //             // custom bbox (rectangle shape + diamond shape (for the circle icon))
    //             { x: -obj2D.size / 2, y: -obj2D.thick / 2 },
    //             { x: -picto_circle_size, y: -obj2D.thick / 2 },
    //             { x: 0, y: -picto_circle_size },
    //             { x: picto_circle_size, y: -obj2D.thick / 2 },
    //             { x: obj2D.size / 2, y: -obj2D.thick / 2 },
    //             { x: obj2D.size / 2, y: obj2D.thick / 2 },
    //             { x: picto_circle_size, y: obj2D.thick / 2 },
    //             { x: 0, y: picto_circle_size },
    //             { x: -picto_circle_size, y: obj2D.thick / 2 },
    //             { x: -obj2D.size / 2, y: obj2D.thick / 2 },
    //         ];
    //         obj2D.realBbox = obj2D.realBbox.map((p: CoordPoint) => {
    //             //rotate & move
    //             return {
    //                 x: p.y * Math.sin(angleRadian) + p.x * Math.cos(angleRadian) + obj2D.x,
    //                 y: p.y * Math.cos(angleRadian) - p.x * Math.sin(angleRadian) + obj2D.y,
    //             };
    //         });
    //         return true;
    //     };

    //     return obj2D;
    // }

    /** Calculates the overlap distance of the two given lines (line = array of two points) */
    public static calcLineOverlapping = (lineA: Array<CoordPoint>, lineB: Array<CoordPoint>) => {
        const lineApointsOnLineB = [];
        for (const point of lineA) {
            if (this.testPointBelongToLine(lineB, point)) {
                lineApointsOnLineB.push(point);
            }
        }

        const lineBpointsOnLineA = [];
        for (const point of lineB) {
            if (this.testPointBelongToLine(lineA, point)) {
                lineBpointsOnLineA.push(point);
            }
        }

        if (lineApointsOnLineB.length === 2) {
            return qSVG.measure(lineA[1], lineA[0]);
        }

        if (lineBpointsOnLineA.length === 2) {
            return qSVG.measure(lineB[0], lineB[1]);
        }

        if (lineApointsOnLineB.length === 1 && lineBpointsOnLineA.length === 1) {
            return qSVG.measure(lineApointsOnLineB[0], lineBpointsOnLineA[0]);
        }
        return 0;
    };

    /**
     * Returns one of the given poly's side as a line (line = array of two points).
     */
    public static getLineFromPoly = (poly: Array<CoordPoint> = [], lineStartPointIndex: number) => {
        return [poly[lineStartPointIndex % (poly.length - 1)], poly[(lineStartPointIndex + 1) % (poly.length - 1)]];
    };

    public static rayCastingRoom = (point: CoordPoint, rooms: Array<IRoom>) => {
        // let x = point.x;
        // let y = point.y;

        let roomGroup = [];
        for (let polygon = 0; polygon < rooms.length; polygon++) {
            const inside = qSVG.rayCasting(point, rooms[polygon].coords);
            if (inside) {
                roomGroup.push(polygon);
            }
        }
        if (roomGroup.length > 0) {
            let bestArea = rooms[roomGroup[0]].area;
            let roomTarget;
            for (let siz = 0; siz < roomGroup.length; siz++) {
                if (rooms[roomGroup[siz]].area! <= bestArea!) {
                    bestArea = rooms[roomGroup[siz]].area;
                    roomTarget = rooms[roomGroup[siz]];
                }
            }
            return roomTarget;
        } else {
            return undefined;
        }
    };

    public static rayCastingRoomItem = (point: CoordPoint, roomItems: Array<IRoomItem>) => {
        // let x = point.x;
        // let y = point.y;
        let roomItemGroup = [];
        for (let polygon = 0; polygon < roomItems.length; polygon++) {
            const inside = qSVG.rayCasting(point, roomItems[polygon].coordsReal);
            if (inside) {
                roomItemGroup.push(polygon);
            }
        }
        if (roomItemGroup.length > 0) {
            // var bestArea = ROOM[roomGroup[0]].area;
            // var roomTarget;
            // for (var siz = 0; siz < roomGroup.length; siz++) {
            //   if (ROOM[roomGroup[siz]].area <= bestArea) {
            //     bestArea = ROOM[roomGroup[siz]].area;
            //     roomTarget = ROOM[roomGroup[siz]];
            //   }
            // }
            return roomItems[roomItemGroup[0]];
        } else {
            return undefined;
        }
    };

    public static testIfPolygonCrossesDiagonalWall = (poly: Array<CoordPoint>, walls: Array<Wall>) => {
        const diagonalWalls = walls.filter((x) => !WallUtils.isWallHorV(x));
        for (const diagonalWall of diagonalWalls) {
            for (let i = 0; i < poly.length; i++) {
                const side = this.getLineFromPoly(poly, i);
                if (
                    PointUtils.intersect(
                        { p1: diagonalWall.start, p2: diagonalWall.end },
                        { p1: side[0], p2: side[1] }
                    ) !== false
                ) {
                    return true;
                }
            }
        }
        return false;
    };
}
