/**
 * Helper function to generate a base64 image of a custom size from a SVGDataURL.
 * @param SVGDataURL
 * @returns Promise
 */
export class ExportMapImageHelper {
    public static exportMapPng(SVGDataURL: string) {
        const quality = 1;
        const mimeType = 'image/png';
        const newWidth = 1080;

        return new Promise<string>((resolve, reject) => {
            // 1. Create an abstract canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;

            // 2. Create an image element to load the SVG
            const img = new Image();
            img.crossOrigin = 'anonymous';

            img.onload = () => {
                // Declare initial dimensions of the image
                let originalWidth = img.width;
                let originalHeight = img.height;

                // Declare the new width of the image
                // And calculate the new height to preserve the aspect ratio
                img.width = newWidth;
                img.height = (originalHeight / originalWidth) * newWidth;

                // Set the dimensions of the canvas to the new dimensions of the image
                canvas.width = img.width;
                canvas.height = img.height;

                // Render image in Canvas
                ctx.drawImage(img, 0, 0, img.width, img.height);

                resolve(canvas.toDataURL(mimeType, quality));
            };

            //* Load the DataURL of the SVG
            if (SVGDataURL) {
                img.src = SVGDataURL;
            } else {
                resolve('');
            }
        });
    }

    //* old name exportSvgDebugLaizeBandOpenLink
    public static openLink = (title: string = '', imageSrc: string) => {
        const image = new Image();
        image.src = imageSrc;
        const w = window.open('about:blank', '_blank');
        if (w) {
            w.document.write(image.outerHTML);
            w.document.title = title;
            w.stop();
        }
    };
}
