import { styled } from '@material-ui/styles';
import React from 'react';

import { GestureEvent } from '../../../../../Events/withEvent';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import {
    InitialGestureContextState as GestureContextInitialState,
    useGestureContext,
    useMultiTouch,
} from '../../../Context/GestureContext';
import { useMapContextState, useMapProvider } from '../../../Context/MapContext';
import { useMapViewerContext } from '../../../Context/MapViewerContext';
import { MapConstants, MapMode } from '../../../MapConstants';
import { IRoom, RoomShape } from '../../../Models/IRoom';
import { EventUtils } from '../../../Utils/EventUtils';
import { RoomMove, useRoomMove } from '../../../Utils/Room/RoomMove';
import { CoordPoint } from '../../../Utils/Types';

type BoxWallsProps = { readonly?: boolean; roomId?: string; rooms?: Array<IRoom>; selectedRoomId?: string };
export const MapBoxWalls = ({ readonly, roomId }: BoxWallsProps) => {
    const { rooms = [], selectedRoomId } = useMapContextState();
    return <RawBoxWalls roomId={roomId} rooms={rooms} readonly={readonly} selectedRoomId={selectedRoomId} />;
};

export const RawBoxWalls = ({ readonly, roomId, rooms = [], selectedRoomId }: BoxWallsProps) => {
    return (
        <React.Fragment>
            {rooms
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .map((room) => (
                    <BoxWall
                        key={`BoxWall-${room.roomId}`}
                        room={room}
                        selected={room.roomId === selectedRoomId}
                        readonly={readonly}
                    />
                ))}
        </React.Fragment>
    );
};

type BoxWallProps = { room: IRoom; selected?: boolean; readonly?: boolean };

const formatPoint = (coord: CoordPoint) => `${coord.x},${coord.y}`;

export const BoxWall = ({ room, selected, readonly }: BoxWallProps) => {
    const GESTURE = useBoxWallsGesture(room);

    const allPointsPoints = room.walls?.map((w) => formatPoint(w.start)).join(' ');
    const wall = room.walls![0]!;
    const points = allPointsPoints + ' ' + formatPoint(wall.start);

    const props: React.SVGProps<SVGPolygonElement> = {
        id: RoomMove.roomWallsId(wall.roomId!),
        className: RoomMove.class(wall.roomId!),
        fill: readonly ? '#FFFFFF' : selected ? '#131A3A' : 'rgba(19, 26, 58, 0.15)',
        stroke: selected ? '#131A3A' : '#13123A',
        strokeWidth: MapConstants.wallThickness,
        points,
        //@ts-ignore
        'start-point': formatPoint(wall.start),
        pointerEvents: 'auto',
    };

    return (
        <WallSvg
            isPolygon={room.shape !== RoomShape.Open_bottom_rectangle}
            {...props}
            onMouseDown={GESTURE.onPressDown}
            onTouchStart={GESTURE.onPressDown}
            onMouseUp={GESTURE.onMouseUp}
            onTouchEnd={GESTURE.onTouchEnd}
        />
    );
};

export type SvgGraphProps = React.SVGProps<SVGPolygonElement | SVGPolylineElement> & { isPolygon: boolean };
const WallSvg = ({ isPolygon, ...props }: SvgGraphProps) => {
    return isPolygon ? <WallPolygon {...props} style={{ cursor: 'pointer' }} /> : <WallPolyline {...props} />;
};

//* HOVER COLOR fill: '#18335e'
const WallPolygon = styled('polygon')({ '&:hover': {} });
const WallPolyline = styled('polyline')({ '&:hover': {} });

const useBoxWallsGesture = (room: IRoom) => {
    const isMobile = useIsMobile();
    const map = useMapProvider();
    const roomGesture = useRoomMove();
    const mapViewer = useMapViewerContext();
    const gestureContext = useGestureContext();

    const multiTouch = useMultiTouch();

    const onPressDown = (pressDownEvent: GestureEvent) => {
        if (!multiTouch.handleMultiTouch(pressDownEvent as React.TouchEvent)) {
            const { roomMoveInit } = map.getState();

            if (MapConstants.mode === undefined && !roomMoveInit) {
                gestureContext.update({ isPressDown: true });
                const snap = EventUtils.calculSnapFromSyntheticEvent(
                    pressDownEvent,
                    MapConstants.grid_snap,
                    mapViewer.value!,
                    (mapViewer.svgViewer as any)?.ViewerDOM
                );
                const { rooms = [] } = map.getState();
                map.update({
                    values: {
                        roomMove: room.roomId,
                        roomMoveInit: roomGesture.pressDownRoom(snap, rooms.find((r) => r.roomId === room.roomId)!),
                        panZoomDisabled: true,
                    },
                    logs: { event: 'useBoxWallsGesture onPressDown' },
                });
                MapConstants.mode = MapMode.MODE_TYPE_MOVE_ROOM;
            }
        }
    };

    const onMouseUp = () => {
        const { isPressDown, menuContextOpenRoomId } = gestureContext.getState();
        if (
            !isMobile &&
            isPressDown &&
            menuContextOpenRoomId !== room.roomId &&
            MapConstants.mode === MapMode.MODE_TYPE_MOVE_ROOM
        ) {
            gestureContext.update({ ...GestureContextInitialState, menuContextOpenRoomId: room.roomId });
            if (map.getState().selectedRoomId !== room.roomId && !isMobile) {
                map.setPanel({ values: { selectedRoomId: room.roomId, panZoomDisabled: false, busy: false } });
            }
            MapConstants.mode = undefined;
        }
    };

    const onTouchEnd = React.useCallback(
        (event: GestureEvent) => {
            const isPressDown = gestureContext.state.isPressDown;
            if (
                isPressDown &&
                MapConstants.mode !== MapMode.MODE_TYPE_OPENING_PLACING &&
                MapConstants.mode !== MapMode.MODE_TYPE_ROOM_ITEM_PLACING
            ) {
                if (gestureContext.state.menuContextOpenRoomId !== room.roomId) {
                    gestureContext.update({ ...GestureContextInitialState, menuContextOpenRoomId: room.roomId });
                } else {
                    gestureContext.clear();
                }
                map.setPanel({ values: { panZoomDisabled: false, busy: false } });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gestureContext.state.isPressDown, gestureContext.state.menuContextOpenRoomId]
    );

    return { onPressDown, onMouseUp, onTouchEnd };
};
