import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { createCustomErrorHandler, wrapError } from '../../../../Errors/PolErrorHandler';
import { StoreHelper } from '../../../../Helpers/StoreHelper';
import { I18n } from '../../../../Locales/I18nService';
import { SetConfigurationDto } from '../../../../Models/Projects/Configuration';
import { ProjectDto, SetProjectReplyResponse } from '../../../../Models/Projects/ProjectDto';
import { StmacSaveProjectMessage } from '../../../../Models/StmacPostMessages/StmacSaveProjectMessage';
import { GtmSaveType } from '../../../../Plugins/GTM/GTM';
import { ProjectsApi } from '../../../../Services/Api/ProjectsApi';
import { AuthHelper } from '../../../../Services/Authentication/AuthHelper';
import { AuthService } from '../../../../Services/Authentication/AuthService';
import { CreateOrSaveProjectParams, DialogService } from '../../../Services/DialogService';
import { StmacPostMessageService } from '../../../Services/StmacPostMessageService';
import { Toast } from '../../../Services/ToastService';
import { useGtmSaveController } from './GtmEventController';
import { useQuoteProvider } from './QuoteProviderController';

interface RequestCreateProjectParams {
    forceSave?: boolean;
    onCancel?: VoidFunction;
    callback: (response: SetProjectReplyResponse) => void;
    saveType?: GtmSaveType;
}

interface CreateNewProjectParameters {
    projectName: string;
    withPostMessage?: boolean;
    fromExistingProject?: boolean;
    saveType?: GtmSaveType;
}

interface UpdateProjectParameters {
    projectId: string;
    projectName: string;
    withPostMessage?: boolean;
    saveType?: GtmSaveType;
}

type SaveAutoParams = { forceSave?: boolean; nextAction?: (saved?: boolean) => void };
type SaveParams = {
    forceSave?: boolean;
    afterSave?: VoidFunction;
    onCancel?: () => void;
    saveType?: GtmSaveType;
};

export const useQuoteSaveController = () => {
    const gtm = useGtmSaveController();
    const quoteProvider = useQuoteProvider();
    const currentProject = useCurrentProject();

    const onSaveAuto = ({ forceSave, nextAction }: SaveAutoParams = {}) => {
        const currentProjectState = currentProject.getState();
        if (!currentProjectState?.lastAutoSave) {
            onSave({
                forceSave,
                saveType: GtmSaveType.AutoSave,
                afterSave: () => nextAction?.(true),
                onCancel: nextAction,
            });
        } else if (currentProject.isLastSaveWasMoreThan5minAgo()) {
            DialogService.showYesNoChoiceDialog({
                title: I18n.get('Dialog_AutoSaveTitle'),
                subtitle: I18n.get('Dialog_AutoSaveHeader'),
                onClickNo: () => {
                    if (currentProjectState.project) {
                        currentProject.update({
                            ...currentProject,
                            lastAutoSave: false,
                            lastSaveDate: new Date(),
                        });
                        nextAction?.();
                    }
                },
                onClickYes: async ({ setValidating, closeDialog }) => {
                    if (currentProjectState.project) {
                        if (currentProjectState.isUpdateAllowed) {
                            setValidating(true);
                            const { id, intitule } = currentProjectState.project;
                            await updateProject({
                                projectId: id,
                                projectName: intitule,
                                withPostMessage: true,
                                saveType: GtmSaveType.AutoSave,
                            });
                            setValidating(false);
                            closeDialog(() => nextAction?.(true));
                        } else {
                            const callback = (response: SetProjectReplyResponse) => {
                                if (response?.is_success) {
                                    currentProject.update({
                                        project: response.data.projet,
                                        isDirty: false,
                                        lastSaveDate: new Date(),
                                        lastAutoSave: true,
                                        isUpdateAllowed: true,
                                    });
                                    nextAction?.();
                                }
                            };
                            requestCreateProject({
                                forceSave,
                                saveType: GtmSaveType.AutoSave,
                                callback,
                                onCancel: nextAction,
                            });
                        }
                    }
                },
                onClosed: nextAction,
            });
        } else {
            nextAction?.();
        }
    };

    const onSave = (saveParams?: SaveParams) => {
        wrapError(async () => {
            const isConnected: boolean = await AuthService.isAuthenticated();
            if (isConnected) {
                save(saveParams);
            } else {
                DialogService.showStmacLoginDialog({
                    closeDisabled: saveParams?.forceSave,
                    afterLoginSuccess: () => save(saveParams),
                    onClosed: () => {
                        if (!saveParams?.forceSave) {
                            saveParams?.onCancel?.();
                        }
                    },
                });
            }
        });
    };

    const save = ({ forceSave, afterSave, saveType, onCancel }: SaveParams = {}) => {
        wrapError(async () => {
            const callback = (response: SetProjectReplyResponse) => {
                if (response?.is_success) {
                    currentProject.update({
                        project: response.data.projet,
                        isDirty: false,
                        lastSaveDate: new Date(),
                        lastAutoSave: true,
                        isUpdateAllowed: true,
                    });
                    afterSave?.();
                }
            };

            const currentProjectState = currentProject.getState();
            if (currentProjectState?.project && currentProjectState.isUpdateAllowed) {
                DialogService.showCreateOrUpdateProjectDialog({
                    cancelDisabled: forceSave,
                    currentProjectName: currentProjectState.project.intitule,
                    //* when click on Validate button
                    updateCurrentProject: async ({ setSaving, closeDialog }: CreateOrSaveProjectParams) => {
                        setSaving(true);
                        const response: SetProjectReplyResponse = await updateProject({
                            projectId: currentProjectState.project!.id,
                            projectName: currentProjectState.project!.intitule,
                            saveType,
                        });
                        setSaving(false);
                        if (response?.is_success) {
                            closeDialog(() => callback(response));
                        }
                    },
                    createNewProject: async ({ projectName, setSaving, closeDialog }: CreateOrSaveProjectParams) => {
                        setSaving(true);
                        const response: SetProjectReplyResponse = await createNewProject({
                            projectName,
                            saveType,
                            fromExistingProject: true,
                        });
                        setSaving(false);
                        if (response?.is_success) {
                            closeDialog(() => callback(response));
                        }
                    },
                    onClosed: onCancel,
                });
            } else {
                requestCreateProject({ forceSave, saveType, callback, onCancel });
            }
        });
    };

    const requestCreateProject = ({ forceSave, callback, onCancel, saveType }: RequestCreateProjectParams) => {
        DialogService.showCreateProjectDialog({
            cancelDisabled: forceSave,
            onClosed: onCancel,
            onValidate: async ({ projectName, setSaving, closeDialog }: CreateOrSaveProjectParams) => {
                setSaving(true);
                const response = await createNewProject({ projectName, saveType });
                setSaving(false);
                if (response?.is_success) {
                    closeDialog(() => callback?.(response));
                }
            },
        });
    };

    const createNewProject = ({
        projectName,
        withPostMessage = true,
        fromExistingProject,
        saveType,
    }: CreateNewProjectParameters): Promise<SetProjectReplyResponse> => {
        return wrapError(
            async () => {
                currentProject.update({ isSaving: true });
                const configuration: SetConfigurationDto = await quoteProvider.getConfiguration();

                const response: SetProjectReplyResponse = await ProjectsApi.createProject({
                    intitule: projectName,
                    configuration: configuration,
                    from_projet_id: currentProject.getState().project?.id,
                });

                if (response?.is_success) {
                    currentProject.update({
                        project: response.data.projet,
                        isUpdateAllowed: true,
                        isDirty: false,
                        lastSaveDate: new Date(),
                        lastAutoSave: true,
                    });

                    if (withPostMessage) {
                        saveProjectWithPostMessage(response.data.projet);
                    }

                    if (saveType) {
                        gtm.pushSaveOrCreateProjectEvent({
                            projectId: response?.data?.projet?.id,
                            isNewProject: true,
                            fromExistingProject,
                            location: saveType,
                        });
                    }

                    Toast.showSuccess({
                        content: I18n.format('Quote_Project_Created', { name: projectName }),
                    });
                }
                currentProject.update({ isSaving: false });
                return response;
            },
            {
                errorHandler: (error: any) => {
                    currentProject.update({ isSaving: false });
                    return createCustomErrorHandler(!AuthHelper.isAuthenticatedAsCustomerUser())(error);
                },
            }
            //TODO: PGU: sans le customErrorHandler, il n'y a pas de toast d'erreur qui s'affiche en cas de 401 sur le point d'API (ce n'est pas normal?). Refactoring à prévoir (?)
        );
    };

    const updateProject = ({
        projectId,
        projectName,
        withPostMessage = true,
        saveType,
    }: UpdateProjectParameters): Promise<SetProjectReplyResponse> => {
        return wrapError(
            async () => {
                currentProject.update({ isSaving: true });
                const configuration = await quoteProvider.getConfiguration();
                const response = await ProjectsApi.updateProjectConfiguration(projectId, configuration);

                if (response?.is_success) {
                    Toast.showSuccess({
                        content: I18n.format('Quote_Project_ConfigurationUpdated', { name: projectName }),
                    });
                    currentProject.update({
                        isUpdateAllowed: true,
                        project: response.data.projet,
                        isDirty: false,
                        lastSaveDate: new Date(),
                        lastAutoSave: true,
                    });

                    if (withPostMessage) {
                        await saveProjectWithPostMessage(response.data.projet);
                    }
                    if (saveType) {
                        gtm.pushSaveOrCreateProjectEvent({ projectId, isNewProject: false, location: saveType });
                    }
                }

                currentProject.update({ isSaving: false });
                return response;
            },
            {
                errorHandler: (error: any) => {
                    currentProject.update({ isSaving: false });
                    return Promise.resolve(false);
                },
            }
        );
    };

    const saveProjectWithPostMessage = async (projet: ProjectDto) => {
        if (AuthHelper.isAuthenticatedAsCustomerUser()) {
            const { quote } = quoteProvider.getState();
            const saveMessage = await StmacSaveProjectMessage.getSaveProjectRequestMessage({
                projet,
                quotationReply: quote,
                mapState: quoteProvider.map.getState(),
                projetStore: StoreHelper.getProjectStore(quoteProvider.getState()),
            });
            if (StmacSaveProjectMessage.isValid(saveMessage)) {
                StmacPostMessageService.postMessage(saveMessage);
            }
        }
    };

    const saveProjectIfNeeded = (
        whenProjectSaved: VoidFunction,
        whenAbort: VoidFunction,
        saveType: GtmSaveType
    ): void => {
        const currentProjectState = currentProject.getState();
        if (!currentProjectState.project) {
            DialogService.showYesNoChoiceDialog({
                title: I18n.get('COMMON_SAVE'),
                subtitle: I18n.get('Dialog_ProjectSaveHeader'),
                body: I18n.get('Dialog_ProjectSaveBody'),
                onClickYes: () => save({ afterSave: whenProjectSaved, saveType }),
                onClickNo: whenAbort,
                onClosed: whenAbort,
            });
        } else if (currentProjectState.isDirty) {
            DialogService.showYesNoChoiceDialog({
                title: I18n.get('COMMON_SAVE'),
                subtitle: I18n.get('Dialog_ProjectSaveDirtyHeader'),
                body: I18n.get('Dialog_ProjectSaveDirtyBody'),
                onClickYes: () => save({ afterSave: whenProjectSaved, saveType }),
            });
        } else {
            whenProjectSaved();
        }
    };

    return { onSaveAuto, onSave, saveProjectIfNeeded, requestCreateProject, updateProject };
};
