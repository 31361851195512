import { IconButton } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { Assets } from '../../assets/Assets';

type ButtonCloseProps = React.DOMAttributes<any> & { style?: CSSProperties };

export const ButtonClose = ({ children, ...rest }: ButtonCloseProps) => {
    return (
        <Container {...rest}>
            {children ? children : <Assets.IconCrossBlack style={{ width: 14, height: 14 }} />}
        </Container>
    );
};
const Container = styled(IconButton)({ position: 'absolute', right: 0 });
