import React from 'react';

import { LaizeDebugController, LaizeDebugParams, useLaizeDebugController } from './LaizeDebugController';

type LaizeDebugContextValue = { laizeDebug: LaizeDebugController };
export const LaizeDebugContext = React.createContext<LaizeDebugContextValue>({
    laizeDebug: {
        clear: () => {},
        laizeDebugDialog: () => {},
        openLaizeDialog: false,

        LaizeDebugDialogV3: () => null,

        openMenu: () => {},
        closeMenu: () => {},
        closeDebug: () => {},
    },
});

export const useLaizeDebug = () => React.useContext(LaizeDebugContext);

let map: Array<string> = [];

export const LaizeDebugProvider: React.FC<any> = ({ children }) => {
    const laizeDebug = useLaizeDebugController();

    const keyboardShortcuts = (map: Array<string>) => {
        const debugKey = map[0];
        const debugMode = map[1];
        const params: LaizeDebugParams = { showToast: true };
        if (debugKey === 'KeyD') {
            switch (debugMode) {
                case 'Digit0':
                case 'Numpad0':
                    //* reset calcul direction
                    laizeDebug.clear(params);
                    break;
                case 'Digit8':
                case 'Numpad8':
                    //* calcul direction droite vers gauche
                    laizeDebug.laizeDebugDialog();
                    break;
                default:
                    break;
            }
        }
    };

    const debugKeyUp = (e: KeyboardEvent) => (map = map.filter((x) => x !== e.code));

    const onKeyDown = (e: KeyboardEvent) => {
        if (map.indexOf(e.code) === -1) {
            map.push(e.code);
        } else if (map.length === 2) {
            map[1] = e.code;
        }
        keyboardShortcuts(map);
    };

    React.useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        document.addEventListener('keyup', debugKeyUp);
        return () => {
            document.removeEventListener('keyup', debugKeyUp);
        };
    }, []);

    return (
        <LaizeDebugContext.Provider value={{ laizeDebug }}>
            {children}
            {laizeDebug.openLaizeDialog && <laizeDebug.LaizeDebugDialogV3 />}
        </LaizeDebugContext.Provider>
    );
};
