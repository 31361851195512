import React, { CSSProperties } from 'react';

import { GestureEvent } from '../../../../Events/withEvent';
import { ThemeRes } from '../../../../Web/Themes/ThemeRes';
import { useMultiTouch } from '../../Context/GestureContext';
import { useMapZoomScaleWithLimit, useScaleCoteSizeText } from './Walls/ZoomScaleComponents';

export const CoteConstants = {
    quote_unit: 'cm',

    fontSize: (shrinkRatio: number = 1) => 14 / shrinkRatio,
    shift: (shrinkRatio: number = 1) => 7 / shrinkRatio,
    offsetShift: (shrinkRatio: number = 1) => 25 / shrinkRatio,
    labelHeight: (shrinkRatio: number = 1) => 24 / shrinkRatio,
    distanceLineEndObj: (shrinkRatio: number = 1) => 5 / shrinkRatio,
    arrowSize: (shrinkRatio: number = 1) => 8 / shrinkRatio,
    charMonospace: (shrinkRatio: number = 1) => 9 / shrinkRatio,
    densedDisplayRuptureValue: (shrinkRatio: number = 1) => 80 / shrinkRatio,
};

type CoteLineProps = { measure: number };
export const CoteLine = ({ measure }: CoteLineProps) => {
    const props: React.SVGProps<SVGLineElement> = {
        x1: -measure / 2,
        y1: 0,
        x2: measure / 2,
        y2: 0,
        stroke: '#424448',
        strokeWidth: 1,
    };
    return <line {...props} />;
};

type CoteLineEndProps = { x: number; shiftValue: number; reversed: boolean; inOut: string; shrinkScale?: number };
export const CoteLineEnd = ({ x, shiftValue, reversed, inOut, shrinkScale }: CoteLineEndProps) => {
    const props = {
        x1: x,
        y1: reversed && inOut !== 'inside' ? 8 : -8,
        x2: x,
        y2:
            -shiftValue +
            //@ts-ignore
            (reversed ^ (inOut === 'inside')
                ? CoteConstants.distanceLineEndObj(shrinkScale)
                : -CoteConstants.distanceLineEndObj(shrinkScale)),
        stroke: '#464646a6', //side != 'inside' ? "#c0c0c0" : "#263740",
        strokeWidth: 1,
    };
    return <line {...props} />;
};
type CoteEndArrowProps = { x: number; reversed?: boolean; shrinkScale?: number };
export const CoteEndArrow = ({ x, reversed, shrinkScale }: CoteEndArrowProps) => {
    const arrowSize = CoteConstants.arrowSize(shrinkScale);
    const props = {
        points: reversed
            ? x + ',' + 0 + ' ' + (x + arrowSize) + ',' + arrowSize / 2 + ' ' + (x + arrowSize) + ',' + -arrowSize / 2
            : x + ',' + 0 + ' ' + (x - arrowSize) + ',' + arrowSize / 2 + ' ' + (x - arrowSize) + ',' + -arrowSize / 2,
        fill: '#424448',
    };
    return <polygon {...props} />;
};

type CoteLabelProps = { textLength: number; highlight?: boolean; shrinkScale?: number } & React.SVGAttributes<unknown>;
export const CoteLabel = ({ textLength, highlight, shrinkScale, ...attrs }: CoteLabelProps) => {
    const labelWidth = CoteConstants.charMonospace(shrinkScale) * textLength;
    const labelHeight = useScaleCoteSizeText(CoteConstants.labelHeight(shrinkScale));
    const props: React.SVGAttributes<unknown> = {
        width: labelWidth,
        height: labelHeight,
        x: -labelWidth / 2,
        y: -labelHeight / 2,
        fill: '#FFFFFF',
        stroke: highlight ? '#424448' : '#D6D6D6',
        strokeWidth: highlight ? 2 : 1,
    };
    return <rect {...props} style={{ cursor: 'pointer' }} />;
};

type CoteSizeTextProps = {
    text: string;
    fontSize?: number;
    angle?: number;
    onClick?: (e: GestureEvent) => void;
    shrinkScale?: number;
} & React.SVGAttributes<unknown>;
export const CoteSizeText = ({ text, fontSize = 14, angle, onClick, shrinkScale, ...attrs }: CoteSizeTextProps) => {
    const style: CSSProperties = {
        cursor: 'pointer',
        transform: angle === -270 || angle === 90 ? 'rotate(180deg)' : 'none',
    };

    const multiTouch = useMultiTouch();
    const handleTouchEnd = (event: GestureEvent) => {
        if (!multiTouch.handleMultiTouch(event as React.TouchEvent)) {
            onClick?.(event);
        }
    };

    let scale = useMapZoomScaleWithLimit(shrinkScale);
    return (
        <text
            y={5 * scale}
            textAnchor="middle"
            stroke="#FFFFFF"
            strokeWidth={0.2}
            fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}
            fontWeight={400}
            fill="#424448"
            style={style}
            fontSize={CoteConstants.fontSize(shrinkScale)}
            onClick={onClick}
            onTouchEnd={handleTouchEnd}>
            {text}
        </text>
    );
};

export class CoteUtils {
    public static calculateShift = (side: string, angle: number, shrinkScale?: number) => {
        let shiftValue =
            side === 'inside' ? CoteConstants.shift(shrinkScale) * 5 : -CoteConstants.shift(shrinkScale) * 5;
        let reversed = false;
        if (angle > 90 || angle < -89) {
            angle -= 180;
            reversed = true;
            shiftValue = -shiftValue;
        }
        return { angle, shiftValue, reversed };
    };

    public static calculateDensedShiftOffset(reversed: boolean, densedDisplay: boolean, shrinkScale?: number) {
        if (!densedDisplay) {
            return 0;
        }
        return reversed ? CoteConstants.offsetShift(shrinkScale) : -CoteConstants.offsetShift(shrinkScale);
    }
}
