import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { ConfigurationUtils } from '../../../../Helpers/ConfigurationUtils';
import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { ProductHelper } from '../../../../Helpers/ProductHelper';
import { QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { GetConfigurationResponse } from '../../../../Models/Projects/Configuration';
import {
    QuestionContextType,
    QuestionItem,
    QuestionStep,
    QuestionType,
} from '../../../../Models/Questions/QuestionDto';
import { CompleteHelper } from '../../../../Plugins/FloorPlan/Helpers/CompleteHelper';
import { MapExportHelper } from '../../../../Plugins/FloorPlan/Helpers/MapExport/MapExportHelper';
import { MapHelper } from '../../../../Plugins/FloorPlan/Helpers/MapHelper';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { ConfigurationApi } from '../../../../Services/Api/ConfigurationApi';
import { useIsVendeur } from '../../../Hooks/Vendeur/useVendeur';
import { useRoomInfosValues } from '../Components/Pannels/Rooms/Controllers/RoomInfosValues';
import { HabitationHelper } from '../Helpers/HabitationHelper';
import { QuestionPrepare } from '../Helpers/QuestionPrepare';
import { LoadingStep, QuoteState } from '../QuoteState';
import { QuestionContextUtils } from '../Utils/QuestionContextUtils';

type LoadParams = { configId: string; questions: Array<QuestionItem> };

type QuoteLoadControllerParams = { updateQuote: (values: Partial<QuoteState>) => void };
export const useQuoteLoadController = ({ updateQuote }: QuoteLoadControllerParams) => {
    const isVendeur = useIsVendeur();
    const currentProject = useCurrentProject();
    const roomInfos = useRoomInfosValues();
    const loadConfiguration = async ({ configId, questions }: LoadParams) => {
        updateQuote({ loadingStep: LoadingStep.Configuration });
        const configurationResponse: GetConfigurationResponse = await ConfigurationApi.getConfiguration(configId);
        const {
            floor_plan_json,
            reponse_list: reponses,
            configuration_projet,
            projet_store,
        } = configurationResponse.data;
        currentProject.update({
            project: configuration_projet.projet,
            isUpdateAllowed: configuration_projet.is_update_allowed,
            isDirty: false,
            lastSaveDate: undefined,
            lastAutoSave: false,
        });

        const stepHabitation = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepHabitation);
        const questionProduitPrincipal = questions.find((q) => q.type === QuestionType.Produit)!;

        QuestionPrepare.updatedStepQuestionsFromLoadConfig({
            reponses,
            questions: stepHabitation.questions,
            autoValidQuestionCommentType: true,
            fromLoadingConfiguration: true,
        });

        const { state: exportMap, flooringDirectionByRoomId } = MapExportHelper.fromApiJsonToState(
            floor_plan_json,
            roomInfos
        );
        exportMap.rooms?.forEach((room: IRoom) => {
            //* load question product principal response
            room.questionProduitPrincipal = ConfigurationUtils.applyReponseToQuestion({
                contextId: room.roomId,
                question: ObjectUtils.clone(questionProduitPrincipal),
                reponses,
            });
        });

        const laizeProps = ProductHelper.ToArrayLaizeProps(exportMap.rooms);

        updateQuote({ loadingStep: LoadingStep.Map });
        const map = MapHelper.loadRooms({
            rooms: exportMap.rooms || [],
            laizeProps: laizeProps,
            flooringDirectionByRoomId,
        });

        updateQuote({ loadingStep: LoadingStep.InitConfiguration });
        map.rooms?.forEach((room: IRoom) => {
            const roomQuestionProduitPrincipal = room.questionProduitPrincipal!;
            const produitPrincipal = roomQuestionProduitPrincipal.produitValue;
            const flooringDirection = flooringDirectionByRoomId?.[room.roomId!];

            room.questionsBaseContext = QuestionContextHelper.mergeContext(
                QuestionContextHelper.getBaseQuestionsContext(stepHabitation.questions),
                QuestionContextUtils.createRoomContext(room, flooringDirection)
            );

            QuestionPrepare.updateStepsQuestions({
                itemId: room.roomId!,
                item: room,
                questions,
                produitPrincipal,
                contextType: QuestionContextType.Rooms,
                fromLoadingConfiguration: true,
                reponses,
                isVendeur,
            });

            room.openings?.forEach((opening: IOpening) => {
                opening.questionsBaseContext = QuestionContextUtils.createOpeningContext(
                    opening,
                    room,
                    flooringDirection
                );
                QuestionPrepare.updateStepsQuestions({
                    itemId: opening.openingId!,
                    item: opening,
                    questions,
                    produitPrincipal,
                    contextType: QuestionContextType.Openings,
                    openingType: opening.type,
                    fromLoadingConfiguration: true,
                    reponses,
                    isVendeur,
                });
                opening.completionStatus = CompleteHelper.isOpeningComplete(opening, room.openings!);
            });
            room.roomItems?.forEach((roomItem: IRoomItem) => {
                roomItem.questionsBaseContext = QuestionContextUtils.createRoomItemContext(
                    roomItem,
                    room,
                    flooringDirection
                );
                QuestionPrepare.updateStepsQuestions({
                    itemId: roomItem.roomItemId!,
                    item: roomItem,
                    questions,
                    produitPrincipal,
                    contextType: QuestionContextType.RoomElements,
                    roomItemType: roomItem.type,
                    fromLoadingConfiguration: true,
                    reponses,
                    isVendeur,
                });
                roomItem.completionStatus = CompleteHelper.isRoomItemComplete(roomItem, room.roomItems!);
            });
            const findFirstIncompatibility = QuestionsHelper.findFirstIncompatibility([
                room.questionProduitPrincipal!,
                ...room.questionsPose!.questions!,
                ...room.questionsSupport!.questions!,
                ...room.questionsPreparation!.questions!,
                ...room.questionsFinition!.questions!,
                ...room.questionsServices!.questions!,
            ]);
            room.findIncompatibilityResult = findFirstIncompatibility.findIncompatibility
                ? findFirstIncompatibility
                : undefined;
            room.completionStatus = CompleteHelper.isRoomComplete(room, map.rooms!);
        });

        const habitationQuestionsOpen = !HabitationHelper.isFinished(stepHabitation.questions);
        map.roomsCompleted = CompleteHelper.allComplete(map.rooms);

        return {
            mapState: map,
            questionProduitPrincipal,
            habitationQuestions: stepHabitation.questions,
            habitationQuestionsOpen,
            projetStore: projet_store,
        };
    };

    return { loadConfiguration };
};
