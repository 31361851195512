import * as React from 'react';
import { FlooringDirectionProps } from './FlooringDirectionAssets';
export const LaizeHorizontal = ({ props }: { props: FlooringDirectionProps }) => {
    const { svgProps, fillColor = '#13123A' } = props;
    return (
        <svg width={49} height={48} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.812 10.316a7.504 7.504 0 0 1 7.506-7.504 7.504 7.504 0 0 1 7.505 7.483v20.31a10.29 10.29 0 0 0-7.505-3.237 10.29 10.29 0 0 0-7.506 3.238v-20.29ZM0 10.302C.008 4.61 4.625 0 10.318 0c5.468 0 9.943 4.254 10.295 9.633h25.983c.776 0 1.406.63 1.406 1.406v35.555c0 .776-.63 1.406-1.406 1.406H10.318C4.62 48 0 43.383 0 37.686V10.302Zm10.371 34.886h-.075a7.504 7.504 0 0 1 .022-15.007 7.505 7.505 0 0 1 7.505 7.461v.059a7.504 7.504 0 0 1-7.452 7.487Zm7.027 0H45.19V12.445H20.635v25.24c0 2.956-1.244 5.622-3.237 7.503Zm-7.08-9.256a1.752 1.752 0 1 0-.001 3.505 1.752 1.752 0 0 0 0-3.505Zm-4.565 1.752a4.564 4.564 0 1 1 9.129 0 4.564 4.564 0 0 1-9.13 0ZM41.06 26.536l-6.26-6.26a.942.942 0 0 0-.672-.276.942.942 0 0 0-.67.276l-.569.569a.96.96 0 0 0 0 1.35l3.652 3.66H24.937a.926.926 0 0 0-.936.932v.804c0 .523.413.974.936.974h11.646l-3.694 3.68a.928.928 0 0 0-.277.665c0 .253.099.489.277.667l.569.567a.941.941 0 0 0 .67.276.942.942 0 0 0 .672-.278l6.26-6.26a.942.942 0 0 0 .277-.672.942.942 0 0 0-.277-.674Z"
                fill={fillColor}
            />
        </svg>
    );
};
