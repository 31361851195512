/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
import jsonLogic, { RulesLogic } from 'json-logic-js';
import Enumerable from 'linq';
import _ from 'lodash';

//import { ClipboardUtils } from '../Utils/ClipbordUtils';
import { Logger } from '../Errors/Logger';

import { ConditionDto, QuestionItem } from '../Models/Questions/QuestionDto';

import { ObjectUtils } from './ObjectUtils';
import { QuestionContext } from './QuestionContextHelper';

type MergedInOperator = { and: Array<InOperator> };

type InOperator = { in: [string, Array<string>] };

export interface IsConditionItemVisibleParams {
    readonly question?: QuestionItem;
    readonly condition: ConditionDto;
    rawContext: QuestionContext;
    withLog?: boolean;
    log?: Record<string, any>;
}

jsonLogic.add_operation('starts_with', (data, params) => {
    return data?.startsWith(params) ?? false;
});
jsonLogic.add_operation('ends_with', (data, params) => {
    return data?.endsWith(params) ?? false;
});

export class ConditionHelper {
    public static addUndefinedInNotAny(rawLogic: string): string {
        const notInRegex = /"!":{"in[^\]]+\]\]\}/g;
        const originalInRegex = /\{"in[^\]]+\]\]\}/g;
        const optionsRegex = /,.*?\]/g;
        const result = rawLogic.replace(notInRegex, (correspondance: string) => {
            const inOperator = correspondance.match(originalInRegex);
            if (inOperator !== null) {
                const options = inOperator[0].match(optionsRegex);
                if (options !== null) {
                    const newOptionString = options[0].replace(']', `, "undefined" ]`);
                    const newInOperator = inOperator[0].replace(optionsRegex, newOptionString);
                    return correspondance.replace(originalInRegex, newInOperator);
                }
            }
            return correspondance;
        });
        return result;
    }

    public static isConditionEvaluateToTrue(isConditionItemVisibleParams: IsConditionItemVisibleParams): boolean {
        const { question, condition, rawContext, withLog, log } = isConditionItemVisibleParams;
        if (condition) {
            const context = ObjectUtils.clone(rawContext);
            const rawLogic: string = condition.json_logic;
            const varValues: Array<string> = ConditionHelper.getVarValues(rawLogic);
            let contextToUse: QuestionContext = context;

            let missingsValues = ConditionHelper.getMissingValues(contextToUse, varValues, withLog);
            if (missingsValues.length > 0) {
                //* inject in rawContext missing keys with default value : undefined
                contextToUse = ConditionHelper.injectKeysWithDefaultValue(missingsValues, contextToUse, withLog);
            }
            let logicToUse = ConditionHelper.addUndefinedInNotAny(rawLogic);
            logicToUse = ConditionHelper.qcmParameterInjectionIntoInOperator(logicToUse, contextToUse); //only alter in operator

            try {
                const jsonRules = JSON.parse(logicToUse);
                const rules: RulesLogic = jsonRules as RulesLogic;
                const isTrue: boolean = jsonLogic.apply(rules, contextToUse);

                if (withLog) {
                    //ClipboardUtils.copy(JSON.stringify(contextToUse));
                    Logger.debug('ConditionHelper: isConditionEvaluateToTrue', {
                        isTrue,
                        question,
                        condition,
                        rawLogic,
                        logicToUse,
                        varValues,
                        result: jsonLogic.apply(rules, contextToUse),
                        context: JSON.stringify(context),
                        rawContext: JSON.stringify(rawContext),
                        contextToUse: JSON.stringify(contextToUse),
                        log,
                    });
                }
                return isTrue;
            } catch (error) {
                Logger.logAnalytics({
                    message: 'POL: ConditionHelper.isConditionEvaluateToTrue',
                    value: {
                        error,
                        condition,
                        context: JSON.stringify(context),
                        rawContext: JSON.stringify(context),
                        contextToUse: JSON.stringify(contextToUse),
                        rawLogic,
                    },
                });
                return false;
            }
        }
        return true;
    }

    public static isSyntheseConditionItemVisible(condition: ConditionDto, context: QuestionContext = {}): boolean {
        if (condition) {
            const rawLogic: string = condition.json_logic;
            const jsonRules = JSON.parse(rawLogic);
            const rules: RulesLogic = jsonRules as RulesLogic;
            const isVisible: boolean = jsonLogic.apply(rules, context);
            return isVisible;
        }
        return true;
    }

    private static getVarValues(rawLogic: string): Array<string> {
        let values: Array<string> = [];

        //* Regex : find string after "var" inside quotes
        //* https://regex101.com/r/nE5eV3/3
        //* ("?)var\1.*?"(.*?)"/g

        const regexPattern = /("?)var\1.*?"(.*?)"/g;
        //const regex = new RegExp(regexPattern);

        const matches = rawLogic.match(regexPattern);
        matches?.forEach((matchItem: string) => {
            const keyAndValue: Array<string> = matchItem.split(':');
            //* keyAndValue[0] === key "var"
            //* keyAndValue[1] === value like question_${id}_option
            let value: string = keyAndValue[1];
            //* remove double quote "
            value = value.replace(/"/g, '');
            values = [...values, value];
        });
        //console.log("getVarValues", { rawLogic, regex, matches, values });
        return values.length > 0 ? Enumerable.from(values).distinct().toArray() : [];
    }

    private static getMissingValues(
        context: QuestionContext = {},
        values: Array<string> = [],
        withLog: boolean = false
    ): Array<string> {
        if (values.length > 0) {
            let missingValues: Array<string> = [];

            values.forEach((key: string) => {
                let valueExist: boolean = false;

                if (key.includes('.')) {
                    //* like "evaluations.isLocataire"
                    const splitKeys: Array<string> = key.split('.');
                    let currentJsonObject: QuestionContext = context;

                    splitKeys.forEach((currentKey: string, index: number) => {
                        const isLastKey = index === splitKeys.length - 1;
                        const valueForCurrentKey: any = currentJsonObject[currentKey];
                        if (isLastKey) {
                            valueExist = valueForCurrentKey !== undefined;
                        } else {
                            currentJsonObject = valueForCurrentKey ?? {};
                        }
                    });
                } else {
                    const value: any = context[key];
                    valueExist = value !== undefined;
                }
                if (!valueExist) {
                    missingValues = [...missingValues, key];
                }
            });
            return missingValues;
        }
        return [];
    }

    private static injectKeysWithDefaultValue(
        keys: Array<string> = [],
        rawContext: QuestionContext = {},
        withLog: boolean = false
    ): QuestionContext {
        let contextToUpdate = ObjectUtils.clone(rawContext);

        keys.forEach((currentKey: string) => {
            if (currentKey.includes('.')) {
                contextToUpdate = {
                    ...contextToUpdate,
                    ...ObjectUtils.toObject({
                        origin: contextToUpdate,
                        path: currentKey,
                        value: undefined,
                        withLog,
                    }),
                };
            } else {
                const value: any = contextToUpdate[currentKey];
                if (value === undefined) {
                    contextToUpdate[currentKey] = 'undefined';
                }
            }
        });

        return contextToUpdate;
    }

    // customize rule with data reorganisation to handle "in" operator correctly
    // take the original in operator in entry, e.g.:
    // {"in":[ "question_a", ["option_1", "option_2", "option_3", "option_4"] ]}
    //
    // inject all the selected QCM parameters as an individual in operator linked with and operator, e.g.:
    // {"and": [{"in":[ "option_2", ["option_1", "option_2", "option_4"] ]}, {"in":[ "option_3", ["option_1", "option_2", "option_4"] ]}]}
    //
    // does it for all IN
    private static qcmParameterInjectionIntoInOperator(rawLogic: string, data: Record<string, any>): string {
        const originalInRegex = /\{"in[^\]]+\]\]\}/g; // extract and isolate each IN operations
        const questionRegex = /"question[^,\}]+/g; // extract question var (e.g. : question_19fdb6ef-08a0-42c4-8418-0bcdb637fbae_option), to get the correct value from context
        const replaceParameterRegex = /\{"var[^,]*/g; // replace the whole var object to the corresponding string value (e.g. : option_51ef2837-2951-4f88-b052-c34f95c4e3f3) from context
        const optionRegex = /"option[^,\]]+/g; // retrieve options from the rule; an exception is defined to handle correctly IN operator with only 1 option

        let abortInTransformation = false;
        // match & substitute values
        const result = rawLogic.replace(originalInRegex, (correspondance: string) => {
            optionRegex.lastIndex = 0;
            let ruleOptions = optionRegex.exec(correspondance);

            let m: RegExpExecArray | null;

            let newInOperator: MergedInOperator = { and: [] };

            questionRegex.lastIndex = 0;
            if ((m = questionRegex.exec(correspondance)) !== null) {
                const match = m[0];

                const questionOption: string = JSON.parse(match);
                const dataQuestionOption = _.get(data, questionOption); // handle access object child properties using dot notation string

                if (!Boolean(dataQuestionOption)) {
                    //! note: le 'abortInTransformation' en dessous
                    //! peut provoquer un bug sur la transformation final
                    //! car il bypass les transformations précedente
                    //! SUGGESTION si bug il y'a : remplacer "abortInTransformation = true"
                    //! par "return correspondance"
                    //! VU AVEC @thierry et @mmc
                    //! origin bug : https://ideine.visualstudio.com/XBacklog/_workitems/edit/21124
                    abortInTransformation = true;
                } else {
                    if (Array.isArray(dataQuestionOption)) {
                        for (const option of dataQuestionOption as Array<string>) {
                            if (ruleOptions?.length === 1 && correspondance.search(JSON.stringify(option)) !== -1) {
                                // exception to handle only one IN with 1 value
                                newInOperator.and.push(
                                    JSON.parse(correspondance.replace(replaceParameterRegex, JSON.stringify(option)))
                                );
                            }
                        }
                    } else if (correspondance.search(JSON.stringify(dataQuestionOption)) !== -1) {
                        newInOperator.and.push(
                            JSON.parse(
                                correspondance.replace(replaceParameterRegex, JSON.stringify(dataQuestionOption))
                            )
                        );
                    }
                }
            } else {
                return correspondance;
            }

            return JSON.stringify(newInOperator);
        });
        return abortInTransformation ? rawLogic : result;
    }
}
