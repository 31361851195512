//@ts-ignore
import lineclip from 'lineclip';

import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { CoordPoint } from '../Types';
import { CollisionRoomUtils } from './CollisionRoomUtils';


export class CollisionsRoomItemUtils {

    public static roomItemCollisionDetection(rooms: Array<IRoom>, roomItems: Array<IRoomItem>, roomItem: IRoomItem, roomItemSideAngle?: number) {

        let points: Array<CoordPoint> = [];
        let closestCollisionPoint: number;

        if (roomItemSideAngle === -90) {
            points = [
                { x: -10, y: roomItem.coordsReal![1].y },
                roomItem.coordsReal![1],
                roomItem.coordsReal![2],
                { x: -10, y: roomItem.coordsReal![2].y },
            ];
        } else if (roomItemSideAngle === 0) {
            points = [
                { x: roomItem.coordsReal![3].x, y: -10 },
                { x: roomItem.coordsReal![2].x, y: -10 },
                roomItem.coordsReal![2],
                roomItem.coordsReal![3],
            ];
        } else if (roomItemSideAngle === 90) {
            points = [
                roomItem.coordsReal![0],
                { x: Infinity, y: roomItem.coordsReal![0].y }, //no limit set
                { x: Infinity, y: roomItem.coordsReal![3].y }, //no limit set
                roomItem.coordsReal![3],
            ];
        } else if (roomItemSideAngle === 180) {
            points = [
                roomItem.coordsReal![0],
                roomItem.coordsReal![1],
                { x: roomItem.coordsReal![1].x, y: Infinity }, //no limit set
                { x: roomItem.coordsReal![0].x, y: Infinity }, //no limit set
            ];
        }

        const xmin = points[0].x;
        const ymin = points[0].y;
        const xmax = points[2].x;
        const ymax = points[2].y;

        if (roomItemSideAngle === -90) {
            closestCollisionPoint = 0;
            for (const testRoomItem of roomItems) {
                if (roomItem.roomItemId !== testRoomItem.roomItemId) {
                    const polygonA = testRoomItem.coordsReal!.map((point) => [point.x, point.y]).slice(0, -1);
                    const clipPolygon = lineclip.polygon(polygonA, [xmin, ymin, xmax, ymax]);

                    for (const p of clipPolygon) {
                        if (p[0] !== xmax && p[1] !== ymax && p[1] !== ymin && p[0] > closestCollisionPoint) {
                            //x
                            closestCollisionPoint = p[0];
                        }
                    }
                    if (CollisionRoomUtils.testIfPolygonAContainedInPolygonB(
                        testRoomItem.coordsReal!.slice(0, -1),
                        [points[0], points[1], points[2], points[3]]
                    )) {
                        for (const p of testRoomItem.coordsReal!) {
                            if (p.x > closestCollisionPoint) {
                                closestCollisionPoint = p.x;
                            }
                        }
                    }
                }
            }
            const testRoom = rooms.find((x) => x.roomId === roomItem.roomId)!;
            const clipLine = lineclip.polyline(
                testRoom.coords!.map((point) => [point.x, point.y]),
                [xmin, ymin, xmax, ymax]
            );

            for (const polyline of clipLine) {
                //when romItem is stick to a wall exclude undesired value
                const filteredPolyline = polyline.filter((p: Array<number>) => p[0] !== xmax); //x
                for (const line of filteredPolyline) {
                    if (line[0] > closestCollisionPoint) {
                        //x
                        closestCollisionPoint = line[0];
                    }
                }
            }
        } else if (roomItemSideAngle === 0) {
            closestCollisionPoint = 0;
            for (const testRoomItem of roomItems) {
                if (roomItem.roomItemId !== testRoomItem.roomItemId) {
                    const polygonA = testRoomItem.coordsReal!.map((point) => [point.x, point.y]).slice(0, -1);
                    const clipPolygon = lineclip.polygon(polygonA, [xmin, ymin, xmax, ymax]);

                    for (const p of clipPolygon) {
                        if (p[1] !== ymax && p[0] !== xmax && p[0] !== xmin && p[1] > closestCollisionPoint) {
                            //x
                            closestCollisionPoint = p[1];
                        }
                    }
                    if (CollisionRoomUtils.testIfPolygonAContainedInPolygonB(
                        testRoomItem.coordsReal!.slice(0, -1),
                        [points[0], points[1], points[2], points[3]]
                    )) {
                        for (const p of testRoomItem.coordsReal!) {
                            if (p.y > closestCollisionPoint) {
                                closestCollisionPoint = p.y;
                            }
                        }
                    }
                }
            }
            const testRoom = rooms.find((x) => x.roomId === roomItem.roomId)!;
            const clipLine = lineclip.polyline(
                testRoom.coords!.map((point) => [point.x, point.y]),
                [xmin, ymin, xmax, ymax]
            );
            for (const polyline of clipLine) {
                //when romItem is stick to a wall exclude undesired value
                const filteredPolyline = polyline.filter((p: Array<number>) => p[1] !== ymax); //y
                for (const line of filteredPolyline) {
                    if (line[1] > closestCollisionPoint) {
                        //y
                        closestCollisionPoint = line[1];
                    }
                }
            }
        } else if (roomItemSideAngle === 90) {
            closestCollisionPoint = Infinity;
            for (const testRoomItem of roomItems) {
                if (roomItem.roomItemId !== testRoomItem.roomItemId) {
                    const polygonA = testRoomItem.coordsReal!.map((point) => [point.x, point.y]).slice(0, -1);
                    const clipPolygon = lineclip.polygon(polygonA, [xmin, ymin, xmax, ymax]);

                    for (const p of clipPolygon) {
                        if (p[0] !== xmin && p[1] !== ymin && p[1] !== ymax && p[0] < closestCollisionPoint) {
                            //x
                            closestCollisionPoint = p[0];
                        }
                    }
                    if (
                        CollisionRoomUtils.testIfPolygonAContainedInPolygonB(testRoomItem.coordsReal!.slice(0, -1), [
                            points[0],
                            points[1],
                            points[2],
                            points[3],
                        ])
                    ) {
                        for (const p of testRoomItem.coordsReal!) {
                            if (p.x < closestCollisionPoint) {
                                closestCollisionPoint = p.x;
                            }
                        }
                    }
                }
            }
            const testRoom = rooms.find((x) => x.roomId === roomItem.roomId)!;
            const clipLine = lineclip.polyline(
                testRoom.coords!.map((point) => [point.x, point.y]),
                [xmin, ymin, xmax, ymax]
            );
            for (const polyline of clipLine) {
                //when romItem is stick to a wall exclude undesired value
                const filteredPolyline = polyline.filter((p: Array<number>) => p[0] !== xmin); //x
                for (const line of filteredPolyline) {
                    if (line[0] < closestCollisionPoint) {
                        //x
                        closestCollisionPoint = line[0];
                    }
                }
            }
        } else if (roomItemSideAngle === 180) {
            closestCollisionPoint = Infinity;
            for (const testRoomItem of roomItems) {
                if (roomItem.roomItemId !== testRoomItem.roomItemId) {
                    const polygonA = testRoomItem.coordsReal!.map((point) => [point.x, point.y]).slice(0, -1);
                    const clipPolygon = lineclip.polygon(polygonA, [xmin, ymin, xmax, ymax]);

                    for (const p of clipPolygon) {
                        if (p[1] !== ymin && p[0] !== xmin && p[0] !== xmax && p[1] < closestCollisionPoint) {
                            //y
                            closestCollisionPoint = p[1];
                        }
                    }
                    if (CollisionRoomUtils.testIfPolygonAContainedInPolygonB(
                        testRoomItem.coordsReal!.slice(0, -1),
                        [points[0], points[1], points[2], points[3]]
                    )
                    ) {
                        for (const p of testRoomItem.coordsReal!) {
                            if (p.y < closestCollisionPoint) {
                                closestCollisionPoint = p.y;
                            }
                        }
                    }
                }
            }
            const testRoom = rooms.find((x) => x.roomId === roomItem.roomId)!;
            const clipLine = lineclip.polyline(
                testRoom.coords!.map((point) => [point.x, point.y]),
                [xmin, ymin, xmax, ymax]
            );
            for (const polyline of clipLine) {
                //when romItem is stick to a wall exclude undesired value
                const filteredPolyline = polyline.filter((p: Array<number>) => p[1] !== ymin); //y
                for (const line of filteredPolyline) {
                    if (line[1] < closestCollisionPoint) {
                        //y
                        closestCollisionPoint = line[1];
                    }
                }
            }
        }

        return closestCollisionPoint!;
    }
}
