import { Constants } from '../Constants';
import { QuestionItem, QuestionType } from '../Models/Questions/QuestionDto';
import { StoreDto } from '../Models/TravelExpenses/StoreDto';
import { LocalityDto } from '../Models/TravelExpenses/TravelExpenseDto';
import { QuoteState } from '../Web/Pages/Quote/QuoteState';

export class StoreHelper {
    public static toDesciption(store: StoreDto) {
        const [line1] = store.address_lines || [];
        return `${line1}, ${store.zipcode} ${store.city}`;
    }

    public static toBookAppointmentUrl(store?: StoreDto) {
        const websiteUrl = store?.website;
        if (websiteUrl) {
            const storeSlug = websiteUrl.slice(websiteUrl.lastIndexOf('/') + 1);
            return Constants.STMAC_BOOK_APPOINTMENT_URL(storeSlug);
        }
        return Constants.STMAC_BOOK_APPOINTMENT_URL();
    }

    public static getStoreId = (questions: Array<QuestionItem>): string => {
        const zipCodeQuestion = questions.find((x) => x.type === QuestionType.ZipCode);
        return zipCodeQuestion?.zipCodeValue?.travel_expenses?.store.store_id ?? '';
    };

    public static getAddressLocality = (questions: QuestionItem[] = []): LocalityDto | undefined => {
        const zipCodeQuestion = questions.find((x) => x.type === QuestionType.ZipCode);
        return zipCodeQuestion?.zipCodeValue;
    };

    public static getProjectStore = ({
        projetStore,
        habitationQuestions = [],
    }: Partial<QuoteState>): StoreDto | undefined => {
        return projetStore ?? StoreHelper.getAddressLocality(habitationQuestions)?.travel_expenses?.store;
    };

    public static getProjectLocation = ({ habitationQuestions = [] }: Partial<QuoteState>): LocalityDto | undefined => {
        return StoreHelper.getAddressLocality(habitationQuestions);
    };
}
