import { Logger } from '../../../../Errors/Logger';
import { I18n } from '../../../../Locales/I18nService';
import { IContournements } from '../../Models/Contournements';
import { YesOrNo } from '../../Models/IMapItem';
import { IOpening, OpeningType } from '../../Models/IOpening';
import { IRoom, RoomDimensions, RoomLocalisation, RoomShape } from '../../Models/IRoom';
import { IRoomItem, RoomItemType } from '../../Models/IRoomItem';
import { RoomCollisionsUtils } from '../../Utils/Collisions/CollisionsUtils';
import { PointUtils } from '../../Utils/PointUtils';
import { CreateRoomFactory } from '../../Utils/Room/RoomUtils';
import { CoordPoint } from '../../Utils/Types';
import { ApiMapState, ExportMap } from './MapExportHelper';

type ExportMapV31Data = {
    rooms?: Partial<IExportRoom>[];
    openings?: Partial<IExportOpeningItem>[];
    roomItems?: Partial<IExportRoomItem>[];
    direction?: any;

    //* USELESS DATA
    // overallWidth?: number;
    // overallHeight?: number;
    // laizes?: any;
    // laizeHorizontal?: number;
    // laizeVertical?: number;
    // global_surface_area?: number;
    // map_svg_preview_base64?: string;
    // map_png_preview_base64?: string;
};

type ExportMapV31 = ExportMap & { map?: ExportMapV31Data };

export class MapExportV31 {
    public static VERSION = 3.1;

    public static fromApiJsonToState = ({ map }: ExportMapV31): ApiMapState => {
        const {
            direction,
            rooms: exportRooms = [],
            openings: exportOpenings = [],
            roomItems: exportRoomItems = [],
        } = map || {};

        const rooms: IRoom[] = exportRooms.map((exportRoom: Partial<IExportRoom>) => {
            const room: IRoom = this.toRoom(exportRoom);
            room.openings = exportOpenings
                .filter((o: Partial<IExportOpeningItem>) => o.roomId === room.roomId)
                .map(this.toOpeningItem);
            room.roomItems = exportRoomItems
                .filter((i: Partial<IExportRoomItem>) => i.roomId === room.roomId)
                .map(this.toRoomItem);

            room.roomItems.forEach((i: IRoomItem) => {
                i.collisions = RoomCollisionsUtils.initCollisionPolygon(
                    i.coordsReal!.slice(0, -1),
                    RoomShape.Rectangle
                );
            });
            return room;
        });

        this.updateOldFields(rooms);

        const exportMap = { state: { rooms }, direction };
        Logger.log('MapExportV3.1 : fromApiJsonToState', exportMap);
        return exportMap;
    };

    /**
     ** Replaces old labels in the saved floorplan object with new ones.
     ** This process is unfortunately necessary to keep older projects compatible with wording changes.
     **/
    private static updateOldFields(rooms: Partial<IExportRoom>[]) {
        const toUndefinedIfDefault = (value: string = '') => (value === 'default' ? undefined : value);
        rooms.forEach((room: IExportRoom) => {
            if (room.roomExistingFloorType === 'Revêtement plastique (vinyle, lino, dalle)') {
                room.roomExistingFloorType = 'Plastique (vinyle, lino, dalle, gazon)';
            }
            if (room.roomHeatingType === 'Je ne connais pas le type de chauffage') {
                room.roomHeatingType = 'Je ne connais pas le type de chauffage au sol';
            }
            room.roomExistingFloorType = toUndefinedIfDefault(room['roomExistingFloorType']);
        });
    }

    //#region //* CONVERTER : MAP_ITEM TO EXPORT_ITEM

    public static toExportRoom = (baseRoom: IRoom): Partial<IExportRoom> => {
        const room = CreateRoomFactory.exportRoom(baseRoom);
        return {
            roomId: room.roomId,
            name: room.name,
            namedByUser: room.namedByUser,
            shape: room.shape,

            // calcul_en_laize: room.calcul_en_laize, //todo @mmc
            // calcul_en_laize_sub_room_elements: room.calcul_en_laize_sub_room_elements,//todo @mmc
            //surface_area: room.surface_area

            //* USELESS
            //surface_area_without_room_item: room.surface_area_without_room_item,
            //perimeter: room.perimeter,
            //perimeter_plinthes: room.perimeter_plinthes,
            //raccord_length: room.raccord_length,
            //room_length: room.room_length,
            //surface_manipulee_prorata: room.surface_manipulee_prorata,
            //should_lay_plinthes_inside_room_element: room.should_lay_plinthes_inside_room_element,

            localisation: room.localisation,
            roomType: room.roomType,
            roomFloor: room.roomFloor,
            roomHeating: room.roomHeating,
            roomHeatingType: room.roomHeatingType,
            roomExistingFlooring: room.roomExistingFlooring,
            roomExistingFloorType: room.roomExistingFloorType,

            questionsCopiedFromId: room.questionsCopiedFromId,

            contournements: room.contournements,

            //* COORDS
            dimensions: room.dimensions,
            coords: room.coords,
            center: room.center,

            //sides: room.sides,
            //objects: room.objects, //todo @mmc, why is this here?
        };
    };

    public static toExportOpeningItem = (opening: IOpening): Partial<IExportOpeningItem> => {
        return {
            openingId: opening.openingId,
            roomId: opening.roomId,
            type: opening.type,
            name: opening.name,
            namedByUser: opening.namedByUser,

            size: opening.size,
            customSize: opening.customSize,

            location_side: opening.location_side,
            location_position_from_edge: opening.location_position_from_edge,

            x: opening.x,
            y: opening.y,
            limit: opening.limit, //! WHY
            angle: opening.angle, //! WHY

            //* USELESS
            // contiguous_between_rooms: opening.contiguous_between_rooms,

            // hinge: opening.hinge, //! WHY
            // scale: opening.scale,
            // thick: opening.thick,
            // value: opening.value,
            // width: opening.width,
            // height: opening.height,

            // position: opening.position,
            // angleSign: opening.angleSign,
        };
    };

    public static toExportRoomItem = (roomItem: IRoomItem): Partial<IExportRoomItem> => {
        return {
            type: roomItem.type,
            roomItemId: roomItem.roomItemId,
            roomId: roomItem.roomId,
            name: roomItem.name,
            namedByUser: roomItem.namedByUser,

            putFlooring: roomItem.putFlooring,
            putPlinthes: roomItem.putPlinthes,
            isMoveableOutside: roomItem.isMoveableOutside,
            width: roomItem.width,
            height: roomItem.height,

            coords: roomItem.coords,
            coordsReal: roomItem.coordsReal,
            graphTranslation: PointUtils.toPosition(roomItem.graphTranslation as CoordPoint),

            //collisions: roomItem.collisions,
            //surface: roomItem.surface,
            //horizontal_size: roomItem.horizontal_size,
            //vertical_size: roomItem.vertical_size,
            //room_elements_length: roomItem.room_elements_length,
            //position: roomItem.position,
        };
    };

    //#endregion

    //#region //* CONVERTER : EXPORT_ITEM TO MAP_ITEM

    public static toRoom = (room: IExportRoom): IRoom => {
        //const room = CreateRoomFactory.exportRoom(baseRoom);
        if (room.roomHeatingType) {
            room.roomHeating =
                room.roomHeatingType === I18n.get('RoomItemDialog_RoomHeatingTypeList_PasDeChauffage')
                    ? YesOrNo.No
                    : YesOrNo.Yes;
        }
        return {
            roomId: room.roomId,
            name: room.name,
            namedByUser: room.namedByUser,
            shape: room.shape,

            localisation: room.localisation,
            roomType: room.roomType,
            roomFloor: room.roomFloor,
            roomHeating: room.roomHeating,
            roomHeatingType: room.roomHeatingType,
            roomExistingFlooring: room.roomExistingFlooring,
            roomExistingFloorType: room.roomExistingFloorType,

            questionsCopiedFromId: room.questionsCopiedFromId,

            contournements: room.contournements,

            //* COORDS
            dimensions: room.dimensions,
            center: room.center,
            coords: room.coords,

            //sides: room.sides,
            //objects: room.objects, //todo @mmc, why is this here?
            // calcul_en_laize: room.calcul_en_laize, //todo @mmc
            // calcul_en_laize_sub_room_elements: room.calcul_en_laize_sub_room_elements,//todo @mmc

            // surface_area: room.surface_area,
            // surface_area_without_room_item: room.surface_area_without_room_item,
            // perimeter: room.perimeter,
            // perimeter_plinthes: room.perimeter_plinthes,
            // raccord_length: room.raccord_length,
            // room_length: room.room_length,
            // surface_manipulee_prorata: room.surface_manipulee_prorata,
            // should_lay_plinthes_inside_room_element: room.should_lay_plinthes_inside_room_element,
        };
    };

    public static toOpeningItem = (opening: IExportOpeningItem): IOpening => {
        return {
            openingId: opening.openingId,
            roomId: opening.roomId,

            type: opening.type,
            name: opening.name,
            size: opening.size,
            customSize: opening.customSize,

            location_side: opening.location_side,
            location_position_from_edge: opening.location_position_from_edge,

            x: opening.x,
            y: opening.y,
            limit: opening.limit,
            angle: opening.angle,

            //* USELESS
            // contiguous_between_rooms: opening.contiguous_between_rooms, //!\ warning on load

            // hinge: opening.hinge,
            // scale: opening.scale,
            // thick: opening.thick,
            // value: opening.value,
            // position: opening.position,
            // angleSign: opening.angleSign,
            // width: opening.width,
            // height: opening.height,

            namedByUser: opening.namedByUser,
        };
    };

    public static toRoomItem = (roomItem: Partial<IExportRoomItem>): IRoomItem => {
        return {
            type: roomItem.type,
            roomItemId: roomItem.roomItemId,
            roomId: roomItem.roomId,
            name: roomItem.name,
            namedByUser: roomItem.namedByUser,

            putFlooring: roomItem.putFlooring,
            putPlinthes: roomItem.putPlinthes,
            isMoveableOutside: roomItem.isMoveableOutside,

            width: roomItem.width,
            height: roomItem.height,
            coords: roomItem.coords,
            coordsReal: roomItem.coordsReal,
            graphTranslation: roomItem.graphTranslation,

            //collisions: roomItem.collisions,
            // position: roomItem.position,
            // surface: roomItem.surface,
            // horizontal_size: roomItem.horizontal_size,
            // vertical_size: roomItem.vertical_size,
            // room_elements_length: roomItem.room_elements_length,
        };
    };

    //#endregion
}

//#region //* EXPORT MODELS

interface IExportRoom {
    shape?: RoomShape;
    roomId?: string;
    name?: string;
    namedByUser?: boolean;

    //* ROOM INFOS : injected in context
    roomType?: string; // rename
    localisation?: RoomLocalisation;
    roomFloor?: string;
    roomExistingFlooring?: YesOrNo | string; //presenceRevetementAuSol
    roomExistingFloorType?: string;
    roomHeating?: YesOrNo | string;
    roomHeatingType?: string;

    questionsCopiedFromId?: string;

    contournements?: IContournements;

    //* injected by MapResult -> calculate
    // raccord_length?: number;
    // room_length?: number;
    // surface_area?: number;
    // surface_area_without_room_item?: number;
    // surface_manipulee_prorata?: number;
    // perimeter?: number;
    // perimeter_plinthes?: number;
    // should_lay_plinthes_inside_room_element?: boolean;

    center?: CoordPoint;
    dimensions?: RoomDimensions;
    coords?: Array<CoordPoint>;
    //sides?: Map<string, Array<CoordPoint>>;
}

interface IExportOpeningItem {
    openingId?: string;
    roomId?: string;

    type?: OpeningType;
    name?: string;
    namedByUser?: boolean;

    size?: number;
    customSize?: boolean;

    //* OpeningProperties
    doorType?: string;
    unhingeableOpening?: string; //* Oui ou Non

    //* COORDS
    x?: number;
    y?: number;
    limit?: CoordPoint[];
    angle?: number;
    location_side?: string;
    location_position_from_edge?: number;

    // contiguous_between_rooms?: Array<string>; //! WHY
    // hinge?: string;
    // position?: CoordPoint;
    // thick?: number;
    // value?: number;
    // scale?: CoordPoint;
    // angleSign?: number;
    // width?: string;
    // height?: string;
}

interface IExportRoomItem {
    roomItemId?: string;
    roomId?: string;

    name?: string;
    namedByUser?: boolean;

    type: RoomItemType;
    putFlooring?: YesOrNo;
    putPlinthes?: string;
    isMoveableOutside?: boolean; // #20350

    width?: number;
    height?: number;

    coords?: Array<CoordPoint>;
    coordsReal?: Array<CoordPoint>;
    position?: CoordPoint;
    graphTranslation?: CoordPoint;

    angle?: number;

    //collisions?: Array<RoomCollision>;
    //injected in context
    //surface?: number;
    //horizontal_size?: number;
    //vertical_size?: number;
    //room_elements_length?: number;
}

//#endregion
