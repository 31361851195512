import React from 'react';
import { ClassiqueHorizontal } from './ClassiqueHorizontal';
import { ClassiqueSelected } from './ClassiqueHorizontalSelected';
import { ClassiqueVertical } from './ClassiqueVertical';
import { ClassiqueVerticalSelected } from './ClassiqueVerticalSelected';
import { LaizeHorizontal } from './LaizeHorizontal';
import { LaizeHorizontalSelected } from './LaizeHorizontalSelected';
import { LaizeVertical } from './LaizeVertical';
import { LaizeVerticalSelected } from './LaizeVerticalSelected';
import { LameHorizontal } from './LameHorizontal';
import { LameHorizontalSelected } from './LameHorizontalSelected';
import { LameVertical } from './LameVertical';
import { LameVerticalSelected } from './LameVerticalSelected';

export type FlooringDirectionProps = { fillColor?: string; svgProps?: React.SVGProps<SVGSVGElement> };
export class FlooringDirectionAssets {
    public static LaizeHorizontal = ({ props = {} }: { props?: FlooringDirectionProps }) => (
        <LaizeHorizontal props={props} />
    );
    public static LaizeHorizontalSelected = () => <LaizeHorizontalSelected />;
    public static LaizeVertical = ({ props = {} }: { props?: FlooringDirectionProps }) => (
        <LaizeVertical props={props} />
    );
    public static LaizeVerticalSelected = () => <LaizeVerticalSelected />;

    public static LameHorizontal = ({ props = {} }: { props?: FlooringDirectionProps }) => (
        <LameHorizontal props={props} />
    );
    public static LameHorizontalSelected = () => <LameHorizontalSelected />;
    public static LameVertical = ({ props = {} }: { props?: FlooringDirectionProps }) => <LameVertical props={props} />;
    public static LameVerticalSelected = () => <LameVerticalSelected />;

    public static ClassiqueHorizontal = ({ props = {} }: { props?: FlooringDirectionProps }) => (
        <ClassiqueHorizontal props={props} />
    );
    public static ClassiqueHorizontalSelected = () => <ClassiqueSelected />;
    public static ClassiqueVertical = ({ props = {} }: { props?: FlooringDirectionProps }) => (
        <ClassiqueVertical props={props} />
    );
    public static ClassiqueVerticalSelected = () => <ClassiqueVerticalSelected />;
}
