import { styled } from '@material-ui/styles';

export const Focus = styled('div')({
    width: 4,
    borderRadius: 4,
    position: 'absolute',
    background: '#E5A033',
    top: 0,
    left: -15,
    bottom: 0,
});
