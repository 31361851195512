import { ProduitDto } from '../../../../Models/Questions/ProduitDto';
import { CoordPoint } from '../../Utils/Types';
import { LaizeBandItemV3 } from './v3/LaizeBandTrace';
import { CalculateOptimalBandsLayoutParams, RoomGroupDebug } from './v3/LaizeV3';

export enum FlooringDirection {
    //* Laize Directions
    LaizeVertical = 'LaizeVertical',
    LaizeHorizontal = 'LaizeHorizontal',

    //* LamePleine Directions
    LameVertical = 'LameVertical',
    LameHorizontal = 'LameHorizontal',
    //* Lame Optimisé Directions
    ClassiqueVertical = 'ClassiqueVertical',
    ClassiqueHorizontal = 'ClassiqueHorizontal',
}

export enum LaizeDirection {
    Vertical = 'vertical',
    Horizontal = 'horizontal',
    Classique = 'classique',
    None = 'non', //!DANS LES EVALUATIONS depuis le BO
}

export enum ProductType {
    LamePleine = 'lamePleine',
    Rouleau = 'rouleau',
}

export enum BandType {
    New = 'band-new',
    Reusable = 'band-reusable',
    Reused = 'band-reused',
}

export enum Direction {
    LeftToRight = 'leftToRight',
    RightToLeft = 'rightToLeft',
}

export interface LaizeProps {
    produit?: ProduitDto;
    productCode?: string;
    type?: ProductType;

    largeurCm?: number;
    longueurCm?: number;
    raccordCm?: number;
    largeurMarginCm?: number;
    longueurMarginCm?: number;

    //! V3
    roomIds?: Array<string>;
}

export const DEFAULT_LAIZE_PROPS: LaizeProps = {
    productCode: '',
    type: undefined,
    largeurCm: 100,
    longueurCm: 500,
    raccordCm: 0,
    largeurMarginCm: 0,
    longueurMarginCm: 0,
    roomIds: [],
};

export type LaizeBand = {
    id: number;
    length: number;
    leftOverUsed?: boolean;

    //!v3
    roomId?: string;
    leftOverCoords?: CoordPoint[][];
    surfaceCoveredCoords?: CoordPoint[];
};

export type LaizeResult = {
    laizes?: Array<LaizeBand>; //* Bandes utilisées
    // horizontal?: number; //* Total ML (ou nombres de lames) dans le sens horizontal
    // vertical?: number; //* Total ML (ou nombres de lames) dans le sens vertical

    calculator?: LaizeCalculatorResult; //* Résultat du calculateur

    roomIds?: Array<string>; //* RoomIds used in the laize computer
    productCode?: string; //* ProductCode used in the laize computer
    result?: number;
    roomsRaccordLength?: Array<LaizeRoomRaccord>;

    /**
     * Flooring direction used in the laize computer by roomId.
     * Comes from the vendor's choice or this rule:
     * - If the product is a rouleau, calculate the cheapest direction.
     * - If the product is a lame pleine, force the direction to "optimisation des lames horizontales"
     */
    flooringDirectionByRoomId?: { [roomId: string]: FlooringDirection };

    /**
     * List of possible flooring directions by roomId.
     * For rouleau: [LaizeDirection.Horizontal, LaizeDirection.Vertical]
     * For lame pleine: [FlooringDirection.ClassiqueHorizontal, FlooringDirection.ClassiqueVertical]
     * + FlooringDirection.LameHorizontal if possible
     * + FlooringDirection.LameVertical if possible
     */
    possibleFlooringDirectionByRoomId?: { [roomId: string]: FlooringDirection[] };
};

export type LaizeRoomRaccord = { roomId: string; length: number; direction: string };

export type LaizeBandDetails = {
    roomId: string;
    bands: Array<LaizeBand>;
    leftOverBandHasBeenReusedInThisRoom?: boolean;
};

export type LaizeCalculatorResult = {
    isPossible: boolean;

    laizeResult: number;

    roomsRaccordLength: Array<LaizeRoomRaccord>;
    roomsBandsLength: Array<number>;
    roomsBandsDetails: Array<LaizeBandDetails>;
    roomsBandsDetailsContiguous: Array<LaizeBand>;

    //!ONLY TO TRACE IN MAP
    requestedBy?: string;
    bands_v3?: Array<LaizeBandItemV3>;
    iteration?: number;

    /**
     * Flooring direction used in the laize computer by roomId.
     * Comes from the vendor's choice or this rule:
     * - If the product is a rouleau, calculate the cheapest direction.
     * - If the product is a lame pleine, force the direction to "optimisation des lames horizontales"
     */
    flooringDirectionByRoomId?: { [roomId: string]: FlooringDirection };

    /**
     * List of possible flooring directions by roomId.
     * For rouleau: [LaizeDirection.Horizontal, LaizeDirection.Vertical]
     * For lame pleine: [FlooringDirection.ClassiqueHorizontal, FlooringDirection.ClassiqueVertical]
     * + FlooringDirection.LameHorizontal if possible
     * + FlooringDirection.LameVertical if possible
     */
    possibleFlooringDirectionByRoomId?: { [roomId: string]: FlooringDirection[] };

    roomGroupsDebug?: RoomGroupDebug[];

    params?: CalculateOptimalBandsLayoutParams;
};
