import { MapConstants } from '../MapConstants';
import { IRoom } from '../Models/IRoom';
import { PointUtils } from './PointUtils';
import { CoordPoint } from './Types';

export class ZoomUtils {
    //* fit function to get the best acceptance distance in relation to zoom
    public static sensibilityFormula = () => -5.1 * MapConstants.zoom + 54.39;

    public static zoomFitAllRooms = (rooms: Array<IRoom> = []) => {
        const points: Array<CoordPoint> = [];
        rooms.forEach((room) => points.push(...room.coords!));

        const boundingBox = PointUtils.calculateBoundingBox({ points });
        if (boundingBox) {
            const topLeft: CoordPoint = { x: boundingBox.xMin, y: boundingBox.yMin };
            const topRight: CoordPoint = { x: boundingBox.xMax, y: boundingBox.yMin };
            const bottomLeft: CoordPoint = { x: boundingBox.xMin, y: boundingBox.yMax };
            const bottomRight: CoordPoint = { x: boundingBox.xMax, y: boundingBox.yMax };

            const selectionWidth = boundingBox.xMax - boundingBox.xMin;
            const selectionHeight = boundingBox.yMax - boundingBox.yMin;

            const Viewer = window.MapViewer;

            //! 100 = côte width (66) + extra width : 44 (valeur arbitraire)
            const width = selectionWidth + 100 * 2;
            //! 150 = côte width (66) + extra height : 44 (valeur arbitraire) + 50 (Tuto)
            const height = selectionHeight + 150 * 2;

            const { viewerWidth, viewerHeight } = Viewer.getValue();
            const centerOfBoundingBox = PointUtils.bboxCenter([topLeft, topRight, bottomLeft, bottomRight]);

            const ratioWidth = viewerWidth / width;
            const ratioHeight = viewerHeight / height;
            const zoom = ratioWidth < ratioHeight ? ratioWidth : ratioHeight;

            Viewer.fitSelection(topLeft.x, topLeft.y, width, height);
            Viewer.setPointOnViewerCenter(centerOfBoundingBox.x, centerOfBoundingBox.y, zoom);
        }
    };
}
