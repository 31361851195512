import React from 'react';

import { GestureEvent } from '../../../../../Events/withEvent';

type MenuDeleteDialogParams = { confirmDelete?: VoidFunction };
export const useMenuDeleteDialog = ({ confirmDelete }: MenuDeleteDialogParams) => {
    const [showDelete, setShowDelete] = React.useState<boolean>(false);
    const requestDelete = (e?: GestureEvent) => {
        setShowDelete(true);
    };
    const onDelete = () => {
        setShowDelete(false);
        confirmDelete?.();
    };

    const close = (from?: string) => {
        setShowDelete(false);
    };

    return { showDelete, onDelete, requestDelete, close };
};
