import { RoomShape } from "../../Models/IRoom";
import { RoomCollision, SATUtils } from "../SAT/SATUtils";
import { CoordPoint } from "../Types";

export class RoomCollisionsUtils {

    public static initCollisionPolygon(coords: Array<CoordPoint>, roomShape?: RoomShape) {
        let collisions: Array<RoomCollision> = [];
        switch (roomShape) {
            //* Convexe shapes
            case RoomShape.Rectangle:
            case RoomShape.Open_bottom_rectangle:
            case RoomShape.Diamond_topleft:
            case RoomShape.Diamond_topright:
            case RoomShape.Diamond_bottomleft:
            case RoomShape.Diamond_bottomright:
            case RoomShape.Custom:
                collisions = [SATUtils.getPolyCollision()];
                break;
            //* Concave shapes
            case RoomShape.Flyingwing_topleft:
            case RoomShape.Flyingwing_topright:
            case RoomShape.Flyingwing_bottomleft:
            case RoomShape.Flyingwing_bottomright:
                collisions = [SATUtils.getPolyCollision(), SATUtils.getPolyCollision()];
                break;
        }
        return this.getCollisionPolygon(collisions, coords, roomShape);
    }

    public static getCollisionPolygon(collisions: Array<RoomCollision> = [], coords: Array<CoordPoint>, roomShape?: RoomShape) {
        switch (roomShape) {
            //Convexe shapes
            case RoomShape.Rectangle:
            case RoomShape.Open_bottom_rectangle:
            case RoomShape.Diamond_topleft:
            case RoomShape.Diamond_topright:
            case RoomShape.Diamond_bottomleft:
            case RoomShape.Diamond_bottomright:
            case RoomShape.Custom:
                collisions[0].setPoints?.(coords);
                return collisions;
            //Concave shapes
            case RoomShape.Flyingwing_topleft:
            case RoomShape.Flyingwing_bottomright:
                {
                    const coordA = { x: coords[0].x, y: coords[3].y };
                    const coordB = { x: coords[3].x, y: coords[0].y };
                    collisions[0].setPoints?.([coords[0], coords[1], coords[2], coordA]);
                    collisions[1].setPoints?.([coordB, coords[4], coords[5], coords[0]]);
                    return collisions;
                }
            case RoomShape.Flyingwing_topright:
            case RoomShape.Flyingwing_bottomleft:
                {
                    const coordA = { x: coords[3].x, y: coords[0].y };
                    const coordB = { x: coords[0].x, y: coords[3].y };
                    collisions[0].setPoints?.([coords[0], coords[1], coords[2], coordA]);
                    collisions[1].setPoints?.([coordB, coords[4], coords[5], coords[0]]);
                    return collisions;
                }
        }
    }
}