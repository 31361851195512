import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { QuestionContext, QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { StringKey } from '../../../../Locales/fr';
import { I18n } from '../../../../Locales/I18nService';
import { FlooringDirection } from '../../../../Plugins/FloorPlan/Components/Laizes/Laize';
import { IMapItem, YesOrNo } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { QuoteStep } from '../../../../Types/QuoteStep';

export class QuestionContextUtils {
    public static createRoomContext = (
        room: IRoom,
        flooringDirection?: FlooringDirection,
        fromContext: QuestionContext = {}
    ): QuestionContext => {
        const contextFromPreviousStep = ObjectUtils.clone(fromContext);

        contextFromPreviousStep.connection_needed = room.raccord_length ? room.raccord_length !== 0 : false;
        contextFromPreviousStep.room_length = room.room_length;
        contextFromPreviousStep.room_surface = room.surface_area;

        contextFromPreviousStep.room_type = room.roomType;
        contextFromPreviousStep.localisation_piece = room.localisation;

        contextFromPreviousStep.flooring_direction = flooringDirection;

        contextFromPreviousStep.room_floor = QuestionContextHelper.transformIntoLongFloorName(room.roomFloor);
        if (room.roomHeating === YesOrNo.No) {
            contextFromPreviousStep.room_heating_type = I18n.get('RoomItemDialog_RoomHeatingTypeList_PasDeChauffage');
        } else if (room.roomHeating === YesOrNo.Yes) {
            contextFromPreviousStep.room_heating_type = room.roomHeatingType;
        }
        contextFromPreviousStep.has_room_existing_flooring = room.roomExistingFlooring;
        contextFromPreviousStep.room_existing_floor_type = room.roomExistingFloorType;
        contextFromPreviousStep.room_should_lay_plinthes_inside_room_element =
            room.should_lay_plinthes_inside_room_element;

        contextFromPreviousStep.contournements = {
            pipe: room.contournements?.Pipe,
            mounted_element: room.contournements?.MountedElement,
            mounted_radiator: room.contournements?.MountedRadiator,
            pillar: room.contournements?.Pillar,
            stonewall: room.contournements?.Stonewall,
            stairs: room.contournements?.Stairs,
            railing: room.contournements?.Railing,
            tree: room.contournements?.Tree,
        };
        return contextFromPreviousStep;
    };

    public static createOpeningContext = (opening: IOpening, room: IRoom, flooringDirection?: FlooringDirection): QuestionContext => {
        const contextFromPreviousStep: QuestionContext = {};
        contextFromPreviousStep.flooring_direction = flooringDirection;
        contextFromPreviousStep.are_rooms_contiguous = Boolean(opening.contiguous_between_rooms?.length);
        contextFromPreviousStep.opening_type_localized = I18n.get(`Floorplan_Opening_${opening.type}` as StringKey);
        contextFromPreviousStep.opening_dimension_centimetre = opening.size;
        const roomContext = this.getLastStepContext(room, QuoteStep.Service);
        return QuestionContextHelper.mergeContext(roomContext, contextFromPreviousStep);
    };

    public static createRoomItemContext = (roomItem: IRoomItem, room: IRoom, flooringDirection?: FlooringDirection): QuestionContext => {
        const contextFromPreviousStep: QuestionContext = {};
        contextFromPreviousStep.room_element_type_localized = I18n.get(`Floorplan_RoomElement_${roomItem.type}` as StringKey);
        contextFromPreviousStep.flooring_direction = flooringDirection;
        contextFromPreviousStep.should_lay_flooring_inside = roomItem.putFlooring ?? QuestionContextHelper.NOT_SET;
        contextFromPreviousStep.should_lay_plinthes_inside = roomItem.putPlinthes ?? QuestionContextHelper.NOT_SET;
        const roomContext = this.getLastStepContext(room, QuoteStep.Service);
        return QuestionContextHelper.mergeContext(roomContext, contextFromPreviousStep);
    };

    public static getLastStepContext = (item: IMapItem, step: QuoteStep): QuestionContext => {
        if (step === QuoteStep.Service) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsServices?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuoteStep.Finitions)
            );
        }
        if (step === QuoteStep.Finitions) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsFinition?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuoteStep.Preparation)
            );
        }
        if (step === QuoteStep.Preparation) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsPreparation?.questions)?.lastShownQuestion
                    ?.context || this.getLastStepContext(item, QuoteStep.Support)
            );
        }
        if (step === QuoteStep.Support) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsSupport?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuoteStep.Pose)
            );
        }
        if (step === QuoteStep.Pose) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsPose?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuoteStep.Infos)
            );
        }
        return item.questionsBaseContext || {};
    };
}
