import { I18n } from '../../../Locales/I18nService';
import { IRoom, RoomDimensions, RoomShape } from '../Models/IRoom';


const BaseRoom: IRoom = {
    name: '',
    roomType: '',
    roomFloor: '',
    roomExistingFlooring: '',
    roomExistingFloorType: '',
    roomHeating: '',
    roomHeatingType: '',
    openings: [],
    roomItems: [],
}

const toDiamondDimensions = (width: number, height: number): RoomDimensions => {
    const min = Math.min(width, height);
    const table = min / 1.5;
    const unkownNumber = Math.sqrt(Math.pow(table, 2) / 2);
    const left_crown = width - unkownNumber;
    const right_crown = height - unkownNumber;
    //console.log('toDiamondDimension', { width, height, min, table, unkownNumber, left_crown, right_crown })

    return { left_crown, table, right_crown };
}

const FLYING_WING_SIZE_RATIO = 0.5; //0.625;(ratio de base avec les anciens tailles en dur)

export class RoomFactory {
    //#region //* NAME GENERATOR

    public static defaultName = (roomCount: number = 0) => `Pièce ${roomCount + 1}`;

    public static isDefaultName = (roomName: string = '') => {
        const [name, num] = roomName.split(' ');
        return name === 'Pièce' && Boolean(num.length) && Number.isFinite(parseInt(num));
    };

    public static getDuplicateName(room: IRoom, rooms: Array<IRoom>) {
        const roomNumbers = rooms
            .filter((r) => r.name)
            .map((r) => {
                const num = r.name!.split(' ')[1];
                return parseInt(num);
            });
        if (roomNumbers.length) {
            const name = room.name!.split(' ')[0];
            const max = Math.max(...roomNumbers) + 1;
            return `${name} ${max}`;
        }
        return `${room.name} 2`;
    }

    public static toUniqueName = (rooms: Array<IRoom>, roomType: string = '', roomFloor: string = '') => {
        if (
            roomFloor === I18n.get('RoomItemDialog_RoomFloorList_Etage1a3') ||
            roomFloor === I18n.get('RoomItemDialog_RoomFloorList_4etPlus')
        ) {
            roomFloor = I18n.get('RoomItemDialog_RoomFloorList_Floor'); //floor exception, fr string
        }
        const toBaseRoomName = (type: string = '', floor: string = '') => `${type}`;
        let wantedRoomName = toBaseRoomName(roomType, roomFloor);
        if (!rooms.some((x) => x.name === wantedRoomName)) {
            return wantedRoomName;
        } else {
            let i = 2;
            wantedRoomName = `${roomType} ${i}`;
            // eslint-disable-next-line no-loop-func
            while (rooms.some((x) => x.name === wantedRoomName)) {
                wantedRoomName = `${roomType} ${i}`;
                i++;
            }
            return wantedRoomName;
        }
    };

    //#endregion

    public static createBaseRoom = (roomShape: RoomShape, width: number, height: number): IRoom => {
        switch (roomShape) {
            case RoomShape.Rectangle:
                return this.createRectangle(width, height);
            case RoomShape.Open_bottom_rectangle:
                return this.createOpenBottomRectangle(width, height);

            case RoomShape.Diamond_topleft:
                return this.createDiamondTopLeft(width, height);
            case RoomShape.Diamond_topright:
                return this.createDiamondTopRight(width, height);
            case RoomShape.Diamond_bottomleft:
                return this.createDiamondBottomLeft(width, height);
            case RoomShape.Diamond_bottomright:
                return this.createDiamondBottomRight(width, height);

            case RoomShape.Flyingwing_topleft:
                return this.createFlyingwingTopLeft(width, height);
            case RoomShape.Flyingwing_topright:
                return this.createFlyingwingTopRight(width, height);
            case RoomShape.Flyingwing_bottomleft:
                return this.createFlyingwingBottomLeft(width, height);
            case RoomShape.Flyingwing_bottomright:
                return this.createFlyingwingBottomRight(width, height);

            default:
                throw new Error(`the ${roomShape} is not supported`);
        }
    };
    //#region //* RECTANGE

    private static createRectangle = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Rectangle,
            //dimensions: { width: 4, height: 3 },
            dimensions: { width, height },
        };
    };

    private static createOpenBottomRectangle = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Open_bottom_rectangle,
            //dimensions: { width: 5, height: 4 },
            dimensions: { width, height },
        };
    };

    //#endregion

    //#region //* DIAMOND
    private static createDiamondTopLeft = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_topleft,
            //dimensions: { left_crown: 1.5, table: 2.12, right_crown: 2.5 },
            dimensions: toDiamondDimensions(height, width)
        };
    };
    private static createDiamondTopRight = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_topright,
            //dimensions: { left_crown: 2.5, table: 2.12, right_crown: 1.5 },
            dimensions: toDiamondDimensions(width, height)
        };
    };
    private static createDiamondBottomLeft = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_bottomleft,
            //dimensions: { left_crown: 2.5, table: 2.12, right_crown: 1.5 },
            dimensions: toDiamondDimensions(width, height)
        };
    };
    private static createDiamondBottomRight = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_bottomright,
            //dimensions: { left_crown: 1.5, table: 2.12, right_crown: 2.5 },
            dimensions: toDiamondDimensions(height, width)
        };
    };

    //#endregion

    //#region  //* FLYING
    private static createFlyingwingTopLeft = (width: number, height: number): IRoom => {
        const right_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const left_wingtip = width - right_trailing_edge;
        const right_wingtip = height / 2;
        const left_trailing_edge = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_topleft,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 2.5, left_trailing_edge: 1.5, left_wingtip: 1.5 },
            dimensions
        };
    };
    private static createFlyingwingTopRight = (width: number, height: number): IRoom => {
        const left_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const right_wingtip = width - left_trailing_edge;
        const right_trailing_edge = height / 2;
        const left_wingtip = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_topright,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 1.5, left_trailing_edge: 2.5, left_wingtip: 1.5 },
            dimensions
        };
    };
    private static createFlyingwingBottomLeft = (width: number, height: number): IRoom => {
        const left_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const right_wingtip = width - left_trailing_edge;
        const right_trailing_edge = height / 2;
        const left_wingtip = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_bottomleft,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 1.5, left_trailing_edge: 2.5, left_wingtip: 1.5 },
            dimensions,
        };
    };
    private static createFlyingwingBottomRight = (width: number, height: number): IRoom => {
        const right_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const left_wingtip = width - right_trailing_edge;
        const left_trailing_edge = height / 2;
        const right_wingtip = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_bottomright,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 2.5, left_trailing_edge: 1.5, left_wingtip: 1.5 },
            dimensions
        };
    };

    //#endregion
}

