import { IOpening } from '../../Models/IOpening';
import { Editor } from '../Editor';
import { PointUtils } from '../PointUtils';
import { qSVG } from '../qSVG';
import { CoordPoint, WallLine } from '../Types';

export class WallTools {

    //take the wall and progressively (recursively) slide each openings of the wall.
    public static slideObjOnWall(wall: WallLine, openings: Array<IOpening>) {
        //todo TLE need refacto
        const objs = Editor.objFromWallRoom(wall, openings);
        const eq = Editor.createEquationFromWall(wall);
        for (let obj of objs) {
            let moved = false;
            const limits = PointUtils.limitObj(eq, obj.size!, obj as CoordPoint);
            if (!qSVG.btwn(limits[0].x, wall.start.x, wall.end.x)) {
                var diff1 = wall.start.x - limits[0].x;
                var diff2 = wall.end.x - limits[0].x;

                let newCoordinates;
                if (Math.abs(diff1) < Math.abs(diff2)) {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: diff1, y: 0 });
                } else {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: diff2, y: 0 });
                }
                obj.x = newCoordinates.x;
                obj.y = newCoordinates.y;
                obj.limit = PointUtils.limitObj(eq, obj.size!, obj as CoordPoint);

                //obj.update(); //todo: rerender
                moved = true;
            }

            if (!qSVG.btwn(limits[0].y, wall.start.y, wall.end.y)) {
                const diff1 = wall.start.y - limits[0].y;
                const diff2 = wall.end.y - limits[0].y;

                let newCoordinates;
                if (Math.abs(diff1) < Math.abs(diff2)) {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: 0, y: diff1 });
                } else {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: 0, y: diff2 });
                }
                obj.x = newCoordinates.x;
                obj.y = newCoordinates.y;
                obj.limit = PointUtils.limitObj(eq, obj.size!, obj as CoordPoint);
                moved = true;

                //obj.update(); //todo : rerender
            }

            if (!qSVG.btwn(limits[1].x, wall.start.x, wall.end.x)) {
                const diff1 = wall.start.x - limits[1].x;
                const diff2 = wall.end.x - limits[1].x;

                let newCoordinates;
                if (Math.abs(diff1) < Math.abs(diff2)) {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: diff1, y: 0 });
                } else {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: diff2, y: 0 });
                }
                obj.x = newCoordinates.x;
                obj.y = newCoordinates.y;
                obj.limit = PointUtils.limitObj(eq, obj.size!, obj as CoordPoint);
                moved = true;
                //obj.update(); //todo: rerender
            }

            if (!qSVG.btwn(limits[1].y, wall.start.y, wall.end.y)) {
                const diff1 = wall.start.y - limits[1].y;
                const diff2 = wall.end.y - limits[1].y;

                let newCoordinates;
                if (Math.abs(diff1) < Math.abs(diff2)) {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: 0, y: diff1 });
                } else {
                    newCoordinates = PointUtils.translation(obj as CoordPoint, { x: 0, y: diff2 });
                }
                obj.x = newCoordinates.x;
                obj.y = newCoordinates.y;
                obj.limit = PointUtils.limitObj(eq, obj.size!, obj as CoordPoint);
                moved = true;
                //obj.update();
            }

            if (moved) {
                //find on which side the last opening is, substract the corresponding portion of the wall
                const distanceFromEachSides = [
                    PointUtils.getClosestPointIndex(wall.start, obj.limit!),
                    PointUtils.getClosestPointIndex(wall.end, obj.limit!),
                ];
                if (distanceFromEachSides[0].closestPointDistance < distanceFromEachSides[1].closestPointDistance) {
                    //recursive call to handle colliding openings.
                    this.slideObjOnWall({ ...wall, start: obj.limit![(distanceFromEachSides[0].closestPointIndex + 1) % 2] }, openings);
                } else {
                    this.slideObjOnWall({ ...wall, end: obj.limit![(distanceFromEachSides[1].closestPointIndex + 1) % 2] }, openings);
                }
            }

            //* UPDATE OPENINGS COORD
            openings.forEach(opening => {
                if (opening.openingId === obj.openingId) {
                    opening.x = obj.x;
                    opening.y = obj.y;
                    opening.limit = obj.limit;
                }
            })
        }
    }

}
