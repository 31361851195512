import { CoordPoint, Wall } from "../Types";
import { qSVG } from "../qSVG";


type BinderWall = { intersection1: CoordPoint; intersection2: CoordPoint }
export class CollisionWallMove {
    public static previousCollisionState: boolean = false;
    public static binderBeforeCollision: BinderWall | undefined = undefined;

    public static blockBinder(binderWall: BinderWall | undefined, collisionState: boolean) {
        console.log('previousCollisionState=' + this.previousCollisionState + ' collisionState=' + collisionState);
        if (collisionState !== this.previousCollisionState) {
            this.binderBeforeCollision = binderWall;
            this.previousCollisionState = collisionState;
            return undefined;
        }
        this.previousCollisionState = collisionState;

        if (collisionState) {
            return this.binderBeforeCollision;
        }
        return undefined;
    }

    public static resetBlockBinder() {
        this.previousCollisionState = false;
        this.binderBeforeCollision = undefined;
    }

    public static isRightAngle(radAngle: number) {
        const degAngle = qSVG.angleRadToDeg(radAngle);
        return degAngle === 0 || degAngle === 90 || degAngle === -90 || degAngle === 180;
    }

    public static forceWall(binder: Wall, intersection1: CoordPoint, intersection2: CoordPoint) {
        binder.parent!.end = intersection1;
        binder.child!.start = intersection2;
        binder.start = intersection1;
        binder.end = intersection2;

        //todo: rerender
        // binder.graph[0].children[0].setAttribute('x1', intersection1.x);
        // binder.graph[0].children[0].setAttribute('x2', intersection2.x);
        // binder.graph[0].children[0].setAttribute('y1', intersection1.y);
        // binder.graph[0].children[0].setAttribute('y2', intersection2.y);
        // binder.graph[0].children[1].setAttribute('cx', intersection1.x);
        // binder.graph[0].children[1].setAttribute('cy', intersection1.y);
        // binder.graph[0].children[2].setAttribute('cx', intersection2.x);
        // binder.graph[0].children[2].setAttribute('cy', intersection2.y);

        //todo: rerender
        // Editor.wallsComputing(WALLS, 'move');
        // const Rooms = qSVG.polygonize(WALLS);

        // $('#boxRoom').empty();
        // $('#boxSurface').empty();
        // hoverOnPlanElement(false);
        // editor.roomMaker(Rooms);
    }
}
