import Enumerable from 'linq';

import { ProduitDto } from '../Models/Questions/ProduitDto';
import { OptionDto, QuestionItem, QuestionType } from '../Models/Questions/QuestionDto';
import { QuestionsHelper } from './QuestionsHelper';


export class QuoteQuestionUtils {

    public static toQuestionId = (questionId?: string): string => `question_${questionId}`;
    public static toStepPlanQuestionItemId = (itemId?: string, questionId?: string): string => {
        return `toStepPlanQuestionItem_${itemId}-question_${questionId}`;
    };

    public static toQuestionItemId = (itemId?: string, questionId?: string): string => {
        return `toQuestionItemId-${itemId}-question_${questionId}`;
    };


    public static withtoListInfobulleId = (id?: string): string => `${id}_list_infobulle`;

    public static endStepActionId = 'QuoteStepEnd';

    public static questionsContainerId = 'QuestionsContainer';

    public static filterSellersQuestions(questions: QuestionItem[] = [], isSeller: boolean): QuestionItem[] {
        return (questions)
            .filter((q) => (isSeller ? true : !q.show_only_to_sellers))
            .map((q) => ({
                ...q,
                multiple_choices_properties: q.multiple_choices_properties
                    ? {
                        ...q.multiple_choices_properties,
                        option_list: QuoteQuestionUtils.filterSellersOptions(
                            q.multiple_choices_properties.option_list,
                            isSeller
                        ),
                    }
                    : undefined,
                single_choice_properties: q.single_choice_properties
                    ? {
                        ...q.single_choice_properties,
                        option_list: QuoteQuestionUtils.filterSellersOptions(
                            q.single_choice_properties.option_list,
                            isSeller
                        ),
                    }
                    : undefined,
            }));
    }

    public static makeFirstProduitReadonly(questions: QuestionItem[], produitRef: string) {
        let isFirstProduct = true;
        return questions.map((q) => {
            if (isFirstProduct && q.type === QuestionType.Produit) {
                isFirstProduct = false;
                return { ...q, produitReadonlyReference: produitRef };
            }
            return q;
        });
    }

    public static markFirstProduitAsFirstProduct(questions: QuestionItem[]) {
        let isFirstProduct = true;
        return questions.map((q) => {
            if (q.type === QuestionType.Produit && isFirstProduct) {
                isFirstProduct = false;
                return { ...q, isFirstProduct: true };
            }
            return q;
        });
    }

    private static filterSellersOptions(options: OptionDto[], isSeller: boolean) {
        return options?.filter((o) => (isSeller ? true : !o.show_only_to_sellers));
    }

    public static getProduitPrincipal = (questions: Array<QuestionItem> = []): ProduitDto | undefined => {
        //* get produit principal
        //* La sous-famille en cours est celle du produit sélectionné
        //* à la première question produit affichée du formulaire
        if (questions.length > 0) {
            const questionWith: QuestionItem | undefined = Enumerable.from(questions)
                .where((q: QuestionItem) =>
                    q.type === QuestionType.Produit && !!q.showQuestion && q.produitValue !== undefined
                )
                .firstOrDefault();

            return questionWith?.produitValue;
        }
        return undefined;
    };

    public static getLastVisibleQuestion = (questions: Array<QuestionItem> = []): QuestionItem | undefined => {
        const { lastShownQuestion } = QuestionsHelper.getLastShownQuestion(questions);
        return lastShownQuestion;
    };

    public static getFirstVisibleNotValidQuestion = (questions: Array<QuestionItem> = []): QuestionItem | undefined => {
        const findQuestion: QuestionItem | undefined = questions.length
            ? Enumerable.from(questions).firstOrDefault((q: QuestionItem) => Boolean(q.showQuestion) && !q.isValid)
            : undefined;
        return findQuestion;
    };



}
