import { QuestionContext } from '../../../Helpers/QuestionContextHelper';
import { FindFirstIncompatibilityResult } from '../../../Helpers/QuestionsHelper';
import { QuestionItem } from '../../../Models/Questions/QuestionDto';
import { QuoteStep } from '../../../Types/QuoteStep';
import { TagType } from '../../../Web/Pages/Quote/Widgets/TagLabel';

export interface IMapItem {
    roomId?: string;
    name?: string;
    namedByUser?: boolean;

    questionsBaseContext?: QuestionContext;
    questionsTva?: ContextQuestionList;
    //questionsProduit?: ContextQuestionList;

    questionsPose?: ContextQuestionList;
    questionsSupport?: ContextQuestionList;
    questionsPreparation?: ContextQuestionList;
    questionsFinition?: ContextQuestionList;
    questionsServices?: ContextQuestionList;

    questionsCopiedFromId?: string;

    openSections?: OpenSections;

    //* NEW
    completionStatus?: CompleteStatus;
    findIncompatibilityResult?: FindFirstIncompatibilityResult;
}

export interface ContextQuestionList {
    step: QuoteStep;
    questions?: Array<QuestionItem>;
    isStepCompleted?: boolean; //? REVIEW : IS IT USED @mmc
    endQuestion?: boolean;
    isEmpty?: boolean;

    initialContext?: QuestionContext; //!\ only for debug
    findIncompatibility?: FindFirstIncompatibilityResult; //!\ only for debug
}

export interface OpenSections {
    infos?: boolean;
    questionsPose?: boolean;
    questionsSupport?: boolean;
    questionsPreparation?: boolean;
    questionsFinition?: boolean;
    questionsServices?: boolean;

    //!\ ONLY FOR ROOMS
    amenagementsSection?: boolean;
}


export interface CompleteStatus {
    completedType?: TagType;
    isCompleted?: boolean;

    propertiesCompleted?: boolean;

    infoCompletedType?: TagType;
    infosCompleted?: boolean;

    questionsComplete?: boolean;

    amenagementsCompleted?: boolean;        //!\ONLY FOR ROOMS
    amenagementsCompletedType?: TagType;    //!\ONLY FOR ROOMS
}

export enum YesOrNo {
    Yes = 'Oui',
    No = 'Non',
}

export const MapItemContants = { QuestionsCopiedFromId_NONE: 'none' };
