import { QuestionContext } from './QuestionContextHelper';

export const TauxDeTvaKey = 'Taux de TVA';

export enum TauxDeTva {
    intermediaire = 'intermediaire',
    intermédiaire = 'intermédiaire',
    normal = 'normal',
}

export class TauxDeTvaHelper {
    public static getTauxDeTva = (context: QuestionContext = {}): TauxDeTva => {
        const evaluations = context.evaluations ?? {};
        const tauxDeTva: TauxDeTva = evaluations[TauxDeTvaKey] as TauxDeTva;
        return tauxDeTva;
    };
}
