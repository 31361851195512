const replaceAll = (target: string, search: string, replacement: string) => {
    return target.split(search).join(replacement);
};

export class SvgHelpers {
    public static capture(outerHTML: string = '', toBase64?: boolean) {
        if (outerHTML) {
            const svgStr = outerHTML;
            const result = toBase64
                ? `data:image/svg+xml;base64,${SvgHelpers.utf8_to_b64(this.convertCssVariableToRawValue(svgStr))}`
                : this.convertCssVariableToRawValue(svgStr);
            return result;
        }
        return '';
    }

    public static convertCssVariableToRawValue(p: string) {
        const regex = /var\(--\S+\)/gm;
        return p.replace(regex, (replacement) => {
            const group = /\((.*?)\)/gm.exec(replacement)!;

            const bodyStyles = window.getComputedStyle(document.body);
            const retrievedStyleVariableValue = bodyStyles.getPropertyValue(group[1]);

            return replaceAll(retrievedStyleVariableValue, '"', "'");
        });
    }

    public static replaceUseSvgReferenceByInlineSvg(svgWrapper: SVGSVGElement) {
        // const useReferences = svgWrapper.find('use');
        // useReferences.replaceWith((index) => {
        //     let newInlineSvg = $('<g></g>');
        //     let use = $(useReferences[index]);
        //     Array.from(use.prop('attributes')).forEach((x) => {
        //         if (x.name !== 'href') {
        //             newInlineSvg.attr(x.name, x.value);
        //         }
        //     });
        //     const loadedSvg = $(use.attr('href')).clone();
        //     newInlineSvg.append(loadedSvg);
        //     return newInlineSvg;
        // });
    }

    public static utf8_to_b64(str: string) {
        return window.btoa(unescape(encodeURIComponent(str)));
    }
}
