import { Logger } from '../../../../Errors/Logger';
import { LogLevelType } from '../../../../Services/Analytics/LogLevelType';
import { MapExportV32 } from '../../Helpers/MapExport/MapExportV32';
import { MapConstants } from '../../MapConstants';
import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { Editor } from '../Editor';
import { PointUtils } from '../PointUtils';
import { qSVG } from '../qSVG';
import { CreateRoomFactory } from '../Room/RoomUtils';
import { CoordPoint, Wall, WallLine, WallSelected } from '../Types';
import { WallUtils } from '../Wall/WallUtils';

type WellSelect = { wall: Wall; x: number; y: number };

export class OpeningUtils {
    public static isOverOtherOpening = (openings: IOpening[], openingLimits: CoordPoint[], openingId?: string) => {
        for (const opening of openings) {
            if (
                opening.openingId !== openingId && opening.limit?.length &&
                ((qSVG.btwn(openingLimits[0].x, opening.limit![0].x, opening.limit![1].x) &&
                    qSVG.btwn(openingLimits[0].y, opening.limit![0].y, opening.limit![1].y)) ||
                    (qSVG.btwn(openingLimits[1].x, opening.limit![0].x, opening.limit![1].x) &&
                        qSVG.btwn(openingLimits[1].y, opening.limit![0].y, opening.limit![1].y)) ||
                    (qSVG.btwn(opening.limit![0].x, openingLimits[0].x, openingLimits[1].x) &&
                        qSVG.btwn(opening.limit![0].y, openingLimits[0].y, openingLimits[1].y)) ||
                    (qSVG.btwn(opening.limit![1].x, openingLimits[0].x, openingLimits[1].x) &&
                        qSVG.btwn(opening.limit![1].y, openingLimits[0].y, openingLimits[1].y)))
            ) {
                return opening;
            }
        }
        return undefined;
    };

    public static placeNextToOtherOpening = (binderOpening: IOpening, wallSelect: any, pressedOpening?: IOpening) => {
        if (pressedOpening) {
            const openingPosition = PointUtils.limitObj(
                wallSelect.wall.equations.base,
                pressedOpening.size! + binderOpening.size!,
                pressedOpening as CoordPoint
            );
            return qSVG.measure(openingPosition[0], wallSelect) < qSVG.measure(openingPosition[1], wallSelect)
                ? openingPosition[0]
                : openingPosition[1];
        }
        return { x: wallSelect.x, y: wallSelect.y };
    };

    public static setOpeningAngleWall = (opening: IOpening) => {
        if (opening.limit?.length) {
            const [limitStart, limitEnd] = opening.limit;
            opening.angle = Math.atan2(limitEnd.y - limitStart.y, limitEnd.x - limitStart.x) * 180 / Math.PI;
        }
    }

    public static openingModePlacement(
        wallSelect: WallSelected,
        placingOpening: IOpening,
        openings: Array<IOpening>,
    ): { placingOpening: IOpening; success?: boolean } {

        const placingOpeningOld: Partial<IOpening> = {
            x: placingOpening.x,
            y: placingOpening.y,
            limit: placingOpening.limit
        };
        const wall = wallSelect.wall!;
        const wallSize = WallUtils.wallSize(wall);
        const openingsInWall = Editor.objFromWall(wall, openings);

        let success = false;

        if (placingOpening.size && wallSize >= placingOpening.size) {
            let limits = PointUtils.limitObj((wall as Wall).equations.base, placingOpening.size, wallSelect as CoordPoint);
            const pressedOpening = OpeningUtils.isOverOtherOpening(openingsInWall, limits, placingOpening.openingId);
            //const pressedOpening = OpeningUtils.isOverOtherOpening(openings, limits, placingOpening.openingId);
            if (!pressedOpening) {
                if (
                    qSVG.btwn(limits[0].x, wall.start.x, wall.end.x, false, 0.5) &&
                    qSVG.btwn(limits[0].y, wall.start.y, wall.end.y, false, 0.5) &&
                    qSVG.btwn(limits[1].x, wall.start.x, wall.end.x, false, 0.5) &&
                    qSVG.btwn(limits[1].y, wall.start.y, wall.end.y, false, 0.5)
                ) {
                    placingOpening.x = wallSelect.x!;
                    placingOpening.y = wallSelect.y!;
                    placingOpening.limit = PointUtils.limitObj((wall as Wall).equations.base, placingOpening.size, placingOpening as CoordPoint);
                    success = true;
                }
                if (
                    (wallSelect.x === wall.start.x && wallSelect.y === wall.start.y) ||
                    (wallSelect.x === wall.end.x && wallSelect.y === wall.end.y)
                ) {
                    if (
                        qSVG.btwn(limits[0].x, wall.start.x, wall.end.x, false, 0.5) &&
                        qSVG.btwn(limits[0].y, wall.start.y, wall.end.y, false, 0.5)
                    ) {
                        placingOpening.x = limits[0].x;
                        placingOpening.y = limits[0].y;
                        placingOpening.limit = PointUtils.limitObj((wall as Wall).equations.base, placingOpening.size, placingOpening as CoordPoint);
                        success = true;
                    }

                    if (
                        qSVG.btwn(limits[1].x, wall.start.x, wall.end.x, false, 0.5) &&
                        qSVG.btwn(limits[1].y, wall.start.y, wall.end.y, false, 0.5)
                    ) {
                        placingOpening.x = limits[1].x;
                        placingOpening.y = limits[1].y;
                        placingOpening.limit = PointUtils.limitObj((wall as Wall).equations.base, placingOpening.size, placingOpening as CoordPoint);
                        success = true;
                    }

                    const pressedOpening = OpeningUtils.isOverOtherOpening(openingsInWall, placingOpening.limit!, placingOpening.openingId);
                    if (pressedOpening) {
                        success = false;
                    }
                }

                if (!success) {
                    limits = [wall.start, wall.end];
                    const [limitStart, limitEnd] = limits;
                    if (limitStart && limitEnd) {
                        const equation = (wall as Wall).equations.base;
                        const Aq = equation.A;
                        if (Aq === 'h') {
                            //* WALL TOPSIDE
                            if (limitEnd.x > limitStart.x) {
                                const distanceToLeft = wallSelect.x! - limitStart.x;
                                const distanceToRight = limitEnd.x - wallSelect.x!;
                                const isLeft = distanceToLeft < distanceToRight;
                                if (isLeft) {
                                    placingOpening.x = limitStart.x + (placingOpening.size / 2);//* WALL TOPSIDE LEFT
                                } else {
                                    placingOpening.x = limitEnd.x - (placingOpening.size / 2);//* WALL TOPSIDE RIGHT
                                }
                            } else {//* WALL BOTTOMSIDE
                                const distanceToLeft = wallSelect.x! - limitStart.x;
                                const distanceToRight = limitEnd.x - wallSelect.x!;
                                const isLeft = distanceToLeft < distanceToRight;
                                if (isLeft) {
                                    placingOpening.x = limitEnd.x + (placingOpening.size / 2); //* WALL BOTTOMSIDE LEFT
                                } else {
                                    placingOpening.x = limitStart.x - (placingOpening.size / 2);//* WALL BOTTOMSIDE RIGHT
                                }
                            }
                            placingOpening.y = wallSelect.y;
                            placingOpening.limit = PointUtils.limitObj((wall as Wall).equations.base, placingOpening.size, placingOpening as CoordPoint);
                            success = true;
                        }

                        if (Aq === 'v') {
                            //* WALL RIGHTSIDE
                            if (limitEnd.y > limitStart.y) {
                                const distanceToTop = wallSelect.y! - limitStart.y;
                                const distanceToBottom = limitEnd.y - wallSelect.y!;
                                const isTop = distanceToTop < distanceToBottom;
                                if (isTop) {
                                    placingOpening.y = limitStart.y + (placingOpening.size / 2);//* WALL RIGHTSIDE TOP
                                } else {
                                    placingOpening.y = limitEnd.y - (placingOpening.size / 2);  //* WALL RIGHTSIDE BOTTOM
                                }
                            } else {//* WALL LEFTSIDE
                                const distanceToTop = wallSelect.y! - limitEnd.y;
                                const distanceToBottom = limitStart.y - wallSelect.y!;
                                const isTop = distanceToTop < distanceToBottom;
                                if (isTop) {
                                    placingOpening.y = limitEnd.y + (placingOpening.size / 2);//* WALL LEFTSIDE TOP
                                } else {
                                    placingOpening.y = limitStart.y - (placingOpening.size / 2);//* WALL LEFTSIDE BOTOM
                                }
                            }

                            placingOpening.x = wallSelect.x;
                            placingOpening.limit = PointUtils.limitObj((wall as Wall).equations.base, placingOpening.size, placingOpening as CoordPoint);
                            success = true;
                        }

                        const pressedOpening = OpeningUtils.isOverOtherOpening(openingsInWall, limits, placingOpening.openingId);
                        if (pressedOpening) {
                            success = false;
                        }
                    }
                }
            }
        }

        if (!success) {
            placingOpening.x = placingOpeningOld.x;
            placingOpening.y = placingOpeningOld.y;
            placingOpening.limit = placingOpeningOld.limit;
        }
        this.setOpeningAngleWall(placingOpening);
        return { placingOpening, success };
    }

    //* usefull if an opening is stuck out of a wall */
    public static resetOpeningsPlacement = (openings: Array<IOpening>, walls: Array<Wall>): IOpening[] => {
        const duplicates = openings.filter(
            (opening) => openings.filter((x) => x.openingId === opening.openingId).length > 1
        );
        if (duplicates.length) {
            const numberOfDifferentValues = (array: Array<any>) => {
                const uniqueValues = array.filter((value, index, self) => self.indexOf(value) === index);
                return uniqueValues.length;
            };
            const duplicatesNumber = duplicates.length - numberOfDifferentValues(duplicates.map((x) => x.openingId));
            Logger.logAnalytics({
                data: { duplicates: duplicates.map(MapExportV32.toExportOpeningItem) },
                level: LogLevelType.Warning,
                message: `OpeningUtils.resetOpeningsPlacement: Found ${duplicatesNumber} duplicates`,
            });

            //Remove openings with the same openingId, keep only one
            openings = openings.reduce((acc, opening) => {
                if (!acc.find((x) => x.openingId === opening.openingId)) {
                    acc.push(opening);
                }
                return acc;
            }, [] as Array<IOpening>);
        }

        //Reset opening position
        openings.forEach((opening) => {
            const nearWall = WallUtils.nearWall(walls, opening as CoordPoint, Infinity, opening.roomId)!;
            if (nearWall && nearWall.distance !== 0) {
                opening.x = nearWall.x;
                opening.y = nearWall.y;
            }
        });

        return openings;
    };

    //old name : exportOpenings

    public static exportOpenings = (openings: Array<IOpening> = [], rooms: Array<IRoom> = []): Array<IOpening> => {
        openings.forEach((opening) => {
            const room = rooms.find((x) => x.roomId === opening.roomId)!;

            const sides = CreateRoomFactory.toRoomSides(room);

            const parsedSides: Array<WallLine> = Array.from(sides).map((x) => ({
                start: x[1][0],
                end: x[1][1],
                location_side: x[0],
            }));

            Array.from(parsedSides).forEach((side) => {
                const objFromWall = Editor.objFromWall(side as Wall, openings).find(
                    (o) => o.openingId === opening.openingId
                );
                const isInlist = Boolean(opening.roomId === room.roomId && opening.limit && objFromWall);
                if (isInlist) {
                    opening.location_side = side.location_side;
                    opening.location_position_from_edge =
                        PointUtils.distanceBetweenPoints(side.start, { x: opening.x!, y: opening.y! }) /
                        MapConstants.meter;
                }
            });
        });

        return openings;
    };

    public static updateRoomOpenings = (room: IRoom, openings: Array<IOpening>) => {
        const updatedOpenings: Array<IOpening> = [];
        const roomOpenings: Array<IOpening> = [];
        const sides = CreateRoomFactory.toRoomSides(room);

        const parsedSides: Array<WallLine> = Array.from(sides).map((x) => ({
            start: x[1][0],
            end: x[1][1],
            location_side: x[0],
        }));

        const meter = MapConstants.meter;

        if (openings.length) {
            openings.forEach((opening) => {
                Array.from(parsedSides)
                    .filter((side) => {
                        const objFromWall = Editor.objFromWall(side as Wall, openings).find(
                            (o) => o.openingId === opening.openingId
                        );
                        const isInlist = Boolean(opening.roomId === room.roomId && opening.limit && objFromWall);
                        return isInlist;
                    })
                    .forEach((side) => {
                        updatedOpenings.push({
                            ...opening,
                            location_side: side.location_side,
                            location_position_from_edge:
                                PointUtils.distanceBetweenPoints(side.start, { x: opening.x!, y: opening.y! }) / meter,
                        });
                        roomOpenings.push(opening);
                    });
            });
        }
        room.openings = roomOpenings;
        return updatedOpenings;
    };
    //* when duplicate room (opening position are not fixed)
    public static fixPositionOpenings = (room: IRoom, openings: Array<IOpening> = [], walls: Array<Wall> = []) => {
        const fixPosition = (opening: IOpening, wallSelect: WellSelect) => {
            const wall = wallSelect.wall;
            const limits = PointUtils.limitObj(wall.equations.base, opening.size!, wallSelect);
            const angleWall = qSVG.angleDeg(wall.start.x, wall.start.y, wall.end.x, wall.end.y);
            opening.x = wallSelect.x;
            opening.y = wallSelect.y;
            opening.angle = angleWall;
            opening.limit = limits;
        };

        const meter = MapConstants.meter;

        const sides = room.sides!;
        for (const opening of room.openings || []) {
            const location_side = opening.location_side!;
            const wallFound = walls.find(
                (x) =>
                    (sides.get(location_side)?.[0] === x.start || sides.get(location_side)?.[0] === x.end) &&
                    (sides.get(location_side)?.[1] === x.start || sides.get(location_side)?.[1] === x.end)
            ); //!TLE TODO handle line with 2 same points

            if (wallFound) {
                const ratio =
                    (opening.location_position_from_edge! * meter) /
                    PointUtils.distanceBetweenPoints(sides.get(location_side)![0], sides.get(location_side)![1]);
                const xCenterObj =
                    (1 - ratio) * sides.get(location_side)![0].x + ratio * sides.get(location_side)![1].x;
                const yCenterObj =
                    (1 - ratio) * sides.get(location_side)![0].y + ratio * sides.get(location_side)![1].y;

                fixPosition(opening, { wall: wallFound, x: xCenterObj, y: yCenterObj });
            }
        }
        return openings;
    };
}
