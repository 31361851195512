import * as React from 'react';
export const LameVerticalSelected = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill="#13123A" stroke="#fff" strokeWidth={1.333} d="M50.667 1.333v49.333H1.334V1.333z" />
        <path stroke="#fff" strokeWidth={1.333} d="M42.667 2v48m-8-48v48m-8-48v48m-8-48v48m-8-48v48" />
        <path
            d="M26.666 15.623c.649 0 1.271.253 1.734.717l6.26 6.26a2.44 2.44 0 0 1 .716 1.73c0 .65-.254 1.27-.716 1.732l-.568.568a2.46 2.46 0 0 1-3.471 0l-1.1-1.096v7.986a2.426 2.426 0 0 1-2.432 2.436h-.804c-1.274 0-2.474-1.009-2.474-2.436v-8.013l-1.118 1.122-.002.002a2.428 2.428 0 0 1-1.725.716c-.654 0-1.27-.259-1.728-.716l-.002-.002-.565-.567a2.44 2.44 0 0 1-.715-1.731c0-.651.256-1.271.717-1.732l1.06 1.06-1.06-1.06 6.26-6.26a2.442 2.442 0 0 1 1.733-.716Zm0 0h-.003l.003 1.5.004-1.5h-.004Z"
            fill="#fff"
            stroke="#13123A"
            strokeWidth={3}
        />
    </svg>
);
