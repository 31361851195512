import $ from "jquery";

type ObjetType = Record<string, any>;

export const isObjectsEquals = (a: ObjetType, b: ObjetType, message?: any) => {
    if (message) console.log(message);
    let isOK = true;
    for (let prop in a) {
        if (a[prop] !== b[prop]) {
            isOK = false;
            break;
        }
    }
    return isOK;
}

// export const emptyBinder = () => {
//     $('#boxbind').empty();
//     $('#boxbindRoom').empty();
//     hoverOnPlanElement(false);
//     if (typeof MapConstants.binder != 'undefined') {
//         if (MapConstants.binder.graph) {
//             MapConstants.binder.graph.remove();
//         } else {
//             MapConstants.binder.remove();
//         }
//         MapConstants.binder = undefined;
//     }
// }

// hover on element

//* from @thierry : "à la poubelle at date (01/02/2023"
export const hoverOnPlanElement = (activate: boolean, type?: string, id?: string) => {
    $('.HUD-menuItemSelected', window.document).toggleClass('HUD-menuItemSelected', false);
    if (!activate) {
        return;
    }

    if (type === 'room') {
        $('.room¤' + id, window.document).toggleClass('HUD-menuItemSelected', true);
    } else if (type === 'opening') {
        $('.opening¤' + id, window.document).toggleClass('HUD-menuItemSelected', true);
    }
}

// export function updateRooms() {
//     window.postMessage(
//         JSON.stringify({
//             error: false,
//             messageId: 'updateRoom',
//             message: exportRooms(),
//         }),
//         window.origin
//     );
//     //saveThrottled();
// }

export function exportRooms() {
    const exports = { rooms: [] };
    // MapConstants.ROOMS.forEach((room) => {
    //     let exportedRoom = null;
    //     switch (room.roomShape) {
    //         case 'rectangle':
    //             exportedRoom = exportRectangleShapeRoom(room);
    //             break;
    //         case 'open_bottom_rectangle':
    //             exportedRoom = exportOpenRectangleShapeRoom(room);
    //             break;
    //         case 'diamond_topleft':
    //         case 'diamond_topright':
    //         case 'diamond_bottomleft':
    //         case 'diamond_bottomright':
    //             exportedRoom = exportDiamondShapeRoom(room);
    //             break;
    //         case 'flyingwing_topleft':
    //         case 'flyingwing_topright':
    //         case 'flyingwing_bottomleft':
    //         case 'flyingwing_bottomright':
    //             exportedRoom = exportFlyingWingShapeRoom(room);
    //             break;
    //     }
    //     if (exportedRoom) {
    //         exports.rooms.push(exportedRoom);
    //     }
    // });

    return exports;
}

// export function floorplanComputingIsBusy(isBusy: boolean) {
//     window.postMessage(
//         JSON.stringify({
//             error: false,
//             messageId: 'floorplanBusyComputing',
//             message: {
//                 isBusy: isBusy,
//             },
//         }),
//         window.origin
//     );
// }

// export function fonc_button(modesetting: string, option?: string, optionSize?: number, optionName?: string, optionProperties?: any) {
//     MapConstants.mode = modesetting;
//     MapConstants.modeOption = option;
//     MapConstants.modeOptionSize = optionSize;
//     MapConstants.modeOptionName = optionName;
//     MapConstants.modeOptionProperties = optionProperties;

//     if (typeof MapConstants.lineIntersectionP !== 'undefined') {
//         MapConstants.lineIntersectionP.remove();
//         MapConstants.lineIntersectionP = undefined;
//     }
// }


// export function debugLog(...messages: any) {
//     console.log(messages);
// }
