import * as React from 'react';
import { SVGProps } from 'react';
export const LameHorizontalSelected = (props: SVGProps<SVGSVGElement>) => (
    <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill="#13123A" stroke="#fff" strokeWidth={1.333} d="M50.667 50.667H1.334V1.334h49.333z" />
        <path stroke="#fff" strokeWidth={1.333} d="M50 42.667H2m48-8H2m48-8H2m48-8H2m48-8H2" />
        <path
            d="M36.377 26.667c0 .648-.253 1.27-.716 1.733v.001l-6.26 6.26a2.442 2.442 0 0 1-1.731.715 2.44 2.44 0 0 1-1.731-.715l-.569-.569a2.46 2.46 0 0 1 0-3.47v-.001l1.097-1.1H18.48a2.426 2.426 0 0 1-2.436-2.432v-.803c0-1.275 1.01-2.475 2.436-2.475h8.014l-1.122-1.117-.002-.003a2.427 2.427 0 0 1-.716-1.724c0-.655.258-1.27.716-1.729l.002-.001.566-.565h.001a2.44 2.44 0 0 1 1.73-.716 2.44 2.44 0 0 1 1.732.717l-1.06 1.06 1.06-1.06 6.26 6.26a2.44 2.44 0 0 1 .716 1.734Zm0 0v-.004l-1.5.003 1.5.004v-.003Z"
            fill="#fff"
            stroke="#13123A"
            strokeWidth={3}
        />
    </svg>
);
