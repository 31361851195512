import { IOpening } from "../Models/IOpening";
import { IRoom } from "../Models/IRoom";
import { IRoomItem, RoomItemType } from "../Models/IRoomItem";
import { Editor } from "./Editor";
import { qSVG } from "./qSVG";
import { Wall } from "./Types";

type RoomItemDetailedPerimeter = { lengthNotAdjacentToWall: number; lengthAdjacentToWall: number; }

export class PerimeterCalc {

    /**
     * Calculates the length of the room's perimeter where 'plinthes' should be placed.
     * Calculated by substracting the sum of the room's openings widths from the room's total perimeter,
     * then substracting the sum of all room items parts that are in contact with the room's walls,
     * and adding the parts of the room's dividers that are not in contact with the room's walls or other room items.
     * Finally, the length of the room's stonewall is substracted.
     */
    public static calculatePerimeterPlinthes(roomId: string, perimeter: number, stonewallLength: number, rooms: Array<IRoom>, roomItems: Array<IRoomItem>, openings: Array<IOpening>) {
        const room = rooms.find((r) => r.roomId === roomId);
        if (!room) {
            return 0;
        }

        let perimeterPlinthes = perimeter - this.calculateOpeningsLength(room.walls, openings);
        const filteredRoomItems = roomItems.filter((x) => x.roomId === roomId);
        if (filteredRoomItems.length > 0) {
            for (const roomItem of filteredRoomItems) {
                perimeterPlinthes += this.calculateRoomItemPerimeterPlinthes(roomItem, rooms, roomItems);
            }
        }

        if (stonewallLength > 0) {
            perimeterPlinthes -= stonewallLength * 100;
            if (perimeterPlinthes < 0) {
                perimeterPlinthes = 0;
            }
        }
        return perimeterPlinthes;
    }

    /** Calculates the sum of all openings widths on the given walls. */
    public static calculateOpeningsLength(walls: Array<Wall> = [], openings: Array<IOpening>) {
        let openingsLength = 0;
        for (const wall of walls) {
            const openingsInWall = Editor.objFromWall(wall, openings);
            if (openingsInWall.length > 0) {
                openingsLength += openingsInWall.reduce((a, b) => a + b.size!, 0);
            }
        }
        return openingsLength;
    }

    public static calculateRoomItemPerimeterPlinthes(roomItem: IRoomItem, rooms: Array<IRoom>, roomItems: Array<IRoomItem>) {
        const detailedPerimeter = this.roomItemDetailedPerimeter(roomItem, rooms);
        let lengthToAdd = 0;
        let lengthToRemove = 0;

        if (roomItem.putPlinthes) {
            lengthToRemove = detailedPerimeter.lengthAdjacentToWall;
            lengthToAdd = detailedPerimeter.lengthNotAdjacentToWall;
        } else {
            switch (roomItem.type) {
                case RoomItemType.Divider:
                    lengthToRemove = detailedPerimeter.lengthAdjacentToWall;
                    lengthToAdd = detailedPerimeter.lengthNotAdjacentToWall - this.roomItemLenghtAdjacentToRoomItems(roomItem, roomItems);
                    break;
                default:
                    lengthToRemove = detailedPerimeter.lengthAdjacentToWall;
                    break;
            }
        }

        return lengthToAdd - lengthToRemove;
    }

    /**
     * Calculates the room item's perimeter, as a detailed object separating the length adjacent to walls
     * and the length not adjacent to any wall.
     * Result object : { lengthNotAdjacentToWall: number, lengthAdjacentToWall: number }
     */
    public static roomItemDetailedPerimeter(roomItem: IRoomItem, rooms: Array<IRoom>): RoomItemDetailedPerimeter {
        const room = rooms.find((x) => x.roomId === roomItem.roomId);
        if (!room) {
            return { lengthAdjacentToWall: 0, lengthNotAdjacentToWall: 0 };
        }
        let lengthAdjacentToWall = 0;
        let totalRoomItemPerimeter = 0;
        for (let i = 0; i < 4; i++) {
            for (const wall of room.walls!) {
                if (
                    Editor.testPointBelongToWall(wall, roomItem.coordsReal![i]) ||
                    Editor.testPointBelongToWall(wall, roomItem.coordsReal![i + 1])
                ) {
                    const adjacentLength = Editor.calcLineOverlapping(Editor.getLineFromPoly(roomItem.coordsReal, i), [
                        wall.start,
                        wall.end,
                    ]);
                    lengthAdjacentToWall += adjacentLength;
                }
            }
            totalRoomItemPerimeter += qSVG.measure(roomItem.coordsReal![i], roomItem.coordsReal![i + 1]);
        }
        return {
            lengthNotAdjacentToWall: totalRoomItemPerimeter - lengthAdjacentToWall,
            lengthAdjacentToWall: lengthAdjacentToWall,
        };
    }


    /** Calculates the length of the perimeter of the room item that is adjacent to other room items. */
    public static roomItemLenghtAdjacentToRoomItems(roomItem: IRoomItem, roomItems: Array<IRoomItem>) {
        const otherRoomItems = roomItems.filter((x) => x.roomId === roomItem.roomId && x.roomItemId !== roomItem.roomItemId);
        let lenghtAdjacentToRoomItems = 0;
        if (otherRoomItems.length > 0) {
            // For each other room item,
            for (const otherRoomItem of otherRoomItems) {
                // ...for each of this other room item's sides,
                for (let i = 0; i < 4; i++) {
                    let adjacentLength = 0;
                    // ...and for each of the target room item's sides,
                    for (let j = 0; j < 4; j++) {
                        // ...calculate overlapping length of the items' sides.
                        adjacentLength = Editor.calcLineOverlapping(
                            Editor.getLineFromPoly(otherRoomItem.coordsReal, i),
                            Editor.getLineFromPoly(roomItem.coordsReal, j)
                        );
                        // Assuming that a room item is a rectangle : if it is adjacent on one side...
                        // ...to another room item, it cannot be adjacent to it on any other side, so the loop can be stopped early.
                        if (adjacentLength > 0) {
                            lenghtAdjacentToRoomItems += adjacentLength;
                            break;
                        }
                    }
                    if (adjacentLength > 0) {
                        break;
                    }
                }
            }
        }
        return lenghtAdjacentToRoomItems;
    }
}