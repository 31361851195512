import SAT from 'sat';
import { CoordPoint } from '../Types';

//! used setPoints to update collisions props
//! points and setPoints are methods from SAT libs
//! todo: Rename SATPolygon
export interface RoomCollision {
    points: Array<CoordPoint>;
    setPoints?: (points: Array<CoordPoint>) => void;

    //! only manualy set in TestCase
    pos: CoordPoint;
    angle: number;
    offset: CoordPoint;
    calcPoints: Array<CoordPoint>;
    edges: Array<CoordPoint>;
    normals: Array<CoordPoint>;
}

export class SATUtils {
    public static getPolyCollision = () => new SAT.Polygon() as never as RoomCollision;
}
