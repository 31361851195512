import { JstsPolygon, JstsUtils } from '../../../Utils/JstsUtils';
import { PointUtils } from '../../../Utils/PointUtils';
import { CoordPoint } from '../../../Utils/Types';
import { Direction } from '../Laize';

type GetBandIntersectionParams = {
    surfacePoly: JstsPolygon;
    bandWidth: number;
    direction: Direction;
    widthMargin: number;
    horizontalMargin: number;
};
export type IntersectionResult = {
    intersection: JstsPolygon;
    xRealBandStart: number;
    xRealBandEnd: number;
    xSurfaceStart: number;
    xSurfaceEnd: number;
};
export type GetBandsParams = { intersectionResult: IntersectionResult; verticalMargin: number };
export type GetBandDimens = { band: Array<CoordPoint> };

export type LaizeBandList = {
    effectiveBand: Array<CoordPoint>;
    realBand: Array<CoordPoint>;
};
export type LaizeBandDimensions = { width: number; height: number };
export class LaizeBands {
    public static getBandIntersection({
        surfacePoly,
        bandWidth,
        direction,
        widthMargin,
        horizontalMargin,
    }: GetBandIntersectionParams): IntersectionResult {
        const surfaceCoords = surfacePoly.getCoordinates();
        const surfaceBoundingBox = PointUtils.calculateBoundingBox({ points: surfaceCoords })!;

        const xSurfaceStart =
            direction === Direction.LeftToRight
                ? surfaceBoundingBox.xMin - horizontalMargin
                : surfaceBoundingBox.xMax + horizontalMargin;
        const surfaceWidth = bandWidth - widthMargin;
        const xSurfaceEnd =
            direction === Direction.LeftToRight ? xSurfaceStart + surfaceWidth : xSurfaceStart - surfaceWidth;

        const realBandXOffset = widthMargin / 2;
        const xRealBandStart =
            direction === Direction.LeftToRight ? xSurfaceStart - realBandXOffset : xSurfaceStart + realBandXOffset;
        const xRealBandEnd =
            direction === Direction.LeftToRight ? xRealBandStart + bandWidth : xRealBandStart - bandWidth;

        const encompassingBandCoords = [
            { x: xSurfaceStart, y: surfaceBoundingBox.yMin },
            { x: xSurfaceEnd, y: surfaceBoundingBox.yMin },
            { x: xSurfaceEnd, y: surfaceBoundingBox.yMax },
            { x: xSurfaceStart, y: surfaceBoundingBox.yMax },
        ];

        const encompassingBandGeometry = JstsUtils.createJstsPolygon(encompassingBandCoords, true);
        const intersection = surfacePoly.intersection(encompassingBandGeometry).buffer(0);

        return {
            xRealBandStart,
            xRealBandEnd,
            xSurfaceStart,
            xSurfaceEnd,
            intersection,
        };
    }

    public static getBands({ intersectionResult, verticalMargin }: GetBandsParams): LaizeBandList {
        const intersectionCoords = PointUtils.getMultiPolyCoords(intersectionResult.intersection);

        const minY = Math.min(...intersectionCoords.map((p) => p.y));
        const maxY = Math.max(...intersectionCoords.map((p) => p.y));

        const xRealBandStart = intersectionResult.xRealBandStart;
        const xRealBandEnd = intersectionResult.xRealBandEnd;

        const xSurfaceStart = intersectionResult.xSurfaceStart;
        const xSurfaceEnd = intersectionResult.xSurfaceEnd;

        const yBandStart = minY - verticalMargin / 2;
        const yBandEnd = maxY + verticalMargin / 2;

        return {
            effectiveBand: [
                { x: xSurfaceStart, y: yBandStart },
                { x: xSurfaceEnd, y: yBandStart },
                { x: xSurfaceEnd, y: yBandEnd },
                { x: xSurfaceStart, y: yBandEnd },
            ],
            realBand: [
                { x: xRealBandStart, y: yBandStart },
                { x: xRealBandEnd, y: yBandStart },
                { x: xRealBandEnd, y: yBandEnd },
                { x: xRealBandStart, y: yBandEnd },
            ],
        };
    }

    public static getBandDimensions = (points: CoordPoint[]): LaizeBandDimensions => {
        return { width: LaizeBands.getBandWidth({ band: points }), height: LaizeBands.getBandHeight({ band: points }) };
    };

    public static getBandHeight = ({ band }: GetBandDimens) => {
        const yCoords = band.map((p) => p.y);
        const minY = Math.min(...yCoords);
        const maxY = Math.max(...yCoords);
        return maxY - minY;
    };

    public static getBandWidth = ({ band }: GetBandDimens) => {
        const xCoords = band.map((p) => p.x);
        const minX = Math.min(...xCoords);
        const maxX = Math.max(...xCoords);
        return maxX - minX;
    };
}
