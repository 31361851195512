import * as _ from 'lodash';

import { Logger } from '../../../../../../Errors/Logger';
import { IRoom, RoomShape } from '../../../../Models/IRoom';
import { CreateRoomFactory } from '../../../../Utils/Room/RoomUtils';
import { LAIZE_TEST_CASES, TestCase } from './TestCases';

export class LaizeDebugTest {
    public static convertLaizeCaseToImportableRoomObj(testCase: TestCase): Array<IRoom> {
        const rooms: Array<IRoom> = [];
        testCase.rooms?.forEach((room) => {
            rooms.push({ ...room, shape: room.customPoints ? RoomShape.Custom : room.shape });
        });
        return rooms;
    }

    public static getTestCases(): Array<TestCase> {
        const localTestCases: Array<TestCase> = JSON.parse(localStorage.getItem('pol-LaizeTestCases') ?? '[]');
        return [...LAIZE_TEST_CASES, ...localTestCases];
    }

    public static saveTestCase(testCase: TestCase, name: string) {
        const newTestCase = { name, savedPlan: _.cloneDeep(testCase) };
        const localTestCases = JSON.parse(localStorage.getItem('pol-LaizeTestCases') ?? '[]');
        localTestCases.push(newTestCase);
        localStorage.setItem('pol-LaizeTestCases', JSON.stringify(localTestCases));
        Logger.log('LaizeDebugTest saveTestCase', { newTestCase });
    }

    public static toSaveRoom = (baseRoom: IRoom): IRoom => {
        const room = CreateRoomFactory.exportRoom(baseRoom);
        return {
            roomId: room.roomId,
            name: room.name,
            shape: room.shape,
            dimensions: room.dimensions,
            roomExistingFloorType: room.roomExistingFloorType,
            roomExistingFlooring: room.roomExistingFlooring,
            roomFloor: room.roomFloor,
            roomHeating: room.roomHeating,
            roomHeatingType: room.roomHeatingType,
            roomType: room.roomType,
            center: room.center,
            openings: room.openings,
            roomItems: room.roomItems,
            customPoints: room.customPoints,
            rotationAngle: room.rotationAngle,
        };
    };
}
