import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCurrentProject } from '../../../Configs/CurrentProjectContext';
import { AppState, useMe } from '../../../Configs/PolStore';
import { Logger } from '../../../Errors/Logger';
import { createCustomErrorHandler, wrapError } from '../../../Errors/PolErrorHandler';
import { EstimationUrlHelper } from '../../../Helpers/EstimationUrlHelper';
import { ObjectUtils } from '../../../Helpers/ObjectUtils';
import { ProductHelper } from '../../../Helpers/ProductHelper';
import { QuestionContextHelper } from '../../../Helpers/QuestionContextHelper';
import {
    FindFirstIncompatibilityResult,
    QuestionsHelper,
    UpdateQuestionAfterLoadProduitsParameters,
} from '../../../Helpers/QuestionsHelper';
import { QuoteQuestionUtils } from '../../../Helpers/QuoteQuestionUtils';
import { RoomHeatingTypeErrorHelper } from '../../../Helpers/RoomHeatingTypeErrorHelper';
import { useWindowLog } from '../../../Hooks/WindowLog';
import { I18n } from '../../../Locales/I18nService';
import { QuestionContextType, QuestionItem, QuestionStep, QuestionType } from '../../../Models/Questions/QuestionDto';
import { QuotationReplyBaseDto } from '../../../Models/Quotations/Quotations';
import { MapContextState, UpdateMapParams, useMapContext } from '../../../Plugins/FloorPlan/Context/MapContext';
import { useMapExport } from '../../../Plugins/FloorPlan/Context/MapExportContext';
import { CompleteHelper } from '../../../Plugins/FloorPlan/Helpers/CompleteHelper';
import { MapError, MapHelper } from '../../../Plugins/FloorPlan/Helpers/MapHelper';
import { IMapItem } from '../../../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../Plugins/FloorPlan/Models/IRoomItem';
import { LogLevelType } from '../../../Services/Analytics/LogLevelType';
import { QuotationsApi } from '../../../Services/Api/QuotationsApi';
import { AuthHelper } from '../../../Services/Authentication/AuthHelper';
import { ApiError } from '../../../Services/XHR/ApiError';
import { QuoteLocationState, RouteQueryParams } from '../../../Types/RouteParams';
import { useQuery } from '../../Hooks/URL/URLUtils';
import { useIsVendeur } from '../../Hooks/Vendeur/useVendeur';
import { EnhancedViewState, useViewState } from '../../Hooks/ViewState/ViewState';
import { NavigationService } from '../../Services/NavigationService';
import { Toast } from '../../Services/ToastService';
import { useGtmMapEventController } from './Controllers/GtmEventController';
import { useQuoteLoadController } from './Controllers/QuoteLoadController';
import { QuoteLoadUtils } from './Controllers/QuoteProviderController';
import { QuestionPrepare } from './Helpers/QuestionPrepare';
import { QuestionsLoader } from './Helpers/QuestionsLoader';
import { LoadingStep, QuoteState } from './QuoteState';
import { QuestionContextUtils } from './Utils/QuestionContextUtils';

type UpdateQuoteParams = {
    quoteValues?: EnhancedViewState<QuoteState>;
    mapValues?: EnhancedViewState<MapContextState>;
    mapModified?: boolean;
    refreshLaizeCalc?: boolean;
    questionsModified?: boolean; //!\ can be quote question on MapItem info

    logs?: any;
};
export interface QuoteContextValue {
    state: EnhancedViewState<QuoteState>;
    getState: () => EnhancedViewState<QuoteState>;
    updateQuote: (values: Partial<QuoteState>) => void;
    update: (params: UpdateQuoteParams) => void;
}

const QuoteContext = React.createContext<QuoteContextValue>({
    state: {},
    getState: () => ({}),
    updateQuote: () => {},
    update: () => {},
});
export const useQuoteContext = () => React.useContext(QuoteContext);
export const useQuoteContextState = () => useQuoteContext().state;
export const useVersionId = () => React.useContext(QuoteContext).state.versionId;

export const QuoteProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const isVendeur = useIsVendeur();
    const location = useLocation<QuoteLocationState>();
    const { version: versionId, ref: produitRef } = useQuery<RouteQueryParams>();
    const currentProject = useCurrentProject();
    const map = useMapContext();

    const quoteContext = useViewState<QuoteState>({ loadingStep: LoadingStep.Auth });
    useWindowLog(() => (window.QuoteState = quoteContext.getState));

    const loader = useQuoteLoadController({ updateQuote: quoteContext.update });
    const exportImageMap = useMapExport();
    useGtmMapEventController(map.state, quoteContext.state);

    const load = () => {
        wrapError(
            async () => {
                const state = quoteContext.getState();
                if (!state.initialized) {
                    quoteContext.update({ loadingStep: LoadingStep.Version, loading: false });
                    const { version, versionData } = await QuestionsLoader.loadVersionParametrage(versionId);
                    NavigationService.replaceParams(
                        EstimationUrlHelper.toQueryParam({ version: version!, ref: produitRef })
                    );
                    versionData.questions
                        .filter((q) => q.related_step === QuestionStep.StepHabitation)
                        .forEach((q) => (q.showQuestion = true));
                    let questions = QuoteQuestionUtils.filterSellersQuestions(versionData.questions, isVendeur);
                    questions = QuoteQuestionUtils.markFirstProduitAsFirstProduct(questions);

                    currentProject.reset();

                    if (location.state?.configId) {
                        quoteContext.update({ loadingStep: LoadingStep.Configuration });

                        const {
                            mapState,
                            habitationQuestions = [],
                            questionProduitPrincipal,
                            habitationQuestionsOpen,
                            projetStore,
                        } = await loader.loadConfiguration({ configId: location.state.configId, questions });
                        quoteContext.update({
                            versionId: version,
                            loadingStep: undefined,
                            versionData,
                            questions,
                            questionProduitPrincipal,
                            habitationQuestions,
                            initialized: true,
                            habitationQuestionsOpen,
                            projetStore,
                        });

                        map.update({ values: mapState, logs: { event: 'load plan from config' } });
                        map.zoomToFit();

                        const currentProjectState = currentProject.getState();
                        if (!isVendeur && mapState.roomsCompleted && currentProjectState.isUpdateAllowed) {
                            const mapState = map.getState();
                            const { bodyRequest, quotePrixContextDebugMap } = await QuoteLoadUtils.getGetQuotationDto({
                                habitationQuestions,
                                exportImageMap,
                                mapState,
                                versionId: version!,
                                versionData,
                                projetStore,
                            });

                            if (!quoteContext.getState().loadingQuotation) {
                                const handleQuotationError = (error: any): Promise<boolean> => {
                                    quoteContext.update({ loadingQuotation: false });
                                    if (RoomHeatingTypeErrorHelper.isErrorFromRoomHeatingTypeHelper(error)) {
                                        return RoomHeatingTypeErrorHelper.handleError(error);
                                    } else {
                                        //this.handleDeadEndError();
                                        return createCustomErrorHandler(AuthHelper.isAuthenticatedAsStoreOrBOUser())(
                                            error
                                        );
                                    }
                                };
                                await wrapError<QuotationReplyBaseDto>(
                                    async () => {
                                        quoteContext.update({ loadingQuotation: true });
                                        const response = await QuotationsApi.computeQuotation(bodyRequest);
                                        const quote = response.data;
                                        currentProject.update({ isDirty: false });
                                        quoteContext.update({
                                            loadingQuotation: false,
                                            quote,
                                            quotePrixContextDebugMap,
                                        });
                                        return quote;
                                    },
                                    { errorHandler: handleQuotationError }
                                );
                            }
                        }
                    } else {
                        const questionProduitPrincipal = ObjectUtils.clone(
                            questions.find((q) => q.type === QuestionType.Produit)
                        );
                        const habitationQuestions = questions.filter(
                            (q) => q.related_step === QuestionStep.StepHabitation
                        );

                        const stepHabitation = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepHabitation);

                        quoteContext.update({
                            versionId: version,
                            loadingStep: undefined,
                            versionData,
                            questions,
                            questionProduitPrincipal,
                            stepHabitation,
                            habitationQuestions,
                            habitationQuestionsOpen: true,
                            loading: false,
                            initialized: true,
                        });
                    }
                }
            },
            {
                errorHandler: (loadError) => {
                    quoteContext.update({ loadingStep: undefined });
                    Logger.error('QUOTE initialization', loadError);
                    if ((loadError as ApiError).isApiError) {
                        Toast.showError({
                            title: I18n.get('Toast_TitleError'),
                            content: I18n.get('COMMON_ERROR_MESSAGE'),
                            duration: 5000,
                        });
                        return Promise.resolve(true);
                    }
                    if ((loadError as MapError).isMapError) {
                        const mapError = loadError as MapError;
                        Logger.logAnalytics({
                            message: MapHelper.LOAD_MAP_ERROR_MESSAGE,
                            level: LogLevelType.Error,
                            value: mapError.error,
                        });
                        Toast.showError({
                            title: I18n.get('Toast_TitleError'),
                            content: I18n.get('ERROR_LOADING_MAP'),
                            duration: 5000,
                        });
                        return Promise.resolve(true);
                    }
                    return Promise.resolve(false);
                },
            }
        );
    };

    const update = (params: UpdateQuoteParams) => {
        let { quoteValues, mapValues, questionsModified, refreshLaizeCalc, mapModified, logs } = params;
        let mapState: MapContextState = { ...map.getState(), ...mapValues };
        Logger.log('QuoteProvider -> update', params);

        //!\ WHEN UPDATE habitations/question produit principale
        if (quoteValues) {
            quoteContext.update(quoteValues);
        }

        if (mapValues) {
            //!\ OCCURS WHEN UPDATE PRODUIT PRINCIPAL OR HABITATION QUESTIONS OR MAP_ITEM INFO
            if (questionsModified || mapModified || refreshLaizeCalc) {
                const {
                    laizeProps,
                    refreshLaizeCalcNeeded,
                    logs: refreshLaizeCalcLogs,
                } = isRefreshLaizeCalcNeeded(mapState, logs);

                const quoteState = quoteContext.getState();
                const needComputeLaizeUpdate = Boolean(mapModified || refreshLaizeCalc || refreshLaizeCalcNeeded);
                mapState = needComputeLaizeUpdate ? map.withComputeLaize(mapState) : mapState;
                const { modified, ...updatedMapValues } = toUpdateQuestions(mapState, quoteState, isVendeur);

                map.update({
                    values: { ...mapState, ...updatedMapValues, laizeProps },
                    logs: { ...refreshLaizeCalcLogs, mapValues, questionsModified },
                });
            } else {
                map.update({
                    values: mapValues,
                    logs: { ...logs, combineWith: 'QuoteProvider update', mapValues, questionsModified },
                });
            }
        }

        if (!currentProject.getState().isDirty && (mapModified || questionsModified)) {
            currentProject.update({ isDirty: true });
        }
    };

    const combineMapState = (mapState: MapContextState): Partial<MapContextState> => {
        if (!currentProject.getState().isDirty) {
            currentProject.update({ isDirty: true });
        }
        //!\ WHEN UPDATE rooms, openings, roomItems from PANELS or map gestures
        mapState = map.withComputeLaize(mapState);
        const quoteState = quoteContext.getState();
        const { modified, ...updatedMapValues } = toUpdateQuestions(mapState, quoteState, isVendeur);
        return { ...mapState, ...updatedMapValues };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => map.setStateCombiner({ merge: combineMapState }), []);

    useReady(load);
    useLoadProduitFromRef(versionId, produitRef, quoteContext.state, update);
    useInvalidQuoteIfNeeded(quoteContext.state, quoteContext.update, map.update);

    return (
        <QuoteContext.Provider
            value={{
                state: quoteContext.state,
                getState: quoteContext.getState,
                updateQuote: quoteContext.update,
                update,
            }}>
            {children}
        </QuoteContext.Provider>
    );
};

const useReady = (load: VoidFunction) => {
    //* So we wait until the MeState is ready to load versionData.
    const isAuthenticated = useSelector((state: AppState) => state.Authentication)?.isAuthenticated;
    const isMeInitialized = useMe()?.data;

    const isMeReady = !isAuthenticated || (isAuthenticated && isMeInitialized);
    React.useEffect(() => {
        if (isMeReady) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMeReady]);

    return isMeReady;
};

const useLoadProduitFromRef = (
    versionId: string,
    produitRef: string,
    state: EnhancedViewState<QuoteState>,
    update: (params: UpdateQuoteParams) => void
) => {
    React.useEffect(() => {
        const { initialized, questionProduitPrincipal: question } = state;
        if (produitRef && initialized && question) {
            question.produitReadonlyReference = produitRef;
            QuestionsHelper.loadProduits({
                question,
                context: question.context,
                versionId: versionId!,
                updateQuestion: ({ question: updatedQuestion }: UpdateQuestionAfterLoadProduitsParameters) => {
                    if (updatedQuestion.produitValue?.is_default) {
                        updatedQuestion.isValid = true;
                    }
                    update({
                        quoteValues: { questionProduitPrincipal: updatedQuestion },
                        logs: { event: 'from useLoadProduitFromRef', produitRef },
                    });
                },
            });
            NavigationService.replaceParams(EstimationUrlHelper.toQueryParam({ version: versionId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produitRef, state.initialized]);
};

const useInvalidQuoteIfNeeded = (
    state: Partial<QuoteState>,
    updateQuote: (values: Partial<QuoteState>) => void,
    updateMap: (params: UpdateMapParams) => void
) => {
    const currentProject = useCurrentProject();
    React.useEffect(() => {
        if (state.quote && currentProject.state?.isDirty) {
            updateQuote({ quote: undefined });
            updateMap({ values: { showEstimation: false }, logs: { event: 'useInvalidQuoteIfNeeded' } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.quote, currentProject.state?.isDirty]);
};

const isRefreshLaizeCalcNeeded = (mapState: Partial<MapContextState>, logs?: any) => {
    //!\ call computelaize if any produitPrincipal changes
    let refreshLaizeCalcNeeded = false;
    let laizeProps = mapState.laizeProps;

    const refreshLaizeCalcRoomIds = mapState.rooms?.some((room) => {
        const produitPrincipal = room.questionProduitPrincipal?.produitValue;
        const laizeProps = mapState.laizeProps?.find((x) => x.roomIds?.some((y) => y === room.roomId));
        return produitPrincipal?.code !== laizeProps?.productCode;
    });
    if (refreshLaizeCalcRoomIds) {
        laizeProps = ProductHelper.ToArrayLaizeProps(mapState.rooms);
        logs = { ...logs, logLaizeProps: `laizeProps updated`, laizeProps };
        refreshLaizeCalcNeeded = true;
    }

    Logger.log('refreshLaizeCalc', { mapState, refreshLaizeCalcNeeded, laizeProps });
    return { laizeProps, refreshLaizeCalcNeeded, logs };
};

const toUpdateQuestions = (
    mapState: MapContextState,
    quoteState: EnhancedViewState<QuoteState>,
    isVendeur?: boolean
): EnhancedViewState<MapContextState> => {
    //!\ WHEN UPDATE rooms, openings, roomItems, from Panels
    const { questions = [], habitationQuestions = [] } = quoteState;
    const { rooms = [], openings = [], roomItems = [], laizeResults = [] } = mapState;

    const getIncompatibility = (item: IMapItem, questionProduit?: QuestionItem) => {
        const questions = questionProduit ? [questionProduit] : [];
        questions.push(
            ...item.questionsPose!.questions!,
            ...item.questionsSupport!.questions!,
            ...item.questionsPreparation!.questions!,
            ...item.questionsFinition!.questions!,
            ...item.questionsServices!.questions!
        );
        return QuestionsHelper.findFirstIncompatibility(questions);
    };

    const updateIncompatibility = (item: IMapItem, firstIncompatibility: FindFirstIncompatibilityResult) => {
        if (firstIncompatibility.findIncompatibility) {
            item.findIncompatibilityResult = firstIncompatibility;
            rooms.find((r) => r.roomId === item.roomId)!.findIncompatibilityResult = firstIncompatibility;
        } else {
            item.findIncompatibilityResult = undefined;
        }
    };

    if (rooms.length || openings.length || roomItems.length) {
        rooms.forEach((item: IRoom) => {
            const questionProduitPrincipal = item.questionProduitPrincipal!;
            const produitPrincipal = questionProduitPrincipal?.produitValue;
            const flooringDirection = laizeResults.find((x) => x.roomIds?.some((y) => y === item.roomId))
                ?.flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextHelper.mergeContext(
                QuestionContextHelper.getBaseQuestionsContext(habitationQuestions),
                QuestionContextUtils.createRoomContext(item, flooringDirection)
            );
            QuestionPrepare.updateStepsQuestions({
                itemId: item.roomId!,
                item,
                questions,
                produitPrincipal,
                contextType: QuestionContextType.Rooms,
                isVendeur,
            });
            let firstIncompatibility = getIncompatibility(item, item.questionProduitPrincipal!);
            item.findIncompatibilityResult = firstIncompatibility.findIncompatibility
                ? firstIncompatibility
                : undefined;
        });

        openings.forEach((item: IOpening) => {
            const room = rooms.find((r) => r.roomId === item.roomId)!;
            const questionProduitPrincipal = room.questionProduitPrincipal;
            const produitPrincipal = questionProduitPrincipal?.produitValue;
            const flooringDirection = laizeResults.find((x) => x.roomIds?.some((y) => y === item.roomId))
                ?.flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextUtils.createOpeningContext(item, room, flooringDirection);
            QuestionPrepare.updateStepsQuestions({
                itemId: item.openingId!,
                item,
                questions,
                produitPrincipal,
                contextType: QuestionContextType.Openings,
                openingType: item.type,
                isVendeur,
            });
            updateIncompatibility(item, getIncompatibility(item));
            item.completionStatus = CompleteHelper.isOpeningComplete(item, openings);
        });

        roomItems.forEach((item: IRoomItem) => {
            const room = rooms.find((r) => r.roomId === item.roomId)!;
            const questionProduitPrincipal = room.questionProduitPrincipal;
            const produitPrincipal = questionProduitPrincipal?.produitValue;
            const flooringDirection = laizeResults.find((x) => x.roomIds?.some((y) => y === item.roomId))
                ?.flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextUtils.createRoomItemContext(item, room, flooringDirection);
            QuestionPrepare.updateStepsQuestions({
                itemId: item.roomItemId!,
                item,
                questions,
                produitPrincipal,
                contextType: QuestionContextType.RoomElements,
                roomItemType: item.type,
                isVendeur,
            });

            updateIncompatibility(item, getIncompatibility(item));
            item.completionStatus = CompleteHelper.isRoomItemComplete(item, roomItems);
        });

        rooms.forEach((item: IRoom) => (item.completionStatus = CompleteHelper.isRoomComplete(item, rooms)));

        const roomsCompleted = CompleteHelper.allComplete(rooms);
        return { modified: true, rooms, openings, roomItems, roomsCompleted };
    }
    return { modified: false, roomsCompleted: false };
};
