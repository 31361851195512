import { styled } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import ReactJson, { ThemeKeys } from 'react-json-view';
import { ObjectUtils } from '../../../../../Helpers/ObjectUtils';

export type JSONViewerProps = {
    data?: any;
    styles?: { root?: CSSProperties; content?: CSSProperties };
    collapsed?: number | boolean;
    theme?: ThemeKeys;
};

export const JSONViewer = ({ data, collapsed = 1, styles, theme }: JSONViewerProps) => {
    const src = ObjectUtils.clone(data);
    src._JSONViewer_DATA_SIZE_ = ObjectUtils.size(data);
    const jsonStyle: CSSProperties = { padding: 10, borderRadius: 0, ...styles?.content };
    return !data ? null : (
        <Container style={styles?.root}>
            <ReactJson
                src={src}
                theme={theme || 'apathy'}
                iconStyle="square"
                style={jsonStyle}
                collapsed={collapsed}
                enableClipboard={(copy) => navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))}
            />
        </Container>
    );
};

const Container = styled('div')({ fontSize: 18 });
