import { AppState } from '../../Configs/PolStore';
import { StoreProvider } from '../../Configs/StoreProvider';
import { Toast } from '../../Web/Services/ToastService';
import { AuthApi } from '../Api/AuthApi';
import { CRMApi } from '../Api/CRMApi';
import { handleError } from '../../Errors/PolErrorHandler';
import { ApiResponse } from '../../Models/ApiData';
import { AuthenticationResponse, AuthenticationResult } from '../../Models/Authentication/AuthResponse';
import { CRMQueryParameter } from '../../Models/CRM/CRM';
import { ApiErrorCodes } from '../../Models/ErrorCodes';
import { MeState } from '../Me/MeReducer';
import { AuthenticationActions } from './AuthenticationActions';
import { AuthenticationState } from './AuthenticationReducer';
import { AuthHelper } from './AuthHelper';
import { InitializedReason } from './typings/AuthenticationActionTypes';
import { I18n } from '../../Locales/I18nService';

export enum AuthenticateResultType {
    SUCCESS = 'SUCCESS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    LOGIN_ERROR = 'LOGIN_ERROR',
}

const dispatch = (action: any) => StoreProvider.dispatch(action);
const getState = (): AppState => StoreProvider.getState();

export class AuthService {

    public static getAuthState = (): Partial<AuthenticationState> => getState()?.Authentication || {}
    public static getMe = (): MeState => getState().Me

    public static CRMlogin = async (params: CRMQueryParameter): Promise<AuthenticateResultType> => {
        try {
            const response: AuthenticationResponse = await CRMApi.authenticate(params);
            dispatch(AuthenticationActions.setAuthorization(response.data, InitializedReason.LOGIN_SUCCESS));
            return Promise.resolve(AuthenticateResultType.SUCCESS);
        } catch (error) {
            const { error_message } = error as ApiResponse;
            if (error_message) {
                if (error_message === ApiErrorCodes.TOKEN_IN_FUTURE) {
                    Toast.showError({ content: I18n.get('ERROR_STORE_TOKEN_IN_FUTUR') });
                } else {
                    Toast.showError({ content: error_message });
                }
            } else {
                handleError(error);
            }

            dispatch(AuthenticationActions.loginFail(error));

            if (error_message === ApiErrorCodes.INVALID_CREDENTIALS_MESSAGE) {
                return Promise.resolve(AuthenticateResultType.INVALID_CREDENTIALS);
            } else {
                return Promise.resolve(AuthenticateResultType.LOGIN_ERROR);
            }
        }
    };

    //? @returns {String} the authorization header, NOT the token
    public static getAuthorization = async (): Promise<string> => {
        const { token_type = '', access_token = '' } = getState()?.Authentication || {};

        const auth = AuthHelper.getAuthorizationFromToken(token_type, access_token);
        return Promise.resolve(auth);
    };

    //? @returns {String} the authorization header, NOT the token
    public static getAccessToken = async (): Promise<string> => {
        const { access_token = '' } = getState()?.Authentication || {};
        return Promise.resolve(access_token);
    };

    //? @returns {String} the refresh_token
    public static getRefreshToken = async (): Promise<string> => {
        const { refresh_token = '' } = getState()?.Authentication || {};
        return Promise.resolve(refresh_token);
    };

    /**
     * If the access token is not expired, fires refreshSuccess right away with current state.
     * Resets the initialized state (keeps the authenticated state) and then refreshes the access token
     * @param {Boolean} forceRefresh - Force the refresh of the tokens, even if the current ones are not expired
     * @returns {Promise<Object>} result - the result of the refresh, same as the login result OR an object
     * with props `isError` and `err`
     * @throws {Promise<Error>} an Axios error if the refresh failed
     */
    public static refreshAuthentication = async (): Promise<string> => {
        const authentication = getState()?.Authentication;

        const { refresh_token = '' } = authentication || {};

        if (!refresh_token) {
            const errorMessage: string = 'No refresh token found, cannot refresh and will logout';
            handleError(errorMessage);
            dispatch(AuthenticationActions.refreshFail(errorMessage));
            return Promise.reject(errorMessage);
        } else {
            dispatch(AuthenticationActions.setInitialized(false, 'loading refresh'));

            try {
                const response: AuthenticationResponse = await AuthApi.refresh(refresh_token);
                const authResult: AuthenticationResult = response.data;
                dispatch(AuthenticationActions.refreshSuccess(authResult));

                const authorization = AuthHelper.getAuthorizationFromToken(
                    response.data.token_type,
                    response.data.access_token
                );
                return Promise.resolve(authorization);
            } catch (error) {
                dispatch(AuthenticationActions.refreshFail(error));
                return Promise.reject(error);
            }
        }
    };

    public static async setAuthorizationToken(authorizationToken: AuthenticationResult): Promise<void> {
        await dispatch(AuthenticationActions.setAuthorization(authorizationToken, InitializedReason.SET_AUTHORIZATION));
    }
    public static isAuthenticated(): Promise<boolean> {
        const accessToken: string = getState()?.Authentication.access_token ?? '';
        const hasAccessToken: boolean = accessToken !== '';
        return Promise.resolve(hasAccessToken);
    }

    public static async removeAuthorizationToken(): Promise<void> {
        await dispatch(AuthenticationActions.clearAuthorizationHeader());
    }

    public static logout = async (reason: string): Promise<void> => {
        await AuthService.removeAuthorizationToken();
    }
}
