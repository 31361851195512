import { ILaizeRoom, ILaizeWall } from '../../Components/Laizes/LaizeCalc';
import { YesOrNo } from '../../Models/IMapItem';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem, RoomItemType } from '../../Models/IRoomItem';
import { Editor } from '../Editor';
import { JstsUtils } from '../JstsUtils';
import { PointUtils } from '../PointUtils';
import { CoordPoint, Wall } from '../Types';
//@ts-ignore

export class RoomDiffUtils {
    public static roomComputeDifference(room: IRoom, roomItems: Array<IRoomItem>, walls: Array<Wall>) {
        const computedResult = this.roomPolygonExcludingRoomItem(room, roomItems, walls);
        room.areaWithoutRoomItems = computedResult.roomArea;
        room.coordsWithoutRoomItems = computedResult.roomShellDifference
            .getCoordinates()
            .map((p: CoordPoint) => ({ x: p.x, y: p.y }));
    }

    //* Calcul d'un polygon à partir de la pièce excluant les roomItems contenus dans la pièce.
    //* Utilisé pour le calcul de surface et le calcul en lé.

    public static roomPolygonExcludingRoomItem(
        room: ILaizeRoom,
        roomItems: Array<IRoomItem>,
        walls: Array<ILaizeWall>
    ) {
        const geometryFactory = JstsUtils.GeometryFactory();
        const geoInputPolyA = JstsUtils.vectorCoordinates2JTS(room.coords);
        geoInputPolyA.push(geoInputPolyA[0]);
        let shellPolyA = geometryFactory.createPolygon(geoInputPolyA) as jsts.geom.Geometry;

        // Fix #24196 On check si un roomItem n'est pas en contact avec un mur de la pièce et on l'ignore pour le calcul en lé
        // JSTS n'arrive pas à interpréter des points qui ne sont pas continues
        // Codesandbox utilisé pour debugger JSTS : https://codesandbox.io/s/affectionate-rain-quiyh3?file=/src/App.js
        //
        // Avant :
        // Plan : https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/EeXc4CuNSZpPtBax0FGT6IUBmiSqgwszgM8-bKwhwS8BpA?e=H1qBxb
        // Interpretation JSTS : https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/EU-CPlIAhitMr0asPJfIHq4BDMSG0d6EMfE4GuUbya0PGw?e=kdUp4y
        //
        // Après :
        // Plan : https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/EafQ_uujqFNDrKPe-D2iRBoBT7CiLRJz09gpUIsZWqN7Gw?e=KtNQad
        // Interpretation JSTS : https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/ER2ZmfF8mgdCtrmbTIuGQIsBFp872mCZeT9dwxLX9lGrqg?e=fA2qcX

        let realRoomShellDifference = shellPolyA;
        const roomItemsToWorkWith = roomItems.filter(
            (x) => x.roomId === room.roomId && !RoomDiffUtils.hasNoImpactOnFlooring(x.type)
        );
        for (const roomItem of roomItemsToWorkWith) {
            const geoInputPolyB = JstsUtils.vectorCoordinates2JTS(roomItem.coordsReal);
            geoInputPolyB.push(geoInputPolyB[0]);
            const shellPolyB = geometryFactory.createPolygon(geoInputPolyB);
            if (roomItem.putFlooring === YesOrNo.No || roomItem.putFlooring === undefined) {
                realRoomShellDifference = realRoomShellDifference.difference(shellPolyB);
            } else {
                realRoomShellDifference = realRoomShellDifference.union(shellPolyB);
            }

            if (!this.roomItemIsInsideRoom(roomItem, room) || this.roomItemIsInContactWithWall(roomItem, walls)) {
                if (roomItem.putFlooring === YesOrNo.No || roomItem.putFlooring === undefined) {
                    //* VDE: Temporary fix: #36597
                    //* On ignore les aménagements qui ont qu'un seul point en contact avec les murs
                    if (this.getRoomItemNumContactWithWall(roomItem, walls) > 1) {
                        shellPolyA = shellPolyA.difference(shellPolyB);
                    }
                } else {
                    shellPolyA = shellPolyA.union(shellPolyB);
                }
            }
        }

        return {
            /**
             * Polygon de la pièce suivant ces opérations:
             * - Ignore les aménagements qui sont entièrement dans la pièce.
             * - différence sur les aménagements sans pose de revêtement de sol qui touchent un mur.
             * - union sur les aménagements avec pose de revêtement de sol qui touchent un mur.
             */
            roomShellDifference: shellPolyA,
            /**
             * Surface de la pièce suivant ces opérations:
             * - différence sur les aménagements sans pose de revêtement de sol
             * - union sur les aménagements avec pose de revêtement de sol.
             * Utile pour le calcul de la surface total de la pièce.
             */
            roomArea: realRoomShellDifference.getArea(),
        };
    }

    public static roomItemIsInContactWithWall(roomItem: IRoomItem, walls: Array<ILaizeWall>) {
        for (const wall of walls) {
            let contactCount = 0;
            for (const coord of roomItem.coordsReal!) {
                if (Editor.testPointBelongToWall(wall, coord)) {
                    contactCount++;
                }
                if (contactCount >= 1) {
                    return true;
                }
            }
        }
        return false;
    }

    public static getRoomItemNumContactWithWall(roomItem: IRoomItem, walls: Array<ILaizeWall>) {
        let contactCount = 0;
        for (const wall of walls) {
            const coords = roomItem.coordsReal!;
            for (const coord of coords.slice(0, coords.length - 1)) {
                if (Editor.testPointBelongToWall(wall, coord)) {
                    contactCount++;
                }
            }
        }
        return contactCount;
    }

    public static roomItemIsInsideRoom(roomItem: IRoomItem, room: ILaizeRoom) {
        for (const coord of roomItem.coordsReal!) {
            const isInside = PointUtils.testPointWithinRoom(coord, room);
            if (!isInside) {
                return false;
            }
        }
        return true;
    }

    /**
     * Required by #41435 Hotfix > C&P > Aménagement, surface éléments lourds et regard (Prod et Pilot-Prod)
     */
    public static hasNoImpactOnFlooring(type?: RoomItemType) {
        return RoomItemType.SewerPlate === type || RoomItemType.HeavyElement === type;
    }
}
