import IconButton from '@material-ui/core/IconButton';
import React, { CSSProperties } from 'react';
import { FormattedNumber } from 'react-intl';

import { styled } from '@material-ui/styles';
import { I18n, Message } from '../../../../Locales/I18nService';
import { CreatedByTypeDto, ProjectDto } from '../../../../Models/Projects/ProjectDto';
import { DateUtils } from '../../../../Utils/DateUtils';
import { CustomLoader } from '../../../Components/Templates/CustomLoader';
import { DeleteButton } from '../../../Components/Views/CustomButton/ButtonsFactory';
import { ButtonVariant, CustomButton } from '../../../Components/Views/CustomButton/CustomButton';
import { PageLayout } from '../../../Components/Views/PageLayout/PageLayout';
import { ThemeRes } from '../../../Themes/ThemeRes';
import { Assets } from '../../../assets/Assets';
import { QuotationList } from '../Components/QuotationList';
import { useProjectDetailsPageController } from './ProjectDetailsPageController';

export const ProjectDetailsPage = () => {
    return (
        <PageLayout>
            <ProjectDetails />
        </PageLayout>
    );
};

const ProjectDetails = () => {
    const { state, events, isBoUser, isVendor } = useProjectDetailsPageController();
    return (
        <Container>
            <Header>
                <HeaderTitleContainer>
                    <BackButton onClick={events.onBack}>
                        <Assets.IconArrowPrevious style={{ width: 7, height: 13 }} />
                    </BackButton>
                    <HeaderTitle>
                        <Message id="ProjectDetails_ProjectSummary" />
                    </HeaderTitle>
                </HeaderTitleContainer>
                <CustomButton
                    title={<Message id="ProjectDetails_Resume" />}
                    variant={ButtonVariant.Blue}
                    onClick={events.onResumeProject}
                    styles={{ root: ResumeBtnStyle, label: { fontWeight: 400 } }}
                    disabled={!state.project || state.loading}
                />
            </Header>
            {state.loading ? (
                <CustomLoader visible={true} darkColor styles={{ loader: LoaderStyle }} />
            ) : (
                <>
                    <SummaryContainer>
                        <LeftColumn>
                            <SummaryInfoLabel>
                                <Message id="ProjectDetails_Area" />
                                <SummaryInfoValue>
                                    <Message
                                        id="ProjectDetails_AreaValue"
                                        values={{
                                            area: (
                                                <FormattedNumber
                                                    value={state.project?.total_surface || 0}
                                                    maximumFractionDigits={2}
                                                />
                                            ),
                                        }}
                                    />
                                </SummaryInfoValue>
                            </SummaryInfoLabel>
                            <SummaryInfoLabel>
                                <Message id="ProjectDetails_OpeningCount" />
                                <SummaryInfoValue>{state.project?.opening_count}</SummaryInfoValue>
                            </SummaryInfoLabel>
                            <SummaryInfoLabel>
                                <Message id="ProjectDetails_RoomCount" />
                                <SummaryInfoValue>{state.project?.room_count}</SummaryInfoValue>
                            </SummaryInfoLabel>
                            <SummaryInfoLabel>
                                <Message id="ProjectDetails_LastSave" />
                                <SummaryInfoValue>
                                    <Message
                                        id="Projects_CreatedByDate"
                                        values={{
                                            date: DateUtils.formatDateTime(
                                                state.project?.last_saved_date,
                                                <Message id="COMMON_DATE_DEFAULT_VALUE" />
                                            ),
                                        }}
                                    />
                                </SummaryInfoValue>
                            </SummaryInfoLabel>
                            <SummaryInfoLabel>
                                <Message id="ProjectDetails_CreatedBy" />
                                <SummaryInfoValue>
                                    {state.project && <CreatedBy project={state.project} />}
                                </SummaryInfoValue>
                            </SummaryInfoLabel>
                            {(isBoUser || isVendor) && state.project && (
                                <AuthorIdInput
                                    customerId={state.customerId ?? ''}
                                    isAllowedToUpdateMetaData={state.isAllowedToUpdateMetaData}
                                    onEditCustomerId={events.onEditCustomerId}
                                />
                            )}
                        </LeftColumn>
                        <RightColumn>
                            <InputContainer>
                                <InputLabelContainer>
                                    <Title>
                                        <Message id="ProjectDetails_Intitule" />
                                    </Title>
                                    {state.isAllowedToUpdateMetaData && state.project ? (
                                        <IconButton onClick={events.onEditIntitule}>
                                            <Assets.IconEditPen />
                                        </IconButton>
                                    ) : undefined}
                                </InputLabelContainer>
                                <InputValue
                                    style={InputTitleStyle}
                                    onClick={state.project ? events.onEditIntitule : undefined}>
                                    {state.project?.intitule}
                                </InputValue>
                            </InputContainer>
                            <InputContainer>
                                <InputLabelContainer>
                                    <Title>
                                        <Message id="ProjectDetails_Description" />
                                    </Title>
                                    {state.isAllowedToUpdateMetaData && state.project ? (
                                        <IconButton onClick={events.onEditDescription}>
                                            <Assets.IconEditPen />
                                        </IconButton>
                                    ) : undefined}
                                </InputLabelContainer>
                                <InputValue
                                    style={{
                                        ...DescriptionStyle,
                                        color: !!state.project?.description ? 'black' : '#979797',
                                    }}
                                    onClick={state.project ? events.onEditDescription : undefined}>
                                    {state.project?.description ?? I18n.get('ProjectDetails_Description_Placeholder')}
                                </InputValue>
                            </InputContainer>
                        </RightColumn>
                    </SummaryContainer>
                    {Boolean(state.project?.quotation_list?.length) && (
                        <>
                            <HistoryTitle>
                                <Message id="ProjectDetails_HistoryList" />
                            </HistoryTitle>
                            <QuotationList
                                items={state.project?.quotation_list}
                                onClickItem={(itemId: string) => events.onResumeConfiguration(itemId)}
                            />
                        </>
                    )}
                    <Separator />
                    <DeleteButtonContainer>
                        <CustomButton
                            visible={isBoUser}
                            onClick={events.downloadExportedProject}
                            icon={<Assets.ExportIcon />}
                            title={I18n.get('ProjectDetails_Export')}
                            variant={ButtonVariant.BorderedBlue}
                            isBusy={state.exporting}
                            disabled={!state.project || state.saving || state.loading}
                            styles={{
                                root: { marginRight: 16, border: '2px solid #13123A' },
                                label: { fontSize: 16 },
                            }}
                        />
                        <DeleteButton
                            onClick={events.onClickDelete}
                            disabled={!state.project || state.saving || state.loading}
                            styles={{
                                root: { height: '48px', width: 'fit-content', float: 'right' },
                                icon: { width: '15px', height: '16px' },
                            }}
                            title={<Message id="ProjectDetails_DeleteProject" />}
                        />
                    </DeleteButtonContainer>
                </>
            )}
        </Container>
    );
};
const DeleteButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 24,

    '@media (max-width: 900px)': {
        justifyContent: 'center',
    },
});
const HistoryTitle = styled('div')({
    fontFamily: ThemeRes.Fonts.fontFamilyGlendale,
    fontSize: 24,
    color: '#464646',
    marginBottom: 24,
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingRight: 16,
    paddingLeft: 16,
});
const LoaderStyle: CSSProperties = {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: 52,
};
const LeftColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    paddingTop: 10,
    gap: 36,
    '@media (max-width: 900px)': {
        gap: 24,
        width: '100%',
        marginBottom: 12,
    },
});
const RightColumn = styled('div')({
    minWidth: 328,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media (max-width: 900px)': {
        gap: 12,
    },
});
const Container = styled('div')({
    color: '#464646',
    backgroundColor: '#fff',
    paddingBottom: '143px',
    paddingLeft: '62px',
    paddingRight: '62px',
    height: '100%',
    width: '100%',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
        paddingBottom: '24px',
        paddingLeft: 0,
        paddingRight: 0,
    },
});

const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginTop: 42,
    marginBottom: 15,
    '@media (max-width: 900px)': {
        flexDirection: 'column',
    },
});

const HeaderTitleContainer = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '@media (max-width: 900px)': {
        marginBottom: '16px',
    },
});

const BackButton = styled(IconButton)({ width: '41px', height: '41px', paddingTop: 8 });
const HeaderTitle = styled('div')({
    fontFamily: ThemeRes.Fonts.fontFamilyGlendale,
    fontSize: 24,
    lineHeight: '44px',
    textTransform: 'uppercase',
    marginLeft: 7,
});
const ResumeBtnStyle: CSSProperties = { height: 50, width: 278 };

const SummaryContainer = styled('div')({
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    gap: 41,
    paddingLeft: 48,
    marginBottom: 72,
    '@media (max-width: 900px)': {
        flexDirection: 'column',
        paddingRight: 16,
        paddingLeft: 16,
        gap: 0,
    },
});

const SummaryInfoLabel = styled('div')({
    display: 'flex',
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
    fontSize: 14,
    color: '#464646',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
});
const SummaryInfoValue = styled('div')({
    marginLeft: '6px',
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    fontSize: 16,
    color: '#464646',
    whiteSpace: 'initial',
});

const InputContainer = styled('div')({
    marginBottom: '24px',
    '@media (max-width: 900px)': {
        marginBottom: 0,
    },
});
const InputLabelContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
const InputValue = styled('div')({
    background: '#fff',
    border: '1px solid #13123A',
    padding: '10px 16px 10px 16px',
    height: 42,
    minWidth: 328,
    color: 'black',
    fontSize: 16,
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,

    '@media (max-width: 900px)': {
        width: '100%',
        maxWidth: '100% !important',
    },
});
const InputStyle: CSSProperties = { cursor: 'pointer' };
const InputTitleStyle: CSSProperties = {
    ...InputStyle,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 328,
};
const DescriptionStyle: CSSProperties = {
    ...InputStyle,
    height: 132,
    maxWidth: 677,
    width: '100%',
    background: '#EFEFEF',
    border: 'unset',
    overflowY: 'auto',
};

const Title = styled('div')({
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
    fontSize: 14,
});

const Separator = styled('div')({
    background: 'rgba(19, 19, 58, 0.15)',
    width: '100%',
    height: '2px',
    marginTop: '32px',
    marginBottom: '25px',
});

//#region //* NESTED COMPONENTS

type CreatedByProps = { project: ProjectDto };
const CreatedBy = ({ project }: CreatedByProps) => {
    let createdByLabel = '';
    const { created_by_type, created_by_customer, created_by_store } = project;
    if (created_by_type === CreatedByTypeDto.Customer && created_by_customer) {
        createdByLabel = I18n.format('COMMON_USER_FULLNAME', {
            firstName: created_by_customer.user.first_name ?? '',
            lastName: created_by_customer.user.last_name ?? '',
        });
    } else if (created_by_type === CreatedByTypeDto.Store && created_by_store) {
        createdByLabel = created_by_store.store.id;
    }
    return <>{createdByLabel}</>;
};

type AuthorIdInputProps = {
    customerId: string;
    isAllowedToUpdateMetaData?: boolean;
    onEditCustomerId: VoidFunction;
};
const AuthorIdInput = ({ customerId, isAllowedToUpdateMetaData, onEditCustomerId }: AuthorIdInputProps) => {
    return (
        <InputContainer style={{ marginTop: -10 }}>
            <InputLabelContainer>
                <Title>
                    <Message id="ProjectDetails_CustomerId" values={{ authorType: customerId }} />
                </Title>
                {isAllowedToUpdateMetaData ? (
                    <IconButton onClick={onEditCustomerId}>
                        <Assets.IconEditPen />
                    </IconButton>
                ) : undefined}
            </InputLabelContainer>
            <InputValue style={InputTitleStyle} onClick={onEditCustomerId}>
                {customerId}
            </InputValue>
        </InputContainer>
    );
};

//#endregion
