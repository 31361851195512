import * as _ from 'lodash';

import { Logger } from '../../../../../Errors/Logger';
import { Toast } from '../../../../../Web/Services/ToastService';
import { MapConstants } from '../../../MapConstants';
import { IdGenerator } from '../../../Utils/IdGenerator';
import { JstsPolygon, JstsUtils } from '../../../Utils/JstsUtils';
import { PointUtils } from '../../../Utils/PointUtils';
import { AdjacentRooms, CoordPoint, RotationParams } from '../../../Utils/Types';
import { WallUtils } from '../../../Utils/Wall/WallUtils';
import { Direction, FlooringDirection, LaizeBand, LaizeCalculatorResult, LaizeDirection, ProductType } from '../Laize';
import {
    CalculLaizeParams,
    ILaizeOpening,
    ILaizeRoom,
    ILaizeRoomItem,
    ILaizeWall,
    LaizeCalc,
    LaizeCalcProps,
} from '../LaizeCalc';
import { LaizeBandItemV3, LaizeTrace } from './LaizeBandTrace';
import { LaizeBands } from './LaizeBands';
import { LaizeLeftOver, LaizeLeftOverItem, LeftOverResult } from './LaizeLeftOver';
import { CoverNextSurfaceParams, CoverableSurface, LaizeOperation, LaizeSurfaces } from './LaizeSurfaces';

export type CalculateOptimalBandsLayoutParams = {
    laizeCalcProps: LaizeCalcProps;
    openings: Array<ILaizeOpening>;
    walls: Array<ILaizeWall>;
    roomItems: Array<ILaizeRoomItem>;
    rooms: Array<ILaizeRoom>;
    iteration?: number;
};
type CalculateOptimalBandsLayoutResult = {
    laizeResults: Array<LaizeResultItem>;
    roomGroupsDebug: RoomGroupDebug[];
};
export type RoomGroup = {
    adjacentRooms: AdjacentRooms[];
    roomIds: Set<string>;
    rooms: Set<ILaizeRoom>;

    /** The current surface laid. */
    laidSurface: JstsPolygon | undefined;

    /** Rooms that are being laid, or fully laid. */
    layingRoomIds: Set<string>;

    /**
     * Openings freed by a laid band.
     * A free opening is an opening that has been covered once by a band and is now free to be covered by another band.
     * This is used to determine if a band from another room and contiguous to the same opening needs to add margins
     */
    freeOpeningIds: Set<string>;

    /**
     * This updates when a free opening is used by a band.
     */
    usedOpeningIds: Set<string>;
};
export type RoomGroupDebug = {
    adjacentRooms: AdjacentRooms[];
    roomIds: Array<string>;
    rooms: Array<ILaizeRoom>;

    laidSurface: JstsPolygon | undefined;
    layingRoomIds: Array<string>;
    freeOpeningIds: Array<string>;
    usedOpeningIds: Array<string>;
};
type PrepareLaizeOperationParams = {
    laizeCalcProps: LaizeCalcProps;
    surfacePoly: JstsPolygon;
    direction: Direction;
    roomId: string;
    surfaceOpenings: Array<ILaizeOpening>;
    isBandNextToAnother?: boolean;
    freeOpeningIds: Set<string>;
    forceContiguousMargins?: boolean;
    hasSameFlooringDirection: boolean;
};
export type LaizeResultItem = {
    id: string;
    laidBand: Array<CoordPoint>;
    coveredSurfaceCoords: Array<CoordPoint>;
    rotationParams: RotationParams;
    surfacesLeft: Array<CoverableSurface>;
    roomId: string;
    bandHeight: number;
    leftOverResult?: LeftOverResult;
    leftOverUsed: boolean;
    usedLeftOverId: string;
    leftOvers: Array<LaizeLeftOverItem>;
    raccordLength: number;

    bandId?: number; //NOT EXIST WHY? @charles
};

type ConvertResultsParams = { laizeResults: Array<LaizeResultItem> };

interface ICALCULATORV3 {
    bandItems: Array<LaizeBandItemV3>;
}
const CALCULATOR_V3_INITIAL: ICALCULATORV3 = { bandItems: [] };
export let CALCULATOR_V3: ICALCULATORV3 = CALCULATOR_V3_INITIAL;

export let DEBUG_BANDS: Array<LaizeBandItemV3> = [];
export const addDebugBand = (points: CoordPoint[], color: string = 'yellow') => {
    DEBUG_BANDS.push({
        points: points,
        roomId: '',
        position: 'top',
        type: 'surface-covered',
        color: 'red',
        fillColor: color,
    });
};
export class LaizeV3 {
    private static showDebug = false;

    public static calculLaizeV3 = (laizeParams: CalculLaizeParams): LaizeCalculatorResult | undefined => {
        const {
            laizeCalcProps,
            rooms,
            forcedGlobalDirection,
            iteration,
            logName = '',
            requestedBy,
            flooringDirectionByRoomId: inputDirectionByRoomId,
        } = laizeParams;
        if (laizeCalcProps.TYPE === undefined) {
            return {
                isPossible: false,
                laizeResult: 0,
                roomsBandsLength: [],
                roomsBandsDetails: [],
                roomsBandsDetailsContiguous: [],
                roomsRaccordLength: [],
                flooringDirectionByRoomId: {},
                possibleFlooringDirectionByRoomId: {},
            };
        }

        if (LaizeCalc.checkIfLaizeNotComputable(laizeCalcProps.LAIZE_PROPS)) {
            return undefined;
        }

        Logger.isLogEnabled() &&
            console.group(`LaizeV3 -> calculLaizeV3 : ${logName}`, { inputDirectionByRoomId, forcedGlobalDirection });

        CALCULATOR_V3 = { ...CALCULATOR_V3_INITIAL, bandItems: [] };
        DEBUG_BANDS = [];

        //* Initialize values
        let globalRotationAngle = this.directionToAngle(forcedGlobalDirection);
        let roomQueue = _.cloneDeep(rooms);
        const flooringDirectionByRoomId: { [roomId: string]: FlooringDirection } = {};
        const lameCalcsByRoomId: {
            [roomId: string]: { [flooringDirection: string]: LaizeCalculatorResult | undefined };
        } = {};
        const possibleFlooringDirectionByRoomId: { [roomId: string]: FlooringDirection[] } = {};

        //* If no global direction is set, calculate the possible lame directions for each room
        if (globalRotationAngle === undefined) {
            roomQueue.forEach((room) => {
                if (laizeCalcProps.TYPE === ProductType.LamePleine) {
                    const laizeVerticalCalc = LaizeCalc.calculLaize({
                        ...laizeParams,
                        rooms: [room],
                        forcedGlobalDirection: LaizeDirection.Vertical,
                        logName: `Computing lame vertical room ${room.roomId}`,
                    });
                    const laizeHorizontalCalc = LaizeCalc.calculLaize({
                        ...laizeParams,
                        rooms: [room],
                        forcedGlobalDirection: LaizeDirection.Horizontal,
                        logName: `Computing lame horizontal room ${room.roomId}`,
                    });
                    CALCULATOR_V3 = { ...CALCULATOR_V3_INITIAL, bandItems: [] };
                    lameCalcsByRoomId[room.roomId!] = {
                        [FlooringDirection.LameHorizontal]: laizeHorizontalCalc,
                        [FlooringDirection.LameVertical]: laizeVerticalCalc,
                    };
                    possibleFlooringDirectionByRoomId[room.roomId!] = [
                        FlooringDirection.ClassiqueHorizontal,
                        FlooringDirection.ClassiqueVertical,
                    ];
                    laizeVerticalCalc?.isPossible &&
                        possibleFlooringDirectionByRoomId[room.roomId!].push(FlooringDirection.LameVertical);
                    laizeHorizontalCalc?.isPossible &&
                        possibleFlooringDirectionByRoomId[room.roomId!].push(FlooringDirection.LameHorizontal);
                } else {
                    possibleFlooringDirectionByRoomId[room.roomId!] = [
                        FlooringDirection.LaizeVertical,
                        FlooringDirection.LaizeHorizontal,
                    ];
                }
            });
        }

        //* If a global direction is set, use the angle from it to rotate all rooms
        if (globalRotationAngle !== undefined) {
            roomQueue.forEach((room) => (room.rotationAngle = globalRotationAngle));
        }
        //* If an input direction by room is set, use the angle from it to rotate each rooms
        //* Assigns the flooringDirectionByRoomId to the inputDirectionByRoomId
        //* Also removes the rooms that are not computable (ClassiqueHorizontal / ClassiqueVertical)
        else if (inputDirectionByRoomId && Object.keys(inputDirectionByRoomId).length > 0) {
            roomQueue
                .filter((room) => inputDirectionByRoomId[room.roomId!])
                .forEach((room) => (flooringDirectionByRoomId[room.roomId!] = inputDirectionByRoomId[room.roomId!]!));

            roomQueue = roomQueue.filter(
                (room) =>
                    inputDirectionByRoomId[room.roomId!] !== FlooringDirection.ClassiqueHorizontal &&
                    inputDirectionByRoomId[room.roomId!] !== FlooringDirection.ClassiqueVertical
            );
            roomQueue.forEach(
                (room) => (room.rotationAngle = this.flooringDirectionToAngle(inputDirectionByRoomId[room.roomId!]))
            );
        }
        //* If no input is given, use the flooringDirection from each room (chosen by the vendor)
        //* If no flooringDirection is given: #35185
        //* - If the product is a rouleau, calculate the cheapest direction for each room
        //* - If the product is a lame pleine, force the direction to "optimisation des lames horizontales"
        //* Also removes the rooms that are not computable (ClassiqueHorizontal / ClassiqueVertical)
        else {
            roomQueue.forEach((room, index, array) => {
                if (room.flooringDirection) {
                    if (
                        room.flooringDirection === FlooringDirection.ClassiqueVertical ||
                        room.flooringDirection === FlooringDirection.ClassiqueHorizontal
                    ) {
                        array.splice(index, 1);
                        flooringDirectionByRoomId[room.roomId!] = room.flooringDirection;
                    } else {
                        const calc = lameCalcsByRoomId[room.roomId!]?.[room.flooringDirection!];
                        if (laizeCalcProps.TYPE === ProductType.LamePleine && !calc?.isPossible) {
                            array.splice(index, 1);
                            flooringDirectionByRoomId[room.roomId!] = FlooringDirection.ClassiqueHorizontal;
                        } else {
                            flooringDirectionByRoomId[room.roomId!] = room.flooringDirection;
                        }
                        room.rotationAngle = this.flooringDirectionToAngle(room.flooringDirection);
                    }
                } else {
                    if (laizeCalcProps.TYPE === ProductType.Rouleau) {
                        const laizeVerticalCalc = LaizeCalc.calculLaize({
                            ...laizeParams,
                            rooms: [room],
                            forcedGlobalDirection: LaizeDirection.Vertical,
                            logName: `Computing rouleau vertical room ${room.roomId}`,
                        });
                        const laizeHorizontalCalc = LaizeCalc.calculLaize({
                            ...laizeParams,
                            rooms: [room],
                            forcedGlobalDirection: LaizeDirection.Horizontal,
                            logName: `Computing rouleau horizontal room ${room.roomId}`,
                        });
                        const cheapestDirection = (() => {
                            if (laizeHorizontalCalc?.isPossible && !laizeVerticalCalc?.isPossible) {
                                return LaizeDirection.Horizontal;
                            } else if (!laizeHorizontalCalc?.isPossible && laizeVerticalCalc?.isPossible) {
                                return LaizeDirection.Vertical;
                            } else if (
                                (laizeHorizontalCalc?.laizeResult ?? 0) < (laizeVerticalCalc?.laizeResult ?? 0)
                            ) {
                                return LaizeDirection.Horizontal;
                            }
                            return LaizeDirection.Vertical;
                        })();
                        room.rotationAngle = this.directionToAngle(cheapestDirection);
                        flooringDirectionByRoomId[room.roomId!] =
                            cheapestDirection === LaizeDirection.Horizontal
                                ? FlooringDirection.LaizeHorizontal
                                : FlooringDirection.LaizeVertical;
                    } else {
                        array.splice(index, 1);
                        flooringDirectionByRoomId[room.roomId!] = FlooringDirection.ClassiqueHorizontal;
                    }
                }
            });
        }

        const params = LaizeCalc.toCalculateOptimalBandsLayoutParams({ ...laizeParams, rooms: roomQueue });
        Logger.log('Params: ', params);
        const { laizeResults, roomGroupsDebug } = this.calculateOptimalBandsLayout(params);
        LaizeTrace.drawLaizeResults({ laizeResults, iteration: iteration });
        const laizeResult = this.convertResults({ laizeResults });

        Logger.isLogEnabled() && console.groupEnd();
        return {
            ...laizeResult,
            bands_v3: [...CALCULATOR_V3.bandItems, ...DEBUG_BANDS],
            iteration,
            requestedBy,
            flooringDirectionByRoomId,
            possibleFlooringDirectionByRoomId,
            roomGroupsDebug,
            params,
        };
    };

    /**
     * Get angle from LaizeDirection
     * @param direction LaizeDirection
     */
    public static directionToAngle = (direction?: LaizeDirection) => {
        if (direction) {
            switch (direction) {
                case LaizeDirection.Vertical:
                    return 0;
                case LaizeDirection.Horizontal:
                    return 90;
            }
        }
    };

    /**
     * Get angle from FlooringDirection
     * @param direction FlooringDirection
     */
    public static flooringDirectionToAngle = (flooringDirection?: FlooringDirection) => {
        switch (flooringDirection) {
            case FlooringDirection.LaizeVertical:
            case FlooringDirection.LameVertical:
                return 0;
            case FlooringDirection.LaizeHorizontal:
            case FlooringDirection.LameHorizontal:
                return 90;
        }
    };

    /**
     * Determines room groups based on adjacent rooms and rooms.
     * @param adjacentRooms An array of adjacent rooms.
     * @param rooms An array of rooms.
     * @returns An array of room groups.
     */
    public static determineRoomGroups(adjacentRooms: AdjacentRooms[], rooms: ILaizeRoom[]): RoomGroup[] {
        const adjacentRoomsLeft = _.cloneDeep(adjacentRooms);
        let roomsLeft = _.cloneDeep(rooms);
        const roomGroups: RoomGroup[] = [];
        while (adjacentRoomsLeft.length > 0) {
            const firstRoom = adjacentRoomsLeft[0];
            const roomGroup: RoomGroup = {
                adjacentRooms: [firstRoom],
                roomIds: new Set(firstRoom.roomIds),
                rooms: new Set(rooms.filter((x) => firstRoom.roomIds.includes(x.roomId!))),
                laidSurface: undefined,
                layingRoomIds: new Set(),
                freeOpeningIds: new Set(),
                usedOpeningIds: new Set(),
            };
            roomsLeft = roomsLeft.filter((x) => !firstRoom.roomIds.includes(x.roomId!));
            roomGroups.push(roomGroup);
            adjacentRoomsLeft.splice(0, 1);
            let i = 0;
            while (i < adjacentRoomsLeft.length) {
                const room = adjacentRoomsLeft[i];
                const isContiguousToGroup = adjacentRoomsLeft.some((x) =>
                    x.roomIds.some((roomId) => roomGroup.roomIds.has(roomId))
                );
                if (isContiguousToGroup) {
                    roomGroup.adjacentRooms.push(room);
                    room.roomIds.forEach((roomId) => roomGroup.roomIds.add(roomId));
                    rooms.filter((x) => room.roomIds.includes(x.roomId!)).forEach((room) => roomGroup.rooms.add(room));
                    adjacentRoomsLeft.splice(i, 1);
                    roomsLeft = roomsLeft.filter((x) => !room.roomIds.includes(x.roomId!));
                } else {
                    i++;
                }
            }
        }
        roomsLeft.forEach((x) => {
            const roomGroup: RoomGroup = {
                adjacentRooms: [],
                roomIds: new Set([x.roomId!]),
                rooms: new Set([x]),
                laidSurface: undefined,
                layingRoomIds: new Set(),
                freeOpeningIds: new Set(),
                usedOpeningIds: new Set(),
            };
            roomGroups.push(roomGroup);
        });
        return roomGroups;
    }

    /**
     * Converts a RoomGroup object to a RoomGroupDebug object for debugging purposes.
     * @param roomGroup The RoomGroup object to convert.
     * @returns A RoomGroupDebug object.
     */
    public static convertToRoomGroupDebug(roomGroup: RoomGroup): RoomGroupDebug {
        const roomGroupToUse = _.cloneDeep(roomGroup);
        return {
            adjacentRooms: roomGroupToUse.adjacentRooms,
            roomIds: Array.from(roomGroupToUse.roomIds),
            laidSurface: roomGroupToUse.laidSurface,
            layingRoomIds: Array.from(roomGroupToUse.layingRoomIds),
            rooms: Array.from(roomGroupToUse.rooms),
            freeOpeningIds: Array.from(roomGroupToUse.freeOpeningIds),
            usedOpeningIds: Array.from(roomGroupToUse.usedOpeningIds),
        };
    }

    /**
     * - If globalAngle is not undefined, set each room's rotationAngle to it.
     * - Converts each room to one or more coverable surface (rotated accordingly).
     * - Calculates a laize result for each surface, iterating until there is no more coverable surfaces left.
     * - Rotates the laize result back to its original angle.
     * - Returns the laize result list.
     */
    public static calculateOptimalBandsLayout({
        laizeCalcProps,
        walls,
        openings,
        roomItems,
        rooms,
        iteration = undefined,
    }: CalculateOptimalBandsLayoutParams): CalculateOptimalBandsLayoutResult {
        const adjacentRooms = WallUtils.determineAdjacentRooms(
            walls,
            openings,
            rooms.map((x) => x.roomId!)
        );
        const roomGroups = LaizeV3.determineRoomGroups(adjacentRooms, rooms);
        const laizeResults: Array<LaizeResultItem> = [];
        let roomGroupsDebug: RoomGroupDebug[] = [];
        let leftOvers: Array<LaizeLeftOverItem> = [];
        let surfacesLeft: Array<CoverableSurface> = [];

        let currentIteration = 0;
        for (const roomGroup of roomGroups) {
            //* Initialize the list of surfaces to cover for each room in the group
            const adjacentRoomIdsByRoomId: { [roomId: string]: Set<string> } = {};
            for (const adjacentRoom of roomGroup.adjacentRooms) {
                for (const roomId of adjacentRoom.roomIds) {
                    if (!adjacentRoomIdsByRoomId[roomId]) {
                        adjacentRoomIdsByRoomId[roomId] = new Set();
                    }
                    adjacentRoomIdsByRoomId[roomId].add(adjacentRoom.roomIds[0]);
                }
            }
            const mostAdjacentRoomIds = Object.keys(adjacentRoomIdsByRoomId).filter(
                (x) =>
                    adjacentRoomIdsByRoomId[x].size ===
                    Math.max(...Object.values(adjacentRoomIdsByRoomId).map((x) => x.size))
            );
            let roomsToCoverFirst = Array.from(roomGroup.rooms);
            if (mostAdjacentRoomIds.length) {
                roomsToCoverFirst = roomsToCoverFirst.filter((x) => mostAdjacentRoomIds.includes(x.roomId!));
            }
            for (let i = 0; i < roomsToCoverFirst.length; i++) {
                const previousRoom = i > 0 ? roomsToCoverFirst[i - 1] : undefined;
                const room = roomsToCoverFirst[i];
                const coverableSurfaces = LaizeSurfaces.convertToCoverableSurfaces({
                    laizeCalcProps,
                    room,
                    roomItems,
                    walls,
                    iteration,
                    adjacentRooms: roomGroup.adjacentRooms,
                    freeOpeningIds: new Set(),
                    isInitialSurfaces: true,
                    hasSameFlooringDirection: room.flooringDirection === previousRoom?.flooringDirection,
                });
                surfacesLeft.push(...coverableSurfaces);
            }
        }

        //* Iterate over surfaces to cover until there is no more surface left to cover
        //* Or until we have laid a band in each room
        while (surfacesLeft.length > 0 || roomGroups.some((x) => x.layingRoomIds.size < x.roomIds.size)) {
            if (currentIteration > 100) {
                Toast.showError({
                    content: `Impossible de calculer la pose du produit ${laizeCalcProps.LAIZE_PROPS.productCode}`,
                });
                break;
            }
            if (
                laizeCalcProps.TYPE === ProductType.LamePleine &&
                !this.checkIfLamePleineComputable(surfacesLeft, laizeCalcProps.LONGUEUR_CM)
            ) {
                return { roomGroupsDebug, laizeResults: [] };
            }

            const isCurrentIteration = iteration === currentIteration;

            if (isCurrentIteration && this.showDebug) {
                const colors = ['red', 'blue', 'yellow', 'purple', 'orange'];
                surfacesLeft.forEach((x, i) => {
                    if (x.nextLaizeOperation?.effectiveBand)
                        addDebugBand(x.nextLaizeOperation.effectiveBand, colors[i % colors.length]);
                });
            }

            if (surfacesLeft.length === 0) {
                for (const roomGroup of roomGroups.filter((x) => x.layingRoomIds.size < x.roomIds.size)) {
                    const roomsNotCovered = Array.from(roomGroup.rooms).filter(
                        (x) => !roomGroup.layingRoomIds.has(x.roomId!)
                    );
                    for (let i = 0; i < roomsNotCovered.length; i++) {
                        const previousRoom = i > 0 ? roomsNotCovered[i - 1] : undefined;
                        const room = roomsNotCovered[i];
                        const coverableSurfaces = LaizeSurfaces.convertToCoverableSurfaces({
                            laizeCalcProps,
                            room,
                            roomItems,
                            walls,
                            iteration,
                            adjacentRooms: roomGroup.adjacentRooms,
                            freeOpeningIds: new Set(),
                            isInitialSurfaces: true,
                            forceContiguousMargins: true,
                            hasSameFlooringDirection: true,
                        });
                        surfacesLeft.push(...coverableSurfaces);
                    }
                }
            }

            //* Cover the next surface
            const laizeResult: LaizeResultItem = this.coverNextSurface({
                previousLaizeRoomId: laizeResults.slice(-1)[0]?.roomId,
                laizeCalcProps,
                surfacesToCover: surfacesLeft,
                leftOvers,
                roomGroups,
                walls,
                roomItems,
            })!;

            if (isCurrentIteration) {
                roomGroupsDebug = roomGroups.map(LaizeV3.convertToRoomGroupDebug);
            }

            //* Update results and surfaces to cover
            if (laizeResult) {
                //* Inverse rotation of the laize result to get it back to its original angle.
                const inverseRotationParams = {
                    angle: -laizeResult.rotationParams?.angle,
                    center: laizeResult.rotationParams?.center,
                };
                if (inverseRotationParams.angle) {
                    PointUtils.rotation(
                        laizeResult.coveredSurfaceCoords,
                        inverseRotationParams.angle,
                        inverseRotationParams.center
                    );
                    PointUtils.rotation(
                        laizeResult.laidBand,
                        inverseRotationParams.angle,
                        inverseRotationParams.center
                    );
                    if (laizeResult.leftOverResult) {
                        laizeResult.leftOverResult.leftOverCoords.forEach((x) =>
                            PointUtils.rotation(x, inverseRotationParams.angle, inverseRotationParams.center)
                        );
                    }
                }
                laizeResults.push(laizeResult);
                const roomGroupModified = roomGroups.find((x) => x.roomIds.has(laizeResult.roomId))!;
                leftOvers = laizeResult.leftOvers;
                surfacesLeft = laizeResult.surfacesLeft;
                const laidBandPolygon = JstsUtils.createJstsPolygon(laizeResult.coveredSurfaceCoords);
                roomGroupModified.laidSurface = roomGroupModified.laidSurface
                    ? roomGroupModified.laidSurface.union(laidBandPolygon)
                    : laidBandPolygon;
            } else {
                leftOvers = [];
                surfacesLeft = [];
            }
            currentIteration++;
        }

        if (roomGroupsDebug.length === 0) {
            roomGroupsDebug = roomGroups.map(LaizeV3.convertToRoomGroupDebug);
        }

        return { roomGroupsDebug, laizeResults };
    }

    private static checkIfLamePleineComputable(surfacesToCover: Array<CoverableSurface>, longueurCm: number = 50_000) {
        let isComputable = true;
        for (let i = 0; i < surfacesToCover.length; i++) {
            const surfaceToCover = surfacesToCover[i];
            // #32686: C&P V2 > Calcul en lame pleine > Marge en longueur
            // On doit ajouter 5cm de marge en longueur pour la lame pleine
            if (surfaceToCover.nextLaizeOperation!.surfaceHeight + 5 > longueurCm) {
                isComputable = false;
                break;
            }
        }
        return isComputable;
    }

    /**
     * Calculates a laize result for a given list of surfaces to cover.
     * - Finds the next surface to cover (meaning: the surface which would need the heighest band or the largest area).
     * - Decides if this surface can be covered using left-over or needs a new band.
     * - If a new band is needed, calculates if new left-over is created.
     * - Removes the covered surface from the list of surfaces left to cover.
     * - If the surface was not completely covered, add the surface(s) left to the list of surfaces left to cover.
     * - For each of those newly added surfaces, calculate their next laize operation (next band).
     */
    private static coverNextSurface({
        previousLaizeRoomId,
        laizeCalcProps,
        surfacesToCover,
        leftOvers,
        roomItems,
        walls,
        roomGroups,
    }: CoverNextSurfaceParams): LaizeResultItem | undefined {
        let coverableSurfaces: Array<CoverableSurface> = Array.from(surfacesToCover);
        let surfaceToLay = LaizeSurfaces.findNextSurfaceByDimensions({ coverableSurfaces });

        if (!surfaceToLay?.nextLaizeOperation || !surfaceToLay) {
            return undefined;
        }

        surfaceToLay = LaizeSurfaces.findNextSurfaceByDimensions({ coverableSurfaces })!;
        const laizeOperation = surfaceToLay.nextLaizeOperation!;

        const { adjacentRooms, layingRoomIds, rooms, roomIds, freeOpeningIds, usedOpeningIds } = roomGroups.find((x) =>
            x.roomIds.has(surfaceToLay!.roomId!)
        )!;

        if (layingRoomIds.size === 0) {
            //* Remove all the surfaces from this roomGroup
            //* => Once we lay a band, we don't want to start the same room from a different direction.
            //* => We can't let "blank" surfaces in a roomGroup
            coverableSurfaces = coverableSurfaces.filter((x) => !roomIds.has(x.roomId));
        }
        if (!layingRoomIds.has(surfaceToLay!.roomId!)) {
            coverableSurfaces = coverableSurfaces.filter((x) => surfaceToLay?.roomId !== x.roomId);
        }
        layingRoomIds.add(surfaceToLay.roomId!);

        const bandPoly = JstsUtils.createJstsPolygon(laizeOperation.effectiveBand, true);
        const differenceGeom = surfaceToLay.surfacePoly.difference(bandPoly);
        const coveredSurface = surfaceToLay.surfacePoly.intersection(bandPoly).buffer(0);
        const coveredSurfaceCoords = _.cloneDeep(coveredSurface.getCoordinates());

        // Calculate the left-over AS IF laying a new band
        const potentialLeftOverResult = LaizeLeftOver.calculateLeftOver({
            laizeCalcProps,
            coveredSurfacePoly: coveredSurface,
            bandCoords: laizeOperation.realBand!,

            verticalMargin: laizeOperation.verticalMargin ?? 0,
            horizontalMargin: laizeOperation.horizontalMargin ?? 0,
            laizeDirection: surfaceToLay.laizeDirection,
        });

        // If laying a new band would result in some left-over, then the surface we are trying to cover
        // has a smaller width than the width of a real band. Thus, we do not try to cut a whole new band
        // from left-overs, instead we try to cut a band equivalent to the surface to cover.

        let bandToCutInLeftOver;
        const roomsArray = Array.from(rooms);
        const previousSurfaceFlooringDirection = roomsArray.find(
            (x) => x.roomId === previousLaizeRoomId
        )?.rotationAngle;
        const nextSurfaceFlooringDirection = roomsArray.find((x) => x.roomId === surfaceToLay?.roomId)?.rotationAngle;
        const sameRoomAsPreviousSurface = previousLaizeRoomId === surfaceToLay?.roomId;
        const hasSameFlooringDirection = previousSurfaceFlooringDirection === nextSurfaceFlooringDirection;

        if (potentialLeftOverResult) {
            const testSurfaceContiguous = LaizeV3.testSurfaceContiguous(
                surfaceToLay.openings,
                JstsUtils.createJstsPolygon(potentialLeftOverResult.surfaceCoveredCoords),
                freeOpeningIds
            );

            const laidLaizeSurface = LaizeLeftOver.computeLaidLaizeFromDensifiedPolygon({
                laizeCalcProps,
                coveredSurfacePoly: JstsUtils.createJstsPolygon(potentialLeftOverResult.surfaceCoveredCoords),
                bandCoords: laizeOperation.realBand!,
                horizontalMargin:
                    laizeCalcProps.LAIZE_MARGIN +
                    (testSurfaceContiguous.needHorizontalMargin && hasSameFlooringDirection
                        ? laizeCalcProps.MARGIN_WIDTH ?? 0
                        : 0) +
                    (sameRoomAsPreviousSurface ? laizeCalcProps.RACCORD_SIZE / 2 : 0),
                verticalMargin:
                    laizeCalcProps.LAIZE_MARGIN +
                    (testSurfaceContiguous.needVerticalMargin && hasSameFlooringDirection
                        ? laizeCalcProps.MARGIN_LENGTH ?? 0
                        : 0) +
                    (sameRoomAsPreviousSurface ? laizeCalcProps.RACCORD_SIZE / 2 : 0),
                laizeDirection: surfaceToLay.laizeDirection,
            });
            bandToCutInLeftOver = laidLaizeSurface.getCoordinates();
        } else {
            bandToCutInLeftOver = laizeOperation.realBand!;
        }

        const usedLeftOver = LaizeLeftOver.tryUsingLeftOver({
            band: bandToCutInLeftOver,
            leftOvers: leftOvers,
        });
        const leftOverUsed = Boolean(usedLeftOver);

        // Left-over is only created if no left-over is used to cover this surface, because when using left-over
        // the band has the minimal width possible.
        if (!leftOverUsed && potentialLeftOverResult) {
            potentialLeftOverResult.leftOverCoords.forEach((coords) =>
                leftOvers.push({
                    id: surfaceToLay!.id,
                    polygon: JstsUtils.createJstsPolygon(coords, true),
                })
            );
        }

        // The result from the difference between the surface and the band might be multiple polygons.
        // For example, for a U-shape room, one band might cover most part of the surface but not the two 'tips'
        // of the U.
        let roomSurfacesLeft = [];
        if (differenceGeom._geometries) {
            roomSurfacesLeft = differenceGeom._geometries;
        } else {
            roomSurfacesLeft.push(differenceGeom);
        }
        roomSurfacesLeft = roomSurfacesLeft.filter((x) => x.getArea() > 0);

        // If we lay a band that has contiguous openings
        if (surfaceToLay.openings.length) {
            //* Update freeOpenings and usedOpenings
            let newFreeOpeningIds = new Set<string>();
            const openingIds = surfaceToLay.openings.map((x) => x.openingId!).filter((id) => !usedOpeningIds.has(id));
            for (let id of openingIds) {
                if (freeOpeningIds.has(id)) {
                    freeOpeningIds.delete(id);
                    usedOpeningIds.add(id);
                } else {
                    freeOpeningIds.add(id);
                    newFreeOpeningIds.add(id);
                }
            }

            //* Create a candidate band for each opening
            const roomIds = adjacentRooms
                .filter((x) => newFreeOpeningIds.has(x.opening.openingId!))
                .flatMap((x) => x.roomIds)
                .filter((x) => !layingRoomIds.has(x));
            for (let roomId of roomIds) {
                const room = Array.from(rooms).find((x) => x.roomId === roomId);
                if (room) {
                    const surfaces = LaizeSurfaces.convertToCoverableSurfaces({
                        laizeCalcProps,
                        room,
                        roomItems,
                        walls,
                        adjacentRooms,
                        freeOpeningIds,
                        isInitialSurfaces: false,
                        hasSameFlooringDirection,
                    });
                    coverableSurfaces.push(...surfaces);
                }
            }
        }

        // Remove the surface we just covered.
        coverableSurfaces = coverableSurfaces.filter((x) => surfaceToLay?.id !== x.id);

        const inverseRotationParams = {
            angle: -surfaceToLay.rotationParams?.angle,
            center: surfaceToLay.rotationParams?.center,
        };
        // Adding each of the surfaces left to cover that resulted from covering this surface.
        // For each surface, calculate next laize operation (calculate next band to lay)
        for (const roomSurface of roomSurfacesLeft) {
            const nextLaizeOperation = this.prepareLaizeOperation({
                laizeCalcProps,
                surfacePoly: roomSurface,
                direction: LaizeSurfaces.getNextSurfaceDirection({
                    previousSurface: surfaceToLay.originalSurfacePoly,
                    nextSurface: roomSurface,
                }),
                roomId: surfaceToLay.roomId,
                //* We don't need to compute surfaceOpenings because this is an adjacent band
                //* And we already know that we can't add contiguous margins to adjacent bands
                surfaceOpenings: [],
                isBandNextToAnother: true,
                freeOpeningIds,
                hasSameFlooringDirection,
            });
            const originalNextLaizeOperationSurfaceCoords = _.cloneDeep(nextLaizeOperation?.surfaceCoords);
            if (inverseRotationParams.angle) {
                PointUtils.rotation(
                    originalNextLaizeOperationSurfaceCoords ?? [],
                    inverseRotationParams.angle,
                    inverseRotationParams.center
                );
            }
            const newSurfaceOpenings = LaizeSurfaces.getSurfaceOpenings({
                surfacePoly: JstsUtils.createJstsPolygon(originalNextLaizeOperationSurfaceCoords, true),
                openings: adjacentRooms.map((x) => x.opening),
            });
            const newSurface: CoverableSurface = {
                id: IdGenerator.generate(),
                roomId: surfaceToLay.roomId,
                surfacePoly: roomSurface,
                originalSurfacePoly: roomSurface,
                nextLaizeOperation,
                rotationParams: surfaceToLay.rotationParams,
                openings: newSurfaceOpenings,
                laizeDirection: surfaceToLay.laizeDirection,
            };
            coverableSurfaces.push(newSurface);
        }

        // If left-over was used, the laid band might not be the one originally calculated.
        const laidBand = leftOverUsed ? bandToCutInLeftOver : laizeOperation.realBand!;

        return {
            id: surfaceToLay.id,
            laidBand: laidBand,
            coveredSurfaceCoords: coveredSurfaceCoords,
            rotationParams: surfaceToLay.rotationParams,
            surfacesLeft: coverableSurfaces,
            roomId: laizeOperation.roomId!,
            bandHeight: laizeOperation.bandHeight!,
            leftOverResult: leftOverUsed ? undefined : potentialLeftOverResult,
            leftOverUsed: leftOverUsed,
            usedLeftOverId: usedLeftOver?.id!,
            leftOvers: leftOvers,
            raccordLength: laizeOperation.raccordLength!,
        };
    }

    public static testSurfaceContiguous = (
        surfaceOpenings: ILaizeOpening[],
        surfaceToCheck: JstsPolygon,
        freeOpeningIds: Set<string>
    ) => {
        let motifMargin: { needVerticalMargin: boolean; needHorizontalMargin: boolean; isSurfaceContiguous: boolean } =
        {
            needVerticalMargin: false,
            needHorizontalMargin: false,
            isSurfaceContiguous: false,
        };

        for (const opening of surfaceOpenings) {
            const isOpeningOnSurface = PointUtils.testPointWithinPolygon(
                JstsUtils.toJstsCoordPoint(opening.x!, opening.y!),
                surfaceToCheck
            );
            if (isOpeningOnSurface) {
                if (freeOpeningIds.has(opening.openingId!)) {
                    motifMargin.isSurfaceContiguous = true;

                    const isVertical = opening.limit?.[0].x === opening.limit?.[1].x;
                    const isHorizontal = opening.limit?.[0].y === opening.limit?.[1].y;
                    if (isVertical) {
                        motifMargin.needVerticalMargin = true;
                    } else if (isHorizontal) {
                        motifMargin.needHorizontalMargin = true;
                    }

                    return motifMargin;
                }
            }
        }
        return motifMargin;
    };

    /**
     * Calculates the band coordinates to cover a given surface, accounting for
     * vertical & horizontal margins.
     */
    public static prepareLaizeOperation({
        laizeCalcProps,
        surfacePoly,
        direction,
        roomId,
        surfaceOpenings = [],
        isBandNextToAnother = false,
        freeOpeningIds,
        forceContiguousMargins = false,
        hasSameFlooringDirection,
    }: PrepareLaizeOperationParams): LaizeOperation | undefined {
        const widthMargin = laizeCalcProps.LAIZE_MARGIN;
        let horizontalMargin = isBandNextToAnother
            ? laizeCalcProps.LAIZE_CONSECUTIVE_BANDS_MARGIN + laizeCalcProps.MARGIN_WIDTH
            : 0;
        let verticalMargin = isBandNextToAnother
            ? laizeCalcProps.LAIZE_MARGIN + laizeCalcProps.RACCORD_SIZE
            : laizeCalcProps.LAIZE_MARGIN;

        // If laying a band over a surface results in more than one intersection polygon
        // (which is the case if a room item separates the room in half for example), then we should
        // not be laying a single band, but one band per resulting intersection surface.
        // To account for this, this first intersectionResult is calculated as if we were laying a single band on the surface,
        // then if the intersection is made of more than one surface, we calculate the bands for each of those surface,
        // then compare them and chose the one with the heighest band, or if equal, the one which covers the most surface.
        let intersectionResult = LaizeBands.getBandIntersection({
            surfacePoly,
            bandWidth: laizeCalcProps.BAND_WIDTH,
            direction,
            widthMargin,
            horizontalMargin,
        });
        let broadIntersection = intersectionResult.intersection;

        if (broadIntersection.getArea() <= 0) {
            return undefined;
        }

        let subSurfaces = [];
        if (broadIntersection._geometries !== undefined) {
            subSurfaces = broadIntersection._geometries;
        } else {
            subSurfaces.push(broadIntersection);
        }
        let isContiguous = false;
        let bestSurface: LaizeOperation | undefined = undefined;
        for (let subSurface of subSurfaces) {
            let horizontalMarginToUse = horizontalMargin;
            let verticalMarginToUse = verticalMargin;
            if (!isBandNextToAnother) {
                const testSurfaceContiguous = LaizeV3.testSurfaceContiguous(
                    surfaceOpenings,
                    subSurface,
                    freeOpeningIds
                );
                if ((testSurfaceContiguous.isSurfaceContiguous || forceContiguousMargins) && hasSameFlooringDirection) {
                    if (testSurfaceContiguous.needHorizontalMargin || forceContiguousMargins) {
                        horizontalMarginToUse += laizeCalcProps.MARGIN_WIDTH;
                    }
                    if (testSurfaceContiguous.needVerticalMargin || forceContiguousMargins) {
                        verticalMarginToUse += laizeCalcProps.MARGIN_LENGTH;
                    }
                    isContiguous = true;
                }
            }

            const subIntersectionResult = LaizeBands.getBandIntersection({
                surfacePoly: subSurface,
                bandWidth: laizeCalcProps.BAND_WIDTH,
                direction,
                widthMargin,
                horizontalMargin: horizontalMarginToUse,
            });
            const bands = LaizeBands.getBands({
                intersectionResult: subIntersectionResult,
                verticalMargin: verticalMarginToUse,
            });
            const realBand = bands.realBand;
            const realBandHeight = LaizeBands.getBandHeight({ band: realBand });
            const surfaceHeight = realBandHeight - verticalMarginToUse;
            const bandSurface = laizeCalcProps.BAND_WIDTH * realBandHeight;
            const potentialSurface: LaizeOperation = {
                intersectionResult: subIntersectionResult,
                bands,
                realBandHeight,
                surfaceHeight,
                bandSurface,
                horizontalMargin: horizontalMarginToUse,
                verticalMargin: verticalMarginToUse,
            };

            if (
                !bestSurface ||
                realBandHeight > bestSurface!.bandHeight! ||
                (realBandHeight === bestSurface.bandHeight && bandSurface > bestSurface!.bandSurface!)
            ) {
                bestSurface = potentialSurface;
            }
        }

        const bands = bestSurface?.bands!;
        const intersection = bestSurface!.intersectionResult!.intersection;

        return {
            effectiveBand: bands.effectiveBand,
            realBand: bands.realBand,
            surfaceCoords: intersection.getCoordinates(),
            surfaceArea: intersection.getArea(),
            surfaceHeight: bestSurface!.surfaceHeight,
            bandHeight: bestSurface!.realBandHeight,
            roomId: roomId,
            raccordLength: isBandNextToAnother ? bestSurface!.surfaceHeight : 0,

            horizontalMargin: bestSurface!.horizontalMargin,
            verticalMargin: bestSurface!.verticalMargin,
            isContiguous: isContiguous,
        };
    }

    public static getLaizeDirection(leftPotentialOperation: any, rightPotentialOperation: any): Direction {
        if (leftPotentialOperation.surfaceHeight > rightPotentialOperation.surfaceHeight) {
            return Direction.LeftToRight;
        } else if (leftPotentialOperation.surfaceHeight < rightPotentialOperation.surfaceHeight) {
            return Direction.RightToLeft;
        } else if (leftPotentialOperation.surfaceArea < rightPotentialOperation.surfaceArea) {
            return Direction.RightToLeft;
        } else {
            return Direction.LeftToRight;
        }
    }

    /** Converts the laize V3 results into laize V1 results **/
    public static convertResults({ laizeResults }: ConvertResultsParams): LaizeCalculatorResult {
        let roomsBands: Array<any> = [];
        let roomsRaccords: Array<any> = [];
        let bandDetailsContiguous: Array<LaizeBand> = [];
        let bandsLength: Array<number> = [];

        if (laizeResults.length === 0) {
            return {
                isPossible: false,
                laizeResult: 0,
                roomsBandsLength: [],
                roomsBandsDetails: [],
                roomsBandsDetailsContiguous: [],
                roomsRaccordLength: [],
                flooringDirectionByRoomId: {},
                possibleFlooringDirectionByRoomId: {},
            };
        }

        let i = 1;
        for (let result of laizeResults) {
            const band: LaizeBand = {
                id: i++,
                length: _.round(result.bandHeight / MapConstants.meter, 2),
                leftOverUsed: result.leftOverUsed,
                roomId: result.roomId,
                leftOverCoords: result.leftOverResult?.leftOverCoords,
                surfaceCoveredCoords: result.leftOverResult?.surfaceCoveredCoords,
            };

            bandDetailsContiguous.push(band);

            //* Only count the lengthes of new bands
            if (!result.leftOverUsed) {
                bandsLength.push(band.length);
            }

            let roomBands = roomsBands.find((x) => x.roomId === result.roomId);
            if (!roomBands) {
                roomBands = {
                    roomId: result.roomId,
                    bands: [],
                    leftOverBandHasBeenReusedInThisRoom: false,
                };
                roomsBands.push(roomBands);
            }
            roomBands.bands.push(band);
            roomBands.leftOverBandHasBeenReusedInThisRoom =
                roomBands.leftOverBandHasBeenReusedInThisRoom || result.leftOverUsed;

            let roomRaccord = roomsRaccords.find((x) => x.roomId === result.roomId);
            if (!roomRaccord) {
                roomRaccord = { roomId: result.roomId, length: 0 };
                roomsRaccords.push(roomRaccord);
            }
            roomRaccord.length += _.round(result.raccordLength / MapConstants.meter, 2);
        }
        return {
            isPossible: true,
            laizeResult: bandsLength.reduce((a, b) => a + b, 0),
            roomsBandsLength: bandsLength,
            roomsBandsDetails: roomsBands,
            roomsBandsDetailsContiguous: bandDetailsContiguous,
            roomsRaccordLength: roomsRaccords,
        };
    }
}
