import React, { SyntheticEvent } from 'react';
import { SvgPanZoomEvent } from '../../../Events/withEvent';
import { MapConstants, MapMode } from '../MapConstants';
import { CoordPoint, SnapGesture } from './Types';
import { Value } from 'react-svg-pan-zoom';
import { PointUtils } from './PointUtils';

type CalculSnapParams = {
    event: SvgPanZoomEvent;
    state: string;
    gridMagnet?: boolean;
    angleMagnet?: { parentPoint: CoordPoint; childPoint: CoordPoint } /** upcomming use with wall points move */;
};

export class EventUtils {
    public static calculSnap({ event, state, gridMagnet, angleMagnet }: CalculSnapParams): SnapGesture {
        let eX;
        let eY;

        const mouseEvent = event.originalEvent as React.MouseEvent<SVGElement>;
        const touchEvent = event.originalEvent as React.TouchEvent<SVGElement>;

        //! documentation, log to get values
        const value = (event as any).value;
        const panX = value.e;
        const panY = value.f;
        const zoom = value.a;

        //if (typeof event !== undefined && typeof touchEvent.touches !== 'undefined' && touchEvent.touches) {
        if (touchEvent.touches) {
            const touches = touchEvent.changedTouches;
            eX = touches[0].pageX;
            eY = touches[0].pageY;
            MapConstants.factor = 1;
        } else {
            eX = mouseEvent.pageX;
            eY = mouseEvent.pageY;
        }
        const offset = event.SVGViewer.getBoundingClientRect();
        const x_mouse = eX * MapConstants.factor - offset.left * MapConstants.factor + MapConstants.originX_viewbox;
        const y_mouse = eY * MapConstants.factor - offset.top * MapConstants.factor + MapConstants.originY_viewbox;

        let x_grid: number;
        let y_grid: number;
        if (state === MapMode.GRID_SNAP_ON) {
            x_grid = Math.round(x_mouse / MapConstants.gridStep) * MapConstants.gridStep;
            y_grid = Math.round(y_mouse / MapConstants.gridStep) * MapConstants.gridStep;
        }
        if (state === MapMode.GRID_SNAP_OFF) {
            x_grid = x_mouse;
            y_grid = y_mouse;
        }

        // const panZoom = (window as any).panZoom;
        //const pan = panZoom?.getPan();
        //const zoom = panZoom?.getZoom();

        //@ts-ignore
        //console.log('calculSnap', { event, mouseEvent, touchEvent, eX, eY, x_grid, y_grid, panX, panY, });

        // return {
        //     x: (x_grid + (pan.x * -1)) / zoom,
        //     y: (y_grid + (pan.y * -1)) / zoom,
        //     xMouse: (x_mouse + (pan.x * -1)) / zoom,
        //     yMouse: (y_mouse + (pan.y * -1)) / zoom
        // };

        const cursorMagnetization = gridMagnet
            ? EventUtils.isCursorGridMagnetized(x_grid!, y_grid!, panX, panY, zoom)
            : undefined;

        let x = PointUtils.mround(
            Math.round((x_grid! + panX * -1) / zoom),
            cursorMagnetization?.isXMagnetized ? MapConstants.snapGridMagnet : MapConstants.gridStep
        );

        let y = PointUtils.mround(
            Math.round((y_grid! + panY * -1) / zoom),
            cursorMagnetization?.isYMagnetized ? MapConstants.snapGridMagnet : MapConstants.gridStep
        );

        if (angleMagnet) {
            const magnet = EventUtils.cursorAngleMagnetized({ ...angleMagnet, realPoint: { x, y } });
            x = magnet.x;
            y = magnet.y;
        }

        return {
            x,
            y,
            xMouse: Math.round((x_mouse + panX * -1) / zoom),
            yMouse: Math.round((y_mouse + panY * -1) / zoom),
            pageX: eX,
            pageY: eY,
        };
    }

    public static calculSnapFromSyntheticEvent(
        event: SyntheticEvent,
        state: string,
        sgvValue: Value,
        svgViewer: SVGSVGElement,
        customSnapValue?: number
    ): SnapGesture {
        let eX;
        let eY;

        const mouseEvent = event as React.MouseEvent<SVGElement>;
        const touchEvent = event as React.TouchEvent<SVGElement>;

        //! documentation, log to get values
        const value = sgvValue;
        const panX = value.e;
        const panY = value.f;
        const zoom = value.a;

        //if (typeof event !== undefined && typeof touchEvent.touches !== 'undefined' && touchEvent.touches) {
        if (touchEvent.touches) {
            const touches = touchEvent.changedTouches;
            eX = touches[0].pageX;
            eY = touches[0].pageY;
            MapConstants.factor = 1;
        } else {
            eX = mouseEvent.pageX;
            eY = mouseEvent.pageY;
        }
        const offset = svgViewer.getBoundingClientRect();
        const x_mouse = eX * MapConstants.factor - offset.left * MapConstants.factor + MapConstants.originX_viewbox;
        const y_mouse = eY * MapConstants.factor - offset.top * MapConstants.factor + MapConstants.originY_viewbox;

        let x_grid: number;
        let y_grid: number;
        if (state === MapMode.GRID_SNAP_ON) {
            x_grid = Math.round(x_mouse / MapConstants.gridStep) * MapConstants.gridStep;
            y_grid = Math.round(y_mouse / MapConstants.gridStep) * MapConstants.gridStep;
        }
        if (state === MapMode.GRID_SNAP_OFF) {
            x_grid = x_mouse;
            y_grid = y_mouse;
        }

        // const panZoom = (window as any).panZoom;
        //const pan = panZoom?.getPan();
        //const zoom = panZoom?.getZoom();

        //@ts-ignore
        //console.log('calculSnap', { event, mouseEvent, touchEvent, eX, eY, x_grid, y_grid, panX, panY, });

        // return {
        //     x: (x_grid + (pan.x * -1)) / zoom,
        //     y: (y_grid + (pan.y * -1)) / zoom,
        //     xMouse: (x_mouse + (pan.x * -1)) / zoom,
        //     yMouse: (y_mouse + (pan.y * -1)) / zoom
        // };

        return {
            x: PointUtils.mround(Math.round((x_grid! + panX * -1) / zoom), customSnapValue ?? MapConstants.gridStep),
            y: PointUtils.mround(Math.round((y_grid! + panY * -1) / zoom), customSnapValue ?? MapConstants.gridStep),
            xMouse: Math.round((x_mouse + panX * -1) / zoom),
            yMouse: Math.round((y_mouse + panY * -1) / zoom),
            pageX: eX,
            pageY: eY,
        };
    }

    private static isCursorGridMagnetized(x: number, y: number, panX: number, panY: number, zoom: number) {
        const realX = Math.round((x! + panX * -1) / zoom)!;
        const isXMagnetized =
            realX % MapConstants.snapGridMagnet <= MapConstants.snapGridMagnetSensitivity / 2 ||
            realX % MapConstants.snapGridMagnet >=
                MapConstants.snapGridMagnet - MapConstants.snapGridMagnetSensitivity / 2
                ? true
                : false;

        const realY = Math.round((y! + panY * -1) / zoom)!;
        const isYMagnetized =
            realY % MapConstants.snapGridMagnet <= MapConstants.snapGridMagnetSensitivity / 2 ||
            realY % MapConstants.snapGridMagnet >=
                MapConstants.snapGridMagnet - MapConstants.snapGridMagnetSensitivity / 2
                ? true
                : false;

        return { isXMagnetized, isYMagnetized };
    }

    private static cursorAngleMagnetized(angleMagnet: {
        realPoint: CoordPoint;
        parentPoint: CoordPoint;
        childPoint: CoordPoint;
    }): CoordPoint {
        const { realPoint, parentPoint, childPoint } = angleMagnet;
        const sensi = MapConstants.snapGridMagnetSensitivity / 2;
        if (realPoint.x > parentPoint.x - sensi && realPoint.x < parentPoint.x + sensi) {
            realPoint.x = parentPoint.x;
        }
        if (realPoint.y > parentPoint.y - sensi && realPoint.y < parentPoint.y + sensi) {
            realPoint.y = parentPoint.y;
        }
        if (realPoint.x > childPoint.x - sensi && realPoint.x < childPoint.x + sensi) {
            realPoint.x = childPoint.x;
        }
        if (realPoint.y > childPoint.y - sensi && realPoint.y < childPoint.y + sensi) {
            realPoint.y = childPoint.y;
        }

        return realPoint;
    }
}
