import * as _ from 'lodash';

import { YesOrNo } from '../../Models/IMapItem';
import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { CoordPoint, Wall } from '../../Utils/Types';
import { FlooringDirection, LaizeCalculatorResult, LaizeDirection, LaizeProps, ProductType } from './Laize';
import { CalculateOptimalBandsLayoutParams, LaizeV3 } from './v3/LaizeV3';

export type ILaizeRoom = {
    roomId?: string;
    rotationAngle?: number;
    flooringDirection?: FlooringDirection;
    coords?: Array<CoordPoint>;
    walls?: Array<ILaizeWall>;
};

export type ILaizeOpening = {
    openingId?: string;
    angle?: number;
    limit?: Array<CoordPoint>;
    x?: number;
    y?: number;
    roomId?: string;
    size?: number;
};

export type ILaizeRoomItem = {
    roomId?: string;
    coordsReal?: Array<CoordPoint>;
    putFlooring?: YesOrNo;
};

export type ILaizeWall = {
    roomId?: string;
    start: CoordPoint;
    end: CoordPoint;
};

export type CalculLaizeParams = {
    laizeCalcProps: LaizeCalcProps;

    rooms: Array<IRoom>;
    openings: Array<IOpening>;
    roomItems: Array<IRoomItem>;
    walls: Array<Wall>;

    forcedGlobalDirection?: LaizeDirection;
    logName?: string;
    requestedBy?: string;

    iteration?: number;

    flooringDirectionByRoomId?: { [roomId: string]: FlooringDirection | undefined };
};

export type LaizeCalcProps = {
    LAIZE_PROPS: LaizeProps;

    TYPE?: ProductType;
    LAIZE_MARGIN: number;
    LAIZE_CONSECUTIVE_BANDS_MARGIN: number;

    RACCORD_SIZE: number;
    MARGIN_LENGTH: number;
    MARGIN_WIDTH: number;
    BAND_WIDTH: number;
    LONGUEUR_CM: number;
};

export class LaizeCalc {
    public static calculLaize(params: CalculLaizeParams): LaizeCalculatorResult | undefined {
        return LaizeV3.calculLaizeV3({ ...params });
    }

    public static checkIfLaizeNotComputable(laizeProps: LaizeProps) {
        const laizeRelevantProps = {
            largeurCm: laizeProps?.largeurCm,
            longueurCm: laizeProps?.longueurCm,
            raccordCm: laizeProps?.raccordCm,
            largeurMarginCm: laizeProps?.largeurMarginCm,
            longueurMarginCm: laizeProps?.longueurMarginCm,
        };

        return _.isEqual(laizeRelevantProps, {
            largeurCm: 0,
            longueurCm: 0,
            raccordCm: 0,
            largeurMarginCm: 0,
            longueurMarginCm: 0,
        });
    }

    public static toLaizeCalcProps = (laizeProps: LaizeProps): LaizeCalcProps => {
        return {
            LAIZE_PROPS: laizeProps,

            //* use in v2
            TYPE: laizeProps?.type,

            LAIZE_CONSECUTIVE_BANDS_MARGIN: laizeProps?.type === ProductType.Rouleau ? 10 : 0,
            LAIZE_MARGIN: laizeProps?.type === ProductType.Rouleau ? 10 : 0,

            RACCORD_SIZE: (laizeProps?.type === ProductType.Rouleau ? laizeProps.raccordCm : 0) || 0,
            MARGIN_LENGTH: (laizeProps?.type === ProductType.Rouleau ? laizeProps.longueurMarginCm : 0) || 0,
            MARGIN_WIDTH: (laizeProps?.type === ProductType.Rouleau ? laizeProps.largeurMarginCm : 0) || 0,
            BAND_WIDTH: laizeProps.largeurCm || 400,
            LONGUEUR_CM: laizeProps.longueurCm || 50_000,
        };
    };

    public static toCalculateOptimalBandsLayoutParams = (
        laizeParams: CalculLaizeParams
    ): CalculateOptimalBandsLayoutParams => {
        const { laizeCalcProps, openings, roomItems, rooms, walls } = laizeParams;
        const laizeOpenings = openings.map(
            (x) =>
                ({
                    roomId: x.roomId!,
                    angle: x.angle,
                    limit: x.limit,
                    openingId: x.openingId,
                    size: x.size,
                    x: x.x,
                    y: x.y,
                } as ILaizeOpening)
        );
        const laizeRoomItems = roomItems.map(
            (x) =>
                ({
                    roomId: x.roomId!,
                    coordsReal: x.coordsReal,
                    putFlooring: x.putFlooring,
                } as ILaizeRoomItem)
        );
        const convertWalls = (walls?: Array<Wall>) =>
            walls?.map(
                (x) =>
                    ({
                        roomId: x.roomId!,
                        start: x.start,
                        end: x.end,
                    } as ILaizeWall)
            ) ?? [];
        const laizeWalls = convertWalls(walls);
        const laizeRooms = rooms.map(
            (x) =>
                ({
                    coords: x.coords,
                    flooringDirection: x.flooringDirection,
                    roomId: x.roomId,
                    rotationAngle: x.rotationAngle,
                    walls: convertWalls(x.walls),
                } as ILaizeRoom)
        );
        return {
            laizeCalcProps,
            openings: laizeOpenings,
            roomItems: laizeRoomItems,
            rooms: laizeRooms,
            walls: laizeWalls,
        };
    };

    public static getFlooringDirectionByRoomId = (rooms: Array<IRoom>) => {
        return rooms.reduce((acc, room) => {
            if (room.flooringDirection) acc[room.roomId!] = room.flooringDirection;
            return acc;
        }, {} as { [roomId: string]: FlooringDirection | undefined });
    };
}
