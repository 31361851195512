import { Constants } from '../Constants';

export class ImageProduitHelper {
    //"https://media.cdn.saint-maclou.com/width-100/96550_3927_Small.jpg"
    //"https://media-pp.cdn.saint-maclou.com/412x180/96550_3927_Small.jpg"

    public static toImageUrl(produitCode: string, width: number): string {
        const produitCodeTransformed: string = ImageProduitHelper.transformProduitCode(produitCode);
        //const oldUrl = `https://media.cdn.saint-maclou.com/width-${width}/${produitCodeTransformed}_Small.jpg`;
        const frzImageUrl = `https://media.cdn.saint-maclou.com/${produitCodeTransformed}_Small.jpg?frz-w=${width}&frz-resizeimgs=true`;

        return Constants.USE_STMAC_CDN_IMAGE_NEW_URL
            ? `${Constants.STMAC_CDN_IMAGE_URL}/cdn-cgi/image/width=${width}/${produitCodeTransformed}_P1.jpg`
            : frzImageUrl;
    }

    public static toImageUrlP1(produitCode: string, size: number): string {
        const produitCodeTransformed: string = ImageProduitHelper.transformProduitCode(produitCode);
        return `https://media.cdn.saint-maclou.com/cdn-cgi/image/format=auto,width=${size},height=${size}/${produitCodeTransformed}_P1.jpg`;
    }

    //* on enleve les zéros (0) au début : 003701 -> 3701
    private static removeZeroFromStart(number: string): string {
        const val = number.replace(/^0+/, '');
        return val === '' ? '0' : val;
    }

    //*  003701-00049 ->  3701-49 : remove 0 before first number other than 0
    public static transformProduitCode(produitCode: string): string {
        const produitCodes: Array<string> = produitCode.split('-');
        if (produitCodes.length === 2) {
            const [produitCode1, produitCode2] = produitCodes;
            return `${ImageProduitHelper.removeZeroFromStart(produitCode1)}_${ImageProduitHelper.removeZeroFromStart(
                produitCode2
            )}`;
        }
        return produitCode;
    }
}
