import { QuestionItem } from '../../../Models/Questions/QuestionDto';
import { FlooringDirection } from '../Components/Laizes/Laize';
import { RoomCollision } from '../Utils/SAT/SATUtils';
import { CoordPoint, Wall } from '../Utils/Types';
import { IContournements } from './Contournements';
import { IMapItem, YesOrNo } from './IMapItem';
import { IOpening } from './IOpening';
import { IRoomItem } from './IRoomItem';

export const LocalisationName = 'localisation_piece';
export enum RoomLocalisation {
    Inside = 'Intérieur',
    Outside = 'Extérieur',
}

export const TypeDeLocalName = 'Type de local';
export enum UnitType {
    Maison = 'Maison',
    Appartement = 'Appartement',
    LocalPro = 'Local pro',
    CoPro = 'Co-propriété',
}

export enum RoomShape {
    Rectangle = 'rectangle',
    Open_bottom_rectangle = 'open_bottom_rectangle',
    Diamond_topleft = 'diamond_topleft',
    Diamond_topright = 'diamond_topright',
    Diamond_bottomleft = 'diamond_bottomleft',
    Diamond_bottomright = 'diamond_bottomright',
    Flyingwing_topleft = 'flyingwing_topleft',
    Flyingwing_topright = 'flyingwing_topright',
    Flyingwing_bottomleft = 'flyingwing_bottomleft',
    Flyingwing_bottomright = 'flyingwing_bottomright',

    Custom = 'custom',
}

export type RoomDimensions = {
    //* Rectangle shape
    width?: number;
    height?: number;

    //* Diamond shape
    table?: number;
    right_crown?: number;
    left_crown?: number;

    //* FlyingWing shape
    left_wingtip?: number;
    left_trailing_edge?: number;
    right_wingtip?: number;
    right_trailing_edge?: number;
};

export interface IRoom extends IMapItem {
    //* ROOM INFOS : injected in context
    shape?: RoomShape;
    roomType?: string; // rename
    localisation?: RoomLocalisation;
    roomFloor?: string;
    roomExistingFlooring?: YesOrNo | string; //presenceRevetementAuSol
    roomExistingFloorType?: string;
    roomHeating?: YesOrNo | string;
    roomHeatingType?: string;

    flooringDirection?: FlooringDirection; //* Direction de pose du revêtement (LaizeComputer V3)

    contournements?: IContournements;

    //* injected by RoomPropEnhancer -> enhance
    raccord_length?: number;
    room_length?: number;
    surface_area?: number;
    surface_area_without_room_item?: number;
    surface_manipulee_prorata?: number;
    perimeter?: number;
    perimeter_plinthes?: number;
    should_lay_plinthes_inside_room_element?: boolean;

    //* PRODUIT PRINCIPAL
    questionProduitPrincipal?: QuestionItem;
    //produitPrincipal?: ProduitDto;

    center?: CoordPoint;
    dimensions?: RoomDimensions;

    openings?: Array<IOpening>;
    roomItems?: Array<IRoomItem>;

    //* set on creation and update when walls changes
    sides?: Map<string, Array<CoordPoint>>;
    sidesObject?: Record<string, Array<CoordPoint>>; //!used for debug sides which is not display in ReactJson

    __comment_enum__?: string;

    //*COORDS
    coords?: Array<CoordPoint>;
    walls?: Array<Wall>;
    areaWithoutRoomItems?: any;
    coordsWithoutRoomItems?: any;
    way?: Array<any>;
    area?: number;
    inside?: any;
    coordsOutside?: any;
    coordsInside?: any;
    collisions?: Array<RoomCollision>;
    customPoints?: Array<CoordPoint>;
    rotationAngle?: number;

    //isMoveableOutside?: boolean;
    //coordsReal?: Array<CoordPoint>;
    //color?: string;
    //surface?: string;
    //showSurface?: boolean;
}
