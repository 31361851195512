import React from 'react';

import { useMapContextState } from '../../../Context/MapContext';
import { IOpening } from '../../../Models/IOpening';
import { PlacingOpening } from './BoxBindOpening';

type CarpentryItemsProps = { readonly?: boolean; roomId?: string; openings?: Array<IOpening> };
export const MapBoxCarpentryItems = ({ readonly, roomId }: CarpentryItemsProps) => {
    const { openings = [] } = useMapContextState();
    return <RawCarpentryItems openings={openings} roomId={roomId} readonly={readonly} />;
};

export const RawCarpentryItems = ({ openings = [], readonly, roomId }: CarpentryItemsProps) => {
    return (
        <React.Fragment>
            {openings
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .map((opening) => (
                    <CarpentryOpeningItem key={opening.openingId} item={opening} readonly={readonly} />
                ))}
        </React.Fragment>
    );
};

type CarpentryOpeningItemProps = { item: IOpening; readonly?: boolean };
const CarpentryOpeningItem = ({ item, readonly }: CarpentryOpeningItemProps) => {
    return (
        <React.Fragment>
            <PlacingOpening key={item.openingId} item={item} readonly={readonly} />
        </React.Fragment>
    );
};
