import {
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInputProps,
    StandardTextFieldProps,
    TextField,
} from '@material-ui/core';
import { CreateCSSProperties, styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

export type SelectableOption = { value?: string; label: React.ReactNode };

interface SelectFieldProps extends StandardTextFieldProps {
    values: Array<SelectableOption>;
    onChangeValue?: (value: string) => void;
    styles?: { root?: CSSProperties };
    withLog?: boolean;
}

export const Selector = ({ value = '', values = [], onChangeValue, styles, withLog, ...props }: SelectFieldProps) => {
    const { openSelector: open, selectedOption, update, setOpenSelector } = useSelectorState();

    const inputEl = React.useRef<HTMLInputElement>();
    const hasItems = Boolean(values.length);

    const getOptionValue = (option?: SelectableOption) => option?.value || option?.label || undefined;
    const getValueToUse = () => {
        if ([selectedOption?.value, selectedOption?.label].includes(value)) {
            return getOptionValue(selectedOption);
        }
        return value;
    };

    const valueToUse = getValueToUse();
    const renderLabel = values.find((x) => getOptionValue(x) === valueToUse)?.label || valueToUse;

    React.useEffect(() => {
        const isValueInOptions = values.map(getOptionValue).includes(value as string);

        const selectedOption = !isValueInOptions
            ? undefined
            : values.find((x) => getOptionValue(x) === getValueToUse());

        update({ openSelector: false, selectedOption });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const togglePopupMenu = () => setOpenSelector(!open);

    const handleTextFieldClick = () => {
        if (values.length) {
            setOpenSelector(!open);
        }
    };

    const handleSelectorItemClick = (selectedOption: SelectableOption) => {
        const value = getOptionValue(selectedOption) as string;
        update({ selectedOption, openSelector: !open });
        onChangeValue?.(value);
    };

    const handleInputChange = (value: string) => onChangeValue?.(value);
    const onFocus = () => setOpenSelector(!hasItems && open ? false : Boolean(open));

    return (
        <>
            <EditText
                inputRef={inputEl}
                onFocus={onFocus}
                InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
                InputProps={
                    {
                        disableUnderline: true,
                        endAdornment: <EndAdornment up={open} onClick={togglePopupMenu} />,
                        readOnly: true,
                    } as Partial<OutlinedInputProps>
                }
                {...props}
                style={useEditTextStyle({
                    hasItems,
                    hasValue: Boolean(value),
                    hasSelectedOption: Boolean(selectedOption),
                    ...styles?.root,
                })}
                value={renderLabel || ''}
                onChange={(e) => handleInputChange(e.target.value)}
                //* click and touch
                onClick={props.disabled ? undefined : handleTextFieldClick}
                inputProps={{ maxLength: 50, style: useInputStyle({ hasItems, disabled: props.disabled }) }}
            />
            <Menu
                anchorEl={inputEl.current}
                open={Boolean(values.length && open)}
                onClose={togglePopupMenu}
                PaperProps={{ style: { ...MenuPaperStyle, width: inputEl.current?.parentElement?.offsetWidth } }}
                MenuListProps={{ style: MenuListStyle }}>
                {values.map((option, index) => {
                    const optionValueToUse = option.value || (option.label as string);
                    return (
                        <MenuLabelContainer key={optionValueToUse}>
                            <BaseMenuItem onClick={(e) => handleSelectorItemClick(option)}>{option.label}</BaseMenuItem>
                            {index < values.length && <MenuLabelDivider />}
                        </MenuLabelContainer>
                    );
                })}
            </Menu>
        </>
    );
};

export const SelectField = React.memo(Selector);

const useSelectorState = () => {
    type SelectorState = { openSelector?: boolean; selectedOption?: SelectableOption };
    const [state, setState] = React.useState<SelectorState>({});

    const update = (fields: Partial<SelectorState>) => setState((prev) => ({ ...prev, ...fields }));
    const setOpenSelector = (openSelector: boolean) => update({ openSelector });
    return { ...state, update, setOpenSelector };
};

//#region //* STYLED COMPONENTS

const EditText = styled(TextField)({ border: '1px solid #C8C8C8', borderRadius: 8, cursor: 'pointer' });

type WithStyle = { disabled?: boolean; hasItems?: boolean; hasSelectedOption?: boolean; hasValue?: boolean };
const useEditTextStyle = ({ hasItems, hasSelectedOption, hasValue, ...rest }: WithStyle): CSSProperties => {
    let style: CreateCSSProperties = {};
    if (hasSelectedOption || hasValue) {
        style = { ...style, border: '3px solid #13123A' };
    }
    return { ...style, ...rest } as CSSProperties;
};
const useInputStyle = ({ hasItems, disabled, ...rest }: WithStyle): CSSProperties => {
    return {
        padding: '0px 16px',
        height: 42,
        color: '#000000',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...rest,
    };
};

const MenuPaperStyle: CSSProperties = { borderRadius: 8, background: '#FFFFFF' };
const MenuListStyle: CSSProperties = { padding: 0 };

const BaseMenuItem = styled(MenuItem)({
    width: '100%',
    color: '#000000',
    height: 42,
    fontWeight: 400,
    fontSize: 14,
    borderRadius: 8,
    '&:hover': { background: '#13123A', color: '#FFFFFF', fontWeight: 700 },
});

const MenuLabelContainer = styled('div')({ display: 'flex', flexDirection: 'column' });
const MenuLabelDivider = styled('div')({ borderBottom: '1px solid #E0E0E0', margin: '0px 8px' });

type EndAdornmentProps = React.DOMAttributes<any> & { up?: boolean };
const EndAdornment = (props: EndAdornmentProps) => {
    return (
        <InputAdornment position="end">
            <IconArrowDown {...props} />
        </InputAdornment>
    );
};
const IconArrowDown = ({ up }: EndAdornmentProps) => {
    const style: CSSProperties = {
        width: 11,
        cursor: 'pointer',
        transform: `rotate(${up ? 180 : 0}deg)`,
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0.3s',
        marginRight: 16,
    };
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="#13123A" style={style}>
            <path d="M13.0461 0.426364C13.1446 0.526233 13.2033 0.658484 13.2114 0.798462C13.2195 0.93844 13.1765 1.07659 13.0902 1.18716L13.0461 1.23734L6.61966 7.72465L0.192663 1.23684C0.0859912 1.12883 0.0261739 0.983147 0.0261737 0.831347C0.0261735 0.679546 0.0859912 0.53386 0.192664 0.425857C0.290601 0.326461 0.421902 0.266888 0.561199 0.258651C0.700495 0.250413 0.837902 0.294094 0.946873 0.381254L0.994011 0.428392L6.61712 6.10523L12.2423 0.426364C12.3403 0.327393 12.4714 0.268183 12.6105 0.260135C12.7496 0.252087 12.8867 0.29577 12.9955 0.382774L13.0461 0.426364Z" />
        </svg>
    );
};
//#endregion
