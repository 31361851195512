import React from 'react';
import { ReactSVGPanZoom, Value } from 'react-svg-pan-zoom';

type MapViewerContextValue = {
    value: Value;
    onChangeValue: (value: Value) => void;

    svgViewer?: ReactSVGPanZoom;
    setViewer: (viewer: ReactSVGPanZoom) => void;
};
const defautValue: Value = {
    version: 2,
    mode: 'idle',
    focus: false,
    a: 0,
    b: 0,
    c: 0,
    d: 0,
    e: 0,
    f: 0,
    viewerWidth: 0,
    viewerHeight: 0,
    SVGWidth: 0,
    SVGHeight: 0,
    miniatureOpen: false,
};
export const MapViewerContext = React.createContext<MapViewerContextValue>({
    value: defautValue,
    onChangeValue: () => {},
    setViewer: () => {},
});
export const useMapViewerContext = () => React.useContext(MapViewerContext);
export const MapViewerProvider: React.FC = ({ children }) => {
    const [value, onChangeValue] = React.useState<Value>(defautValue);
    const [svgViewer, setViewer] = React.useState<ReactSVGPanZoom>();

    return (
        <MapViewerContext.Provider value={{ value, onChangeValue, svgViewer, setViewer }}>
            {children}
        </MapViewerContext.Provider>
    );
};
