import { useSelector } from 'react-redux';
import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { AppState } from '../../../../Configs/PolStore';
import { Logger } from '../../../../Errors/Logger';
import { createCustomErrorHandler, wrapError } from '../../../../Errors/PolErrorHandler';
import { ConfigurationUtils, ToConfigurationReponseParameters } from '../../../../Helpers/ConfigurationUtils';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { PrixContextDebugMap, QuotationHelperV3 } from '../../../../Helpers/QuotationHelperV3';
import { RoomHeatingTypeErrorHelper } from '../../../../Helpers/RoomHeatingTypeErrorHelper';
import { StoreHelper } from '../../../../Helpers/StoreHelper';
import { SetConfigurationDto, SetConfigurationReponseDto } from '../../../../Models/Projects/Configuration';
import { QuestionItem } from '../../../../Models/Questions/QuestionDto';
import { ComputeQuotationsResponse, QuotationReplyBaseDto } from '../../../../Models/Quotations/Quotations';
import { StoreDto } from '../../../../Models/TravelExpenses/StoreDto';
import { TauxTvaDto, VersionParametrageDto } from '../../../../Models/VersionParametrages/VersionParametrage';
import { MapContextState, useMapProvider } from '../../../../Plugins/FloorPlan/Context/MapContext';
import { MapExportContextValue, useMapExport } from '../../../../Plugins/FloorPlan/Context/MapExportContext';
import { MapExportHelper } from '../../../../Plugins/FloorPlan/Helpers/MapExport/MapExportHelper';
import { IMapItem } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { GTM, GtmSaveType } from '../../../../Plugins/GTM/GTM';
import { QuotationsApi } from '../../../../Services/Api/QuotationsApi';
import { AuthHelper } from '../../../../Services/Authentication/AuthHelper';
import { MeState } from '../../../../Services/Me/MeReducer';
import { useQuoteContext } from '../QuotePageController';
import { useHabitationContextController } from './HabitationContextController';
import { useQuoteSaveController } from './QuoteSaveController';

type OnRefreshQuotationParams = { forceSave?: boolean };
type RefreshQuotationPricesParams = { nextAction?: VoidFunction; saveConfig?: () => Promise<boolean> };

export const useQuoteProvider = () => {
    const map = useMapProvider();
    const quoteContext = useQuoteContext();
    const habitation = useHabitationContextController(quoteContext.getState().habitationQuestions);
    const exportImageMap = useMapExport();
    const currentProject = useCurrentProject();
    const me: MeState = useSelector((state: AppState) => state.Me);

    const getTauxTva = (): TauxTvaDto => quoteContext.getState().versionData?.taux_tva!;

    const getGeneralContext = () => {
        const { habitationQuestions = [] } = quoteContext.getState();
        return QuestionsHelper.getLastShownQuestion(habitationQuestions).lastShownQuestion?.context || {};
    };

    const getConfiguration = async (): Promise<SetConfigurationDto> => {
        const { versionId, habitationQuestions = [] } = quoteContext.getState();
        return QuoteLoadUtils.getConfiguration({
            exportImageMap,
            habitationQuestions,
            mapState: map.getState(),
            versionId: versionId!,
            projetStore: StoreHelper.getProjectStore(quoteContext.getState()),
        });
    };

    const getGetQuotationDto = async () => {
        const { versionData, habitationQuestions = [], versionId } = quoteContext.getState();
        return QuoteLoadUtils.getGetQuotationDto({
            exportImageMap,
            habitationQuestions,
            mapState: map.getState(),
            versionData,
            versionId: versionId!,
            projetStore: StoreHelper.getProjectStore(quoteContext.getState()),
        });
    };

    const handleQuotationError = (error: any): Promise<boolean> => {
        quoteContext.updateQuote({ loadingQuotation: false });
        if (RoomHeatingTypeErrorHelper.isErrorFromRoomHeatingTypeHelper(error)) {
            return RoomHeatingTypeErrorHelper.handleError(error);
        } else {
            //this.handleDeadEndError();
            return createCustomErrorHandler(AuthHelper.isAuthenticatedAsStoreOrBOUser())(error);
        }
    };

    const refreshQuotationPrices = ({
        nextAction,
        saveConfig,
    }: RefreshQuotationPricesParams): Promise<QuotationReplyBaseDto> => {
        if (quoteContext.getState().loadingQuotation) return Promise.resolve({} as QuotationReplyBaseDto);
        return wrapError<QuotationReplyBaseDto>(
            async () => {
                const getQuote = async (): Promise<{
                    quote: QuotationReplyBaseDto;
                    quotePrixContextDebugMap: PrixContextDebugMap;
                }> => {
                    const currentProjectState = currentProject.getState();
                    const { bodyRequest, quotePrixContextDebugMap } = await getGetQuotationDto();
                    if (currentProjectState.project) {
                        const response = await QuotationsApi.createQuotation(
                            currentProjectState.project.id,
                            bodyRequest
                        );
                        return { quote: response.data, quotePrixContextDebugMap };
                    } else {
                        const response: ComputeQuotationsResponse = await QuotationsApi.computeQuotation(bodyRequest);
                        return { quote: response.data, quotePrixContextDebugMap };
                    }
                };

                const loadQuote = async () => {
                    quoteContext.updateQuote({ loadingQuotation: true });
                    const { quote, quotePrixContextDebugMap } = await getQuote();
                    GTM.pushEstimationEvent({
                        projetId: currentProject.getState().project?.id,
                        totalPrice: quote.detail_total_price.total_price_ttc,
                        products: GTM.getGTMProducts(map.getState().rooms ?? []),
                        user_mail: me?.data?.customer_properties?.email ?? '',
                    });

                    currentProject.update({ isDirty: false });
                    quoteContext.updateQuote({ loadingQuotation: false, quote, quotePrixContextDebugMap });
                    nextAction?.();
                    return quote;
                };

                if (saveConfig) {
                    await saveConfig();
                }
                return loadQuote();
            },
            { errorHandler: handleQuotationError }
        );
    };

    return {
        loadingQuotation: quoteContext.getState().loadingQuotation,
        getTauxTva,
        //getProduitPrincipal,
        habitation,
        getGeneralContext,
        getConfiguration,
        refreshQuotationPrices,
        getState: quoteContext.getState,
        updateQuote: quoteContext.updateQuote,
        update: quoteContext.update,
        map,
    };
};

type GetSetConfigurationDtoParams = {
    versionId: string;
    habitationQuestions: Array<QuestionItem>;
    projetStore?: StoreDto;
    mapState: MapContextState;
    exportImageMap: MapExportContextValue;
};

export class QuoteLoadUtils {
    public static getConfiguration = async ({
        versionId,
        projetStore,
        habitationQuestions = [],
        mapState,
        exportImageMap,
    }: GetSetConfigurationDtoParams): Promise<SetConfigurationDto> => {
        const toConfigurationResponses = <T extends IMapItem>(
            items: Array<T> = [],
            getParams: (item: T) => Partial<ToConfigurationReponseParameters>
        ): Array<SetConfigurationReponseDto> => {
            let responses: Array<SetConfigurationReponseDto> = [];
            items.forEach((item) => {
                const {
                    questionsTva,
                    questionsPose,
                    questionsSupport,
                    questionsFinition,
                    questionsPreparation,
                    questionsServices,
                } = item;
                const params = getParams(item);

                //* ROOM
                const room = item as IRoom;
                if (room.questionProduitPrincipal) {
                    responses.push(
                        ...ConfigurationUtils.toConfigurationReponses([room.questionProduitPrincipal], params)
                    );
                }

                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsTva?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsPose?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsFinition?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsSupport?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsPreparation?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsServices?.questions, params));
            });
            return responses;
        };
        const responseList: Array<SetConfigurationReponseDto> = [
            ...ConfigurationUtils.toConfigurationReponses(habitationQuestions),
            ...toConfigurationResponses<IRoom>(mapState.rooms, (item) => ({ room_id: item.roomId })),
            ...toConfigurationResponses<IOpening>(mapState.openings, (item) => ({
                opening_id: item.openingId,
                room_id: item.roomId,
            })),
            ...toConfigurationResponses<IRoomItem>(mapState.roomItems, (item) => ({
                room_element_id: item.roomItemId,
                room_id: item.roomId,
            })),
        ];

        const planJson = MapExportHelper.toApiJson(mapState);

        Logger.isLogEnabled() && console.groupCollapsed('configuration');
        Logger.log('planJson', JSON.parse(planJson));
        Logger.log('responseList', responseList);
        const bodyRequest: SetConfigurationDto = {
            version_parametrage_id: versionId!,
            floor_plan_json: planJson,
            reponse_list: responseList,
            total_surface: mapState.global_surface_area!,
            opening_count: mapState.openings?.length ?? 0,
            room_count: mapState.rooms?.length ?? 0,
            room_element_count: mapState.roomItems?.length ?? 0,
            store_id: projetStore?.store_id!,
        };
        Logger.log('bodyRequest', bodyRequest);
        Logger.isLogEnabled() && console.groupEnd();
        return bodyRequest;
    };

    public static getGetQuotationDto = async ({
        versionData,
        habitationQuestions = [],
        mapState,
        exportImageMap,
        versionId,
        projetStore,
    }: {
        versionData?: VersionParametrageDto;
        habitationQuestions: Array<QuestionItem>;
        mapState: MapContextState;
        exportImageMap: MapExportContextValue;
        versionId: string;
        projetStore?: StoreDto;
    }) => {
        const bodyRequest = await QuotationHelperV3.getGetQuotationDto({
            habitationQuestions,
            lastGeneralQuestion: habitationQuestions[habitationQuestions.length - 1],
            prixList: versionData?.prix_list,
            map: mapState,
            configuration: await QuoteLoadUtils.getConfiguration({
                exportImageMap,
                habitationQuestions,
                mapState,
                versionId,
                projetStore,
            }),
            exportImageMap,
        });
        return bodyRequest;
    };
}

export const useRefreshQuote = ({ nextAction }: { nextAction?: VoidFunction }) => {
    const currentProject = useCurrentProject();
    const { refreshQuotationPrices: refresh } = useQuoteProvider();
    const { requestCreateProject, updateProject } = useQuoteSaveController();

    const saveConfig = async (): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            const currentProjectState = currentProject.getState();
            if (currentProjectState.project) {
                const { id, intitule } = currentProjectState.project;
                await updateProject({
                    projectId: id,
                    projectName: intitule,
                    withPostMessage: true,
                    saveType: GtmSaveType.DevisEstimatif,
                });
                resolve(true);
            } else {
                resolve(false);
            }
        });
    };

    const onRefreshQuotationPrices = ({ forceSave }: OnRefreshQuotationParams) => {
        const currentProjectState = currentProject.getState();
        if (currentProjectState?.project && !currentProjectState.isUpdateAllowed) {
            requestCreateProject({
                callback: () => refresh({ nextAction }),
            });
        } else {
            refresh({ nextAction, saveConfig: forceSave ? saveConfig : undefined });
        }
    };

    return { onRefreshQuotationPrices };
};
