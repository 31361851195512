/**
 * Take all rooms in parameters, output base64 svg
 * Output example : https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/EVPE9m3CMgxHqO9-mDJqRaUBWkUxy3DllRJIa6kG-vmivw?e=gAiDjQ
 * @constructor
 */
import React from 'react';

import { useMapContextState } from '../../../Context/MapContext';
import { ExportRoomLaizeBandsSvgItem, SvgByRoom } from './LaizeExportBands';
import { LaizeExportRoomBandsSvgV3 } from './LaizeExportRoomBandsSvgV3';

export const useExportMapLaizeBandsSvgV3 = () => {
    const { rooms = [], roomItems = [], laizeResults = [], busy } = useMapContextState();
    const svgHolders = React.useRef<Array<SvgByRoom>>(rooms.map((room) => ({ roomId: room.roomId! })));
    const exportItems = React.useRef<Array<ExportRoomLaizeBandsSvgItem>>([]);

    React.useEffect(() => {
        const currentHolders = svgHolders.current || [];
        svgHolders.current = rooms.map((room) => {
            const currentRoomHolder = currentHolders.find((x) => x.roomId === room.roomId);
            return { ...currentRoomHolder, roomId: room.roomId! };
        });
    }, [rooms]);

    if (busy || !Boolean(laizeResults.length) || !svgHolders.current.length) {
        exportItems.current = [];
    } else {
        exportItems.current = rooms
            .filter((room) => laizeResults.some((x) => x.roomIds?.some((y) => y === room.roomId)))
            .map((room) => {
                const result = LaizeExportRoomBandsSvgV3({
                    room,
                    rooms,
                    roomItems,
                    laizeResult: laizeResults.find((x) => x.roomIds?.some((y) => y === room.roomId))?.calculator,
                    getHolder: () => svgHolders.current.find((r) => r.roomId === room.roomId)!,
                });
                return result;
            });
    }

    return { exportItems: () => exportItems.current };
};
