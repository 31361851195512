// * Magnetized walls (snap to wall) with anchor lines

import { AnchorLine, useGestureContext } from "../Context/GestureContext";
import { IRoom } from "../Models/IRoom";
import { CoordPoint, SnapGesture, Wall } from "./Types";
import { WallUtils } from "./Wall/WallUtils";
import { qSVG } from "./qSVG";

export type WallDirection = { direction: string, x?: number; y?: number };

const MAGNETIZE_SENSIBILITY = 10;

function toVerticalAnchorLine(x: number, id: string) {
    const anchorLine: AnchorLine = { id, start: { x, y: 0 }, end: { x, y: 15000 } };
    return anchorLine;
}

function toHorizontalAnchorLine(y: number, id: string) {
    const anchorLine: AnchorLine = { id, start: { x: 0, y }, end: { x: 10000, y } };
    return anchorLine;
}

export const useMagnetize = () => {
    const gesture = useGestureContext();

    const magnetizedWalls = (snap: SnapGesture, bindedWall: Wall, walls: Array<Wall>): WallDirection | undefined => {
        for (const wall of walls) {
            if (wall !== bindedWall) {
                //* diagonal angles are not supported
                const wallDirection = WallUtils.angleDirection(qSVG.angleRadToDeg(wall.angle));
                const bindedWallDirection = WallUtils.angleDirection(qSVG.angleRadToDeg(bindedWall.angle));

                if (wallDirection === bindedWallDirection) {
                    if (
                        wallDirection === 'v' &&
                        snap.x - MAGNETIZE_SENSIBILITY < wall.start.x &&
                        snap.x + MAGNETIZE_SENSIBILITY > wall.start.x
                    ) {
                        //! vertical : color line markup
                        const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                        gesture.update({ verticalAnchorLine: toVerticalAnchorLine(wall.start.x, anchorLineId) });

                        const offsetSnap = { direction: 'v', x: wall.start.x };
                        return offsetSnap;
                    } else if (
                        wallDirection === 'h' &&
                        snap.y - MAGNETIZE_SENSIBILITY < wall.start.y &&
                        snap.y + MAGNETIZE_SENSIBILITY > wall.start.y
                    ) {
                        //! horizontal : color line markup
                        const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                        gesture.update({ horizontalAnchorLine: toHorizontalAnchorLine(wall.start.y, anchorLineId) });

                        const offsetSnap = { direction: 'h', y: wall.start.y };
                        return offsetSnap;
                    } else {
                        gesture.clearAnchorLines();
                    }
                }
            }
        }
        return undefined;
    }

    // * Magnetized rooms (snap to wall) with anchor lines

    // * Description :
    // * Cette méthode renvoie un nouveau snap, qui doit être utiliser pour overrider le snap courant.
    // * Si la pièce n'est pas déplacée dans l'interval de sensibilité (MAGNETIZE_SENSIBILITY) d'un mur d'une autre pièce, les propriétés (x ou y) de forcedSnap sont undefined.

    // * Réalise un tri des murs de la pièce séléctionnée : murs verticaux et murs horizontaux (bindedRoomHorizontalWalls, bindedRoomHorizontalWalls)
    // * On regarde les murs des autres pièces,
    // * on prend le mur qui est le plus proche verticalement ou horizontalement dans les liste bindedRoomVerticalWalls ou bindedRoomHorizontalWalls
    // * on regarde si le mur qu'on a pris précédement rentre dans l'interval de sensibilité (MAGNETIZE_SENSIBILITY)
    // * si c'est le cas on sauvegarde sa coordonnée verticale ou horizontale et on calcul un nouveau snap : forceSnap

    const magnetizedRooms = (snap: SnapGesture, bindedRoom: IRoom, translation: CoordPoint, initialSnapPosition: CoordPoint, rooms: Array<IRoom>) => {
        const bindedRoomVerticalWalls = bindedRoom.walls!.filter((x) => WallUtils.angleDirection(qSVG.angleRadToDeg(x.angle)) === 'v');
        const bindedRoomHorizontalWalls = bindedRoom.walls!.filter((x) => WallUtils.angleDirection(qSVG.angleRadToDeg(x.angle)) === 'h');

        let forcedSnapVertical;
        let forcedSnapHorizontal;
        let closestWall;
        let translateX, translateY;

        gesture.clearAnchorLines();

        for (const room of rooms) {
            if (room.roomId !== bindedRoom.roomId) {
                for (const wall of room.walls!) {
                    const wallDirection = WallUtils.angleDirection(qSVG.angleRadToDeg(wall.angle));
                    if (wallDirection === 'v') {
                        closestWall = bindedRoomVerticalWalls.reduce((previousWall, currentWall) => {
                            if (
                                Math.abs(previousWall.start.x + translation.x - wall.start.x) <
                                Math.abs(currentWall.start.x + translation.x - wall.start.x)
                            ) {
                                return previousWall;
                            } else {
                                return currentWall;
                            }
                        });
                        translateX = snap.x - initialSnapPosition.x;
                        if (Math.abs(closestWall.start.x + translateX - wall.start.x) < MAGNETIZE_SENSIBILITY) {
                            const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                            gesture.update({ verticalAnchorLine: toVerticalAnchorLine(wall.start.x, anchorLineId) });

                            forcedSnapVertical = wall.start.x - closestWall.start.x + initialSnapPosition.x;
                        }
                    } else if (wallDirection === 'h') {
                        closestWall = bindedRoomHorizontalWalls.reduce((previousWall, currentWall) => {
                            if (
                                Math.abs(previousWall.start.y + translation.y - wall.start.y) <
                                Math.abs(currentWall.start.y + translation.y - wall.start.y)
                            ) {
                                return previousWall;
                            } else {
                                return currentWall;
                            }
                        });
                        translateY = snap.y - initialSnapPosition.y;
                        if (Math.abs(closestWall.start.y + translateY - wall.start.y) < MAGNETIZE_SENSIBILITY) {
                            const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                            gesture.update({ horizontalAnchorLine: toHorizontalAnchorLine(wall.start.y, anchorLineId) });

                            forcedSnapHorizontal = wall.start.y - closestWall.start.y + initialSnapPosition.y;
                        }
                    }
                }
            }
        }

        const forcedSnap = { x: forcedSnapVertical, y: forcedSnapHorizontal };
        return forcedSnap;
    }

    return { magnetizedWalls, magnetizedRooms }
}