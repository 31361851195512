import { IMapItem } from '../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../Plugins/FloorPlan/Models/IRoomItem';
import { OptionDto, QuestionItem, QuestionStep, QuestionType, SyntheseDto } from '../Models/Questions/QuestionDto';
import { ConditionHelper } from './ConditionHelper';
import { QuestionContext, QuestionContextHelper } from './QuestionContextHelper';
import { QuoteQuestionUtils } from './QuoteQuestionUtils';
import { MapContextState } from '../Plugins/FloorPlan/Context/MapContext';

export interface SyntheseFloorPlanItem<T extends IMapItem> {
    item?: T;
    formattedSyntheses: Array<string>;
}

export interface QuestionsSyntheses {
    synthesesGeneral: Array<string>;
    synthesesByRoom: Array<SyntheseFloorPlanItem<IRoom>>;
    synthesesByOpening: Array<SyntheseFloorPlanItem<IOpening>>;
    synthesesByRoomElement: Array<SyntheseFloorPlanItem<IRoomItem>>;
}

export interface GetSynthesesParameters {
    map: MapContextState;
    habitationQuestions: Array<QuestionItem>;
}

export class MatchHelper {
    public static UUIDV4_Regex = new RegExp(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/gi);
    public static SyntheseFloorplanQuestion_Regex = new RegExp(/question_floorplan_(\w+)/g);
}

export class SynthesesHelperV3 {
    public static getSyntheses = ({
        habitationQuestions,
        map,
    }: GetSynthesesParameters) => {
        const { rooms, openings, roomItems } = map;
        const synthesesGeneral: Array<string> = this.toFormattedSyntheses(habitationQuestions);
        const synthesesByRoom = this.toSyntheseFloorPlanItems<IRoom>(rooms);
        const synthesesByOpening = this.toSyntheseFloorPlanItems<IOpening>(openings);
        const synthesesByRoomElement = this.toSyntheseFloorPlanItems<IRoomItem>(roomItems);

        return { synthesesGeneral, synthesesByRoom, synthesesByOpening, synthesesByRoomElement };
    };

    private static toSyntheseFloorPlanItems = <T extends IMapItem>(
        floorPlanItems: Array<T> = []
    ): Array<SyntheseFloorPlanItem<T>> => {
        return floorPlanItems.map((item) => {

            let questions = [
                ...(item.questionsPose?.questions || []),
                ...(item.questionsSupport?.questions || []),
                ...(item.questionsPreparation?.questions || []),
                ...(item.questionsFinition?.questions || []),
                ...(item.questionsServices?.questions || []),
            ];
            const room = item as IRoom;
            if (room.questionProduitPrincipal) {
                questions = [room.questionProduitPrincipal, ...questions]
            }

            const syntheseItems: SyntheseFloorPlanItem<T> = {
                item,
                formattedSyntheses: this.toFormattedSyntheses(questions),
            };
            return syntheseItems;
        });
    };

    private static toFormattedSyntheses = (questions: Array<QuestionItem> = []): Array<string> => {
        let formattedSyntheses: Array<string> = [];
        questions
            .filter((question) => question.related_step === QuestionStep.StepHabitation || question?.showQuestion)
            .forEach((question: QuestionItem): void => {
                question.synthese_list?.forEach((synthese: SyntheseDto): void => {
                    if (
                        ConditionHelper.isSyntheseConditionItemVisible(synthese.visibility_condition, question.context)
                    ) {
                        let templateSynthese: string = synthese.template;
                        let formattedSynthese: string | undefined;

                        const tags = templateSynthese.match(MatchHelper.UUIDV4_Regex);
                        if (tags) {
                            for (const tag of tags) {
                                const currentSyntheseQuestion = questions.find((x) => x.id === tag);
                                if (currentSyntheseQuestion) {
                                    formattedSynthese = this.transformSyntheseTemplate(
                                        currentSyntheseQuestion,
                                        question.context,
                                        questions,
                                        templateSynthese
                                    );
                                }
                            }
                        } else {
                            const floorplanTags = Array.from(
                                templateSynthese.matchAll(MatchHelper.SyntheseFloorplanQuestion_Regex),
                                (m) => ({ baseTag: m[0], shortTag: m[1] })
                            );
                            if (floorplanTags) {
                                formattedSynthese = this.transformFloorplanAnswers(
                                    question.context,
                                    templateSynthese,
                                    floorplanTags
                                );
                            }
                        }

                        if (formattedSynthese !== undefined) {
                            formattedSyntheses.push(formattedSynthese);
                        }
                    }
                });
            });
        return formattedSyntheses;
    };

    private static transformSyntheseTemplate(
        syntheseQuestion: QuestionItem,
        context: QuestionContext = {},
        questionSyntheses: Array<QuestionItem>,
        formattedSynthese: string
    ): string | undefined {
        const syntheseQuestionId: string = QuoteQuestionUtils.toQuestionId(syntheseQuestion.id);
        let formattedSyntheseResult: string | undefined;

        const isInContext = (key: string, context: QuestionContext): boolean => {
            return context[key] !== undefined && context[key] !== '';
        };

        const transform = (template: string, key: string, value: string): string => {
            if (template.includes(key)) {
                return template.replaceAll(key, value);
            }
            return template;
        };

        switch (syntheseQuestion.type) {
            case QuestionType.SingleChoice:
                if (syntheseQuestion.single_choice_properties) {
                    if (formattedSynthese.includes(syntheseQuestionId)) {
                        const syntheseQuestionOption: string = QuestionContextHelper.toQuestionOption(
                            syntheseQuestion.id
                        );
                        if (isInContext(syntheseQuestionOption, context)) {
                            const questionOptionValue: string = context[syntheseQuestionOption];
                            //* search option label
                            const currentSyntheseQuestion = questionSyntheses.find((x) => x.id === syntheseQuestion.id);
                            currentSyntheseQuestion?.single_choice_properties!.option_list.forEach(
                                (option: OptionDto) => {
                                    const optionIdKey = QuestionContextHelper.toOptionId(option.id);
                                    if (questionOptionValue === optionIdKey) {
                                        formattedSyntheseResult = transform(
                                            formattedSynthese,
                                            syntheseQuestionId,
                                            option.label
                                        );
                                    }
                                }
                            );
                        }
                    }
                }
                break;

            case QuestionType.Produit:
                if (formattedSynthese.includes(syntheseQuestionId)) {
                    if (isInContext(syntheseQuestionId, context)) {
                        const produit = context[syntheseQuestionId];
                        const libelleKey = Object.keys(produit).filter((x) => x.endsWith('LibelleWeb'))[0];
                        const produitLibelleWeb = `${produit[libelleKey]} (${parseInt(
                            produit['infos_produit']['code_article'],
                        )}/${parseInt(produit['infos_produit']['code_coloris'])})`;
                        formattedSyntheseResult = transform(formattedSynthese, syntheseQuestionId, produitLibelleWeb);
                    }
                }
                break;

            case QuestionType.Number:
                if (formattedSynthese.includes(syntheseQuestionId)) {
                    const questionValueKey: string = QuestionContextHelper.toQuestionValue(syntheseQuestion.id);
                    if (isInContext(questionValueKey, context)) {
                        const number = context[questionValueKey];
                        formattedSyntheseResult = transform(formattedSynthese, syntheseQuestionId, number);
                    }
                }
                break;

            case QuestionType.MultipleChoices:
                if (syntheseQuestion.multiple_choices_properties) {
                    if (formattedSynthese.includes(syntheseQuestionId)) {
                        const questionOptionKey: string = QuestionContextHelper.toQuestionOption(syntheseQuestion.id);

                        if (isInContext(questionOptionKey, context)) {
                            const questionOptionsValues: Array<string> = context[questionOptionKey];
                            const selectedOptionsLabels: Array<string> =
                                syntheseQuestion.multiple_choices_properties.option_list
                                    .filter((x) =>
                                        questionOptionsValues.includes(QuestionContextHelper.toOptionId(x.id))
                                    )
                                    .map((x) => x.label);

                            formattedSyntheseResult = transform(
                                formattedSynthese,
                                syntheseQuestionId,
                                selectedOptionsLabels.join(', ')
                            );
                        }
                    }
                }
                break;

            case QuestionType.Comment:
                if (formattedSynthese.includes(syntheseQuestionId)) {
                    const questionValueKey: string = QuestionContextHelper.toQuestionValue(syntheseQuestion.id);
                    if (isInContext(questionValueKey, context)) {
                        const comment = context[questionValueKey];
                        formattedSyntheseResult = transform(formattedSynthese, syntheseQuestionId, comment);
                    }
                }
                break;

            case QuestionType.ZipCode:
                const zipCode = syntheseQuestion.zipCodeValue?.zipcode;
                formattedSyntheseResult = zipCode
                    ? transform(formattedSynthese, syntheseQuestionId, zipCode)
                    : formattedSynthese;
                break;
        }
        return formattedSyntheseResult;
    }

    private static transformFloorplanAnswers(
        context: QuestionContext,
        formattedSynthese: string,
        floorplanTags: Array<{
            baseTag: string;
            shortTag: string;
        }>
    ): string | undefined {
        const defaultValueIfNeeded = (value: string): string => {
            return value === QuestionContextHelper.NOT_SET ? '-' : value;
        };
        for (const tag of floorplanTags) {
            if (context?.[tag.shortTag] !== undefined && context?.[tag.shortTag] !== '') {
                formattedSynthese = formattedSynthese.replace(tag.baseTag, defaultValueIfNeeded(context[tag.shortTag]));
            } else if (
                //@ts-ignore
                context?.contournements?.[tag.shortTag] !== undefined &&
                //@ts-ignore
                context?.contournements?.[tag.shortTag] !== ''
            ) {
                //@ts-ignore
                formattedSynthese = formattedSynthese.replace(tag.baseTag, context?.contournements[tag.shortTag]);
            } else {
                return undefined;
            }
        }
        return formattedSynthese;
    }
}
