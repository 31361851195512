import React from 'react';
import { useViewState } from '../../../Web/Hooks/ViewState/ViewState';
import { MapConstants, MapMode } from '../MapConstants';

type EditNameContextValue = {
    state: Partial<EditNameContextState>;
    update: (values: Partial<EditNameContextState>) => void;
};
export const EditNameContext = React.createContext<EditNameContextValue>({
    state: {},
    update: (values: Partial<EditNameContextState>) => {},
});
export const useEditNameContext = () => React.useContext(EditNameContext);

type EditNameContextState = { isEditMode: boolean; roomId: string; name: string };

export const EditNameProvider: React.FC = ({ children }) => {
    const { state, update } = useViewState<EditNameContextState>();

    React.useEffect(() => {
        if (state.isEditMode) {
            MapConstants.mode = MapMode.MODE_TYPE_RENAMING_ROOM_NAME;
        } else if (MapConstants.mode === MapMode.MODE_TYPE_RENAMING_ROOM_NAME) {
            MapConstants.mode = undefined;
        }
    }, [state]);
    return <EditNameContext.Provider value={{ state, update }}>{children}</EditNameContext.Provider>;
};
