import { ApiDataResponse } from '../ApiData';
import { ProduitDto } from '../Questions/ProduitDto';
import { StoreDto } from '../TravelExpenses/StoreDto';
import { LocalityDto } from '../TravelExpenses/TravelExpenseDto';
import { ProjectDto } from './ProjectDto';

export enum ReponseQuestionTypeDto {
    Comment = 'Comment',
    Number = 'Number',
    Produit = 'Produit',
    ZipCode = 'ZipCode',
    SingleChoice = 'SingleChoice',
    MultipleChoices = 'MultipleChoices',
}

export interface ReponseCommentPropertiesDto {
    readonly value: string;
}
export interface ReponseNumberPropertiesDto {
    readonly value: string;
}
export interface SetReponseProduitPropertiesDto {
    readonly user_search?: string;
    readonly code?: string;
}

export interface SetReponseZipCodePropertiesDto {
    readonly value: string;
    readonly description: string;
    readonly name: string;
}
export interface ReponseSingleChoicePropertiesDto {
    readonly option_id: string;
}

export interface ReponseMultipleChoicesPropertiesDto {
    readonly option_id_list: Array<string>;
}

export enum ReponseContextTypeDto {
    General = 'General',
    Rooms = 'Rooms',
    Openings = 'Openings',
    RoomElements = 'RoomElements',
}
export interface ReponseQuestionRoomContextPropertiesDto { room_id: string; }
export interface ReponseQuestionOpeningsContextPropertiesDto { opening_id: string; }
export interface ReponseQuestionRoomElementsContextPropertiesDto { room_element_id: string; }

export interface SetConfigurationReponseDto {
    readonly question_id: string;
    readonly type: ReponseQuestionTypeDto;

    comment_properties?: ReponseCommentPropertiesDto;
    number_properties?: ReponseNumberPropertiesDto;
    single_choice_properties?: ReponseSingleChoicePropertiesDto;
    multiple_choices_properties?: ReponseMultipleChoicesPropertiesDto;
    produit_properties?: SetReponseProduitPropertiesDto;
    zip_code_properties?: SetReponseZipCodePropertiesDto;

    context_type?: ReponseContextTypeDto;
    rooms_context_properties?: ReponseQuestionRoomContextPropertiesDto;
    openings_context_properties?: ReponseQuestionOpeningsContextPropertiesDto;
    room_elements_context_properties?: ReponseQuestionRoomElementsContextPropertiesDto;

    exclude_response_selected?: boolean;
}

export interface SetConfigurationDto {
    readonly total_surface: number;
    readonly opening_count: number;
    readonly room_count: number;
    readonly room_element_count?: number;
    readonly version_parametrage_id: string;
    readonly floor_plan_json: string;
    readonly reponse_list: Array<SetConfigurationReponseDto>;
    readonly store_id: string;
}

export interface ReponseProduitPropertiesDto {
    readonly user_search: string;
    readonly produit: ProduitDto; // * without is_default
}

export interface ReponseZipCodePropertiesDto {
    readonly value: string;
    readonly locality?: LocalityDto;
}

export interface ConfigurationReponseDto {
    readonly question_id: string;
    readonly type: ReponseQuestionTypeDto;

    readonly comment_properties?: ReponseCommentPropertiesDto;
    readonly number_properties?: ReponseNumberPropertiesDto;
    readonly single_choice_properties?: ReponseSingleChoicePropertiesDto;
    readonly multiple_choices_properties?: ReponseMultipleChoicesPropertiesDto;
    readonly produit_properties?: ReponseProduitPropertiesDto;
    readonly zip_code_properties?: ReponseZipCodePropertiesDto;

    readonly context_type?: ReponseContextTypeDto;
    readonly rooms_context_properties?: ReponseQuestionRoomContextPropertiesDto;
    readonly openings_context_properties?: ReponseQuestionOpeningsContextPropertiesDto;
    readonly room_elements_context_properties?: ReponseQuestionRoomElementsContextPropertiesDto;

    readonly exclude_response_selected?: boolean;
}

export interface ConfigurationProjetDto {
    readonly projet: ProjectDto;
    readonly touched_by_sellers: boolean;
    readonly is_update_allowed: boolean;
}

export interface ConfigurationDto {
    readonly floor_plan_json: string;
    readonly reponse_list: Array<ConfigurationReponseDto>;
    readonly configuration_projet: ConfigurationProjetDto;
    readonly projet_store: StoreDto;
}

export type GetConfigurationResponse = ApiDataResponse<ConfigurationDto>;
