import React from 'react';

import { Assets } from '../../../../../Web/assets/Assets';
import { CarpentryPicto } from './CarpentryPicto';

//! from CarpentryCalc

const picto_size = 13;
const picto_circle_size = 14;
export const OpeningDefaultColor = '#D5D6DB';
export const OpeningStrokeColor = '#13123A';

type CarpentryItemProps = {
    size: number;
    transform: string;
    iconTransform?: string;
    attrs?: React.SVGAttributes<any>;
};

const Item: React.FC<CarpentryItemProps> = ({ size, transform, iconTransform, attrs, children }) => {
    return (
        <g {...attrs}>
            <line
                className="outline"
                x1={(-size / 2 + 6.5).toString()}
                y1={0}
                x2={(size / 2 - 6.5).toString()}
                y2={0}
                style={{ strokeWidth: 10 }}
                strokeLinecap="round"
                transform={transform}
            />
            <circle
                className="outcircle"
                cx={0}
                cy={0}
                r={picto_circle_size}
                fill={OpeningDefaultColor}
                style={{ strokeWidth: 1 }}
                transform={transform}
            />
            <line
                className="inline"
                x1={(-size / 2 + 6.5).toString()}
                y1={0}
                x2={(size / 2 - 6.5).toString()}
                y2={0}
                style={{ strokeWidth: 8 }}
                strokeLinecap="round"
                transform={transform}
            />
            <circle
                className="incircle"
                cx={0}
                cy={0}
                r={picto_circle_size}
                fill="white"
                transform={transform}
                display="none"
                style={{ stroke: OpeningStrokeColor, strokeWidth: 1 }}
            />
            <g
                className="picto"
                x={(-picto_size / 2).toString()}
                y={(-picto_size / 2).toString()}
                width={picto_size}
                height={picto_size}
                transform={iconTransform}>
                {children}
            </g>
        </g>
    );
};

export const Door = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.30), translate(-24, -28)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'Door' }}>
            <CarpentryPicto.Door />
        </Item>
    );
};

export const ArmoredDoor = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.30), translate(-24, -28)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'ArmoredDoor' }}>
            <CarpentryPicto.ArmoredDoor />
        </Item>
    );
};

export const FrenchDoor = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.30), translate(-24, -28)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'FrenchDoor' }}>
            <CarpentryPicto.FrenchDoor />
        </Item>
    );
};

export const FrontDoor = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.60), translate(-18.65, -17)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'FrontDoor' }}>
            <CarpentryPicto.FrontDoor />
        </Item>
    );
};

export const PatioDoor = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.30), translate(-26, -28)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'PatioDoor' }}>
            <CarpentryPicto.PatioDoor />
        </Item>
    );
};

export const Window = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.30), translate(-26, -28)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'Window' }}>
            <CarpentryPicto.Window />
        </Item>
    );
};

export const Opening = (props: CarpentryItemProps) => {
    const iconTransform = 'scale(0.30), translate(-26, -28)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ role: 'Opening' }}>
            <CarpentryPicto.Opening />
        </Item>
    );
};

export const Moving = (props: CarpentryItemProps) => {
    const iconTransform = 'translate(-11, -11)';
    return (
        <Item {...props} iconTransform={iconTransform} attrs={{ className: 'CarpentryMoving' }}>
            <Assets.IconMoveMapItem />
        </Item>
    );
};
