import * as React from 'react';
import { FlooringDirectionProps } from './FlooringDirectionAssets';
export const ClassiqueVertical = ({ props }: { props: FlooringDirectionProps }) => {
    const { fillColor = '#13123A', svgProps } = props;
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48 48H0V0h48v48Zm-2.4-2.4V2.4H2.4v43.2h43.2Z"
                fill={fillColor}
            />
            <path
                d="m25.263 15.944 6.26 6.26a.942.942 0 0 1 .277.67.942.942 0 0 1-.277.671l-.568.568a.96.96 0 0 1-1.35 0l-3.66-3.651v11.602c0 .523-.41.936-.933.936h-.803c-.523 0-.974-.413-.974-.936V20.42l-3.68 3.693a.935.935 0 0 1-1.332 0l-.567-.568a.94.94 0 0 1-.276-.671c0-.254.098-.492.277-.67l6.26-6.26a.942.942 0 0 1 .673-.277c.255 0 .494.098.673.277Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 1v9.394h4.8V1.8h2.4v17.838l-2.4-2.4V12.85h-5.017a3.962 3.962 0 0 0-1.192-.183h.007v.183h-.014v-.183h.007c-.337 0-.67.042-.99.125V1H26Zm-8.4 17.018V1.8h-2.4v8.594h-4.8V1.8H8v22.1H2v2.455h6V46h2.4v-7.367h4.8V46h2.4V27.174a3.942 3.942 0 0 1-1.499-.94l-.566-.568-.004-.003a3.96 3.96 0 0 1-.33-.377v10.892h-4.8V12.85h4.8v7.614c.102-.134.214-.261.335-.382l2.064-2.064Zm0 4.247a.941.941 0 0 0-.22.609c0 .226.077.44.22.61v-1.219Zm.41 1.635.213.213a.935.935 0 0 0 .667.277.928.928 0 0 0 .665-.277l.213-.213H18.01Zm5.225 0v2.455H24.8V23.9h-1.565Zm2.766 11.976v.302h4.8v-8.821a3.939 3.939 0 0 0 2.275-1.122l.001-.001.568-.568a3.939 3.939 0 0 0 1.02-1.766H38V1.8h2.4v8.594h6v2.456h-6v23.328h6v2.455h-6V46H38V26.355h-4.8V46h-2.4v-7.367H26V46h-2.4V35.952c.201.032.405.048.61.048h.803c.342 0 .673-.043.988-.124Zm4.8-11.637V21.48l.722.723a.941.941 0 0 1 .277.671.942.942 0 0 1-.277.671l-.568.568a.94.94 0 0 1-.155.126Z"
                fill={fillColor}
            />
        </svg>
    );
};
