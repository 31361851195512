import { I18n } from "../Locales/I18nService";
import { IRoom, LocalisationName, RoomLocalisation } from "../Plugins/FloorPlan/Models/IRoom";
import { DialogService } from "../Web/Services/DialogService";

import { EvaluationContext } from "./QuestionContextHelper";

type RoomHeatingTypeError = { errorCode: string }

//* HOTFIX #29953 C&P > Ajouter un message d'erreur de non enregistrement (type de chauffage)
export class RoomHeatingTypeErrorHelper {

    public static ERROR_CODE_MISSING_ROOM_HEATING_TYPE = 'MISSING_ROOM_HEATING_TYPE'

    public static createMissingRoomHeatingTypeError = (): RoomHeatingTypeError => {
        return { errorCode: this.ERROR_CODE_MISSING_ROOM_HEATING_TYPE }
    }

    public static isRoomHeatingTypeMissing = (rooms: Array<IRoom> = [], evaluations: EvaluationContext = {}) => {
        const localisation = evaluations[LocalisationName];
        if (localisation === RoomLocalisation.Inside) {
            const hasRoomWithoutHeatingType = rooms.some(room => {
                const isMissing = !Boolean(room.roomHeatingType);
                isMissing && console.log('isRoomHeatingTypeMissing', { room, isMissing });
                return isMissing;
            })
            return hasRoomWithoutHeatingType;
        }
        return false;
    }

    public static isErrorFromRoomHeatingTypeHelper = (error: RoomHeatingTypeError) => {
        return error.errorCode === this.ERROR_CODE_MISSING_ROOM_HEATING_TYPE;
    }

    public static handleError = async (error: RoomHeatingTypeError) => {
        DialogService.showDeadEndErrorDialog({
            title: I18n.get('ERROR_ROOM_HEATING_TYPE_MISSING_TITLE'),
            body: I18n.get('ERROR_ROOM_HEATING_TYPE_MISSING_CONTENT'),
            labelChoice: I18n.get('COMMON_CONTINUE'),
            onClickChoice: ({ closeDialog }) => closeDialog()
        });
        return true;
    }
}