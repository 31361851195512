import _ from 'lodash';

import { ProductHelper } from '../../../Helpers/ProductHelper';
import { LaizeProps, LaizeResult } from '../Components/Laizes/Laize';
import { MapConstants } from '../MapConstants';
import { IOpening } from '../Models/IOpening';
import { IRoom } from '../Models/IRoom';
import { IRoomItem } from '../Models/IRoomItem';
import { Editor } from '../Utils/Editor';
import { PerimeterCalc } from '../Utils/PerimeterCalc';
import { PointUtils } from '../Utils/PointUtils';
import { Wall } from '../Utils/Types';
import { WallUtils } from '../Utils/Wall/WallUtils';
import { qSVG } from '../Utils/qSVG';
import { LaizeComputer, LaizeResultParams } from './LaizeComputer';

export type MapResultParams = {
    rooms: Array<IRoom>;
    roomItems: Array<IRoomItem>;
    walls: Array<Wall>;
    openings: Array<IOpening>;
    logs?: any;
    laizeProps: Array<LaizeProps>;
};

//!\ OLD NAME : FloorPlanResult
export class MapResult {
    public static calculate = (props: MapResultParams) => {
        const { laizeProps, rooms, roomItems, walls, openings, logs } = props;
        const wrappedMapResult = (() => {
            const result = [];
            for (const laizeProp of laizeProps) {
                const productCode = laizeProp.productCode;
                const roomsToUpdate = rooms.filter(
                    (x) => x.questionProduitPrincipal?.produitValue?.code === productCode
                );
                const roomItemsToUpdate = roomItems.filter((x) => roomsToUpdate.some((y) => y.roomId === x.roomId));
                const wallsToUpdate = walls.filter((x) => roomsToUpdate.some((y) => y.roomId === x.roomId));
                const openingsToUpdate = openings.filter((x) => roomsToUpdate.some((y) => y.roomId === x.roomId));

                const laizeResultParams: LaizeResultParams = {
                    rooms: roomsToUpdate,
                    roomItems: roomItemsToUpdate,
                    walls: wallsToUpdate,
                    openings: openingsToUpdate,
                    logs,
                    laizeProps: laizeProp,
                };
                const { laizesCalc, laizeResult } = LaizeComputer.compute(laizeResultParams);

                const meter = MapConstants.meter;
                const adjacentRooms = WallUtils.determineAdjacentRooms(
                    wallsToUpdate,
                    openingsToUpdate,
                    roomsToUpdate.map((x) => x.roomId!)
                );

                //Surface totale des pièces de ce calcul en lé
                const total_surface_area = _.round(_.sum(roomsToUpdate.map((x) => x.area! / (meter * meter))), 2);

                const bandWidth = (laizeProp.largeurCm || 100) / 100;
                for (const room of roomsToUpdate) {
                    //If the flooring direction used by the laize computer is different from the one selected by the vendor
                    //Or if the product type is not rouleau or lamePleine
                    //Or if the flooring direction selected by the vendor is not available with this room
                    //=> We should reset the flooring direction
                    if (
                        laizeResult?.flooringDirectionByRoomId?.[room.roomId!] !== room.flooringDirection ||
                        ProductHelper.getProductType(room.questionProduitPrincipal?.produitValue) === undefined ||
                        !laizeResult?.possibleFlooringDirectionByRoomId?.[room.roomId!]?.some(
                            (x) => x === room.flooringDirection
                        )
                    ) {
                        room.flooringDirection = undefined;
                    }

                    const room_bbox = PointUtils.calculateBoundingBox({ points: room.coords });
                    const room_width = (room_bbox!.xMax - room_bbox!.xMin) / meter;
                    const room_height = (room_bbox!.yMax - room_bbox!.yMin) / meter;
                    const room_length = _.round(room_width > room_height ? room_width : room_height, 2);
                    const surface_area = _.round(room.area! / (meter * meter), 2);
                    const surface_area_without_room_item = _.round(room.areaWithoutRoomItems / (meter * meter), 2);
                    const surface_manipulee_prorata = _.round(
                        _.sum(laizesCalc?.roomsBandsLength) * bandWidth * (surface_area / total_surface_area),
                        2
                    ); //in ml ?

                    const rawPerimeter = WallUtils.calculatePerimeter(room.walls);
                    const perimeter = _.round(rawPerimeter / meter, 2); //* in meter
                    const perimeter_plinthes = _.round(
                        PerimeterCalc.calculatePerimeterPlinthes(
                            room.roomId!,
                            rawPerimeter,
                            room.contournements?.Stonewall!,
                            roomsToUpdate,
                            roomItemsToUpdate,
                            openingsToUpdate
                        ) / meter,
                        2
                    ); //in meter

                    const pre_raccord_length = laizeResult?.roomsRaccordLength?.find((x) => x.roomId === room.roomId);
                    const raccord_length = _.round(pre_raccord_length?.length || 0, 2);

                    const should_lay_plinthes_inside_room_element = MapResult.shouldLayPlinthesInsideRoomElement(
                        roomItemsToUpdate,
                        room.roomId
                    );
                    room.surface_area = surface_area;
                    room.surface_area_without_room_item = surface_area_without_room_item;
                    room.surface_manipulee_prorata = surface_manipulee_prorata;
                    room.perimeter = perimeter;
                    room.perimeter_plinthes = perimeter_plinthes;
                    room.raccord_length = raccord_length;
                    room.room_length = room_length;
                    room.should_lay_plinthes_inside_room_element = should_lay_plinthes_inside_room_element;

                    for (const opening of openingsToUpdate) {
                        for (const wall of room.walls || []) {
                            if (
                                Editor.objFromWall(wall, openingsToUpdate).find((x) => x === opening) &&
                                opening.roomId === room.roomId
                            ) {
                                const contiguous_opening_detail = adjacentRooms.find(
                                    (x) => x.opening.openingId === opening.openingId
                                );
                                opening.size = (opening.size! * 100) / meter; //in cm
                                opening.contiguous_between_rooms = Boolean(contiguous_opening_detail)
                                    ? contiguous_opening_detail?.roomIds
                                    : [];
                            }
                        }
                    }
                }

                for (const roomItem of roomItemsToUpdate) {
                    const room_item_width = qSVG.measure(roomItem.coords![0], roomItem.coords![1]);
                    const room_item_height = qSVG.measure(roomItem.coords![1], roomItem.coords![2]);
                    roomItem.surface = (room_item_width / 100) * (room_item_height / 100); //* in cm

                    //! RoomElementsMetreLineaire longueur en ml qui n'est pas en contact avec le mur
                    roomItem.room_elements_length =
                        PerimeterCalc.roomItemDetailedPerimeter(roomItem, roomsToUpdate).lengthNotAdjacentToWall / 100;
                    roomItem.width = room_item_width;
                    roomItem.height = room_item_height;
                    roomItem.horizontal_size = room_item_width;
                    roomItem.vertical_size = room_item_height;
                    //roomItem.position = { x: roomItem.graphTranslation!.x, y: roomItem.graphTranslation!.y };
                }
                result.push({
                    rooms: roomsToUpdate,
                    roomItems: roomItemsToUpdate,
                    walls: wallsToUpdate,
                    openings: openingsToUpdate,
                    laizeResult,
                    laizeProp,
                    total_surface_area,
                });
            }
            return result;
        })();

        const mergedRooms: Array<IRoom> = [];
        const mergedRoomItems: Array<IRoomItem> = [];
        const mergedWalls: Array<Wall> = [];
        const mergedOpenings: Array<IOpening> = [];
        const mergedLaizeResults: Array<LaizeResult> = [];

        //Sort the result by code article and code coloris (for the laize result)
        wrappedMapResult.sort((a, b) => {
            const aProductCode = parseInt(a.laizeProp.produit?.infos_produit.code_article!);
            const bProductCode = parseInt(b.laizeProp.produit?.infos_produit.code_article!);
            const aColorCode = parseInt(a.laizeProp.produit?.infos_produit.code_coloris!);
            const bColorCode = parseInt(b.laizeProp.produit?.infos_produit.code_coloris!);
            if (aProductCode < bProductCode) {
                return -1;
            }
            if (aProductCode > bProductCode) {
                return 1;
            }
            if (aColorCode < bColorCode) {
                return -1;
            }
            if (aColorCode > bColorCode) {
                return 1;
            }
            return 0;
        });

        let nextMinBandId = 0;
        let lastMaxBandId = 0;

        for (const mapResult of wrappedMapResult) {
            mergedRooms.push(...mapResult.rooms);
            mergedRoomItems.push(...mapResult.roomItems);
            mergedWalls.push(...mapResult.walls);
            mergedOpenings.push(...mapResult.openings);

            const newLaizeResult = mapResult.laizeResult!;

            if (newLaizeResult.calculator) {
                // eslint-disable-next-line no-loop-func
                newLaizeResult.calculator.bands_v3 = newLaizeResult.calculator?.bands_v3?.map((x) => {
                    const newBand = { ...x };
                    if (newBand.bandId) {
                        newBand.bandId += nextMinBandId;
                        if (lastMaxBandId < newBand.bandId) {
                            lastMaxBandId = newBand.bandId;
                        }
                    }
                    return newBand;
                });
            }

            // eslint-disable-next-line no-loop-func
            newLaizeResult.laizes = newLaizeResult.laizes?.map((x) => {
                const newLaize = { ...x };
                newLaize.id += nextMinBandId;
                return newLaize;
            });

            mergedLaizeResults.push(newLaizeResult);
            nextMinBandId += lastMaxBandId;
        }

        mergedRooms.push(...rooms.filter((x) => !mergedRooms.some((y) => y.roomId === x.roomId)));
        mergedRoomItems.push(...roomItems.filter((x) => !mergedRoomItems.some((y) => y.roomItemId === x.roomItemId)));
        mergedWalls.push(...walls.filter((x) => !mergedWalls.some((y) => y.roomId === x.roomId)));
        mergedOpenings.push(...openings.filter((x) => !mergedOpenings.some((y) => y.openingId === x.openingId)));

        //Surface global du projet (Somme de la surface total de chaque pièce)
        const global_surface_area = _.round(_.sum(wrappedMapResult.map((x) => x.total_surface_area)), 2);

        return {
            rooms: mergedRooms,
            walls: mergedWalls,
            roomItems: mergedRoomItems,
            openings: mergedOpenings,
            laizeResults: mergedLaizeResults,
            global_surface_area,
        };
    };

    public static shouldLayPlinthesInsideRoomElement(roomItems: Array<IRoomItem>, roomId?: string) {
        for (const roomItem of roomItems.filter((x) => x.roomId === roomId)) {
            if (roomItem.putPlinthes) {
                return true;
            }
        }
        return false;
    }
}
