import { ApiRequest } from '../XHR/ApiRequest';
import { XHRRequestCanceler, XHRRequestConfig } from '../XHR/XHRRequest';
import { ApiUrls } from '../../Models/ApiUrls';
import { GetProduitsParams, ProduitListResponse, ProduitResponse } from '../../Models/Questions/ProduitDto';
import { GetLocalitiesParam, GetLocalitiesResponse } from '../../Models/TravelExpenses/TravelExpenseDto';
import { GetStoreSearchResponse } from '../../Models/TravelExpenses/StoreDto';

export class QuestionApi {

    public static getProduits(questionId: string, params: GetProduitsParams,): Promise<ProduitListResponse> {
        const { count, search, data_context } = params;
        const config: XHRRequestConfig = {
            params: { count, search },
            data: data_context,
        };
        //* c'est bien un POST
        return ApiRequest.post<ProduitListResponse>(
            ApiUrls.QUESTION_PRODUIT_LIST(params.versionId!, questionId), {}, config
        );
    }

    public static getProduit(produitRef: string, params: GetProduitsParams): Promise<ProduitResponse> {
        const { data_context } = params;
        const config: XHRRequestConfig = {
            data: data_context,
        };
        //* c'est bien un POST
        return ApiRequest.post<ProduitResponse>(ApiUrls.QUESTION_PRODUIT_GET(produitRef), {}, config);
    }

    private static travelExpenseCanceler: XHRRequestCanceler | undefined = undefined;

    private static searchStoreLocatiesCanceler: XHRRequestCanceler | undefined = undefined;
    private static searchStoresCanceler: XHRRequestCanceler | undefined = undefined;

    public static getLocalities(questionId: string, params: GetLocalitiesParam,): Promise<GetLocalitiesResponse> {
        if (QuestionApi.travelExpenseCanceler) {
            QuestionApi.travelExpenseCanceler.cancel("cancel getLocalities");
        }
        QuestionApi.travelExpenseCanceler = new XHRRequestCanceler();

        return ApiRequest.get<GetLocalitiesResponse>(
            ApiUrls.QUESTION_LOCALITY_SEARCH(params.versionId!, questionId, params.zipCode),
            { cts: QuestionApi.travelExpenseCanceler }
        );
    }

    public static getStoreLocalities(versionId: string, search: string): Promise<GetLocalitiesResponse> {
        if (QuestionApi.searchStoreLocatiesCanceler) {
            QuestionApi.searchStoreLocatiesCanceler.cancel("cancel getStoreLocalities");
        }
        QuestionApi.searchStoreLocatiesCanceler = new XHRRequestCanceler();

        return ApiRequest.get<GetLocalitiesResponse>(
            ApiUrls.STORE_LOCALITY_SEARCH(versionId, search),
            { cts: QuestionApi.travelExpenseCanceler }
        );
    }

    public static getStores(versionId: string, latitude: number, longitude: number): Promise<GetStoreSearchResponse> {
        if (QuestionApi.searchStoresCanceler) {
            QuestionApi.searchStoresCanceler.cancel("cancel getStores");
        }
        QuestionApi.searchStoresCanceler = new XHRRequestCanceler();

        return ApiRequest.get<GetStoreSearchResponse>(
            ApiUrls.STORE_SEARCH(versionId),
            {
                params: { latitude, longitude },
                cts: QuestionApi.travelExpenseCanceler,
            }
        );
    }
}
