import { ApiRequest } from '../XHR/ApiRequest';
import { ApiUrls } from '../../Models/ApiUrls';
import {
    CurrentVersionParametrageResponse,
    VersionParametrageResponse,
} from '../../Models/VersionParametrages/VersionParametrage';

export class VersionApi {

    public static getCurrentVersion(): Promise<CurrentVersionParametrageResponse> {
        return ApiRequest.get<CurrentVersionParametrageResponse>(ApiUrls.GET_CURRENT_VERSION);
    }

    public static getVersion(verionId: string): Promise<VersionParametrageResponse> {
        return ApiRequest.get<VersionParametrageResponse>(ApiUrls.GET_VERSION(verionId));
    }
}
