import { CoordPoint } from '../../../Utils/Types';
import { LaizeOperation } from './LaizeSurfaces';
import { CALCULATOR_V3, LaizeResultItem } from './LaizeV3';

export type LaizeBandItemV3 = {
    points: Array<CoordPoint>;
    mainText?: string;
    color?: string;
    strokeWidth?: number;
    position: string;
    roomId: string;
    type: string;
    bandId?: number;
    surface?: number;
    leftAlignText?: boolean;
    fillColor?: string;
    displayText?: boolean;
    dashed?: boolean;
};

type DrawLaizeResults = { laizeResults: Array<LaizeResultItem>; iteration?: number };

export class LaizeTrace {
    public static drawLaizeResults({ laizeResults, iteration }: DrawLaizeResults) {
        if (iteration === undefined) {
            iteration = laizeResults.length;
        }

        for (let i = 0; i < laizeResults.length && i < iteration; i++) {
            const laizeResultToDraw = laizeResults[i];
            const bandColor = laizeResultToDraw.leftOverUsed ? 'blue' : 'red';
            const bandText = laizeResultToDraw.leftOverUsed
                ? `${i + 1} - Chute réutilisée`
                : `${i + 1} - Nouvelle bande`;
            const type = laizeResultToDraw.leftOverUsed ? 'band-reused' : 'band-new';

            // bandId is used to get svg/png band images
            let bandId = i + 1;
            // Finding the bandId of the band from which the left-over used is from
            if (laizeResultToDraw.leftOverUsed) {
                const fromResult = laizeResults.find((x) => x.id === laizeResultToDraw.usedLeftOverId)!;
                bandId = fromResult.bandId!;
            }
            laizeResultToDraw.bandId = bandId;

            this.drawBand({
                points: laizeResultToDraw.laidBand,
                mainText: bandText,
                color: bandColor,
                strokeWidth: 2,
                position: 'bottom',
                roomId: laizeResultToDraw.roomId,
                type,
                bandId,
                leftAlignText: true,
                displayText: true,
                dashed: true,
            });

            this.drawBand({
                points: laizeResultToDraw.coveredSurfaceCoords,
                color: 'DarkSlateGrey',
                strokeWidth: 2,
                position: 'top',
                roomId: laizeResultToDraw.roomId,
                type: 'surface-covered',
                leftAlignText: true,
                fillColor: 'green',
                displayText: false,
            });

            if (laizeResultToDraw.leftOverResult) {
                const leftOverBands = laizeResultToDraw.leftOverResult.leftOverCoords;
                leftOverBands.forEach((leftOverBand) => {
                    this.drawBand({
                        points: leftOverBand,
                        mainText: `Chute réutilisable`,
                        color: 'purple',
                        strokeWidth: 0.5,
                        position: 'top',
                        roomId: laizeResultToDraw.roomId,
                        type: 'band-reusable',
                        leftAlignText: false,
                        fillColor: 'purple',
                        displayText: true,
                        dashed: true,
                    });
                });
            }
        }
    }

    public static displayPotentialOperations(operation: LaizeOperation, rotationAngle?: number) {
        // Draw left potential surface
        this.drawBand({
            points: operation.surfaceCoords!,
            color: 'grey',
            strokeWidth: 1,
            position: 'top',
            roomId: operation.roomId!,
            type: 'surface-covered-potential',
            leftAlignText: true,
            fillColor: 'grey',
            displayText: false,
        });
        // Draw left potential band
        this.drawBand({
            points: operation.realBand!,
            mainText: 'Bande potentielle',
            color: '#72bcd4',
            strokeWidth: 2,
            position: 'bottom',
            roomId: operation.roomId!,
            type: 'band-new-potential',
            leftAlignText: true,
            displayText: rotationAngle === undefined,
            dashed: true,
        });
    }

    public static drawBand(item: LaizeBandItemV3) {
        CALCULATOR_V3.bandItems.push(item);

        // const {
        //     points,
        //     mainText = '',
        //     color = 'red',
        //     strokeWidth = 1,
        //     position = 'top',
        //     customWidth,
        //     customHeight,
        //     roomId,
        //     type,
        //     bandId,
        //     surface,
        //     leftAlignText = true,
        //     fillColor,
        //     displayText = true,
        //     dashed = false,
        // } = item;

        // let bbox = PointUtils.calculateBoundingBox({ points })!;
        // let textPos = { x: leftAlignText ? bbox.xMin : bbox.xMax - 180, y: bbox.yMin + 15 };
        // let width = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMax, y: bbox.yMin });
        // let height = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMin, y: bbox.yMax });

        // customWidth = customWidth === undefined || isNaN(customWidth) ? width : customWidth;
        // customHeight = customHeight === undefined || isNaN(customHeight) ? height : customHeight;

        // let rotate = false;
        // position = rotate ? 'bottom' : position;

        // let groupBandWithDesc = qSVG.create('boxDebug', 'g', {
        //     class: `room-${roomId}`,
        // });

        // let textWrapper = qSVG.create('none', 'g', {
        //     class: `room-${roomId}`,
        //     transform:
        //         'translate(' +
        //         (textPos.x + (rotate ? 10 : 0)) +
        //         ',' +
        //         (textPos.y + (position === 'top' ? 0 : height)) +
        //         ')' +
        //         (rotate ? ' rotate(90)' : ''),
        // });

        // if (displayText) {
        //     groupBandWithDesc.append(textWrapper);
        // }

        // let pointsStr = '';
        // let styled: CSSProperties = { color: color, fontSize: '16px', fontFamily: 'var(--fontFamilyAvenir)', textAnchor: 'start' };
        // qSVG.textOnDiv(
        //     mainText,
        //     { x: rotate ? 10 : 0, y: 2 },
        //     styled,
        //     'debugTextTrace-' + textPos.x + ';' + textPos.y,
        //     textWrapper,
        //     { type }
        // );

        // const meter = MapConstants.meter;
        // if (!surface) {
        //     var displayedWidth = Math.round((customWidth * 100) / meter);
        //     var displayedHeight = Math.round((customHeight * 100) / meter);
        //     var dimensionsText = `${displayedWidth}cm x ${displayedHeight}cm`;
        //     qSVG.textOnDiv(
        //         dimensionsText,
        //         { x: rotate ? 10 : 0, y: 14 },
        //         styled,
        //         'debugTextTrace-' + textPos.x + ';' + +textPos.y + '2',
        //         textWrapper,
        //         {
        //             type: type,
        //             'text-content': 'dimension',
        //             'band-width': displayedWidth,
        //             'band-height': displayedHeight,
        //         }
        //     );
        // }

        // if (surface) {
        //     let styledSurface: CSSProperties = {
        //         color: 'black',
        //         fontSize: '14px',
        //         fontFamily: 'var(--fontFamilyAvenir)',
        //         textAnchor: 'start',
        //         fontWeight: 'bold',
        //     };
        //     var surfaceRounded = Math.round(surface / meter / 100);
        //     var surfaceText = `Surface couverte : ${surfaceRounded}m2`;
        //     qSVG.textOnDiv(
        //         surfaceText,
        //         { x: rotate ? 10 : 5, y: 20 },
        //         styledSurface,
        //         'debugTextTrace-' + textPos.x + ';' + +textPos.y + '2',
        //         textWrapper,
        //         {
        //             type,
        //         }
        //     );
        // }

        // for (const point of points) {
        //     pointsStr += point.x + ',' + point.y + ' ';
        // }
        // fillColor = fillColor || 'none';
        // const opacity = fillColor !== 'none' ? 0.5 : 1;
        // let bandPolygon = qSVG.create('none', 'polygon', {
        //     class: 'room-' + roomId,
        //     type: type,
        //     bandId: bandId,
        //     points: pointsStr,
        //     fill: fillColor,
        //     stroke: color,
        //     'stroke-width': strokeWidth,
        //     opacity: opacity,
        //     'stroke-dasharray': dashed ? '10,10' : '',
        // });

        // groupBandWithDesc.append(bandPolygon);
    }
}
