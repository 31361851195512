import React from 'react';

import { useExportMapSvgV3 } from '../Components/Images/ExportMapSvgV3';
import { ExportRoomLaizeBandsSvgItem } from '../Components/Images/Laizes/LaizeExportBands';
import { useExportMapLaizeBandsSvgV3 } from '../Components/Images/Laizes/LaizeExportBandsSvgV3';

export type MapExportContextValue = {
    captureSvg: () => string;
    capturePng: () => Promise<string>;
    exportBandItems: () => Array<ExportRoomLaizeBandsSvgItem>;
};
const MapExportContext = React.createContext<MapExportContextValue>({
    captureSvg: () => '',
    capturePng: () => Promise.resolve(''),
    exportBandItems: () => [],
});

export const useMapExport = () => React.useContext(MapExportContext);

export const MapExportProvider: React.FC = ({ children }) => {
    const { mapView, captureSvg, capturePng } = useExportMapSvgV3();
    const { exportItems: exportBandItems } = useExportMapLaizeBandsSvgV3();

    return (
        <MapExportContext.Provider value={{ captureSvg, capturePng, exportBandItems }}>
            {children}
            {mapView}
            {exportBandItems().map((item) => item.renderView({ id: item.room.roomId }))}
        </MapExportContext.Provider>
    );
};
