import { RoomCollision } from '../Utils/SAT/SATUtils';
import { CoordPoint, WallLine } from '../Utils/Types';
import { IMapItem, YesOrNo } from './IMapItem';

export enum RoomItemType {
    ShowerBathtub = 'shower_bathtub',
    FixedFurniture = 'fixed_furniture',
    Cupboard = 'cupboard',
    KitchenFurniture = 'kitchen_furniture',
    Trap = 'trap',
    Fireplace = 'fireplace',
    Opening = 'opening',
    Stairs = 'stairs',
    Divider = 'divider',
    HeavyElement = 'heavy_element',
    SewerPlate = 'sewer_plate',
}

export interface IRoomItem extends IMapItem {
    roomItemId?: string;

    //* INFOS
    type?: RoomItemType;
    putFlooring?: YesOrNo;
    putPlinthes?: string;
    width?: number;
    height?: number;

    //* COORDS
    isMoveableOutside?: boolean; // #20350
    coords?: Array<CoordPoint>;
    coordsReal?: Array<CoordPoint>;
    graphTranslation?: GraphTranslation;
    collisions?: Array<RoomCollision>;

    surface?: number;
    horizontal_size?: number;
    vertical_size?: number;
    room_elements_length?: number;

    //* Used for gestures
    isMoving?: boolean;

    //position?: CoordPoint;
}

type GraphTranslation = CoordPoint & {
    //!only set in TestCase
    wall?: WallLine;
    roomId?: string;
    distance?: number;
}

export type RoomItemWithAngle = { roomItem: IRoomItem; angle: number };
