import React from 'react';
import { useParams } from 'react-router-dom';

import { handleError } from '../../../../Errors/PolErrorHandler';
import { EstimationUrlHelper } from '../../../../Helpers/EstimationUrlHelper';
import { I18n } from '../../../../Locales/I18nService';
import { ProjectDto, ProjectResponse } from '../../../../Models/Projects/ProjectDto';
import { ProjectsApi } from '../../../../Services/Api/ProjectsApi';
import { RouteParams, RouteQueryParams } from '../../../../Types/RouteParams';
import { useQuery } from '../../../Hooks/URL/URLUtils';
import { useViewState } from '../../../Hooks/ViewState/ViewState';
import { DialogService, InputValidateActionParams, ValidateActionParams } from '../../../Services/DialogService';
import { NavigationService } from '../../../Services/NavigationService';
import { ExportFileType, ExportUtils } from '../../../../Utils/ExportUtils';
import { useIsBoUser } from '../../../Hooks/useIsBoUser';
import { useMe } from '../../../../Configs/PolStore';
import { ProjectHelper } from '../../../../Helpers/ProjectHelper';
import { useIsVendeur } from '../../../Hooks/Vendeur/useVendeur';

export type ProjectDetailsViewState = {
    project?: ProjectDto;
    isAllowedToUpdateMetaData: boolean;
    exporting: boolean;
    customerId?: string;
};

export enum EditDialogType {
    Intitule = 'Intitule',
    Description = 'Description',
    CustomerId = 'CustomerId',
}

export const useProjectDetailsPageController = () => {
    const { projectId, configId } = useParams<RouteParams>();
    const version = useQuery<RouteQueryParams>().version;
    const me = useMe();
    const isBoUser = useIsBoUser();
    const isVendor = useIsVendeur();

    const { state, update } = useViewState<ProjectDetailsViewState>({});

    //#region //* LOAD PROJECT

    React.useEffect(() => {
        if (Boolean(me.data)) {
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, configId, me.data]);

    const refresh = async () => {
        try {
            update({ loading: true });
            const projectResponse: ProjectResponse = await ProjectsApi.getProject(projectId);
            const project = projectResponse.data;
            update({
                project,
                loading: false,
                isAllowedToUpdateMetaData: ProjectHelper.isAllowedToUpdateMetaData({
                    me,
                    createdByStoreId: project.created_by_store?.store.id,
                    createdByCustomerID: project.created_by_customer?.user.id,
                }),
                customerId: project.customer_id,
            });
        } catch (error) {
            update({ loading: false });
            handleError(error);
        }
    };

    //#endregion

    //#region //* EVENTS

    const onValidateEditDialog = async (
        type: EditDialogType,
        { input, setValidating, closeDialog }: InputValidateActionParams
    ) => {
        const { intitule = '', description = '' } = state.project ?? {};
        try {
            setValidating(true);
            await ProjectsApi.updateProjectMetaData(projectId, {
                intitule: type === EditDialogType.Intitule ? input : intitule,
                description: type === EditDialogType.Description ? input : description,
                customer_id: type === EditDialogType.CustomerId ? input : state.customerId,
            });
            setValidating(false);
            closeDialog(() => {
                if (type === EditDialogType.CustomerId) {
                    onBack();
                } else {
                    refresh();
                }
            });
        } catch (error) {
            setValidating(false);
            handleError(error);
        }
    };

    const onEditIntitule = (): void => {
        DialogService.showEditInputDialog({
            title: I18n.get('ProjectDetails_Intitule'),
            description: I18n.get('ProjectDetails_DialogEditIntituleInformation'),
            initialValue: state.project?.intitule,
            onValidate: (validateParams) => onValidateEditDialog(EditDialogType.Intitule, validateParams),
        });
    };

    const onEditDescription = (): void => {
        DialogService.showEditInputDialog({
            title: I18n.get('ProjectDetails_Description'),
            description: I18n.get('ProjectDetails_DialogEditDescriptionInformation'),
            initialValue: state.project?.description,
            onValidate: (validateParams) => onValidateEditDialog(EditDialogType.Description, validateParams),
        });
    };

    const onEditCustomerId = (): void => {
        DialogService.showEditInputDialog({
            title: I18n.get('ProjectDetails_CustomerId'),
            description: I18n.format('ProjectDetails_DialogEditCustomerIdInformation'),
            initialValue: state.customerId,
            onValidate: (validateParams) => onValidateEditDialog(EditDialogType.CustomerId, validateParams),
        });
    };

    const onClickDelete = (): void => {
        const confirmDelete = async ({ setValidating, closeDialog }: ValidateActionParams) => {
            try {
                setValidating(true);
                await ProjectsApi.deleteProject(projectId);
                setValidating(false);
                closeDialog(() => NavigationService.goToMyProjects(EstimationUrlHelper.toQueryParam({ version })));
            } catch (error) {
                setValidating(false);
                handleError(error);
            }
        };

        DialogService.showYesNoChoiceDialog({
            title: I18n.get('ProjectDetails_deleteDialogTitle'),
            subtitle: I18n.get('ProjectDetails_deleteDialogHeader'),
            body: I18n.get('ProjectDetails_deleteDialogBody'),
            onClickYes: confirmDelete,
        });
    };

    const onBack = (): void => NavigationService.goToMyProjects(EstimationUrlHelper.toQueryParam({ version }));
    const onResumeConfiguration = (configurationId: string): void => {
        return NavigationService.goToQuote(configurationId, EstimationUrlHelper.toQueryParam({ version }));
    };

    const onResumeProject = (): void => {
        const { configuration_id, quotation_list = [] } = state.project ?? {};
        //! If the project's CurrentConfiguration is null, use latest configuration.
        const configIdToLoad = configuration_id || quotation_list[0]?.configuration_id;

        NavigationService.goToQuote(configIdToLoad, EstimationUrlHelper.toQueryParam({ version }));
    };

    const downloadExportedProject = async () => {
        try {
            update({ exporting: true });
            const data = await ProjectsApi.exportProject(projectId);
            ExportUtils.saveToFile(data, `exported-project-${projectId}`, ExportFileType.ZIP);
        } finally {
            update({ exporting: false });
        }
    };

    //#endregion

    return {
        state,
        isBoUser,
        isVendor,
        events: {
            update,
            onBack,
            onResumeProject,
            onResumeConfiguration,
            onEditIntitule,
            onEditDescription,
            onEditCustomerId,
            onClickDelete,
            downloadExportedProject,
        },
    };
};
