import React from 'react';
import { useIntl } from 'react-intl';

import { RoomLocalisation, UnitType } from '../../../../../../../Plugins/FloorPlan/Models/IRoom';

export type SelectItemOption = { label: string; value?: string };

export interface RoomOptionItem extends SelectItemOption {
    localisation: Array<RoomLocalisation>;
    unitType?: Array<UnitType>;
    ignoredRoomType?: Array<string>;
}

export enum RoomTypeValue {
    //TODO RoomType when 'Présence d'un revêtement au sol' === 'Oui'

    //TODO RoomType when 'Présence d'un revêtement au sol' === 'Non'
    BETON_FONDATION_DALLE_BRUTE = 'Béton (dalle brute)',
}

export type RoomInfos = {
    roomFloors: Array<SelectItemOption>;
    getRoomTypes: (localisation?: RoomLocalisation, unitType?: UnitType) => Array<SelectItemOption>;
    getRoomCurrentFloorWithoutFlooring: (localisation: RoomLocalisation, type: string) => Array<SelectItemOption>;
    getRoomCurrentFloorWithFlooring: (localisation: RoomLocalisation) => Array<SelectItemOption>;
    getRoomHeatingTypes: (localisation: RoomLocalisation) => Array<SelectItemOption>;
    PasDeChauffage: string;
};

export const useRoomInfosValues = (): RoomInfos => {
    const i18n = useIntl();

    const roomTypes: Array<RoomOptionItem> = React.useMemo(
        () => [
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_ChambreDressing' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Bureau' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Salon' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_SalleManger' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Cuisine' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_WC' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.CoPro, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_SalleEau' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Veranda' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_HallEntree' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.CoPro, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Couloir' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.CoPro, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Garage' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_TerasseBalconCouvert' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_TerasseBalconNonCouvert' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_BordPiscinePlat' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.Appartement],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Jardin' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.CoPro, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_LocalProIndividuel' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_PartieCommuneCoPro' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
                unitType: [UnitType.CoPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_LocalProCollectif' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_ChambreHotel' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Boutique' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_LieuTresFrequente' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Industrie' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Evenementiel' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
                unitType: [UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Ascenseur' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.CoPro, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_AutresPartiesCommunes' }),
                localisation: [RoomLocalisation.Inside],
                unitType: [UnitType.CoPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Cour' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.Appartement, UnitType.CoPro, UnitType.LocalPro],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_ToitTerrasseAvecEtanchéite' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.LocalPro, UnitType.CoPro],
            },
        ],
        [i18n]
    );

    const getRoomTypes = (localisation?: RoomLocalisation, unitType?: UnitType): Array<SelectItemOption> => {
        const results = roomTypes.filter(
            (x) =>
                x.localisation &&
                localisation &&
                x.localisation.includes(localisation) &&
                x.unitType &&
                unitType &&
                x.unitType.includes(unitType)
        );
        return results;
    };

    const roomFloors: Array<SelectItemOption> = React.useMemo(
        () => [
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomFloorList_RDC' }),
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomFloorList_Etage1a3' }),
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomFloorList_4etPlus' }),
            },
        ],
        [i18n]
    );

    const getRoomCurrentFloorWithoutFlooring = (
        localisation: RoomLocalisation,
        type: string
    ): Array<SelectItemOption> => {
        const roomTypes: Array<RoomOptionItem> = [
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_Ciment' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
                ignoredRoomType: [i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Jardin' })],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_PlancherBois' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_BétonPeintCire' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_ChapeSeche' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_Terre' }),
                localisation: [RoomLocalisation.Outside],
                ignoredRoomType: [
                    i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_TerasseBalconCouvert' }),
                    i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_TerasseBalconNonCouvert' }),
                    i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_ToitTerrasseAvecEtanchéite' }),
                ],
            },
            {
                value: RoomTypeValue.BETON_FONDATION_DALLE_BRUTE,
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_Beton' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
                ignoredRoomType: [i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Jardin' })],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_GraviersEnrobes' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.LocalPro, UnitType.CoPro],
                ignoredRoomType: [
                    i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_TerasseBalconCouvert' }),
                    i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_TerasseBalconNonCouvert' }),
                ],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_StructureBoisMetal' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.LocalPro, UnitType.CoPro],
                ignoredRoomType: [i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_ToitTerrasseAvecEtanchéite' })],
            },
        ];
        return roomTypes
            .filter((x) => x.localisation.includes(localisation))
            .filter((x) => !x.ignoredRoomType?.includes(type));
    };

    const getRoomCurrentFloorWithFlooring = (localisation: RoomLocalisation): Array<SelectItemOption> => {
        const roomTyps: Array<RoomOptionItem> = [
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_ParquetColle' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_ParquetStratifieNonColle' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_Carrelage' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_Moquette' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_RevetementPlastique' }),
                localisation: [RoomLocalisation.Inside, RoomLocalisation.Outside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomCurrentFlooringList_TerasseBoisComposite' }),
                localisation: [RoomLocalisation.Outside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomTypeList_Paves' }),
                localisation: [RoomLocalisation.Outside],
                unitType: [UnitType.Maison, UnitType.LocalPro, UnitType.CoPro],
            },
        ];
        return roomTyps.filter((x) => x.localisation.includes(localisation));
    };

    const PasDeChauffage = i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_PasDeChauffage' });

    const getRoomHeatingTypes = (localisation: RoomLocalisation): Array<SelectItemOption> => {
        const roomTypes: Array<RoomOptionItem> = [
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_BasseTemperatureEau' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_BasseTemperatureAccumulation' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_HauteTemperature' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_ElectriqueRayonnant' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_Reversible' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_SystemeRapporte' }),
                localisation: [RoomLocalisation.Inside],
            },
            {
                label: i18n.formatMessage({ id: 'RoomItemDialog_RoomHeatingTypeList_UnknownType' }),
                localisation: [RoomLocalisation.Inside],
            },
        ];
        return roomTypes.filter((x) => localisation && x.localisation.includes(localisation));
    };

    return {
        roomFloors,
        getRoomTypes,
        getRoomCurrentFloorWithoutFlooring,
        getRoomCurrentFloorWithFlooring,
        getRoomHeatingTypes,
        PasDeChauffage,
    };
};
