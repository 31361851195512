import React from 'react';
import { GestureEvent } from '../../../../../Events/withEvent';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useGestureContext, useMultiTouch } from '../../../Context/GestureContext';
import { useMapProvider } from '../../../Context/MapContext';
import { RoomItemFactory } from '../../../Helpers/BaseRoomItemFactory';
import { MapConstants, MapMode } from '../../../MapConstants';
import { IRoomItem } from '../../../Models/IRoomItem';
import { useMenuContext } from '../MenuContext/MenuContext';
import { useMenuDeleteDialog } from '../MenuContext/MenuDeleteDialog';

export const useRoomItemGestureController = (item: IRoomItem) => {
    const map = useMapProvider();
    const menuContext = useMenuContext();
    const gestureContext = useGestureContext();
    const isMobile = useIsMobile();

    const multiTouch = useMultiTouch();

    const onPressDown = React.useCallback(
        (event: GestureEvent) => {
            if (!multiTouch.handleMultiTouch(event as React.TouchEvent)) {
                if (!menuContext.showMenuContext && !MapConstants.mode) {
                    gestureContext.update({ isPressDown: true, roomItemId: item.roomItemId });
                    map.update({
                        values: { panZoomDisabled: true, busy: true },
                        logs: { event: 'useRoomItemGestureController onPressDown' },
                    });
                    MapConstants.mode = MapMode.MODE_TYPE_MOVE_ROOM_ITEM;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menuContext.showMenuContext]
    );

    const onMouseUp = React.useCallback(
        (event: GestureEvent) => {
            if (!isMobile && gestureContext.getState().isPressDown && !menuContext.showMenuContext) {
                menuContext.show();
                const { selectedRoomItemId } = map.getState();
                if (selectedRoomItemId !== item.roomItemId) {
                    map.setPanel({
                        values: { selectedRoomItemId: item.roomItemId, panZoomDisabled: false, busy: false },
                    });
                }
                gestureContext.clear();
                MapConstants.mode = undefined;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menuContext.showMenuContext, isMobile]
    );

    const onTouchEnd = React.useCallback(
        (event: GestureEvent) => {
            event.preventDefault(); //To prevent mouse events being fired
            if (menuContext.showMenuContext) {
                menuContext.close();
                event.stopPropagation(); //To prevent TouchEndController being fired
            }
            if (gestureContext.getState().isPressDown && !menuContext.showMenuContext) {
                menuContext.show();
                map.setPanel({ values: { panZoomDisabled: false, busy: false } });
                gestureContext.clear();
                MapConstants.mode = undefined;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menuContext.showMenuContext]
    );

    //* ACTIONS
    const onMove = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            const { roomItems = [] } = map.getState();
            const updatedroomItems = roomItems.map((r) => {
                if (r.roomItemId === item.roomItemId) r.isMoving = true;
                return r;
            });
            map.setPanel({ values: { roomItems: updatedroomItems, panZoomDisabled: true, busy: true } });
            gestureContext.update({ roomItemId: item.roomItemId });
            MapConstants.mode = MapMode.MODE_TYPE_MOVE_ROOM_ITEM;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteDialog = useMenuDeleteDialog({
        confirmDelete: () => map.deleteRoomItem(item.roomItemId!),
    });

    const onModify = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            map.setPanel({ values: { selectedRoomItemId: item.roomItemId } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDuplicate = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            const roomItems = map.getState().roomItems || [];
            const baseRoomItem = roomItems.find((r) => r.roomItemId === item.roomItemId)!;

            const name = RoomItemFactory.getNewName(baseRoomItem, roomItems);
            const newRoomItem = RoomItemFactory.createRoomItem({ ...baseRoomItem, name });
            const updatedRoomItems = [...roomItems, newRoomItem];
            map.setPanel({
                values: { roomItems: updatedRoomItems, panZoomDisabled: true, busy: true },
                mapModified: true,
            });
            gestureContext.update({ roomItemId: newRoomItem.roomItemId });
            MapConstants.mode = MapMode.MODE_TYPE_ROOM_ITEM_PLACING;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDelete = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            deleteDialog.requestDelete();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        onMove,
        onModify,
        onDuplicate,
        onDelete,
        gestures: {
            onMouseDown: onPressDown,
            onMouseUp,
            onTouchStart: onPressDown,
            onTouchEnd,
        },
        menuContext,
        deleteDialog,
    };
};
