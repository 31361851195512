//! COPY of polylabel.js from lib/polylabel
import TinyQueue from 'tinyqueue';

type Cell = {
    x: number;      //* cell center x
    y: number;      //* cell center y
    h: number;      //* half the cell size
    d: number;      //* distance from cell center to polygon
    max: number;    //* max distance to polygon within a cell
}

type Centroid = { x: number; y: number; distance: number }

export class PolylabelUtils {
    public static polylabel(polygon: any, precision: number, debug?: boolean): Centroid {
        precision = precision || 1.0;

        //* find the bounding box of the outer ring
        let minX, minY, maxX, maxY;
        for (let i = 0; i < polygon[0].length; i++) {
            const p = polygon[0][i];
            if (!i || p[0] < minX) minX = p[0];
            if (!i || p[1] < minY) minY = p[1];
            if (!i || p[0] > maxX) maxX = p[0];
            if (!i || p[1] > maxY) maxY = p[1];
        }

        const width = maxX - minX;
        const height = maxY - minY;
        const cellSize = Math.min(width, height);
        let h = cellSize / 2;

        if (cellSize === 0) {
            const degeneratePoleOfInaccessibility: Centroid = { x: minX, y: minY, distance: 0 };
            return degeneratePoleOfInaccessibility;
        }

        //* a priority queue of cells in order of their "potential" (max distance to polygon)
        const cellQueue = new TinyQueue(undefined, this.compareMax);

        //* cover polygon with initial cells
        for (let x = minX; x < maxX; x += cellSize) {
            for (let y = minY; y < maxY; y += cellSize) {
                cellQueue.push(this.toCell(x + h, y + h, h, polygon));
            }
        }

        //* take centroid as the first best guess
        let bestCell = this.getCentroidCell(polygon);

        //* second guess: bounding box centroid
        const bboxCell = this.toCell(minX + width / 2, minY + height / 2, 0, polygon);
        if (bboxCell.d > bestCell.d) {
            bestCell = bboxCell;
        }

        let numProbes = cellQueue.length;

        while (cellQueue.length) {
            //* pick the most promising cell from the queue
            const cell = cellQueue.pop();
            if (cell) {

                //* update the best cell if we found a better one
                if (cell.d > bestCell.d) {
                    bestCell = cell;
                    debug && console.log('found best %f after %d probes', Math.round(1e4 * cell.d) / 1e4, numProbes);
                }

                //* do not drill down further if there's no chance of a better solution
                if (cell.max - bestCell.d <= precision) {
                    continue;
                }

                //* split the cell into four cells
                h = cell.h / 2;
                cellQueue.push(this.toCell(cell.x - h, cell.y - h, h, polygon));
                cellQueue.push(this.toCell(cell.x + h, cell.y - h, h, polygon));
                cellQueue.push(this.toCell(cell.x - h, cell.y + h, h, polygon));
                cellQueue.push(this.toCell(cell.x + h, cell.y + h, h, polygon));
                numProbes += 4;
            }
        }

        debug && console.log('num probes & best distance ', { numProbes, distance: bestCell.d });

        const poleOfInaccessibility: Centroid = { x: bestCell.x, y: bestCell.y, distance: bestCell.d }
        return poleOfInaccessibility;
    }

    public static compareMax = (a: Cell, b: Cell) => b.max - a.max;

    public static toCell(x: number, y: number, h: number, polygon: any): Cell {
        const d = this.pointToPolygonDist(x, y, polygon); // distance from cell center to polygon
        return { x, y, h, d, max: d + h * Math.SQRT2 }
    }

    // signed distance from point to polygon outline (negative if point is outside)
    public static pointToPolygonDist(x: number, y: number, polygon: Array<any>) {
        let inside = false;
        let minDistSq = Infinity;

        for (let k = 0; k < polygon.length; k++) {
            const ring = polygon[k];

            let i = 0;
            const len = ring.length;
            let j = len - 1;
            for (; i < len; j = i++) {
                const a = ring[i];
                const b = ring[j];

                if (((a[1] > y) !== (b[1] > y)) &&
                    (x < (b[0] - a[0]) * (y - a[1]) / (b[1] - a[1]) + a[0])) inside = !inside;

                minDistSq = Math.min(minDistSq, this.getSegDistSq(x, y, a, b));
            }
        }

        return minDistSq === 0 ? 0 : (inside ? 1 : -1) * Math.sqrt(minDistSq);
    }

    //* get polygon centroid
    public static getCentroidCell(polygon: Array<any>) {
        let area = 0;
        let x = 0;
        let y = 0;
        const points = polygon[0];

        let i = 0;
        const len = points.length;
        let j = len - 1;
        for (; i < len; j = i++) {
            const a = points[i];
            const b = points[j];
            const f = a[0] * b[1] - b[0] * a[1];
            x += (a[0] + b[0]) * f;
            y += (a[1] + b[1]) * f;
            area += f * 3;
        }
        if (area === 0) {
            return this.toCell(points[0][0], points[0][1], 0, polygon);
        }
        return this.toCell(x / area, y / area, 0, polygon);
    }

    //* get squared distance from a point to a segment
    public static getSegDistSq(px: number, py: number, a: Array<number>, b: Array<number>) {

        let x = a[0];
        let y = a[1];
        let dx = b[0] - x;
        let dy = b[1] - y;

        if (dx !== 0 || dy !== 0) {

            const t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);

            if (t > 1) {
                x = b[0];
                y = b[1];
            } else if (t > 0) {
                x += dx * t;
                y += dy * t;
            }
        }

        dx = px - x;
        dy = py - y;

        return dx * dx + dy * dy;
    }
}
