import { ApiRequest } from '../XHR/ApiRequest';
import { ApiUrls } from '../../Models/ApiUrls';
import { ComputeQuotationsResponse, CreateQuotationResponse, GetQuotationDto } from '../../Models/Quotations/Quotations';
import { GetLocalitiesResponse, LocalityDto } from '../../Models/TravelExpenses/TravelExpenseDto';
import { UserSummary } from '../../Models/Users/Me';


export interface GetQuotationPdfBodyRequest {
    address_locality?: LocalityDto;
    address?: string;
    user?: UserSummary;
}

export class QuotationsApi {

    public static createQuotation(projetId: string, bodyRequest: GetQuotationDto): Promise<CreateQuotationResponse> {
        return ApiRequest.post<CreateQuotationResponse>(ApiUrls.CREATE_QUOTATIONS(projetId), bodyRequest);
    }

    public static computeQuotation(bodyRequest: GetQuotationDto): Promise<ComputeQuotationsResponse> {
        return ApiRequest.post<ComputeQuotationsResponse>(ApiUrls.GET_QUOTATION, bodyRequest);
    }

    public static getQuotationPdf(projetId: string, configurationId: string, bodyRequest: GetQuotationPdfBodyRequest): Promise<ArrayBuffer> {
        //! C'EST BIEN UN POST
        return ApiRequest.post(ApiUrls.GET_QUOTATION_PDF(projetId, configurationId), bodyRequest, {
            noCheckError: true,
            responseType: 'arraybuffer',
        });
    }

    public static checkAddress(address: string): Promise<GetLocalitiesResponse> {
        return ApiRequest.get<GetLocalitiesResponse>(ApiUrls.CHECK_ADDRESS(address));
    }
}
