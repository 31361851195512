import * as jsts from 'jsts';
import { BoundingBox, CoordPoint } from './Types';

export type JstsPolygon = {
    getCoordinates: () => Array<CoordPoint>;
    contains: (polygon: JstsPolygon) => boolean;
    difference: (polygon: JstsPolygon) => JstsPolygon;
    intersection: (polygon: JstsPolygon) => JstsPolygon;
    getArea(): number;
    getNumGeometries(): number;
    getGeometryN(i: number): JstsPolygon;
    buffer: (size: number, nodeNumber?: number, parameter?: number) => JstsPolygon;
    union: (polygon: JstsPolygon) => JstsPolygon;

    _geometries: Array<JstsPolygon>;
};

export class JstsUtils {
    public static createJstsPolygonFromBoundingBox = (bb: BoundingBox): JstsPolygon => {
        const geometryFactory = new jsts.geom.GeometryFactory();
        const polygon = [
            new jsts.geom.Coordinate(bb.xMin, bb.yMin),
            new jsts.geom.Coordinate(bb.xMax, bb.yMin),
            new jsts.geom.Coordinate(bb.xMax, bb.yMax),
            new jsts.geom.Coordinate(bb.xMin, bb.yMax),
            new jsts.geom.Coordinate(bb.xMin, bb.yMin),
        ];
        return geometryFactory.createPolygon(polygon) as never as JstsPolygon;
    };

    public static GeometryFactory = () => {
        return new jsts.geom.GeometryFactory();
    };

    public static toJstsCoordPoint = (x: number, y: number): CoordPoint => {
        return new jsts.geom.Coordinate(x, y);
    };

    public static createJstsPolygon = (
        points: Array<CoordPoint> = [],
        makeCyclic?: boolean,
        geometryFactory?: any
    ): JstsPolygon => {
        if (!geometryFactory) {
            geometryFactory = this.GeometryFactory();
        }
        const geoInputPolyA = this.vectorCoordinates2JTS(points);
        if (makeCyclic) {
            geoInputPolyA.push(geoInputPolyA[0]);
        }
        return geometryFactory.createPolygon(geoInputPolyA);
    };

    public static createPolygon = (polygon: Array<CoordPoint>) => {
        const geometryFactory = this.GeometryFactory();
        return geometryFactory.createPolygon(polygon);
    };

    public static vectorCoordinates2JTS(polygon: Array<CoordPoint> = []) {
        const coordinates: Array<CoordPoint> = [];
        for (let i = 0; i < polygon.length; i++) {
            coordinates.push(new jsts.geom.Coordinate(polygon[i].x, polygon[i].y));
        }
        return coordinates;
    }

    public static within = (point: CoordPoint, polygon: number[][][]) => {
        const geometryFactory = JstsUtils.GeometryFactory();
        const geojsonReader = new jsts.io.GeoJSONReader(geometryFactory);
        const jPoint = { type: 'Point', coordinates: [point.x, point.y] };
        const jstsPoint = geojsonReader.read(jPoint);
        const jstsPoly = geojsonReader.read({ type: 'Polygon', coordinates: polygon });
        const isWithin: boolean = jstsPoint.within(jstsPoly);
        return isWithin;
    };

    public static buffer = (geoInput: Array<CoordPoint>, spacing: number) => {
        const geometryFactory = JstsUtils.GeometryFactory();
        const shell = geometryFactory.createPolygon(geoInput);
        const bufferParameters = new jsts.operation.buffer.BufferParameters();
        bufferParameters.setJoinStyle(jsts.operation.buffer.BufferParameters.CAP_FLAT);
        const buffer = new jsts.operation.buffer.BufferOp(shell, bufferParameters);
        return buffer.getResultGeometry(spacing);
    };
}
