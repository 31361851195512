import React from 'react';

import { useDebugContext } from '../../../../../Web/Context/Debug/DebugContext';
import { Toast } from '../../../../../Web/Services/ToastService';
import { useMapContext } from '../../../Context/MapContext';
import { LaizeCalculatorResult } from '../Laize';
import { LaizeV3DebugDialog } from './LaizeV3DebugDialog';

export enum LaizeDebugRequest {
    Debug = 'LaizeDebug',

    Horizontal = 'LaizeDebugHorizontal',
    Vertical = 'LaizeDebugVertical',

    TopToBottom = 'LaizeDebugTopToBottom',
    BottomToTop = 'LaizeDebugBottomToTop',
    LeftToRight = 'LaizeDebugLeftToRight',
    RightToLeft = 'LaizeDebugRightToLeft',
}

export type LaizeDebugController = {
    show?: boolean;
    laizeDebugResult?: Array<LaizeCalculatorResult>;
    clear: (params?: LaizeDebugParams) => void;

    laizeDebugDialog: (params?: LaizeDebugParams) => void;
    openLaizeDialog: boolean;

    LaizeDebugDialogV3: React.ComponentType<any>;

    menuOpened?: boolean;
    closeMenu: () => void;
    openMenu: () => void;

    closeDebug: () => void;
    showDebugControl?: boolean;
};

export type LaizeDebugParams = { showToast?: boolean };

export const useLaizeDebugController = (): LaizeDebugController => {
    const debugContext = useDebugContext();
    const [showDebugControl, setShowDebug] = React.useState<boolean>(false);
    const [menuOpened, setMenuOpen] = React.useState<boolean>(false);

    const openMenu = () => setMenuOpen(true);
    const closeMenu = () => setMenuOpen(false);

    const [openLaizeDialog, setOpenLaizeDialog] = React.useState<boolean>(false);
    const map = useMapContext();

    const { rooms = [], laizeDebugResults = [] } = map.state;
    const show = Boolean(rooms?.length) && (Boolean(laizeDebugResults.length) || Boolean(openLaizeDialog));

    React.useEffect(() => {
        if (show && !showDebugControl) {
            setShowDebug(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const closeDebug = () => {
        setShowDebug(false);
        setMenuOpen(false);
        debugContext.update({ floorPlanLaizeTraceEnabled: false });
        map.update({
            values: { laizeDebugResults: [] },
            logs: { event: 'useLaizeDebugController closeDebug' },
        });
    };

    const clear = ({ showToast }: LaizeDebugParams = {}) => {
        map.update({
            values: { laizeDebugResults: [] },
            logs: { event: 'useLaizeDebugController clear', showToast },
        });
        setOpenLaizeDialog(false);
        if (showToast) {
            Toast.showInfo({ title: 'DEBUG CALCUL EN LÉS', content: 'RÉINITIALIZATION' });
        }
    };

    const laizeDebugDialog = (params: LaizeDebugParams = {}) => {
        clear(params);
        setOpenLaizeDialog(true);
    };

    const LaizeDebugDialogV3 = () => <LaizeV3DebugDialog onClose={clear} />;

    return {
        show,
        laizeDebugResult: map.state.laizeDebugResults,
        clear,

        openLaizeDialog,
        laizeDebugDialog,

        LaizeDebugDialogV3,

        menuOpened,
        openMenu,
        closeMenu,

        showDebugControl,
        closeDebug,
    };
};
