import { Logger } from '../../../../../../../Errors/Logger';
import { QuestionsHelper } from '../../../../../../../Helpers/QuestionsHelper';
import { QuoteQuestionUtils } from '../../../../../../../Helpers/QuoteQuestionUtils';
import { QuestionItem, QuestionType } from '../../../../../../../Models/Questions/QuestionDto';
import { useIncompatibilityContext } from '../../../../../../../Plugins/FloorPlan/Context/IncompatibilityContext';
import { IMapItem } from '../../../../../../../Plugins/FloorPlan/Models/IMapItem';
import { IRoom } from '../../../../../../../Plugins/FloorPlan/Models/IRoom';
import { QuoteStep } from '../../../../../../../Types/QuoteStep';
import { QuestionPrepare } from '../../../../Helpers/QuestionPrepare';
import { Scroller, useScroller } from '../../../../Helpers/Scroller';
import { useRoomEdit } from './RoomEditController';

export const useRoomSections = (step: QuoteStep) => {
    const scroller = useScroller();
    const { state: room, update, getState } = useRoomEdit();

    const roomSections = useOpenNextSection<IRoom>({
        getItemId: (room) => room.roomId!,
        updateItem: (item) => update({ values: item, logs: { event: 'openSection', step } }),
    });

    const openNextSection = (step: QuoteStep) => {
        const room = getState();
        Logger.log('openNextSection', { step, room })
        roomSections.openSection(room, step);
    };

    const arePreviousStepsCompleted = () => {
        const room = getState();
        const previousCompleted = QuestionPrepare.allPreviousStepCompleted(room, step);
        const result = previousCompleted && room.completionStatus?.infosCompleted;
        return result;
    };

    const setExpanded = (open: boolean) => {
        const room = getState();
        let questions: Array<QuestionItem> | undefined = [];
        if (step === QuoteStep.Pose) {
            room.openSections = { questionsPose: open };
            questions = room.questionsPose?.questions;
        }
        if (step === QuoteStep.Support) {
            room.openSections = { questionsSupport: open };
            questions = room.questionsSupport?.questions;
        }
        if (step === QuoteStep.Preparation) {
            room.openSections = { questionsPreparation: open };
            questions = room.questionsPreparation?.questions;
        }
        if (step === QuoteStep.Finitions) {
            room.openSections = { questionsFinition: open };
            questions = room.questionsFinition?.questions;
        }
        if (step === QuoteStep.Service) {
            room.openSections = { questionsServices: open };
            questions = room.questionsServices?.questions;
        }
        update({ values: room, logs: { event: 'setExpanded', open, step } });

        const nextQuestionToScroll = QuoteQuestionUtils.getFirstVisibleNotValidQuestion(questions);
        if (nextQuestionToScroll) {
            scroller.scrollToQuestion(
                Scroller.ID,
                room.roomId!,
                nextQuestionToScroll,
                questions,
                Scroller.ScrollerDelay
            );
        }
    };

    const expanded = QuestionPrepare.isStepQuestionsOpened(room, step);

    return { expanded, setExpanded, openNextSection, arePreviousStepsCompleted };
};

type OpenNextSectionParams<T extends Partial<IMapItem>> = {
    getItemId: (item: T) => string;
    updateItem: (item: T) => void;
};
export const useOpenNextSection = <T extends Partial<IMapItem>>({
    getItemId,
    updateItem,
}: OpenNextSectionParams<T>) => {
    const scroller = useScroller();
    const incompatibility = useIncompatibilityContext();

    const openSection = (item: T, fromStep: QuoteStep, noScroll?: boolean) => {
        const nextStep = QuestionPrepare.getNextStep(item, fromStep);

        Logger.log('openSection', { fromStep, nextStep, item });

        if (nextStep?.step === QuoteStep.Pose) {
            item.openSections = { questionsPose: true };
        }
        if (nextStep?.step === QuoteStep.Support) {
            item.openSections = { questionsSupport: true };
        }
        if (nextStep?.step === QuoteStep.Preparation) {
            item.openSections = { questionsPreparation: true };
        }
        if (nextStep?.step === QuoteStep.Finitions) {
            item.openSections = { questionsFinition: true };
        }
        if (nextStep?.step === QuoteStep.Service) {
            item.openSections = { questionsServices: true };
        }
        if (!nextStep) {
            item.openSections = { amenagementsSection: true };
            scroller.scollToBottom(Scroller.ID);
        }

        updateItem(item);

        if (nextStep) {
            const questions = nextStep.questions!;

            const findFirstIncompatibility = QuestionsHelper.findFirstIncompatibility(
                questions.filter((q) => q.showQuestion && q.type === QuestionType.Respiration)
            );
            if (findFirstIncompatibility.findIncompatibility) {
                incompatibility.showIncompatibilityDialog(item.roomId!, findFirstIncompatibility);
            } else if (!noScroll) {
                const questionToScroll = QuestionsHelper.firstQuestionwithNoResponse(nextStep.questions);
                if (questionToScroll) {
                    scroller.scrollToQuestion(
                        Scroller.ID,
                        getItemId(item),
                        questionToScroll,
                        questions,
                        Scroller.ScrollerDelay
                    );
                }
            }
        }
    };

    return { openSection };
};
