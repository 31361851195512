import { Logger } from '../../../../Errors/Logger';
import { RoomInfos } from '../../../../Web/Pages/Quote/Components/Pannels/Rooms/Controllers/RoomInfosValues';
import { FlooringDirection } from '../../Components/Laizes/Laize';
import { MapContextState } from '../../Context/MapContext';
import { MapExportV2 } from './MapExportV2';
import { MapExportV3 } from './MapExportV3';
import { MapExportV31 } from './MapExportV31';
import { MapExportV32 } from './MapExportV32';

export type ApiMapState = {
    state: MapContextState;
    flooringDirectionByRoomId?: { [roomId: string]: FlooringDirection };
};
export type ExportMap = { version?: number };

export class MapExportHelper {
    public static toApiJson = (state: MapContextState) => MapExportV32.toApiJson(state);

    public static fromApiJsonToState = (dataJson: string, roomInfos: RoomInfos): ApiMapState => {
        const exportMap: ExportMap = JSON.parse(dataJson);
        Logger.log('MapExportHelper : fromApiJsonToState', exportMap);
        if (exportMap.version === MapExportV32.VERSION) {
            return MapExportV32.fromApiJsonToState(exportMap, roomInfos);
        }
        if (exportMap.version === MapExportV31.VERSION) {
            return MapExportV31.fromApiJsonToState(exportMap);
        }
        if (exportMap.version === MapExportV3.VERSION) {
            return MapExportV3.fromApiJsonToState(exportMap);
        }
        return MapExportV2.fromApiJsonToState(exportMap);
    };
}
