import React from 'react';

import { useMapContextState } from '../../../Context/MapContext';
import { IRoomItem } from '../../../Models/IRoomItem';
import { PlacingRoomItem } from './BoxBindRoomItem';

type BoxRoomItemsProps = { readonly?: boolean; roomId?: string; roomItems?: Array<IRoomItem> };
export const MapBoxRoomItems = ({ readonly, roomId }: BoxRoomItemsProps) => {
    const { roomItems = [] } = useMapContextState();
    return <RawBoxRoomItems roomItems={roomItems} readonly={readonly} roomId={roomId} />;
};

export const RawBoxRoomItems = ({ readonly, roomId, roomItems = [] }: BoxRoomItemsProps) => {
    return (
        <React.Fragment>
            {roomItems
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .map((roomItem) => (
                    <BoxRoomItem key={roomItem.roomItemId} item={roomItem} readonly={readonly} />
                ))}
        </React.Fragment>
    );
};

type BoxRoomItemProps = { item: IRoomItem; readonly?: boolean };
export const BoxRoomItem = ({ item, readonly }: BoxRoomItemProps) => {
    return (
        <React.Fragment>
            <PlacingRoomItem key={item.roomItemId} item={item} readonly={readonly} />
        </React.Fragment>
    );
};
