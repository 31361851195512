import { styled } from '@material-ui/core';
import React from 'react';

import { GestureEvent } from '../../../../../Events/withEvent';
import * as Icons from './ContextIconMenu';
import { I18n } from '../../../../../Locales/I18nService';

type MenuItemProps = {
    onClick?: (event?: GestureEvent) => void;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    hasWhiteHoverStroke?: boolean;
};

export const Modify = (props: MenuItemProps) => {
    return <BaseMenuItem icon={<Icons.IconModify />} title={I18n.get('MapContextMenu_Modify')} {...props} />;
};

export const Move = (props: MenuItemProps) => {
    return (
        <BaseMenuItem
            {...props}
            icon={<Icons.IconMove />}
            title={I18n.get('MapContextMenu_Move')}
            hasWhiteHoverStroke
        />
    );
};

export const Duplicate = (props: MenuItemProps) => {
    return (
        <BaseMenuItem
            {...props}
            icon={<Icons.IconDuplicate />}
            title={I18n.get('MapContextMenu_Duplicate')}
            hasWhiteHoverStroke
        />
    );
};

export const AddOpening = (props: MenuItemProps) => {
    return <BaseMenuItem {...props} icon={<Icons.IconAddOpenig />} title={I18n.get('MapContextMenu_Add')} />;
};

export const Delete = (props: MenuItemProps) => {
    return (
        <BaseMenuItem
            {...props}
            icon={<Icons.IconDelete />}
            title={I18n.get('MapContextMenu_Delete')}
            hasWhiteHoverStroke
        />
    );
};

//#region //* BASE LIST ITEM

const BaseMenuItem = ({ onClick, icon, title, hasWhiteHoverStroke }: MenuItemProps) => {
    return (
        <Container
            onClick={onClick}
            onTouchStart={onClick}
            className={hasWhiteHoverStroke ? 'whiteHoverStroke' : undefined}>
            <IconContainer>{icon}</IconContainer>
            <Title>{title}</Title>
        </Container>
    );
};

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: 42,
    color: '#000000',
    '@media (hover)': {
        '&:hover': { background: '#13123A', color: '#FFFFFF' },
        '&:hover.whiteHoverStroke': { stroke: '#FFFFFF' },
    },
});

const IconContainer = styled('div')({ width: 42, display: 'flex', justifyContent: 'center' });

const Title = styled('div')({
    fontFamily: 'Avenir',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '14px',
});

export const Divider = styled('div')({ margin: '0px 9px', borderTop: '1px solid #EFEFEF' });

//#endregion
