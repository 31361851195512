import { makeStyles, styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { Theme } from '@material-ui/core';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useMapContextState } from '../../../Context/MapContext';
import { MapConstants, MapMode } from '../../../MapConstants';
import { IRoomItem, RoomItemType } from '../../../Models/IRoomItem';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import * as ContextMenuItem from '../MenuContext/ContextMenuItems';
import { useRoomItemGestureController } from './RoomItemGestureController';
import * as IconFactory from './RoomItemIconFactory';
import {
    RoomItemDefaultColor,
    RoomItemSelectedColor,
    RoomitemStrokeColor as RoomItemStrokeColor,
} from './RoomItemIconFactory';
import { YesNoChoiceDialog } from '../../../../../Web/Pages/Projects/Components/ChoiceDialog/YesNoChoiceDialog';

type PlacingRoomItemProps = { item: IRoomItem; readonly?: boolean };
export const PlacingRoomItem = ({ item, readonly }: PlacingRoomItemProps) => {
    const position = item.graphTranslation!;
    const containerPosition = `translate(${position.x},${position.y})`;

    const selected = useMapContextState().selectedRoomItemId === item.roomItemId;
    const { onMove, onDuplicate, onModify, onDelete, menuContext, deleteDialog, gestures } =
        useRoomItemGestureController(item);
    const isMobile = useIsMobile();
    const classes = useStyles({ isMoving: item.isMoving, selected, mode: MapConstants.mode, readonly, isMobile });
    const gesturesToUse = readonly ? {} : gestures;
    const readonlyStyle: CSSProperties = { fill: RoomItemDefaultColor, stroke: RoomItemStrokeColor };
    return (
        <GroupMovable id={RoomMove.roomItemId(item.roomId!)} className={RoomMove.class(item.roomId!)}>
            <Group
                style={readonly ? readonlyStyle : {}}
                className={classes.root}
                transform={containerPosition}
                ref={menuContext.menuAnchorEl}
                {...gesturesToUse}>
                <polygon
                    points={item.coords!.map((p) => `${p.x},${p.y}`).join(' ')}
                    strokeWidth={3}
                    strokeDasharray="12 4"
                />
                <g>
                    <circle r={23} fill="white" />
                    <g className="RoomItemPicto">
                        {item.type === RoomItemType.ShowerBathtub && <IconFactory.ShowerBathtub />}
                        {item.type === RoomItemType.FixedFurniture && <IconFactory.FixedFurniture />}
                        {item.type === RoomItemType.Cupboard && <IconFactory.Cupboard />}
                        {item.type === RoomItemType.KitchenFurniture && <IconFactory.KitchenFurniture />}
                        {item.type === RoomItemType.Trap && <IconFactory.Trap />}
                        {item.type === RoomItemType.Fireplace && <IconFactory.Fireplace />}
                        {item.type === RoomItemType.Opening && <IconFactory.Opening />}
                        {item.type === RoomItemType.Stairs && <IconFactory.Stairs />}
                        {item.type === RoomItemType.Divider && <IconFactory.Divider />}
                        {item.type === RoomItemType.HeavyElement && <IconFactory.HeavyElement />}
                        {item.type === RoomItemType.SewerPlate && <IconFactory.SewerPlate />}
                    </g>
                    {!readonly && <IconFactory.Moving />}
                </g>
                <menuContext.PopupMenu>
                    <ContextMenuItem.Modify onClick={onModify} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Move onClick={onMove} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Duplicate onClick={onDuplicate} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Delete onClick={onDelete} />
                </menuContext.PopupMenu>
            </Group>
            {deleteDialog.showDelete && (
                <YesNoChoiceDialog
                    title="êtes -vous sûr ?"
                    subtitle={`Supprimer l'aménagement "${item.name}"`}
                    onClickYes={deleteDialog.onDelete}
                    onClickNo={deleteDialog.close}
                    onClose={deleteDialog.close}
                    enableCloseOutside={false}
                    noHighlight
                />
            )}
        </GroupMovable>
    );
};

const GroupMovable = styled('g')({});
const Group = styled('g')({ cursor: 'pointer', stroke: '#18335e' });

interface IStyleProps {
    isMoving?: boolean;
    selected?: boolean;
    mode?: string;
    readonly?: boolean;
    isMobile?: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>({
    root: (props: IStyleProps) => ({
        cursor: props.readonly ? 'default' : 'move',
        '& .RoomItemMoving': { display: props.isMoving ? 'inline' : 'none' },
        '& .RoomItemPicto': { display: props.isMoving ? 'none' : 'inline' },
        '&:hover':
            props.mode !== MapMode.MODE_TYPE_ROOM_ITEM_PLACING && !props.readonly && !props.isMobile
                ? {
                      '& .RoomItemMoving': { display: 'inline' },
                      '& .RoomItemPicto': { display: 'none' },
                      fill: RoomItemSelectedColor,
                  }
                : {},
        fill: props.isMoving ? RoomItemSelectedColor : props.selected ? RoomItemSelectedColor : RoomItemDefaultColor,
        stroke: RoomItemStrokeColor,
    }),
});
