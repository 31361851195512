import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { I18n } from '../../../../Locales/I18nService';
import { StringKey } from '../../../../Locales/fr';
import { Assets } from '../../../assets/Assets';

export enum TagType {
    ToDo = 'ToDo',
    Done = 'Done',
    Error = 'Error',
}
type TagLabelProps = { type: TagType; style?: CSSProperties };

export const TagLabel = ({ type, style }: TagLabelProps) => {
    const tagStyles = useTagStyle(type);
    return (
        <Container style={{ ...tagStyles, ...style }}>
            {type === TagType.Error && <Assets.ErrorIcon style={{ marginRight: 4 }} />}
            <Text>{I18n.get(`TagType_${type}` as StringKey)}</Text>
        </Container>
    );
};

const useTagStyle = (type: TagType) => {
    const style: CSSProperties = {};
    switch (type) {
        case TagType.ToDo:
            style.color = '#000000';
            style.background = '#E5A033';
            break;
        case TagType.Done:
            style.color = 'black';
            style.background = '#46DBA9';
            break;
        case TagType.Error:
            style.color = '#FF453A';
            style.background = '#ff453a19';
            break;
    }
    return style;
};

const Container = styled('div')({
    height: 21,
    minWidth: 80,
    fontWeight: 700,
    fontSize: 9,
    lineHeight: '12px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Text = styled('span')({
    paddingTop: 1,
});
