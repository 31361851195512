import * as React from 'react';
import { FlooringDirectionProps } from './FlooringDirectionAssets';
export const LameHorizontal = ({ props }: { props: FlooringDirectionProps }) => {
    const { fillColor = '#13123A', svgProps } = props;
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48 48H0V0h48v48Zm-2.4-2.4V2.4H2.4v43.2h43.2Z"
                fill={fillColor}
            />
            <path
                d="M31.58 24.972 25.752 30.8a.877.877 0 0 1-.624.258.877.877 0 0 1-.625-.258l-.53-.53a.894.894 0 0 1 0-1.256l3.4-3.407h-10.8a.862.862 0 0 1-.872-.869v-.748c0-.487.385-.907.872-.907h10.84l-3.438-3.426a.864.864 0 0 1-.258-.619.87.87 0 0 1 .258-.621l.53-.528a.876.876 0 0 1 .624-.257c.236 0 .458.092.624.258l5.828 5.828c.167.167.259.39.258.627 0 .237-.09.46-.258.627Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.6 38.4H2.4v2.4h43.2v-2.4Zm-24.56-7.2H2.4v2.4h20.897a3.879 3.879 0 0 1-.914-.678l-.53-.53a3.874 3.874 0 0 1-.813-1.192Zm5.542-4.8h3.57l1.428-1.428a.877.877 0 0 0 .258-.627.884.884 0 0 0-.068-.345H15.7v.738c0 .488.385.869.872.869h10.802l-.792.793Zm-13.51 0H2.4V24h10.3v.738c0 .598.134 1.16.372 1.662Zm7.785-8.4H2.4v-2.4h20.16c-.06.053-.119.108-.176.166l-.002.002-.53.527A3.874 3.874 0 0 0 20.858 18Zm6.837-2.4h17.905V18H30.105l-2.231-2.231a3.861 3.861 0 0 0-.18-.169ZM25.862 18h-1.47l.111-.111a.876.876 0 0 1 .625-.257c.232 0 .45.089.615.25l.004.003.005.005.11.11Zm8.961 6H45.6v2.4H34.252c.064-.103.123-.208.177-.317h.244l-.166-.165A3.888 3.888 0 0 0 34.823 24Zm.015.346v.007l-1.907-.005-.005-.006 1.912-.004v.008ZM29.595 31.2l-1.721 1.721a3.875 3.875 0 0 1-.915.68h18.64v-2.4H29.595ZM2.399 8.4h43.2v2.4H2.4V8.4Z"
                fill={fillColor}
            />
        </svg>
    );
};
