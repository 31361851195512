import SAT from 'sat';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { JstsUtils } from '../JstsUtils';
import { RoomCollision } from '../SAT/SATUtils';
import { CoordPoint } from '../Types';
import { RoomCollisionsUtils } from './CollisionsUtils';
export class CollisionRoomUtils {
    public static testAllCollisions(rooms: Array<IRoom>) {
        let collision = false;
        rooms.forEach((room) => (collision = Boolean(this.testCollisionWithOtherRooms(rooms, room))));
        return collision;
    }

    public static testCollisionWithOtherRooms(rooms: Array<IRoom>, room: IRoom): CoordPoint | undefined {
        for (const currentRoom of rooms.filter((x) => x.roomId !== room.roomId)) {
            const overlap = this.testCollision(currentRoom.collisions, room.collisions);
            if (overlap && (overlap.x !== 0 || overlap.y !== 0)) {
                return overlap;
            }
        }
        return undefined;
    }

    public static testCollision(satPolygonA: Array<RoomCollision> = [], satPolygonB: Array<RoomCollision> = []) {
        const totalOverlap = { x: 0, y: 0 };
        for (const subPolygonA of satPolygonA) {
            for (const subPolygonB of satPolygonB) {
                const response = new SAT.Response();
                const result = SAT.testPolygonPolygon(
                    subPolygonA as never as SAT.Polygon,
                    subPolygonB as never as SAT.Polygon,
                    response
                );
                if (result) {
                    if (totalOverlap.x === 0) {
                        totalOverlap.x += response.overlapV.x;
                    }
                    if (totalOverlap.y === 0) {
                        totalOverlap.y += response.overlapV.y;
                    }
                }
            }
        }
        return totalOverlap;
    }

    public static testAllRoomItemsAreInsideRoom(rooms: Array<IRoom>, roomItems: Array<IRoomItem>) {
        for (const roomItem of roomItems) {
            const room = rooms.find((x) => x.roomId === roomItem.roomId);

            if (
                !this.testIfPolygonAContainedInPolygonB(
                    roomItem.coordsReal!.slice(0, -1),
                    room?.coords!.slice(0, -1)
                ) &&
                !roomItem.isMoveableOutside
            ) {
                return false;
            }
        }
        return true;
    }

    public static testIfPolygonAContainedInPolygonB(polyA: Array<CoordPoint> = [], polyB: Array<CoordPoint> = []) {
        const geometryFactory = JstsUtils.GeometryFactory();

        const geoInputPolyA = JstsUtils.vectorCoordinates2JTS(polyA);
        geoInputPolyA.push(geoInputPolyA[0]);
        const shellPolyA = geometryFactory.createPolygon(geoInputPolyA);

        const geoInputPolyB = JstsUtils.vectorCoordinates2JTS(polyB);
        geoInputPolyB.push(geoInputPolyB[0]);
        const shellPolyB = geometryFactory.createPolygon(geoInputPolyB);

        return shellPolyB.contains(shellPolyA);
    }

    public static testCollisionWithOtherRoomItems(
        roomItems: Array<IRoomItem>,
        collisions: Array<RoomCollision> = [],
        roomItemId: string,
        roomId: string
    ) {
        let totalOverlap = undefined;
        for (const roomItem of roomItems) {
            if (roomItem.roomItemId !== roomItemId && roomItem.roomId === roomId) {
                console.log(
                    'roomItem(' + roomItem.collisions![0].points[0].x + ';' + roomItem.collisions![0].points[0].y + ')'
                );
                console.log('collision(' + collisions[0].points[0].x + ';' + collisions[0].points[0].y + ')');

                const overlap = this.testCollision(roomItem.collisions, collisions);
                if (overlap && (overlap.x !== 0 || overlap.y !== 0)) {
                    if (totalOverlap === undefined) {
                        totalOverlap = { x: 0, y: 0 };
                    }
                    totalOverlap.x += overlap.x;
                    totalOverlap.y += overlap.y;
                }
            }
        }
        return totalOverlap;
    }

    public static testIfPolygonAContainedIn(polyA: Array<CoordPoint>, rooms: Array<IRoom>) {
        const geometryFactory = JstsUtils.GeometryFactory();

        const geoInputPolyA = JstsUtils.vectorCoordinates2JTS(polyA);
        geoInputPolyA.push(geoInputPolyA[0]);

        const shellPolyA = geometryFactory.createPolygon(geoInputPolyA);
        for (const room of rooms) {
            const geoInputRoom = JstsUtils.vectorCoordinates2JTS(room.coords);
            geoInputRoom.push(geoInputRoom[0]);

            const shellRoom = geometryFactory.createPolygon(geoInputRoom);
            if (shellRoom.contains(shellPolyA)) {
                return room.roomId;
            }
            // var roomCollision = new SAT.Polygon();
            // roomCollision.setPoints(room.coords);
            // // for (const subPolygon of room.coords) {
            // var response = new SAT.Response();
            // roomCollision.exclusion
            // if (SAT.testPolygonPolygon(polyA, roomCollision, response)) {
            //     return response.aInB;
            // }
            // }
        }
        return undefined;
    }

    public static refreshAllCollisionPolygons(rooms: Array<IRoom>) {
        for (const room of rooms) {
            room.collisions = RoomCollisionsUtils.getCollisionPolygon(
                room.collisions,
                room.walls!.map((x) => x.start),
                room.shape
            );
        }
    }
}
