import React, { ReactElement } from 'react';

import { CreateOrUpdateProjectDialog } from '../Components/Views/Dialogs/CreateOrUpdateProjectDialog/CreateOrUpdateProjectDialog';
import { CreateProjectDialog } from '../Components/Views/Dialogs/CreateProjectDialog/CreateProjectDialog';
import { StmacLoginDialog } from '../Pages/Auth/StmacLoginDialog';
import { SingleChoiceDialog } from '../Pages/Projects/Components/ChoiceDialog/SingleChoiceDialog';
import { YesNoChoiceDialog } from '../Pages/Projects/Components/ChoiceDialog/YesNoChoiceDialog';
import { EditInputDialog } from '../Pages/Projects/Components/EditInputDialog';

//#region //* TYPES

export type DialogAction = () => void | Promise<void>;

export enum DialogStyle {
    Default,
    Danger,
}

export interface DialogContainer {
    showDialog: (dialog: React.ReactNode) => void;
    destroyDialog: () => void;
}

export interface ConfirmDialogParameters {
    leftAction?: React.ReactNode;
    rightAction?: React.ReactNode;
    title: string;
    subtitle?: string;
    body?: string;
    onClickYes: (params: ValidateActionParams) => void;
    onClickNo?: DialogAction;
    onClosed?: DialogAction;
    styles?: { rightAction?: React.CSSProperties };
    noHighlight?: boolean;
    yesHighlight?: boolean;
}

export interface CreateOrSaveProjectParams {
    projectName: string;
    setSaving: (saving: boolean) => void;
    closeDialog: (afterClose?: () => void) => void;
}

type CreateProjectParams = {
    onValidate: (params: CreateOrSaveProjectParams) => void;
    onClosed?: DialogAction;
    cancelDisabled?: boolean;
};

type CreateOrUpdateProjectParams = {
    currentProjectName: string;
    updateCurrentProject: (params: CreateOrSaveProjectParams) => void;
    createNewProject: (params: CreateOrSaveProjectParams) => void;
    onClosed?: DialogAction;
    cancelDisabled?: boolean;
};

export interface EditInputDialogParams {
    title: React.ReactNode;
    description?: React.ReactNode;
    subtitle?: React.ReactNode;
    placeholder?: string;
    initialValue?: string;
    onValidate: (params: InputValidateActionParams) => void;
}

export interface ValidateActionParams {
    setValidating: (busy: boolean) => void;
    closeDialog: (afterClose?: () => void) => void;
}

export interface InputValidateActionParams {
    input: string;
    setValidating: (busy: boolean) => void;
    closeDialog: (afterClose?: () => void) => void;
}

export interface ShowStmacLoginDialogParams {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    afterLoginSuccess: () => void;
    onClosed?: () => void;
    closeDisabled?: boolean;
}

export interface ShowDeadEndErrorDialogParams {
    title: string;
    body: string;
    labelChoice: string;
    onClickChoice: (params: ValidateActionParams) => void;
    onClosed?: DialogAction;
}

//#endregion

let dialogContainer: DialogContainer | null = null;

const internalShowDialog = (content: ReactElement) => {
    dialogContainer?.showDialog(content);
};

const closeDialog = (actionToDo?: Function) => {
    if (dialogContainer !== null) {
        dialogContainer.destroyDialog();
        actionToDo?.();
    }
};
export class DialogService {
    //#region //* OPEN AND CLOSE

    public static setDialogContainer = (container: DialogContainer): void => {
        if (dialogContainer) {
            dialogContainer.destroyDialog();
        }
        dialogContainer = container;
    };

    //#endregion

    //#region //* CREATE OR UPDATE PROJECT

    public static showCreateProjectDialog = ({ cancelDisabled, onValidate, onClosed }: CreateProjectParams): void => {
        internalShowDialog(
            <CreateProjectDialog
                open={true}
                validateAction={(projectName: string, setSaving: (saving: boolean) => void) => {
                    onValidate({ projectName, setSaving, closeDialog });
                }}
                onClose={() => closeDialog(onClosed)}
                cancelDisabled={cancelDisabled}
            />
        );
    };

    public static showCreateOrUpdateProjectDialog = ({
        currentProjectName,
        updateCurrentProject,
        createNewProject,
        onClosed,
        cancelDisabled,
    }: CreateOrUpdateProjectParams): void => {
        internalShowDialog(
            <CreateOrUpdateProjectDialog
                currentProjectName={currentProjectName}
                updateCurrentProjectAction={(setSaving: (saving: boolean) => void) => {
                    updateCurrentProject({
                        projectName: currentProjectName,
                        setSaving,
                        closeDialog,
                    });
                }}
                createNewProjectAction={(projectName: string, setSaving: (saving: boolean) => void) => {
                    createNewProject({ projectName, setSaving, closeDialog });
                }}
                onClose={() => closeDialog(onClosed)}
                cancelDisabled={cancelDisabled}
            />
        );
    };

    //#endregion //* CREATE OR UPDATE PROJECT

    public static showYesNoChoiceDialog = ({
        title,
        body,
        subtitle,
        leftAction,
        rightAction,
        onClickYes,
        onClickNo,
        onClosed,
        styles,
        yesHighlight = false,
        noHighlight = true,
    }: ConfirmDialogParameters): void => {
        internalShowDialog(
            <YesNoChoiceDialog
                leftAction={leftAction}
                rightAction={rightAction}
                open={true}
                title={title}
                subtitle={subtitle}
                body={body}
                onClickYes={(setValidating) => {
                    onClickYes({ setValidating, closeDialog });
                }}
                onClickNo={() => closeDialog(onClickNo)}
                onClose={() => closeDialog(onClosed)}
                styles={styles}
                yesHighlight={yesHighlight}
                noHighlight={noHighlight}
            />
        );
    };

    //* TODO: to remove when review ProjectDetailsPage
    public static showEditInputDialog = ({
        title,
        subtitle,
        description,
        initialValue,
        placeholder,
        onValidate,
    }: EditInputDialogParams): void => {
        internalShowDialog(
            <EditInputDialog
                open={true}
                title={title}
                subtitle={subtitle}
                description={description}
                initialValue={initialValue}
                placeholder={placeholder}
                onValidate={(input, setValidating) => {
                    onValidate({
                        input,
                        setValidating,
                        closeDialog: (afterClose) => closeDialog(afterClose),
                    });
                }}
                onClose={() => closeDialog()}
            />
        );
    };

    public static showStmacLoginDialog = ({
        title,
        subtitle,
        afterLoginSuccess,
        onClosed,
        closeDisabled,
    }: ShowStmacLoginDialogParams): void => {
        internalShowDialog(
            <StmacLoginDialog
                open={true}
                title={title}
                subtitle={subtitle}
                onConnected={() => closeDialog(afterLoginSuccess)}
                onClose={() => closeDialog(onClosed)}
                closeDisabled={closeDisabled}
            />
        );
    };

    public static showDeadEndErrorDialog = ({
        title,
        body,
        labelChoice,
        onClickChoice,
        onClosed,
    }: ShowDeadEndErrorDialogParams): void => {
        internalShowDialog(
            <SingleChoiceDialog
                title={title}
                body={body}
                labelChoice={labelChoice}
                onClickChoice={(setValidating) => {
                    onClickChoice({ setValidating, closeDialog });
                }}
                onClose={() => closeDialog(onClosed)}
            />
        );
    };
}
