import { EvaluationContext, QuestionContext } from '../../../../Helpers/QuestionContextHelper';
import { QuestionItem } from '../../../../Models/Questions/QuestionDto';
import { TypeDeLocalName, UnitType } from '../../../../Plugins/FloorPlan/Models/IRoom';

export const useHabitationContextController = (habitationQuestions: Array<QuestionItem> = []) => {
    const getContextWithEvaluations = (): QuestionContext => {
        const lastHabitationQuestionContext = habitationQuestions[habitationQuestions.length - 1]?.context;
        return lastHabitationQuestionContext ?? {};
    };

    const getUnitType = () => {
        const context = getContextWithEvaluations();
        const evaluations: EvaluationContext = context?.evaluations ?? {};
        const unitType: UnitType = evaluations[TypeDeLocalName];
        return unitType;
    };

    return { getContextWithEvaluations, getUnitType };
};
