export enum QuoteStep {
    Infos = 'Infos',                //* (par pièce, ouvertures et autres éléments)
    Tva = 'Tva',                    //* (par pièce)
    Produit = 'Produit',                //* (par pièce)
    Pose = 'Pose',                  //* (par pièce)
    Support = 'Support',            //* (par pièce)
    Preparation = 'Preparation',    //* (par pièce)
    Finitions = 'Finitions',        //* (par pièce, ouvertures et autres éléments)
    Service = 'Services',           //* (par pièce, ouvertures et autres éléments)
}

