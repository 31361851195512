import { Dialog } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { GestureEvent } from '../../../Events/withEvent';

import { ThemeKeys } from 'react-json-view';
import { JSONViewer } from '../../Components/Templates/Wrappers/JSONViewer/JSONViewer';
import { ButtonClose } from '../../Components/Widgets/ButtonClose';
import { useIsMobile } from '../PlatformContext/PlatformContext';
import { DebugColorContants } from './DebugControl';

type DebugDialogProps = {
    title: React.ReactNode;
    data: any;
    onClose: (e: GestureEvent) => void;
    theme?: DebugDialogStyle;
    styles?: { header?: CSSProperties; body?: CSSProperties };
    collapsed?: number | boolean;
};
export const DebugDialog: React.FC<DebugDialogProps> = ({
    title,
    data = {},
    onClose,
    styles: styleProps,
    children,
    collapsed,
    theme = DebugDialogStyles.ApathyInverted,
}) => {
    const headerStyle: CSSProperties = {
        background: DebugColorContants.PANEL_CONTROL,
        color: DebugColorContants.COLOR,
        ...styleProps?.header,
    };

    const bodyStyle: CSSProperties = { ...theme.styles?.body, ...styleProps?.body };

    return (
        <Dialog open PaperProps={{ style: PaperStyle }} fullScreen={useIsMobile()} onClose={onClose}>
            <Content>
                <Header style={headerStyle}>
                    <Title>{title}</Title>
                    <ButtonClose
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose(e);
                        }}
                        style={{ top: 0, right: 16, bottom: 0, margin: 'auto', width: 40, height: 40 }}>
                        <CloseIcon style={{ width: 14, height: 14, fill: '#FFFFFF' }} />
                    </ButtonClose>
                </Header>
                <Body style={bodyStyle}>
                    {children || (
                        <JSONViewer
                            data={data}
                            theme={theme.theme}
                            styles={{ root: { flex: 1, overflowY: 'scroll' } }}
                            collapsed={collapsed}
                        />
                    )}
                </Body>
            </Content>
        </Dialog>
    );
};
const Content = styled('div')({ display: 'flex', flexDirection: 'column', height: '100%' });
const Header = styled('div')({
    position: 'relative',
    width: '100%',
    minHeight: 50,
    display: 'flex',
    fontWeight: 700,
    alignItems: 'center',
    fontSize: 18,
    padding: 24,
});
const Title = styled('div')({ marginRight: 32 });

const Body = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    background: 'rgb(3, 26, 22)', //*color from JsonViewer theme : apathy
});
const PaperStyle: CSSProperties = {
    position: 'relative',
    width: '100%',
    minWidth: 'calc(100% - 128px)',
    height: '100%',
    borderRadius: 0,
};
const CloseIcon = (props: React.SVGProps<any>) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#clip0_90_1362)">
                <path d="M1.68374 15C1.40721 15.0001 1.15789 14.8335 1.05206 14.578C0.946233 14.3226 1.00474 14.0285 1.2003 13.833L13.833 1.20025C14.1 0.933252 14.5329 0.933252 14.7999 1.20025C15.0669 1.46724 15.0669 1.90012 14.7999 2.16711L2.16717 14.7999C2.03907 14.9282 1.86509 15.0003 1.68374 15Z" />
                <path d="M14.3165 15C14.1352 15.0003 13.9612 14.9282 13.8331 14.7999L1.20037 2.16711C0.933374 1.90012 0.933374 1.46724 1.20037 1.20025C1.46736 0.933252 1.90024 0.933252 2.16724 1.20025L14.8 13.833C14.9955 14.0285 15.054 14.3226 14.9482 14.578C14.8424 14.8335 14.5931 15.0001 14.3165 15Z" />
            </g>
            <defs>
                <clipPath id="clip0_90_1362">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

type IDebugDialogStyles = { ApathyInverted: DebugDialogStyle };
export type DebugDialogStyle = {
    theme: ThemeKeys;
    styles?: { header?: CSSProperties; body?: CSSProperties };
};
export const DebugDialogStyles: IDebugDialogStyles = {
    ApathyInverted: {
        theme: 'apathy:inverted',
        //* color from JsonViewer theme : apathy:inverted
        styles: { body: { background: 'rgb(239, 255, 255)' } },
    },
};
