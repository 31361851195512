import './CreateProjectDialog.scss';

import { DialogActions } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { ButtonVariant, CustomButton } from '../../CustomButton/CustomButton';
import { CustomTextField } from '../../CustomTextField/CustomTextField';
import { GestureEvent } from '../../../../../Events/withEvent';
import { BaseDialog } from '../../../../../Plugins/FloorPlan/Widgets/BaseDialog';

export interface CreateProjectDialogProps {
    open: boolean;
    validateAction: (text: string, setSaving: (saving: boolean) => void) => void;
    onClose(): void;
    cancelDisabled?: boolean;
}

export const CreateProjectDialog = ({ open, validateAction, onClose, cancelDisabled }: CreateProjectDialogProps) => {
    const i18n: IntlShape = useIntl();

    const [inputText, setInputText] = React.useState('');

    const [saving, setIsSaving] = React.useState<boolean>();
    const setSaving = (saving: boolean) => setIsSaving(saving);

    const onSubmit = (e?: React.FormEvent | GestureEvent) => {
        e?.preventDefault();
        if (inputText) {
            validateAction(inputText, setSaving);
        }
    };

    const onCloseDialog = () => {
        if (!saving) {
            onClose();
        }
    };

    return (
        <BaseDialog
            onClose={cancelDisabled ? undefined : onCloseDialog}
            title={i18n.formatMessage({ id: 'COMMON_SAVE' })}>
            <form className="CreateProjectDialog-content" onSubmit={onSubmit}>
                <CustomTextField
                    styles={{ input: Input, label: Label }}
                    label={<FormattedMessage id="CreateProjectDialog_InputLabel" />}
                    placeholder={i18n.formatMessage({ id: 'CreateProjectDialog_InputPlaceholder' })}
                    value={inputText}
                    onChange={setInputText}
                    autoFocus
                />
                <DialogActions classes={{ root: 'CreateProjectDialog-btn-container' }}>
                    <CustomButton
                        variant={ButtonVariant.Blue}
                        title={<FormattedMessage id="COMMON_VALIDATE" />}
                        onClick={onSubmit}
                        classes={{ root: 'CreateProjectDialog-btn' }}
                        disabled={!inputText || saving}
                        isBusy={saving}
                    />
                </DialogActions>
            </form>
        </BaseDialog>
    );
};

const Input: CSSProperties = {
    borderRadius: 0,
    height: 44,
    padding: 0,
    paddingLeft: 16,
    paddingRight: 16,
    border: '1px solid #8E8E93',
    background: '#fff',
    fontFamily: 'Avenir',
    fontWeight: 400,
    fontSize: 14,
};
const Label: CSSProperties = {
    fontFamily: 'Avenir',
    fontWeight: 350,
    fontSize: 16,
};
