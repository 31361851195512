import * as React from 'react';
import { SVGProps } from 'react';
export const ClassiqueSelected = (props: SVGProps<SVGSVGElement>) => (
    <svg width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M2.422 45.61V2.41h43.2v43.2h-43.2Z" fill="#13123A" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.022 48.01v-48h48v48h-48Zm2.4-2.4h43.2V2.41h-43.2v43.2Z"
            fill="#13123A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.014 16.385H48v-1.6h-9.487V8.6H48V7H23.846V0h-1.5v7H0v1.6h9.015v6.185H0v1.6h21.348v7.483H0v1.6h9.015v6.185H0v1.6h21.348v6.185H0v1.6h9.015v6.971h1.5v-6.971h26.498v6.971h1.5v-6.971H48v-1.6H22.948v-6.185H48v-1.6h-9.487v-6.185H48v-1.6H24.014v-7.483Zm12.999 9.083H10.515v6.185h26.498v-6.185ZM10.515 8.6h26.498v6.185H10.515V8.6Z"
            fill="#fff"
        />
        <path
            d="M33.848 24.21c0 .65-.253 1.271-.716 1.734l-.001.001-6.26 6.259a2.442 2.442 0 0 1-1.73.716c-.65 0-1.27-.254-1.732-.715v-.001l-.568-.568a2.46 2.46 0 0 1 0-3.471l1.096-1.1h-7.986a2.426 2.426 0 0 1-2.436-2.432v-.804c0-1.274 1.009-2.474 2.436-2.474h8.013l-1.121-1.118-.003-.002a2.427 2.427 0 0 1-.715-1.725c0-.654.258-1.27.716-1.728l.001-.002.567-.565a2.44 2.44 0 0 1 1.732-.715c.65 0 1.27.256 1.731.717l-1.06 1.06 1.06-1.06 6.26 6.26c.462.462.717 1.083.716 1.733Zm0 0v-.003l-1.5.003 1.5.004v-.004Z"
            fill="#fff"
            stroke="#13123A"
            strokeWidth={3}
        />
    </svg>
);
