import React from 'react';

type LaizeViewContextValue = { expand: boolean; setExpand: (expand: boolean) => void };
export const LaizeViewContext = React.createContext<LaizeViewContextValue>({ expand: false, setExpand: () => {} });
export const useLaizeViewContext = () => React.useContext(LaizeViewContext);

export const LaizeViewProvider: React.FC = ({ children }) => {
    const [expand, setExpand] = React.useState<boolean>(false);
    return <LaizeViewContext.Provider value={{ expand, setExpand }}>{children}</LaizeViewContext.Provider>;
};
