import { ObjectUtils } from '../../../Helpers/ObjectUtils';
import { I18n } from '../../../Locales/I18nService';
import { MapConstants } from '../MapConstants';
import { IOpening, OpeningType } from '../Models/IOpening';
import { IdGenerator } from '../Utils/IdGenerator';

export class OpeningFactory {
    public static openingThickness = 12;
    public static getNewName = (baseOpening: IOpening, openings: Array<IOpening>) => {
        return OpeningFactory.isDefaultName(baseOpening)
            ? OpeningFactory.getDuplicateName(baseOpening, openings)
            : OpeningFactory.toUniqueName(baseOpening.type!, openings);
    };
    //#region //* NAME GENERATOR
    public static defaultName = (openingCount: number = 0) => `Ouverture ${openingCount + 1}`;
    public static isDefaultName = (opening: IOpening) => {
        const [name, num] = opening.name!.split(' ');
        return name === 'Ouverture' && Boolean(num.length) && Number.isFinite(parseInt(num));
    };
    public static getDuplicateName(opening: IOpening, openings: Array<IOpening>) {
        const openingNumbers = openings.filter(this.isDefaultName).map((o) => {
            const num = o.name!.split(' ')[1];
            return parseInt(num);
        });
        if (openingNumbers.length) {
            const name = opening.name!.split(' ')[0];
            const max = Math.max(...openingNumbers) + 1;
            return `${name} ${max}`;
        }
        return `${opening.name} 2`;
    }

    public static toUniqueName = (type: OpeningType, openings: Array<IOpening>) => {
        let baseName = '';
        //const baseName = I18n.get(`Opening_${type}` as StringKey);
        switch (type) {
            case OpeningType.ArmoredDoor:
                baseName = I18n.get('Opening_ArmoredDoor');
                break;
            case OpeningType.Door:
                baseName = I18n.get('Opening_Door');
                break;
            case OpeningType.FrenchDoor:
                baseName = I18n.get('Opening_FrenchDoor');
                break;
            case OpeningType.FrontDoor:
                baseName = I18n.get('Opening_FrontDoor');
                break;
            case OpeningType.PatioDoor:
                baseName = I18n.get('Opening_PatioDoor');
                break;
            case OpeningType.Opening:
                baseName = I18n.get('Opening_opening');
                break;
        }
        const toBaseName = () => {
            let wantedName = baseName;
            if (!openings.some((x) => x.name === wantedName)) {
                return wantedName;
            } else {
                let i = 2;
                wantedName = `${baseName} ${i}`;
                // eslint-disable-next-line no-loop-func
                while (openings.some((x) => x.name === wantedName)) {
                    i++;
                    wantedName = `${baseName} ${i}`;
                }
                return wantedName;
            }
        };
        return toBaseName();
    };

    //#endregion

    public static createOpening = ({ name, type, size, ...rest }: Partial<IOpening>): IOpening => {
        const thick = this.openingThickness;
        const placingOpening: IOpening = ObjectUtils.clone({
            ...rest,
            family: 'inWall',
            type,
            angle: 0,
            angleSign: 0,
            size,
            name,
            hinge: 'normal',
            thick,
            limit: [],
            scale: { x: 1, y: 1 },
            width: (size! / MapConstants.meter).toFixed(2),
            height: (thick / MapConstants.meter).toFixed(2),
            openingId: IdGenerator.generate(),
        });
        return placingOpening;
    };
}
