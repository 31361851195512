import * as React from 'react';
import { FlooringDirectionProps } from './FlooringDirectionAssets';
export const LaizeVertical = ({ props }: { props: FlooringDirectionProps }) => {
    const { fillColor = '#13123A', svgProps } = props;
    return (
        <svg width={49} height={48} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.206 45.185a7.504 7.504 0 0 0-.022-15.007h-20.29a10.287 10.287 0 0 1 3.238 7.503c0 2.957-1.244 5.623-3.237 7.504h20.31Zm-.045 2.812h.023c5.698 0 10.316-4.618 10.316-10.315 0-5.468-4.254-9.942-9.633-10.294V1.41c0-.777-.63-1.406-1.406-1.406H1.906C1.13.004.5.634.5 1.41v36.272c0 5.697 4.618 10.315 10.316 10.315H38.16ZM3.312 37.617v.091a7.504 7.504 0 0 0 7.503 7.477h.004a7.504 7.504 0 0 0 .044-15.007h-.066a7.504 7.504 0 0 0-7.485 7.44Zm0-7.015V2.816h32.743v24.55H10.816a10.287 10.287 0 0 0-7.504 3.236Zm9.256 7.08a1.752 1.752 0 1 1-3.504 0 1.752 1.752 0 0 1 3.504 0Zm-1.752 4.564a4.564 4.564 0 1 0 0-9.128 4.564 4.564 0 0 0 0 9.128ZM21.964 6.944l6.26 6.26a.942.942 0 0 1 .276.67.942.942 0 0 1-.276.671l-.569.569a.96.96 0 0 1-1.35 0l-3.66-3.652v11.602a.925.925 0 0 1-.932.936h-.804c-.523 0-.974-.413-.974-.936V11.42l-3.68 3.694a.928.928 0 0 1-.665.276.934.934 0 0 1-.667-.277l-.567-.568a.94.94 0 0 1-.276-.67c0-.255.099-.493.277-.671l6.26-6.26a.942.942 0 0 1 .673-.277c.255 0 .495.098.674.277Z"
                fill={fillColor}
            />
        </svg>
    );
};
