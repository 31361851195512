import { styled } from '@material-ui/core';
import React, { CSSProperties } from 'react';

import { GestureEvent } from '../../../../../Events/withEvent';
import { ImageProduitHelper } from '../../../../../Helpers/ImageProduitHelper';
import { ObjectUtils } from '../../../../../Helpers/ObjectUtils';
import { ProductHelper } from '../../../../../Helpers/ProductHelper';
import { I18n, Message } from '../../../../../Locales/I18nService';
import { Assets } from '../../../../../Web/assets/Assets';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useIsVendeur } from '../../../../../Web/Hooks/Vendeur/useVendeur';
import { RoomInfoIds } from '../../../../../Web/Pages/Quote/Components/Pannels/Rooms/Controllers/RoomInfosFocus';
import { FlooringDirectionIcon } from '../../../../../Web/Pages/Quote/Components/Pannels/Rooms/RoomDirection/RoomDirection';
import { Scroller } from '../../../../../Web/Pages/Quote/Helpers/Scroller';
import { TagType } from '../../../../../Web/Pages/Quote/Widgets/TagLabel';
import { ThemeRes } from '../../../../../Web/Themes/ThemeRes';
import { useGestureContext } from '../../../Context/GestureContext';
import { useIncompatibilityContext } from '../../../Context/IncompatibilityContext';
import { useMapContextState, useMapProvider } from '../../../Context/MapContext';
import { RoomFactory } from '../../../Helpers/BaseRoomFactory';
import { MapConstants, MapMode } from '../../../MapConstants';
import { IRoom } from '../../../Models/IRoom';
import { PointUtils } from '../../../Utils/PointUtils';
import { PolylabelUtils } from '../../../Utils/PolylabelUtils';
import { qSVG } from '../../../Utils/qSVG';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import { CreateRoomFactory } from '../../../Utils/Room/RoomUtils';
import { CoordPoint } from '../../../Utils/Types';
import * as ContextMenuItem from '../MenuContext/ContextMenuItems';
import { useMenuContext } from '../MenuContext/MenuContext';
import { useMenuDeleteDialog } from '../MenuContext/MenuDeleteDialog';
import { useMapZoomScale } from '../Walls/ZoomScaleComponents';
import { YesNoChoiceDialog } from '../../../../../Web/Pages/Projects/Components/ChoiceDialog/YesNoChoiceDialog';

const picto_edit_size = 16;

type BoxAreasProps = { readonly?: boolean; roomId?: string; rooms?: Array<IRoom>; selectedRoomId?: string };
export const MapBoxAreas = ({ readonly, roomId }: BoxAreasProps) => {
    const { rooms = [], selectedRoomId } = useMapContextState();
    return <RawBoxAreas rooms={rooms} readonly={readonly} selectedRoomId={selectedRoomId} roomId={roomId} />;
};

export const RawBoxAreas = ({ readonly, roomId, rooms = [], selectedRoomId }: BoxAreasProps) => {
    return (
        <React.Fragment>
            {rooms
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .map((room) => {
                    const centroid = PolylabelUtils.polylabel([room.coords?.map((p) => [p.x, p.y])], 1.0);
                    return (
                        <BoxAreaProvider key={room.roomId}>
                            <BoxArea
                                room={room}
                                centroid={centroid}
                                selected={room.roomId === selectedRoomId}
                                type={room.completionStatus?.completedType}
                                readonly={readonly}
                            />
                        </BoxAreaProvider>
                    );
                })}
        </React.Fragment>
    );
};

type BoxAreaProps = { room: IRoom; centroid: CoordPoint; selected?: boolean; type?: TagType; readonly?: boolean };

export const BoxArea = ({ room, centroid, selected, type = TagType.ToDo, readonly }: BoxAreaProps) => {
    const isVendeur = useIsVendeur();
    const boxAreaContext = useBoxAreaContext();
    const map = useMapProvider();
    const isMobile = useIsMobile();

    const showFlooringDirection = Boolean(
        isVendeur && room.flooringDirection && room.questionProduitPrincipal?.produitValue
    );
    const hasProductImage = Boolean(boxAreaContext.state?.hasImage);

    const hasImageContainer = hasProductImage || showFlooringDirection;
    //* NOTE 11.5 = 23/2, where 23 is EditIcon height and 10.5 = 21/2 where 21 = CompletionLabel height
    const nameHeight = 18;
    const completionHeight = readonly ? 0 : 20;
    const areaHeight = 18;
    const editIconHeight = readonly ? 0 : picto_edit_size;
    const center = {
        x: centroid.x,
        y:
            centroid.y -
            (hasImageContainer ? 40 : 0) -
            nameHeight / 2 -
            completionHeight / 2 -
            areaHeight / 2 -
            editIconHeight / 2,
    };

    const bbox: any = PointUtils.calculateBoundingBox({ points: room.coords });
    const width = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMax, y: bbox.yMin });
    const height = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMin, y: bbox.yMax });
    const minSize = (Math.min(width, height) * 100) / 60;
    const scale = minSize < 60 ? 0.2 : minSize < 120 ? 0.3 : minSize < 180 ? 0.5 : minSize < 220 ? 0.7 : 1;

    const imageContainer = { x: -20, y: 20 / 2 };
    const imagePosition: CoordPoint = { x: showFlooringDirection ? imageContainer.x - 20 : imageContainer.x, y: 0 };
    const flooringDirectionPosition: CoordPoint = {
        x: hasProductImage ? imageContainer.x + 20 + 8 : imageContainer.x,
        y: 0,
    };

    const GAP = 8;
    const nameLabelPosition: CoordPoint = { x: 0, y: hasImageContainer ? imageContainer.y + 40 + GAP : 0 };

    const completionLabelPosition: CoordPoint = readonly
        ? { x: 0, y: nameLabelPosition.y }
        : { x: 0, y: room.name ? nameLabelPosition.y + 20 : 0 };

    const surfaceAreaLabelPosition: CoordPoint = readonly
        ? { x: 0, y: nameLabelPosition.y + 20 / 2 + GAP } //!\\ 20 = nameLabelHeight
        : { x: 0, y: completionLabelPosition.y + 40 / 2 + GAP }; //!\\ 40 = completionHeight
    const iconPosition: CoordPoint = {
        x: 0,
        y: (room.surface_area ? surfaceAreaLabelPosition.y : completionLabelPosition.y) + GAP,
    };
    //15 = area heigth ; 12 = margin top;

    const openQuestionPose = () => {
        map.setPanel({
            values: {
                selectedRoomId: room.roomId,
                rooms: map.state.rooms?.map((x) => {
                    if (x.roomId === room.roomId) {
                        return { ...x, openSections: { questionsPose: true } };
                    }
                    return x;
                }),
            },
            afterUpdate: () => {
                setTimeout(() => {
                    Scroller.scrollToDiv(Scroller.ID, RoomInfoIds.FlooringDirection, isMobile);
                }, Scroller.ScrollerDelay);
            },
        });
    };

    const openQuestionProduit = () => {
        map.setPanel({
            values: {
                selectedRoomId: room.roomId,
                rooms: map.state.rooms?.map((x) => {
                    if (x.roomId === room.roomId) {
                        return { ...x, openSections: { infos: true } };
                    }
                    return x;
                }),
            },
            afterUpdate: () => {
                setTimeout(() => {
                    Scroller.scrollToDiv(Scroller.ID, RoomInfoIds.ProduitPrincipal, isMobile);
                }, Scroller.ScrollerDelay);
            },
        });
    };

    return (
        <g id={RoomMove.roomAreaId(room.roomId!)} className={RoomMove.class(room.roomId!)}>
            <g transform={`translate(${center.x},${center.y}) scale(${scale})`}>
                {!readonly && <RoomProductImage room={room} pos={imagePosition} onClick={openQuestionProduit} />}
                {showFlooringDirection && (
                    <GClickable onClick={openQuestionPose}>
                        <circle cx={25} cy={20} r={25} fill="transparent" />
                        <g
                            transform={`translate(${flooringDirectionPosition.x}, ${flooringDirectionPosition.y}) scale(0.75)`}>
                            <FlooringDirectionIcon
                                direction={room.flooringDirection!}
                                productType={ProductHelper.getProductType(room.questionProduitPrincipal!.produitValue)!}
                                props={{ fillColor: selected ? '#FFFFFF' : undefined }}
                            />
                        </g>
                    </GClickable>
                )}
                {room.name && (
                    <RoomNameLabel room={room} pos={nameLabelPosition} selected={selected} readonly={readonly} />
                )}
                {!readonly && (
                    <CompletionLabel type={type} room={room} pos={completionLabelPosition} selected={selected} />
                )}
                {room.surface_area && (
                    <RoomAreaLabel room={room} pos={surfaceAreaLabelPosition} selected={selected} readonly={readonly} />
                )}
                {readonly && room.questionProduitPrincipal?.produitValue?.code && (
                    <text
                        y={surfaceAreaLabelPosition.y + 20}
                        textAnchor="middle"
                        fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}>
                        {room.questionProduitPrincipal.produitValue.code}
                    </text>
                )}
                {!readonly && <EditIcon room={room} pos={iconPosition} selected={selected} />}
            </g>
        </g>
    );
};

const GClickable = styled('g')({ cursor: 'pointer' });
type WidgetProps = { room: IRoom; pos: CoordPoint; selected?: boolean; readonly?: boolean; onClick?: () => void };

const RoomNameLabel = ({ room, pos, selected, readonly }: WidgetProps) => {
    if (!pos) {
        return null;
    }

    const style: CSSProperties = {
        fontWeight: 800,
        fontSize: 14,
        fill: !readonly && selected ? '#FFFFFF' : '#13123A',
        pointerEvents: 'none',
        touchAction: 'none',
    };

    const label = similyStringEllipsis(room.name, 20);
    return (
        <text x={pos.x} y={pos.y} style={style} textAnchor="middle" fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}>
            {label}
            <title>{room.name}</title>
        </text>
    );
};

const CompletionLabel = ({ pos, type }: WidgetProps & { type: TagType }) => {
    if (!pos) {
        return null;
    }

    const backgroundStyle: CSSProperties = {
        pointerEvents: 'none',
    };

    const textStyle: CSSProperties = {
        fontWeight: 600,
        fontSize: '9.4px',
        textTransform: 'uppercase',
        pointerEvents: 'none',
    };

    switch (type) {
        case TagType.ToDo:
            textStyle.fill = '#000000';
            backgroundStyle.fill = '#E5A033';
            break;
        case TagType.Done:
            textStyle.fill = 'black';
            backgroundStyle.fill = '#46DBA9';
            break;
        case TagType.Error:
            textStyle.fill = '#FF453A';
            backgroundStyle.fill = '#ff453a19';
            break;
    }

    return (
        <g>
            {type === TagType.Error && <Assets.ErrorIcon attr={{ x: pos.x - 30, y: pos.y - 7 }} />}
            <rect style={backgroundStyle} width={80} height={21} x={pos.x - 80 / 2} y={pos.y - 21 / 2} />
            <text style={textStyle} textAnchor="middle" x={type === TagType.Error ? pos.x + 8 : pos.x} y={pos.y + 3}>
                {I18n.get(`TagType_${type}`)}
            </text>
        </g>
    );
};

const RoomAreaLabel = ({ room, pos, selected, readonly }: WidgetProps) => {
    const meter = MapConstants.meter;

    const toArea = (area: number) => (area / (meter * meter)).toFixed(2);
    const style: CSSProperties = {
        fontWeight: 400,
        fontSize: 14,
        fill: !readonly && selected ? '#FFFFFF' : '#13123A',
        pointerEvents: 'none',
        touchAction: 'none',
    };

    return (
        <text x={pos.x} y={pos.y} style={style} textAnchor="middle" fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}>
            {room.roomItems?.length! > 0 ? (
                <Message
                    id="Room_AreaWithoutItems"
                    values={{
                        area: toArea(room.area!),
                        areaWithoutRoomItems: toArea(room.areaWithoutRoomItems || room.area),
                    }}
                />
            ) : (
                <Message id="Room_Area" values={{ area: toArea(room.area!) }} />
            )}
        </text>
    );
};

const EditIcon = ({ room, pos }: WidgetProps) => {
    const map = useMapProvider();
    const menuContext = useMenuContext();
    const incompatibility = useIncompatibilityContext();
    const deleteDialog = useMenuDeleteDialog({
        confirmDelete: () => {
            map.deleteRoom(room.roomId!);
            incompatibility.reset(room.roomId);
        },
    });

    const onMove = React.useCallback(
        (e?: GestureEvent | undefined) => {
            menuContext.close();
            const centroid = PolylabelUtils.polylabel([room.coords?.map((p) => [p.x, p.y])], 1.0);
            map.update({
                values: {
                    roomMove: room.roomId,
                    roomMoveInit: { x1: centroid.x, y1: centroid.y, x2: centroid.x, y2: centroid.y },
                    panZoomDisabled: true,
                    busy: true,
                },
                logs: { event: 'EditIcon onMove', room },
            });
            MapConstants.mode = MapMode.MODE_TYPE_MOVE_ROOM;
            e?.stopPropagation();
            e?.preventDefault();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [room]
    );

    const onClickModify = React.useCallback(() => {
        if (!MapConstants.mode) {
            menuContext.close();
            map.setPanel({ values: { selectedRoomId: room.roomId } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickAddOpening = React.useCallback(() => {
        if (!MapConstants.mode) {
            menuContext.close();
            map.setPanel({ values: { selectAmenagementOpen: { roomId: room.roomId } } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDuplicate = React.useCallback(() => {
        if (!MapConstants.mode) {
            menuContext.close();
            const { walls = [], rooms = [], openings = [], roomItems = [] } = map.getState();
            const baseRoom = ObjectUtils.clone(rooms.find((r) => r.roomId === room.roomId))!;

            const name = RoomFactory.isDefaultName(baseRoom.name)
                ? RoomFactory.getDuplicateName(baseRoom, rooms)
                : RoomFactory.toUniqueName(rooms, baseRoom.roomType, baseRoom.roomFloor);

            const { room: newRoom } = CreateRoomFactory.duplicate(baseRoom, name, walls, openings, roomItems);
            map.addRooms({ rooms: [newRoom], openRoomPanel: true, mapModified: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRequestDelete = React.useCallback(() => {
        menuContext.close();
        deleteDialog.requestDelete();
        MapConstants.mode = undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gesture = useGestureContext();
    React.useEffect(() => {
        if (gesture.state.menuContextOpenRoomId === room.roomId) {
            menuContext.show();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gesture.state.menuContextOpenRoomId]);

    const isMoving = MapConstants.mode === MapMode.MODE_TYPE_MOVE_ROOM && map.state.roomMove === room.roomId;
    const scale = useMapZoomScale();
    return (
        <g
            ref={menuContext.menuAnchorEl}
            transform={`translate(${(pos.x - picto_edit_size / 2) * scale}, ${pos.y + picto_edit_size})`}
            style={{ pointerEvents: 'none', touchAction: 'none' }}>
            <MenuEditIconBackground />
            {isMoving ? <MovingIcon /> : <MenuEditIcon />}
            <menuContext.PopupMenu>
                <ContextMenuItem.Modify onClick={onClickModify} title={I18n.get('MapContextMenu_Complete')} />
                <ContextMenuItem.Divider />
                <ContextMenuItem.AddOpening onClick={onClickAddOpening} />
                <ContextMenuItem.Divider />
                <ContextMenuItem.Move onClick={onMove} />
                <ContextMenuItem.Divider />
                <ContextMenuItem.Duplicate onClick={onDuplicate} />
                <ContextMenuItem.Divider />
                <ContextMenuItem.Delete onClick={onRequestDelete} />
            </menuContext.PopupMenu>

            {deleteDialog.showDelete && (
                <YesNoChoiceDialog
                    title="êtes -vous sûr ?"
                    subtitle={I18n.format('MapContextMenu_DeleteRoom', { name: room.name })}
                    onClickYes={deleteDialog.onDelete}
                    onClickNo={() => deleteDialog.close('FROM_onClickNo')}
                    onClose={() => deleteDialog.close('FROM_onClose')}
                    enableCloseOutside={false}
                    noHighlight
                />
            )}
        </g>
    );
};

const MovingIcon = () => {
    const scale = useMapZoomScale();
    const t = scale * -2.6;
    return (
        <g transform={`translate(${t}, ${t}) scale(${scale})`} fill="#13123A">
            <Assets.IconMoveMapItem />
        </g>
    );
};

const MenuEditIconBackground = () => {
    const scale = useMapZoomScale();
    const attr: React.SVGAttributes<SVGCircleElement> = {
        cx: (scale * picto_edit_size) / 2,
        cy: (scale * picto_edit_size) / 2,
        r: scale * picto_edit_size,
        fill: '#FFFFFF',
    };
    return <circle {...attr} />;
};

const MenuEditIcon = () => {
    const scale = useMapZoomScale();
    return (
        <g transform={`translate(-1, -1) scale(${scale})`} fill="#13123A">
            <path d="M3.37984 13.4721C3.53984 13.4721 3.57184 13.4561 3.71584 13.4241L6.59584 12.8481C6.89984 12.7681 7.20384 12.6241 7.44384 12.3841L14.4198 5.40809C15.4918 4.33609 15.4918 2.49609 14.4198 1.42409L13.8278 0.800094C12.7558 -0.271906 10.8998 -0.271906 9.82784 0.800094L2.85184 7.79209C2.62784 8.01609 2.46784 8.33609 2.38784 8.64009L1.77984 11.5521C1.69984 12.0961 1.85984 12.6241 2.24384 13.0081C2.54784 13.3121 2.99584 13.4721 3.37984 13.4721ZM3.92384 8.94409L10.8998 1.95209C11.3638 1.48809 12.2118 1.48809 12.6598 1.95209L13.2678 2.56009C13.8118 3.10409 13.8118 3.87209 13.2678 4.40009L6.30784 11.3921L3.34784 11.8881L3.92384 8.94409Z" />
            <path d="M14.3553 14.4639H2.5473C2.0833 14.4639 1.7793 14.7679 1.7793 15.2319C1.7793 15.6959 2.1633 15.9999 2.5473 15.9999H14.2913C14.7553 15.9999 15.1393 15.6959 15.1393 15.2319C15.1233 14.7679 14.7393 14.4639 14.3553 14.4639Z" />
        </g>
    );
};

export const similyStringEllipsis = (text: string = '', length: number) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
};

const RoomProductImage = ({ pos, room, onClick }: WidgetProps) => {
    const productCode = room.questionProduitPrincipal?.produitValue?.code;
    const imageUrl = productCode ? ImageProduitHelper.toImageUrl(productCode, 40) : '';

    const boxArea = useBoxAreaContext();
    React.useEffect(() => {
        boxArea.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl]);

    if (!pos || !imageUrl || (boxArea.state?.imageLoaded && !boxArea.state?.hasImage)) {
        return null;
    }

    return (
        <g transform={`translate(${pos.x}, ${pos.y})`}>
            <mask id="productImage">
                <circle fill="white" cx={20} cy={20} r={20} />
            </mask>
            <GClickable mask="url(#productImage)" onClick={onClick}>
                <image
                    width={40}
                    height={40}
                    preserveAspectRatio="xMidYMid slice"
                    href={imageUrl}
                    onLoad={boxArea.onImageLoaded}
                    onError={() => boxArea.onImageLoadingFailed(imageUrl)}
                />
            </GClickable>
        </g>
    );
};

type BoxAreaContextState = { imageLoading?: boolean; imageLoaded?: boolean; hasImage?: boolean };
type BoxAreaContextValue = {
    state?: BoxAreaContextState;
    onImageLoaded: () => void;
    onImageLoadingFailed: (imageUrl: string) => void;
    reset: () => void;
};
const BoxAreaContext = React.createContext<BoxAreaContextValue>({
    onImageLoaded: () => {},
    onImageLoadingFailed: () => {},
    reset: () => {},
});

const useBoxAreaContext = () => React.useContext(BoxAreaContext);

const BoxAreaProvider: React.FC = ({ children }) => {
    const [state, setState] = React.useState<BoxAreaContextState>({ imageLoading: true });

    const reset = () => setState((prev) => ({ ...prev, imageLoaded: false, hasImage: false }));
    const onImageLoaded = () => {
        setState((prev) => ({ imageLoading: false, imageLoaded: true, hasImage: true }));
    };
    const onImageLoadingFailed = (imageUrl: string) => {
        setState((prev) => ({ imageLoading: false, imageLoaded: true, hasImage: false }));
        console.error('BoxAreaProvider loading failed', imageUrl);
    };

    return (
        <BoxAreaContext.Provider
            value={{
                state,
                onImageLoaded,
                onImageLoadingFailed,
                reset,
            }}>
            {children}
        </BoxAreaContext.Provider>
    );
};
