import * as React from 'react';
import { FlooringDirectionProps } from './FlooringDirectionAssets';
export const LameVertical = ({ props }: { props: FlooringDirectionProps }) => {
    const { fillColor = '#13123A', svgProps } = props;
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path stroke={fillColor} strokeWidth={2} d="M39 46V2M9 46V2" />
            <path
                d="m24.614 16.12 5.828 5.828a.877.877 0 0 1 .258.625.877.877 0 0 1-.258.624l-.529.53a.894.894 0 0 1-1.256 0l-3.408-3.4v10.801a.862.862 0 0 1-.868.872h-.748c-.487 0-.907-.385-.907-.872v-10.84l-3.427 3.438a.87.87 0 0 1-1.24 0l-.527-.529a.876.876 0 0 1-.257-.625c0-.236.091-.458.258-.624l5.828-5.828a.877.877 0 0 1 .626-.258c.238 0 .46.091.627.258Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 18.238V2h-2v18.311c.123-.17.26-.333.412-.485L17 18.238Zm0 8.364a3.884 3.884 0 0 1-1.062-.755l-.528-.529-.002-.002a3.888 3.888 0 0 1-.408-.481V46h2V26.602Zm8-13.607V2h-2v10.989a3.89 3.89 0 0 1 2 .006Zm0 3.51v15.24a.863.863 0 0 1-.62.255h-.747a.927.927 0 0 1-.633-.25V16.48l.36-.36a.877.877 0 0 1 .627-.258c.238 0 .46.091.627.258l.386.386Zm-2 18.442c.209.035.42.053.633.053h.748c.21 0 .418-.017.619-.049V46h-2V34.947Zm.988-22.085h.007l-.005 1.907-.005.005-.005-1.912h.008ZM33 20.347V2h-2v16.263l1.564 1.564c.161.161.307.336.436.52Zm0 4.451a3.882 3.882 0 0 1-.436.52l-.529.529a3.878 3.878 0 0 1-1.035.74V46h2V24.798Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48 0v48H0V0h48Zm-2.4 2.4H2.4v43.2h43.2V2.4Z"
                fill={fillColor}
            />
        </svg>
    );
};
