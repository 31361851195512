import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';
import { I18n, Message } from '../../../Locales/I18nService';
import { ThemeRes } from '../../../Web/Themes/ThemeRes';

export const DimensInput = ({ style, value = '', ...props }: StandardTextFieldProps) => {
    const classes = ThemeRes.createNumberStyles();
    return (
        <Input
            type="number"
            placeholder={I18n.get('Dimens_InputPlaceholder')}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <Unit>
                        <Message id="Dimens_DimensUnit" />
                    </Unit>
                ),
            }}
            value={value}
            inputProps={{ min: 0, style: { padding: 0 } }}
            className={classes.input}
            {...props}
            style={{
                boxShadow: props.error ? 'inset 0 0 0 1px  #FF3B30' : 'inset 0 0 0 1px transparent',
                ...style,
            }}
        />
    );
};
const Input = styled(TextField)({
    background: '#EFEFEF',
    height: 42,
    marginTop: 10,
    padding: '11px 11px 11px 13px',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
});
const Unit = styled('div')({ fontWeight: 400, fontSize: 16, lineHeight: '20px', color: '#000000' });
