import { Popover } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { GestureEvent } from '../../../../../Events/withEvent';
import { useGestureContext } from '../../../Context/GestureContext';
import { useUpdateMapContext } from '../../../Context/MapContext';

export const useMenuContext = () => {
    const menuAnchorEl = React.useRef(null);
    const [showMenuContext, setShowMenuContext] = React.useState<boolean>(false);

    const onClick = (e?: GestureEvent) => {
        e?.stopPropagation();
        setShowMenuContext((prev) => !prev);
    };
    const gestureContext = useGestureContext();

    const updateMap = useUpdateMapContext();

    const show = () => setShowMenuContext(true);
    const close = React.useCallback((e?: GestureEvent) => {
        e?.preventDefault();
        setShowMenuContext(false);
        gestureContext.clearMenuContext();
        updateMap({
            values: { roomMove: undefined, roomMoveInit: undefined },
            logs: { event: 'close MenuContext' },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const PopupMenu = React.useCallback(
        ({ children }: React.PropsWithChildren<any>) => {
            return (
                <Popover
                    open={showMenuContext}
                    anchorEl={menuAnchorEl.current}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    PaperProps={{ style: MenuContentPaperStyle }}
                    BackdropProps={{
                        style: { opacity: 0 },
                        onClick: close,
                        onTouchEnd: close,
                    }}
                    onClickCapture={() => {}}>
                    <Triangle />
                    <Container>{children}</Container>
                </Popover>
            );
        },
        [showMenuContext, close]
    );

    return { menuAnchorEl, showMenuContext, toggleShowMenu: onClick, PopupMenu, close, show };
};

export const MenuContentPaperStyle: CSSProperties = {
    padding: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
};

const Triangle = styled('div')({
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid white',
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: 125,
    backgroundColor: 'white',
    margin: '0 8px 8px 8px',
    boxShadow: '-4px 4px 4px rgba(0, 0, 0, 0.25)',
});
