import React from 'react';
import { GestureEvent } from '../../../../../Events/withEvent';
import { ObjectUtils } from '../../../../../Helpers/ObjectUtils';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useGestureContext, useMultiTouch } from '../../../Context/GestureContext';
import { useMapProvider } from '../../../Context/MapContext';
import { OpeningFactory } from '../../../Helpers/BaseOpeningFactory';
import { MapConstants, MapMode } from '../../../MapConstants';
import { IOpening } from '../../../Models/IOpening';
import { useMenuContext } from '../MenuContext/MenuContext';
import { useMenuDeleteDialog } from '../MenuContext/MenuDeleteDialog';

export const useOpeningGestureController = (item: IOpening) => {
    const map = useMapProvider();
    const menuContext = useMenuContext();
    const gestureContext = useGestureContext();
    const isMobile = useIsMobile();
    const multiTouch = useMultiTouch();

    const onPressDown = React.useCallback(
        (event: GestureEvent) => {
            if (!multiTouch.handleMultiTouch(event as React.TouchEvent)) {
                if (!menuContext.showMenuContext && !MapConstants.mode) {
                    gestureContext.update({ isPressDown: true, openingId: item.openingId });
                    const { openings = [] } = map.getState();
                    const updatedOpenings = openings.map((o) => {
                        if (o.openingId === item.openingId) o.isMoving = true;
                        return o;
                    });
                    const openingMoveInit = ObjectUtils.clone(openings.find((o) => o.openingId === item.openingId));
                    map.update({
                        values: { openingMoveInit, openings: updatedOpenings, panZoomDisabled: true, busy: true },
                        logs: { event: 'useOpeningGestureController onPressDown', openingMoveInit },
                    });
                    MapConstants.mode = MapMode.MODE_TYPE_OPENING_MOVE;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menuContext.showMenuContext]
    );

    const onMouseUp = React.useCallback(
        (event: GestureEvent) => {
            if (!isMobile && gestureContext.getState().isPressDown && !menuContext.showMenuContext) {
                menuContext.show();
                const { selectedOpeningId } = map.getState();
                if (selectedOpeningId !== item.openingId) {
                    map.setPanel({
                        values: { selectedOpeningId: item.openingId, panZoomDisabled: false, busy: false },
                    });
                }
                gestureContext.clear();
                MapConstants.mode = undefined;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menuContext.showMenuContext, isMobile]
    );

    const onTouchEnd = React.useCallback(
        (event: GestureEvent) => {
            event.preventDefault(); //To prevent mouse events being fired
            if (menuContext.showMenuContext) {
                menuContext.close();
                event.stopPropagation(); //To prevent TouchEndController being fired
            }
            if (gestureContext.getState().isPressDown && !menuContext.showMenuContext) {
                menuContext.show();
                map.setPanel({ values: { panZoomDisabled: false, busy: false } });
                gestureContext.clearMenuContext();
                MapConstants.mode = undefined;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menuContext.showMenuContext]
    );

    //* ACTIONS
    const onMove = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            const { openings = [] } = map.getState();
            const updatedOpenings = openings.map((o) => {
                if (o.openingId === item.openingId) o.isMoving = true;
                return o;
            });
            map.setPanel({ values: { openings: updatedOpenings, panZoomDisabled: true, busy: true } });
            gestureContext.update({ openingId: item.openingId });
            MapConstants.mode = MapMode.MODE_TYPE_OPENING_MOVE;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteDialog = useMenuDeleteDialog({
        confirmDelete: () => map.deleteOpening(item.openingId!),
    });

    const onModify = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            map.setPanel({ values: { selectedOpeningId: item.openingId } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDuplicate = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            const openings = map.getState().openings || [];
            const baseOpening = openings.find((o) => o.openingId === item.openingId)!;

            const name = OpeningFactory.getNewName(baseOpening, openings);
            const newOpening = OpeningFactory.createOpening({
                ...baseOpening,
                name,
                x: undefined,
                y: undefined,
                limit: [],
            });
            const updatedOpenings = [...openings, newOpening];
            map.setPanel({
                values: { openings: updatedOpenings, panZoomDisabled: true, busy: true },
                mapModified: true,
            });
            gestureContext.update({ openingId: newOpening.openingId });
            MapConstants.mode = MapMode.MODE_TYPE_OPENING_PLACING;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDelete = React.useCallback((event?: GestureEvent) => {
        if (!MapConstants.mode) {
            menuContext.close();
            deleteDialog.requestDelete();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        onMove,
        onModify,
        onDuplicate,
        onDelete,
        gestures: {
            onMouseDown: onPressDown,
            onMouseUp,
            onTouchStart: onPressDown,
            onTouchEnd,
        },
        menuContext,
        deleteDialog,
    };
};
