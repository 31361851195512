import * as React from 'react';
import { SVGProps } from 'react';
export const LaizeVerticalSelected = (props: SVGProps<SVGSVGElement>) => (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <path
                d="M11.838 39.679a2.044 2.044 0 0 1-2.042 2.041 2.044 2.044 0 0 1-2.042-2.041c0-1.126.916-2.042 2.042-2.042s2.042.916 2.042 2.042Z"
                fill="#13123A"
            />
            <path
                d="M9.796 31.357c4.588 0 8.321 3.733 8.321 8.322 0 4.588-3.733 8.321-8.321 8.321-4.589 0-8.321-3.733-8.321-8.321 0-4.589 3.732-8.322 8.32-8.322ZM4.942 39.68a4.86 4.86 0 0 0 4.854 4.853 4.86 4.86 0 0 0 4.854-4.853 4.86 4.86 0 0 0-4.854-4.854 4.86 4.86 0 0 0-4.854 4.854Z"
                fill="#13123A"
            />
            <path
                d="M38.822 1.94v26.605H9.796c-4.223 0-7.905 2.365-9.792 5.84V1.94A1.94 1.94 0 0 1 1.945 0h34.937a1.94 1.94 0 0 1 1.94 1.94Z"
                fill="#13123A"
            />
            <path
                d="M39.676 31.357c4.588 0 8.321 3.733 8.321 8.322 0 4.588-3.733 8.321-8.321 8.321H17.184a11.109 11.109 0 0 0 3.745-8.321c0-3.307-1.45-6.28-3.745-8.322h22.492Z"
                fill="#13123A"
            />
            <path
                d="m21.463 6.944 6.26 6.26a.942.942 0 0 1 .277.67.942.942 0 0 1-.277.671l-.568.568a.96.96 0 0 1-1.35 0l-3.66-3.651v11.602a.925.925 0 0 1-.932.936h-.804c-.523 0-.974-.413-.974-.936V11.42l-3.68 3.693a.935.935 0 0 1-1.332 0l-.567-.568a.94.94 0 0 1-.276-.671c0-.254.099-.492.277-.67l6.26-6.26a.942.942 0 0 1 .673-.277.94.94 0 0 1 .673.277Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M48 48V0H0v48z" />
            </clipPath>
        </defs>
    </svg>
);
