import * as React from 'react';
import { SVGProps } from 'react';
export const LaizeHorizontalSelected = (props: SVGProps<SVGSVGElement>) => (
    <svg width={49} height={48} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <path
                d="M8.821 36.163a2.044 2.044 0 0 0-2.042 2.041c0 1.126.916 2.042 2.042 2.042a2.044 2.044 0 0 0 2.042-2.042 2.044 2.044 0 0 0-2.042-2.041Z"
                fill="#13123A"
            />
            <path
                d="M17.143 38.204c0-4.588-3.733-8.32-8.322-8.32-4.588 0-8.321 3.732-8.321 8.32 0 4.589 3.733 8.322 8.321 8.322 4.589 0 8.322-3.733 8.322-8.322ZM8.82 43.058a4.86 4.86 0 0 1-4.854-4.853A4.86 4.86 0 0 1 8.82 33.35a4.86 4.86 0 0 1 4.854 4.855 4.86 4.86 0 0 1-4.854 4.853Z"
                fill="#13123A"
            />
            <path
                d="M46.56 9.178H19.955v29.026c0 4.224-2.364 7.906-5.839 9.792H46.56a1.94 1.94 0 0 0 1.94-1.94V11.118a1.94 1.94 0 0 0-1.94-1.94Z"
                fill="#13123A"
            />
            <path
                d="M17.143 8.324c0-4.588-3.733-8.32-8.322-8.32C4.233.003.5 3.735.5 8.323v22.493a11.109 11.109 0 0 1 8.321-3.746c3.307 0 6.28 1.45 8.322 3.746V8.324Z"
                fill="#13123A"
            />
            <path
                d="m41.556 26.537-6.26-6.26a.942.942 0 0 0-.67-.277.942.942 0 0 0-.671.277l-.568.568a.96.96 0 0 0 0 1.35l3.651 3.66H25.436a.926.926 0 0 0-.936.932v.804c0 .523.413.974.936.974H37.08l-3.693 3.68a.935.935 0 0 0 0 1.332l.568.567a.94.94 0 0 0 .671.276.942.942 0 0 0 .67-.277l6.26-6.26a.942.942 0 0 0 .277-.673.942.942 0 0 0-.277-.673Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="transparent" d="M.5 0h48v48H.5z" />
            </clipPath>
        </defs>
    </svg>
);
