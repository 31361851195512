import { styled } from '@material-ui/styles';
import React, { CSSProperties, useRef } from 'react';

import { GestureEvent } from '../../../../../Events/withEvent';
import { Message } from '../../../../../Locales/I18nService';
import { ButtonVariant, CustomButton } from '../../../../../Web/Components/Views/CustomButton/CustomButton';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useViewState } from '../../../../../Web/Hooks/ViewState/ViewState';
import { BaseDialog } from '../../../Widgets/BaseDialog';
import { DimensInput } from '../../../Widgets/DimensInput';

export type SizeLimit = { min: number; max: number };

export type EditWallCoteDialogProps = {
    getLimit?: () => SizeLimit;
    value?: string;
    onClose?: VoidFunction;
    onValidate: (dimens: number) => void;
};

type EditCoteState = { dimens?: number; dimensOutOfBounds?: boolean; limit?: SizeLimit };
export const EditCoteDialog = ({ getLimit, value = '', onValidate, onClose }: EditWallCoteDialogProps) => {
    const { state, update } = useViewState<EditCoteState>({ dimens: parseInt(value) });

    const onSubmit = (e?: GestureEvent) => {
        e?.preventDefault();
        const { dimens, limit } = state;
        const dimensOutOfBounds = !(dimens! >= limit?.min! && dimens! <= limit?.max!);
        update({ dimensOutOfBounds });
        if (!dimensOutOfBounds) {
            onValidate(dimens!);
        }
    };
    React.useEffect(() => {
        if (state.dimensOutOfBounds) {
            const { dimens, limit } = state;
            const dimensOutOfBounds = !(dimens! >= limit?.min! && dimens! <= limit?.max!);
            update({ dimensOutOfBounds });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.dimens]);

    React.useEffect(() => {
        update({ limit: getLimit?.() });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLimit]);

    const isMobile = useIsMobile();
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <BaseDialog
            title={<Message id="EditCoteDialog_Title" />}
            subtitle={<Message id="EditCoteDialog_DimensionPropertyLabel" />}
            onClose={onClose}>
            <Body style={{ color: state.dimensOutOfBounds ? '#FF3B30' : '#000000' }}>
                <Message
                    id="EditCoteDialog_DimensionPropertyDescription"
                    values={{ min: state.limit?.min, max: state.limit?.max }}
                />
            </Body>
            <Footer
                style={{ border: `1px solid ${state.dimensOutOfBounds ? '#FF3B30' : '#13123A'}` }}
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
                onSubmit={onSubmit}>
                <DimensInput
                    inputRef={inputRef}
                    value={state.dimens}
                    onChange={(event) => update({ dimens: parseInt(event.target.value) })}
                    style={DimensStyle}
                    onClick={() => inputRef.current?.focus()}
                    autoFocus={!isMobile}
                />
                <CustomButton
                    type="submit"
                    onClick={onSubmit}
                    disabled={!state.dimens}
                    variant={ButtonVariant.Blue}
                    title={<Message id="COMMON_VALIDATE" />}
                    styles={{ root: BtnStyle }}
                />
            </Footer>
        </BaseDialog>
    );
};

const Body = styled('div')({
    marginTop: 4,
    fontFamily: 'Avenir',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16.39px',
});

const Footer = styled('form')({
    marginTop: 17,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #13123A',
    height: 50,
});
const BtnStyle: CSSProperties = { width: 128, minHeight: 48 };
const DimensStyle: CSSProperties = { margin: 0, background: '#FFFFFF', flex: 1 };
