import { ApiRequest } from '../XHR/ApiRequest';
import { ApiUrls } from '../../Models/ApiUrls';
import { GetConfigurationResponse } from '../../Models/Projects/Configuration';

export class ConfigurationApi {

  public static getConfiguration(config_id: string): Promise<GetConfigurationResponse> {
    return ApiRequest.get<GetConfigurationResponse>(ApiUrls.GET_CONFIGURATION(config_id));
  }
}
