import * as React from 'react';
import { SVGProps } from 'react';
export const ClassiqueVerticalSelected = (props: SVGProps<SVGSVGElement>) => (
    <svg width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M45.615 45.6h-43.2V2.4h43.2v43.2Z" fill="#13123A" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.016 48h-48V0h48v48Zm-2.4-2.4V2.4h-43.2v43.2h43.2Z"
            fill="#13123A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.391 24.008V.022h-1.6V9.51H8.606V.022h-1.6v24.155h-7v1.5h7v22.345h1.6v-9.014h6.185v9.014h1.6V26.675h7.483v21.347h1.6v-9.014h6.184v9.014h1.6V26.675h6.186v21.347h1.6v-9.014h6.97v-1.5h-6.97V11.01h6.97v-1.5h-6.97V.022h-1.6v25.053h-6.186V.022h-1.6V9.51h-6.184V.022h-1.6v23.986h-7.483Zm9.083-12.998v26.498h6.184V11.01h-6.184ZM8.606 37.508V11.01h6.185v26.498H8.606Z"
            fill="#fff"
        />
        <path
            d="M24.216 14.175a2.438 2.438 0 0 1 1.734.716l6.26 6.26c.462.462.716 1.083.716 1.731 0 .65-.255 1.27-.716 1.731l-.568.569a2.46 2.46 0 0 1-3.47 0h-.002l-1.099-1.097v7.986a2.426 2.426 0 0 1-2.433 2.437h-.803c-1.274 0-2.474-1.01-2.474-2.437v-8.013l-1.118 1.122-.002.002a2.428 2.428 0 0 1-1.725.716c-.654 0-1.27-.258-1.728-.716l-.002-.002-.565-.567a2.44 2.44 0 0 1-.715-1.731c0-.65.256-1.27.716-1.732l1.061 1.061-1.06-1.06 6.259-6.26a2.442 2.442 0 0 1 1.734-.716Zm0 0h-.004l.004 1.5.004-1.5h-.004Z"
            fill="#fff"
            stroke="#13123A"
            strokeWidth={3}
        />
    </svg>
);
