export class IdGenerator {

    public static generate() {
        const length = 8;
        const timestamp = + new Date();

        const _getRandomInt = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        const ts = timestamp.toString();
        const parts = ts.split('').reverse();
        let id = '';

        for (let i = 0; i < length; ++i) {
            const index = _getRandomInt(0, parts.length - 1);
            id += parts[index];
        }
        return id;
    }
}
