import { PrixContextDebugMap } from '../../../Helpers/QuotationHelperV3';
import { QuestionItem } from '../../../Models/Questions/QuestionDto';
import { QuotationReplyBaseDto } from '../../../Models/Quotations/Quotations';
import { StoreDto } from '../../../Models/TravelExpenses/StoreDto';
import { VersionParametrageDto } from '../../../Models/VersionParametrages/VersionParametrage';
import { ContextQuestionList } from '../../../Plugins/FloorPlan/Models/IMapItem';

export enum LoadingStep {
    Auth = 'LoadingAuth',
    Version = 'LoadingVersion',
    Configuration = 'LoadingConfiguration',
    Map = 'LoadingMap',
    InitConfiguration = 'InitConfiguration',
}

export interface QuoteState {
    loadingStep: LoadingStep;
    versionId: string;

    versionData: VersionParametrageDto;

    questions: Array<QuestionItem>; //!\ questions filtred by seller
    questionProduitPrincipal: QuestionItem; //!\ use only when produit question from ref

    stepHabitation: ContextQuestionList;
    habitationQuestions: Array<QuestionItem>;
    habitationQuestionsOpen?: boolean; //* DIALOG

    projetStore?: StoreDto;

    loadingQuotation: boolean;
    quote: QuotationReplyBaseDto;
    quotePrixContextDebugMap: PrixContextDebugMap;
}
