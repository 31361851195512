import { QuestionItem } from '../../../../Models/Questions/QuestionDto';

export class HabitationHelper {

    public static isFinished = (questions: QuestionItem[] = []) => {
        return !questions.filter(q => q.showQuestion).some((q, index) => !q.isValid);
    };

    public static isLastQuestion = (question: QuestionItem, questions: QuestionItem[] = []) => {
        return questions[questions.length - 1].id === question.id;
    };
}