import * as _ from 'lodash';

import { IRoomItem } from '../../../Models/IRoomItem';
import { IdGenerator } from '../../../Utils/IdGenerator';
import { JstsPolygon, JstsUtils } from '../../../Utils/JstsUtils';
import { PointUtils } from '../../../Utils/PointUtils';
import { RoomDiffUtils } from '../../../Utils/Room/RoomDiffUtils';
import { AdjacentRooms, CoordPoint, RotationParams } from '../../../Utils/Types';
import { Direction } from '../Laize';
import { ILaizeOpening, ILaizeRoom, ILaizeWall, LaizeCalcProps } from '../LaizeCalc';
import { LaizeTrace } from './LaizeBandTrace';
import { IntersectionResult, LaizeBandList } from './LaizeBands';
import { LaizeLeftOverItem } from './LaizeLeftOver';
import { LaizeV3, RoomGroup } from './LaizeV3';

export type GetSurfaceOpeningsParams = {
    surfacePoly: JstsPolygon;
    openings: Array<ILaizeOpening>;
};

export type LaizeOperation = {
    surfaceHeight: number;
    roomId?: string;
    intersectionResult?: IntersectionResult;

    bands?: LaizeBandList;
    realBandHeight?: number;
    bandSurface?: number;

    surfaceArea?: number;
    effectiveBand?: Array<CoordPoint>;
    realBand?: Array<CoordPoint>;
    bandHeight?: number;
    surfaceCoords?: Array<CoordPoint>;
    raccordLength?: number;

    horizontalMargin?: number;
    verticalMargin?: number;
    isContiguous?: boolean;
};

export type CoverableSurface = {
    id: string;
    surfacePoly: JstsPolygon;
    originalSurfacePoly: JstsPolygon;
    laizeDirection: Direction;
    openings: Array<ILaizeOpening>;

    roomId: string;
    nextLaizeOperation?: LaizeOperation;

    rotationParams: RotationParams;
};
export type CoverNextSurfaceParams = {
    previousLaizeRoomId?: string;
    laizeCalcProps: LaizeCalcProps;
    surfacesToCover: Array<CoverableSurface>;
    leftOvers: Array<LaizeLeftOverItem>;
    walls: Array<ILaizeWall>;
    roomItems: Array<IRoomItem>;
    roomGroups: Array<RoomGroup>;
};

type GetRoomSurfaces = { room: ILaizeRoom; roomItems: Array<IRoomItem>; walls: Array<ILaizeWall> };
type FindNextSurfaceByDimensionsParams = { coverableSurfaces: Array<CoverableSurface> };
type ConvertToCoverableSurfacesParams = {
    laizeCalcProps: LaizeCalcProps;
    room: ILaizeRoom;
    roomItems: Array<IRoomItem>;
    walls: Array<ILaizeWall>;
    iteration?: number;
    adjacentRooms?: Array<AdjacentRooms>;
    freeOpeningIds: Set<string>;
    isInitialSurfaces: boolean;
    forceContiguousMargins?: boolean;
    hasSameFlooringDirection: boolean;
};

export class LaizeSurfaces {
    public static convertToCoverableSurfaces({
        laizeCalcProps,
        room,
        roomItems,
        walls,
        iteration,
        adjacentRooms,
        freeOpeningIds,
        isInitialSurfaces,
        forceContiguousMargins = false,
        hasSameFlooringDirection,
    }: ConvertToCoverableSurfacesParams) {
        const useRotation = room.rotationAngle;
        const roomCenter = PointUtils.bboxCenter(room.coords!);
        const rotationParams = { angle: room.rotationAngle || 0, center: roomCenter };
        const openingsBetweenRooms = adjacentRooms?.map((x) => x.opening) ?? [];
        const roomSurfaces = this.getRoomSurfaces({ room, roomItems, walls });
        const coverableSurfaces: Array<CoverableSurface> = [];

        for (let roomSurface of roomSurfaces) {
            // Get and rotate the surface's openings
            const openings = this.getSurfaceOpenings({ surfacePoly: roomSurface, openings: openingsBetweenRooms });
            const surfaceOpenings = _.cloneDeep(openings);
            surfaceOpenings.forEach((opening: ILaizeOpening) => {
                const points: Array<CoordPoint> = [{ x: opening.x!, y: opening.y! }, ...opening.limit!];
                PointUtils.rotation(points, rotationParams.angle, rotationParams.center);
                opening.x = points[0].x;
                opening.y = points[0].y;
            });

            //* Rotate the surface
            const coords = roomSurface.getCoordinates();
            PointUtils.rotateCyclicCoords(coords, rotationParams);
            const rotatedSurfacePoly = JstsUtils.createJstsPolygon(coords);

            //* Potential bands
            const possibleLaizeOperations: Array<LaizeOperation> = [];
            const addLaizeOperation = (direction: Direction) => {
                const operation = LaizeV3.prepareLaizeOperation({
                    laizeCalcProps,
                    surfacePoly: rotatedSurfacePoly,
                    direction,
                    roomId: room.roomId!,
                    surfaceOpenings,
                    freeOpeningIds,
                    forceContiguousMargins,
                    hasSameFlooringDirection,
                });
                if (operation) {
                    const newSurface = {
                        id: IdGenerator.generate(),
                        roomId: room.roomId!,
                        surfacePoly: rotatedSurfacePoly,
                        originalSurfacePoly: rotatedSurfacePoly,
                        nextLaizeOperation: operation,
                        rotationParams: rotationParams,
                        laizeDirection: direction,
                        openings: LaizeSurfaces.getSurfaceOpenings({
                            surfacePoly: JstsUtils.createJstsPolygon(operation.surfaceCoords, true),
                            openings: surfaceOpenings,
                        }),
                    };
                    coverableSurfaces.push(newSurface);
                    possibleLaizeOperations.push(operation);
                }
            };

            addLaizeOperation(Direction.LeftToRight);
            addLaizeOperation(Direction.RightToLeft);

            //* Only draw left & right potential laize ops if iteration = 0
            if (iteration === 0) {
                const possibleLaizeOperationsToDraw = _.cloneDeep(possibleLaizeOperations);
                if (useRotation) {
                    possibleLaizeOperationsToDraw.forEach((operation) => {
                        PointUtils.rotation(operation.realBand!, -rotationParams.angle, rotationParams.center);
                        PointUtils.rotation(operation.effectiveBand!, -rotationParams.angle, rotationParams.center);
                        PointUtils.rotation(operation.surfaceCoords!, -rotationParams.angle, rotationParams.center);
                        LaizeTrace.displayPotentialOperations(operation, room.rotationAngle);
                    });
                } else {
                    possibleLaizeOperationsToDraw.forEach((operation) => {
                        LaizeTrace.displayPotentialOperations(operation);
                    });
                }
            }
        }
        return coverableSurfaces.filter((x) => (isInitialSurfaces ? true : x.nextLaizeOperation?.isContiguous));
    }

    /**
     * Input: list of coverable surfaces and their pre-calculated next laize operation (next band to lay).
     * Output: the surface with the longest next band, or if there are multiple bands at that length,
     * pick the one with the largest area.
     */
    public static findNextSurfaceByDimensions({
        coverableSurfaces,
    }: FindNextSurfaceByDimensionsParams): CoverableSurface | undefined {
        const surfaceWithHighestBand = coverableSurfaces.reduce((previousPotentialSurface, potentialSurface) => {
            const potentialBand = potentialSurface.nextLaizeOperation;
            const previousPotentialBand = previousPotentialSurface?.nextLaizeOperation;

            if (!potentialBand) return previousPotentialSurface;
            if (!previousPotentialBand) return potentialSurface;

            const potentialBandHeight = potentialBand.bandHeight ?? 0;
            const previousPotentialBandHeight = previousPotentialBand.bandHeight ?? 0;

            //* Take the surface with the highest band
            if (potentialBandHeight > previousPotentialBandHeight) {
                return potentialSurface;
            } else if (potentialBandHeight < previousPotentialBandHeight) {
                return previousPotentialSurface;
            }
            //* Else take the surface with the highest area
            else {
                const potentialBandSurface = potentialBand.surfaceArea ?? 0;
                const previousPotentialBandSurface = previousPotentialBand.surfaceArea ?? 0;
                return potentialBandSurface > previousPotentialBandSurface
                    ? potentialSurface
                    : previousPotentialSurface;
            }
        }, undefined as CoverableSurface | undefined);
        return surfaceWithHighestBand;
    }

    /**
     * Obtain list of jsts polygons from a room by excluding the non-layable room items.
     * The list will mostly contain a single element, but excluding room items can result in multiple polygons
     * (for example when a room item 'cuts' the room in half).
     */
    public static getRoomSurfaces({ room, roomItems, walls }: GetRoomSurfaces) {
        let roomWithoutRoomItem = RoomDiffUtils.roomPolygonExcludingRoomItem(
            room,
            roomItems,
            walls
        ).roomShellDifference;

        let polygons: Array<JstsPolygon> = [];
        const numSurfaces = roomWithoutRoomItem.getNumGeometries();
        for (let i = 0; i < numSurfaces; i++) {
            const surface = roomWithoutRoomItem.getGeometryN(i);
            if (surface.getArea() !== 0) {
                polygons.push(surface as never as JstsPolygon);
            }
        }
        return polygons;
    }

    /**
     * Gets the list of openings that are geometrically inside the given surface.
     */
    public static getSurfaceOpenings({ surfacePoly, openings }: GetSurfaceOpeningsParams) {
        const surfaceOpenings = openings.filter((op) => {
            return PointUtils.testPointWithinPolygon(JstsUtils.toJstsCoordPoint(op.x!, op.y!), surfacePoly);
        });
        return surfaceOpenings;
    }

    public static getNextSurfaceDirection({
        previousSurface,
        nextSurface,
    }: {
        previousSurface: JstsPolygon;
        nextSurface: JstsPolygon;
    }): Direction {
        const previousSurfaceCenter = PointUtils.meanPoint(previousSurface.getCoordinates());
        const nextSurfaceCenter = PointUtils.meanPoint(nextSurface.getCoordinates());

        return previousSurfaceCenter.x < nextSurfaceCenter.x ? Direction.LeftToRight : Direction.RightToLeft;
    }
}
