import * as React from 'react';
import { FlooringDirectionProps } from './FlooringDirectionAssets';
export const ClassiqueHorizontal = ({ props }: { props: FlooringDirectionProps }) => {
    const { fillColor = '#13123A', svgProps } = props;
    return (
        <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 48V0h48v48H0Zm2.4-2.4h43.2V2.4H2.4v43.2Z"
                fill={fillColor}
            />
            <path
                d="m32.056 25.264-6.26 6.26a.941.941 0 0 1-.67.276.942.942 0 0 1-.671-.277l-.568-.568a.96.96 0 0 1 0-1.35l3.651-3.66H15.936a.926.926 0 0 1-.936-.932v-.804c0-.523.413-.974.936-.974H27.58l-3.693-3.68a.928.928 0 0 1-.277-.665.93.93 0 0 1 .277-.667l.568-.567a.94.94 0 0 1 .671-.276c.254 0 .492.099.67.277l6.26 6.26c.18.18.278.418.277.673a.942.942 0 0 1-.277.674Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47 26h-9.394v4.8H46.2v2.4H28.362l2.4-2.4h4.388v-5.017c.121-.383.183-.785.183-1.192v.007h-.183v-.014h.183v.007c0-.337-.042-.67-.125-.99H47V26Zm-17.018-8.4H46.2v-2.4h-8.594v-4.8H46.2V8H24.1V2h-2.455v6H2v2.4h7.367v4.8H2v2.4h18.826c.194-.56.513-1.073.94-1.499l.568-.566.003-.004c.12-.119.245-.23.377-.33H11.823v-4.8H35.15v4.8h-7.614c.134.102.261.214.382.335l2.064 2.064Zm-4.247 0a.941.941 0 0 0-.609-.22.942.942 0 0 0-.61.22h1.219Zm-1.635.41-.213.213a.935.935 0 0 0-.277.667c0 .254.098.486.277.665l.213.213V18.01Zm0 5.225h-2.455V24.8H24.1v-1.565Zm-11.976 2.766h-.302v4.8h8.821c.112.853.5 1.654 1.122 2.275l.001.001.568.568a3.939 3.939 0 0 0 1.766 1.02V38h22.1v2.4h-8.594v6H35.15v-6H11.822v6H9.367v-6H2V38h19.645v-4.8H2v-2.4h7.367V26H2v-2.4h10.048a3.88 3.88 0 0 0-.048.61v.803c0 .342.043.673.124.988Zm11.637 4.8h2.759l-.723.722a.942.942 0 0 1-.671.277.942.942 0 0 1-.671-.277l-.568-.568a.94.94 0 0 1-.126-.155Z"
                fill={fillColor}
            />
        </svg>
    );
};
