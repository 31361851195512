import { Constants } from '../../Constants';
import { Logger } from '../../Errors/Logger';
import {
    SaveProjectRequestMessage,
    StmacSaveProjectMessage,
} from '../../Models/StmacPostMessages/StmacSaveProjectMessage';
import { LogLevelType } from '../../Services/Analytics/LogLevelType';

export class StmacPostMessageService {
    public static postMessage = (messageData: SaveProjectRequestMessage) => {
        const frameContainer = StmacPostMessageService.getFrameContainer();
        Logger.debug('POL send message : ', { messageData, frameContainer });
        if (frameContainer.contentWindow) {
            frameContainer.contentWindow.postMessage(messageData, Constants.STMAC_URL);
            const data = StmacSaveProjectMessage.toDataToLog(messageData);
            Logger.logAnalytics({
                level: LogLevelType.Information,
                message: "POL: PostMessage d'enregistrement projet",
                data: data,
                body: JSON.stringify(data),
            });
        }
    };

    private static getFrameContainer = (): HTMLIFrameElement => {
        const iframe: HTMLIFrameElement = document.getElementById(Constants.STMAC_FRAME_ID) as HTMLIFrameElement;
        return iframe;
    };
}
